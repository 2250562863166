import { FC, ReactNode } from "react";

import { Column, SectionHeading, Box, Text } from "@hightouchio/ui";

export interface PageAlertProps {
  title: string;
  description: string;
  button?: ReactNode;
  link?: ReactNode;
  bg?: string;
}

export const PageAlert: FC<Readonly<PageAlertProps>> = ({ title, description, button, link, bg = "avocado.100" }) => {
  return (
    <Column align="flex-start" bg={bg} gap={4} px={6} py={6}>
      <SectionHeading>{title}</SectionHeading>
      <Box maxW="64ch">
        <Text>{description}</Text>
      </Box>
      {button}
      {link}
    </Column>
  );
};
