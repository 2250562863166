import moment from "moment";

import { Maybe } from "src/graphql";

export enum ScheduleType {
  MANUAL = "manual",
  INTERVAL = "interval",
  CRON = "cron",
  CUSTOM = "visual_cron",
  DBT_CLOUD = "dbt",
  FIVETRAN = "fivetran",
}

export function scheduleTypeToLabel(scheduleType: ScheduleType | null) {
  switch (scheduleType) {
    case ScheduleType.MANUAL || null:
      return "Manual";
    case ScheduleType.INTERVAL:
      return "Interval";
    case ScheduleType.CRON:
      return "Cron";
    case ScheduleType.CUSTOM:
      return "Custom";
    case ScheduleType.DBT_CLOUD:
      return "dbt Cloud";
    case ScheduleType.FIVETRAN:
      return "Fivetran";
    default:
      return "Unknown";
  }
}

interface BaseSchedule {
  type: ScheduleType;
  startDate?: string | null;
  endDate?: string | null;
}

export interface ManualSchedule extends BaseSchedule {
  type: ScheduleType.MANUAL;
}

export interface IntervalSchedule extends BaseSchedule {
  type: ScheduleType.INTERVAL;
  schedule?: {
    interval: ScheduleInterval;
  };
}
export enum ScheduleIntervalUnit {
  MINUTE = "minute",
  HOUR = "hour",
  DAY = "day",
  WEEK = "week",
}

export interface CronSchedule extends BaseSchedule {
  type: ScheduleType.CRON;
  schedule?: {
    expression?: string;
  };
}
export const defaultTime = moment("12:00", "HH:mm").utc().format("HH:mm");

export interface VisualCronSchedule extends BaseSchedule {
  type: ScheduleType.CUSTOM;
  schedule?: {
    expressions?: ScheduleExpression[];
  };
}

export interface DbtCloudSchedule extends BaseSchedule {
  type: ScheduleType.DBT_CLOUD;
  schedule?: {
    account?: {
      id: string;
      name: string;
    };
    job?: {
      id: string;
      name: string;
    } | null;
  };
}

export type FivetranScheduleTrigger = {
  id: string;
  type: string;
  name: string;
  fivetran_id: Maybe<string>;
};

export interface FivetranSchedule extends BaseSchedule {
  type: ScheduleType.FIVETRAN;
  schedule?: {
    groupId: string;
    groupName: string;
    triggers: FivetranScheduleTrigger[];
  };
}

export type Schedule =
  | ManualSchedule
  | IntervalSchedule
  | CronSchedule
  | VisualCronSchedule
  | DbtCloudSchedule
  | FivetranSchedule;

export interface ScheduleInterval {
  quantity?: number | null;
  unit?: ScheduleIntervalUnit;
}

export interface ScheduleExpression {
  time: string;
  days?: Record<string, boolean | undefined>;
}
