/* eslint-disable prettier/prettier */
import { Suspense, useEffect } from "react";

import { ThemeProvider as HightouchUiThemeProvider } from "@hightouchio/ui";
import * as Sentry from "@sentry/react";
import { createRoot } from "react-dom/client";
import Helmet from "react-helmet";
import { useLocation, useNavigationType, createRoutesFromChildren, matchRoutes, Routes } from "react-router-dom";

import { App } from "src/components/app";
import { FeatureFlagProvider } from "src/contexts/feature-flags";
import { UserProvider } from "src/contexts/user-context";
import { PageSpinner } from "src/ui/loading";
import { QueryProvider } from "src/utils/query-client";

import "react-ace";
import "ace-builds/src-min-noconflict/ext-searchbox";
import "ace-builds/src-noconflict/mode-sql";
import "ace-builds/src-noconflict/theme-sqlserver";
import "ace-builds/src-min-noconflict/ext-language_tools";

import { ErrorBoundary } from "./components/error-boundary";
import { ThemeProvider } from "./theme";

Sentry.init({
  enabled: import.meta.env.PROD && !import.meta.env.PREVIEW,
  dsn: "https://b61c707a79854bc2902b1392410fa6ae@o1224454.ingest.sentry.io/6505512",
  integrations: [
    new Sentry.BrowserTracing({
      routingInstrumentation: Sentry.reactRouterV6Instrumentation(
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      ),
    }),
  ],
  tracesSampleRate: 1,
});

window.Hightouch = window.Hightouch || {};

export const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

const root = createRoot(document.getElementById("react")!);

root.render(
  <>
    <Helmet titleTemplate="%s - Hightouch" />
    <QueryProvider>
      <ThemeProvider>
        <HightouchUiThemeProvider>
          <ErrorBoundary>
            <Suspense fallback={<PageSpinner />}>
              <UserProvider>
                <FeatureFlagProvider>
                  <App />
                </FeatureFlagProvider>
              </UserProvider>
            </Suspense>
          </ErrorBoundary>
        </HightouchUiThemeProvider>
      </ThemeProvider>
    </QueryProvider>
  </>,
);
