import { FC, ReactNode, useEffect, useState } from "react";

import {
  Box,
  Button,
  Column,
  EmptyState,
  Link,
  LinkButton,
  Paragraph,
  Row,
  SectionHeading,
  Select,
  Spinner,
  Switch,
  TagInput,
  Text,
  Tooltip,
  WarningIcon,
  useToast,
} from "@hightouchio/ui";
import * as Sentry from "@sentry/browser";
import { useFlags } from "launchdarkly-react-client-sdk";
import { isPresent } from "ts-extras";

import goalsPlaceholder from "src/assets/placeholders/goals.svg";
import { useUser } from "src/contexts/user-context";
import {
  useDisableAudienceGoalsForAudienceMutation,
  useEnableAudienceGoalsForAudienceMutation,
  useGoalMetricsQuery,
} from "src/graphql";
import { NonNullableAudience, OperatorsWithoutValue } from "src/types/visual";

import { ActionBar } from "../action-bar";
import { formatValue, getOperatorLabel, getPropertyNameFromProperty } from "../explore/visual/utils";
import { graphColors } from "./colors";
import { ParameterizedMetricsModal } from "./parameterized-audience-conditions/modal";
import {
  ParameterizedModel,
  allParameterizedConditionsHaveValue,
  getParameterizedModels,
  getParameterizedModelsWithSubstitutions,
} from "./parameterized-audience-conditions/utils";
import { PerformanceGraph } from "./performance-graph";
import { useTimestampFilter } from "./use-timestamp-filter";
import { TimeOptions, timeOptions } from "./utils";

type PerformanceProps = {
  audience: NonNullableAudience;
  metrics: { name: string; id: string }[];
};

export const Performance: FC<PerformanceProps> = ({ metrics, audience }) => {
  const [selectedMetrics, setSelectedMetrics] = useState<string[]>([]);
  const { toast } = useToast();
  const { schemaV2 } = useFlags();
  const { workspace } = useUser();
  const audienceSnapshottingEnabled = workspace?.audience_snapshotting_enabled;
  const sourceHasInWarehousePlanning = audience.parent?.connection?.plan_in_warehouse;
  const canEnablePerformance = audienceSnapshottingEnabled && sourceHasInWarehousePlanning;

  const [performanceEnabled, setPerformanceEnabled] = useState<boolean>(false);
  const [showMetricParametersModal, setShowMetricParametersModal] = useState<boolean>(false);

  const { before, since, timeValue, setTimeValue } = useTimestampFilter();

  useEffect(() => {
    setSelectedMetrics(metrics.map(({ id }) => id));
  }, [metrics]);

  const metricOptions = metrics.filter(Boolean).map(({ name, id }) => ({ label: name, value: id }));

  // these metrics refer to the metrics that have been enabled on this audience via `audience_goals`
  const noMetrics = metrics.length === 0;
  const metricsSelected = selectedMetrics.length > 0;

  useEffect(() => {
    setPerformanceEnabled(!noMetrics);
  }, [noMetrics]);

  const parent = audience?.parent;
  const goals = parent?.goals;
  const hasGoalsSetup = Boolean(goals?.length);

  const parentParameterizedModels: ParameterizedModel[] = getParameterizedModels(goals, parent?.relationships);
  const audienceParameterizedModels: ParameterizedModel[] = audience.parameterized_conditions.map(
    ({ filtered_model, conditions }) => ({
      id: filtered_model!.id,
      name: filtered_model!.name,
      propertyConditions: conditions,
      goalIds: [],
    }),
  );

  const parameterizedModels = getParameterizedModelsWithSubstitutions(parentParameterizedModels, audienceParameterizedModels);

  const { mutateAsync: enableGoals, isLoading: enableLoading } = useEnableAudienceGoalsForAudienceMutation();
  const { mutateAsync: disableGoals, isLoading: disableLoading } = useDisableAudienceGoalsForAudienceMutation();

  const toggleLoading = enableLoading || disableLoading;

  const togglePerformance = async (enabled: boolean): Promise<void> => {
    setPerformanceEnabled(enabled);
    try {
      if (enabled) {
        await enableGoals({ objects: goals?.map((g) => ({ goal_id: g.id, segment_id: audience?.id, enabled: true })) ?? [] });

        // When performance becomes enabled, check that all the parameterized conditions from the goals have values.
        // If not, open a modal to prompt the user to set values for all conditions.
        const doAllParameterizedConditionsHaveValue = allParameterizedConditionsHaveValue(
          audience?.parent?.goals,
          audience?.audience_goals?.map(({ goal }) => goal),
          audience?.parameterized_conditions,
        );

        if (!doAllParameterizedConditionsHaveValue) {
          setShowMetricParametersModal(true);
        }
      } else {
        await disableGoals({ segment_id: audience?.id });
      }

      toast({
        id: "enable-metrics-toast",
        title: `Successfully ${enabled ? "enabled" : "disabled"} metrics.`,
        message: `Performance tracking has been ${enabled ? "enabled" : "disabled"} for this audience.`,
        variant: "success",
      });
    } catch (error) {
      setPerformanceEnabled(!enabled);
      Sentry.captureException(error);
      toast({
        id: "enable-metrics-toast",
        title: `Error ${enabled ? "enabling" : "disabling"} metrics`,
        message: "Enabling metrics for this audience failed.",
        variant: "error",
      });
    }
  };

  const removeMetric = (metric: string) => {
    setSelectedMetrics((prevMetrics) => prevMetrics.filter((currentMetric) => currentMetric !== metric));
  };

  const { data: metricData, isFetching: metricFetching } = useGoalMetricsQuery(
    { filter: { id: { _in: selectedMetrics } }, audienceIds: [audience?.id ?? ""], since, before },
    {
      enabled: Boolean(selectedMetrics.length) && isPresent(audience?.id),
      select: (data) => data.goals,
      keepPreviousData: true,
    },
  );

  const showEmptyStateWithWarning = !hasGoalsSetup || !canEnablePerformance;

  if (showEmptyStateWithWarning) {
    let message: ReactNode;

    if (!audienceSnapshottingEnabled) {
      message = (
        <>
          Performance data requires snapshots of this audience over time. Please enable audience snapshotting in your{" "}
          <Link href="/settings/workspace">Workspace settings</Link>.
        </>
      );
    } else if (!sourceHasInWarehousePlanning) {
      message = (
        <>
          Performance tracking requires using the{" "}
          <Link href={`${import.meta.env.VITE_DOCS_URL}/syncs/warehouse-sync-logs/#get-the-most-common-sync-error`}>
            Lightning sync engine
          </Link>
          . Please go to the source configuration for{" "}
          <Link href={`/sources/${audience.connection?.id}`}>{audience.connection?.name}</Link> to turn it on.
        </>
      );
    } else if (!hasGoalsSetup) {
      message = (
        <>
          Performance data requires metrics to be defined on the parent model. To view this audience’s performance, please add a
          metric to the {parent?.name} parent model first.
        </>
      );
    }

    return (
      <EmptyState
        imageUrl={goalsPlaceholder}
        title="No performance data available"
        message={message}
        actions={
          <LinkButton
            isDisabled={!canEnablePerformance}
            href={schemaV2 ? "/schema-v2/settings/metrics" : "/schema/metrics/new"}
            variant="primary"
          >
            Add metric
          </LinkButton>
        }
      />
    );
  }

  return (
    <>
      <Column flex={1} gap={2} mb={16} minHeight={0} width="100%">
        <Column gap={2} maxWidth="640px">
          <Row align="center" gap={4}>
            <SectionHeading>Enable performance tracking</SectionHeading>
            <Switch isChecked={performanceEnabled} onChange={togglePerformance} />
          </Row>
          <Paragraph color="text.secondary">
            View performance of this audience and its split groups against{" "}
            <Link href={schemaV2 ? "/schema-v2/settings/metrics" : "/schema/metrics"}>metrics defined in your schema</Link>.
          </Paragraph>

          {performanceEnabled && Boolean(parameterizedModels.length) && (
            <Box mt={6} mb={11}>
              <Row align="center" gap={2}>
                <SectionHeading>Metric parameters</SectionHeading>
                <Button size="sm" onClick={() => setShowMetricParametersModal(true)}>
                  Edit
                </Button>
              </Row>
              <Column color="text.secondary" mt={2}>
                <Text color="text.secondary">
                  Metric parameters filter performance data to ensure only relevant events are counted for this audience.
                </Text>

                {parameterizedModels.map(({ id, name, propertyConditions }) => (
                  <Column key={id} mt={4}>
                    <Text color="text.secondary" fontWeight="semibold" textTransform="uppercase" mb={2}>
                      "{name}" parameters
                    </Text>
                    {propertyConditions.map((propertyCondition, index) => (
                      <Row key={index} gap={1}>
                        <Text fontWeight="medium">{getPropertyNameFromProperty(propertyCondition.property)}</Text>
                        {getOperatorLabel(propertyCondition.operator, propertyCondition.propertyType)}{" "}
                        {formatValue(propertyCondition, propertyCondition.value)}
                        {propertyCondition.value == null && !OperatorsWithoutValue.includes(propertyCondition.operator) && (
                          <Tooltip message="Please set a value for this condition or your metrics may not be computed correctly.">
                            <WarningIcon color="text.danger" />
                          </Tooltip>
                        )}
                      </Row>
                    ))}
                  </Column>
                ))}
              </Column>
            </Box>
          )}
        </Column>

        {performanceEnabled && (
          <Column flex={1} minHeight={0} gap={4}>
            {toggleLoading && <Spinner size="lg" m="auto" />}
            {noMetrics && !toggleLoading && (
              // this state should not occur right now, because when there are no metrics, the entire performance section is hidden
              <EmptyState
                imageUrl={goalsPlaceholder}
                title="No performance metrics enabled for this audience"
                message="Performance data requires metrics to be enabled for this audience."
              />
            )}

            {!noMetrics && !toggleLoading && (
              <Row justify="space-between" mt={6}>
                <Text fontWeight="medium" size="lg">
                  Audience performance
                </Text>
                <Row gap={2}>
                  <TagInput
                    width="md"
                    options={metricOptions}
                    value={selectedMetrics}
                    onChange={setSelectedMetrics}
                    placeholder="Select a metric..."
                  />
                  <Select
                    isLoading={metricFetching}
                    options={timeOptions}
                    width="3xs"
                    value={timeValue}
                    onChange={(value) => setTimeValue(value ?? TimeOptions.OneWeek)}
                  />
                </Row>
              </Row>
            )}

            {!noMetrics && !metricsSelected && (
              <EmptyState title="No metrics selected" message="Select a metric above to view audience performance" />
            )}

            {selectedMetrics.map((id) => {
              const metric = metricData?.find((m) => m.id === id);

              return (
                <PerformanceGraph key={id} audience={audience} metric={metric ?? null} onRemove={() => removeMetric(id)} />
              );
            })}

            {!noMetrics && metricsSelected && Boolean(audience?.splits.length) && (
              <ActionBar>
                <Row as="ul" gap={2} listStyleType="none">
                  {audience?.splits.map(({ friendly_name }, index) => (
                    <Box
                      key={index}
                      as="li"
                      bg={graphColors[index % graphColors.length]!.bg}
                      borderRadius="36px"
                      mt={1}
                      px={2}
                      width="fit-content"
                    >
                      <Text color={graphColors[index % graphColors.length]!.color}>{friendly_name}</Text>
                    </Box>
                  ))}
                </Row>
              </ActionBar>
            )}
          </Column>
        )}
      </Column>
      {showMetricParametersModal && (
        <ParameterizedMetricsModal
          audience={audience}
          parameterizedModels={parameterizedModels}
          onClose={() => setShowMetricParametersModal(false)}
        />
      )}
    </>
  );
};
