import { FC } from "react";

import { Alert, Link, LinkButton, Column, Paragraph, Box } from "@hightouchio/ui";

import { useEntitlements } from "src/hooks/use-entitlement";
import { newIntercomMessage } from "src/lib/intercom";

export const ChatLink: FC = () => (
  <Box
    display="inline-block"
    onClick={() => {
      newIntercomMessage(`Hi, can you help me with the duplicate primary key error I'm seeing in my sync?`);
    }}
  >
    <Link href="">available via chat</Link>
  </Box>
);

export const DocsLink: FC = () => (
  <Link href="http://hightouch-21018508.hs-sites.com/duplicate-records-detected-in-your-hightouch-model">Click here</Link>
);

export type Props = {
  inWarehouseDiff: boolean;
  count: number;
  primaryKey: string;
  parentModelUrl: string;
};
export const DuplicateKeyWarning: FC<Readonly<Props>> = ({ count, primaryKey, parentModelUrl, inWarehouseDiff = false }) => {
  const { data: entitlementsData } = useEntitlements(true);
  const isFreePlan = !entitlementsData?.organization?.plan?.name;
  const CUTOFF_DATE = isFreePlan ? "August 21, 2023" : "August 28, 2023";

  if (inWarehouseDiff) {
    return (
      <Alert
        type="error"
        title={`Hightouch aborted the most recent sync run because ${count} duplicate row${
          count > 1 ? "s" : ""
        } were detected in your model.`}
        message={
          <Column gap={2}>
            <Paragraph>
              Please edit your model to ensure that its primary key column ({primaryKey}) does not contain any repeated values.
              The primary key must serve as a unique identifier for each row.
            </Paragraph>
            <Paragraph>
              This behavior is changing soon. Starting {CUTOFF_DATE}, Hightouch will no longer abort syncs when duplicate rows
              are detected. Instead, Hightouch will automatically filter out rows containing non-unique primary keys. All other
              rows will be processed normally. To keep you informed when this happens, an error will be logged for each
              non-unique row. <DocsLink /> to learn more about this upcoming change. Our support team is <ChatLink /> if you
              have questions.
            </Paragraph>
          </Column>
        }
        actions={<LinkButton href={parentModelUrl}>Edit model</LinkButton>}
      />
    );
  }
  return (
    <Alert
      type="warning"
      title={`Hightouch detected ${count} duplicate row${count > 1 ? "s" : ""} during the most recent sync run.`}
      message={
        <Column gap={2}>
          <Paragraph>
            Please edit your model to ensure that its primary key column ({primaryKey}) does not contain any repeated values.
            The primary key must serve as a unique identifier for each row.
          </Paragraph>
          <Paragraph>
            In the coming weeks, we're introducing additional safeguards to prevent duplicate records from getting synced to
            your destination. Starting {CUTOFF_DATE}, Hightouch will automatically filter out all rows containing non-unique
            primary keys. To keep you informed when this happens, an error will be logged for each affected row. <DocsLink /> to
            learn more about this upcoming change. Our support team is <ChatLink /> if you have questions.
          </Paragraph>
        </Column>
      }
      actions={<LinkButton href={parentModelUrl}>Edit model</LinkButton>}
    />
  );
};
