import moment from "moment";
import { format as formatNumber } from "numerable";

import { GoalMetricsQuery } from "src/graphql";

import { graphColors } from "../../performance/colors";
import { Graph, GraphSeries } from "./types";

export const transformMetricDataForGraph = ({
  metrics,
  normalized = false,
}: {
  metrics: GoalMetricsQuery | undefined;
  normalized: boolean;
}) => {
  const graph: Graph = { series: [], dates: [], normalized };

  if (!metrics) return graph;

  // We need the min and max dates of the X-axis to create the "domain" of the graph
  let minDate;
  let maxDate;

  // Use a different color for each series
  let colorIndex = 0;

  metrics.goals.forEach(({ audience_goals, goal_metrics }) => {
    // Keep track of all audience-split pairs
    // The key is just the audienceId-split id as a string
    const seriesNameByKey: Record<string, string> = {};
    audience_goals.forEach(({ segment }) => {
      const { id: audienceId, name: audienceName } = segment;
      seriesNameByKey[getSeriesKey(audienceId)] = getSeriesName(audienceName);
      segment.splits.forEach((split) => {
        seriesNameByKey[getSeriesKey(audienceId, split.id)] = getSeriesName(audienceName, split.friendly_name);
      });
    });

    const series = {};
    goal_metrics.forEach((metric) => {
      const { metric_value, segment_id: audienceId, split_id, audience_contribution_size, calculated_at } = metric;
      const seriesKey = getSeriesKey(audienceId, split_id);
      const seriesName = seriesNameByKey[seriesKey];

      const datum: GraphSeries["data"][0] = {
        calculatedAt: formatDate(calculated_at),
        metricValue: normalized ? metric_value / (audience_contribution_size / 100) : metric_value,
        seriesKey,
      };

      if (series[seriesKey]) {
        series[seriesKey].data.push(datum);
      } else {
        series[seriesKey] = {
          key: seriesKey,
          name: seriesName,
          color: graphColors[colorIndex % graphColors.length]!.color,
          data: [datum],
        };
        colorIndex++;
      }

      const date = new Date(calculated_at);
      minDate = minDate ? (minDate < date ? minDate : date) : date;
      maxDate = maxDate ? (maxDate > date ? maxDate : date) : date;
    });

    Object.keys(series).forEach((seriesKey) => {
      graph.series.push(series[seriesKey]);
    });
  });

  // If there are multiple series on the graph, there's a chance that they don't have overlapping dates.
  // Therefore, we need to plot out all the possible dates otherwise the graph will will be unordered.
  graph.dates = getDatesInRange(minDate, maxDate).map((date) => ({ calculatedAt: date }));

  return graph;
};

export const valueFormatter = (value: number): string => formatNumber(value, "0,0[.]0a");

const getSeriesKey = (audienceId: number, splitId?: string | null): string => {
  return splitId ? `${audienceId}:${splitId}` : audienceId.toString();
};

const getSeriesName = (audienceName: string, splitName?: string): string => {
  return splitName ? `${audienceName} / ${splitName}` : audienceName;
};

const getDatesInRange = (startDate: Date, endDate: Date) => {
  const dates: string[] = [];

  if (!startDate || !endDate) return dates;

  const date = startDate;
  while (date <= endDate) {
    dates.push(formatDate(date));
    date.setDate(date.getDate() + 1);
  }

  return dates;
};

const formatDate = (date: Date | string) => moment(date).format("YYYY-MM-DD");
