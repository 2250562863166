import { FC, ReactNode } from "react";

import { BoxProps, Column, Row, ChakraStyledOptions } from "@hightouchio/ui";

export type CardProps = BoxProps & {
  bg?: BoxProps["bg"];
  children: ReactNode;
  footer?: ReactNode;
  // temporary
  sx?: ChakraStyledOptions;
};

const defaultPadding = 6;

export const Card: FC<Readonly<CardProps>> = ({ children, footer, onClick, ...props }) => {
  return (
    <Column
      _hover={{
        borderColor: onClick ? "gray.400" : undefined,
      }}
      bg="white"
      border="1px solid"
      borderColor="base.border"
      borderRadius="md"
      cursor={onClick ? "pointer" : "auto"}
      transition="border-color 200ms"
      onClick={onClick}
      {...props}
    >
      <Column p={defaultPadding}>{children}</Column>

      {footer && (
        <Row sx={{ borderTop: "1px solid", borderColor: "base.border", py: 4, px: props.p || props.px || defaultPadding }}>
          {footer}
        </Row>
      )}
    </Column>
  );
};
