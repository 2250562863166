import { FC } from "react";

import { Box, Row, Text } from "@hightouchio/ui";

export type LegendProps = {
  payload?: {
    key: string;
    name: string;
    color: string;
  }[];
};

const Legend: FC<Readonly<LegendProps>> = ({ payload }) => {
  return (
    <Row justifyContent="center" gap={8} py={5} px="60px">
      {payload?.map(({ key, name, color }) => (
        <Row key={key} gap="8px" alignItems="center">
          <Box bg={color} borderRadius="base" h={4} w={4} flexGrow={1} flexShrink={0} />
          <Text color="text.secondary">{name}</Text>
        </Row>
      ))}
    </Row>
  );
};

export default Legend;
