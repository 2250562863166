import { ComponentProps, FC, ReactNode } from "react";

import { Box, Tooltip, Link, Text, Button } from "@hightouchio/ui";
import { useLocation } from "react-router-dom";
import { noop } from "ts-essentials";

import { useUser } from "src/contexts/user-context";
import { ResourcePermission } from "src/hooks/use-has-permission";

import { NAV_HEIGHT_STYLES } from "./constants";

export const NavButton: FC<Readonly<{ children: ReactNode; onClick?: () => void } & ComponentProps<typeof Box>>> = ({
  children,
  onClick = noop,
  ...props
}) => {
  return (
    <Box
      _active={{
        bg: "transparent",
      }}
      _focus={{
        bg: "transparent",
      }}
      _focusVisible={{
        boxShadow: "0 0 0 4px rgba(255, 255, 255, 0.7)",
      }}
      _hover={{
        bg: "transparent",
      }}
      as={Button}
      bg="transparent"
      border="none"
      cursor="pointer"
      p={0}
      width="100%"
      onClick={onClick}
      {...NAV_HEIGHT_STYLES}
      {...props}
    >
      {children}
    </Box>
  );
};

export const NavLink: FC<
  Readonly<{
    isSelected?: boolean;
    label: string;
    href?: string;
    icon: any;
    permissions?: ResourcePermission[];
    disableContent?: string;
    newTab?: boolean;
    hideTooltip?: boolean;
    fontSize?: number;
    isSub?: boolean;
  }>
> = ({
  isSelected,
  href,
  label,
  permissions,
  icon,
  disableContent = "You do not have permission to view this.",
  newTab = false,
  hideTooltip,
  fontSize,
  isSub = false,
}) => {
  const router = useLocation();
  const { hasPermissions } = useUser();

  const paths = router.pathname.split("/");
  const path = isSub ? `/${paths[1]}/${paths[2]}` : `/${paths[1]}`;

  const active = Boolean(typeof isSelected === "undefined" && href ? path === href : isSelected);

  const hasPermission = !permissions || hasPermissions(permissions);

  if (hasPermission) {
    return (
      <Link href={href ?? ""} {...(newTab ? { target: "_blank", rel: "noreferrer" } : {})}>
        <NavItem active={active} fontSize={fontSize} hideTooltip={hideTooltip} icon={icon} label={label} />
      </Link>
    );
  } else {
    return (
      <Box sx={{ ">span": { width: "100%" } }}>
        <Tooltip message={disableContent} placement="right">
          <NavItem active={active} fontSize={fontSize} icon={icon} label={label} />
        </Tooltip>
      </Box>
    );
  }
};

export const NavItem = ({
  active,
  hideTooltip,
  icon: Icon,
  label,
}: {
  active?: boolean;
  fontSize?: number;
  hideTooltip?: boolean;
  icon?: any;
  label: string;
}) => {
  return (
    <Box
      _hover={{ bg: active ? undefined : "rgba(255,255,255,.08)" }}
      bg={active ? "rgba(255,255,255,.08)" : "transparent"}
      borderRadius="6px"
      overflow="hidden"
      position="relative"
      width="100%"
    >
      <Box
        alignItems="center"
        bg="transparent"
        borderRadius="6px"
        color={active ? "white !important" : "rgba(255,255,255,.8) !important"}
        display="flex"
        gap={2.5}
        justifyContent={["center", "center", "center", "flex-start"]}
        position="relative"
        px={2}
        zIndex={2}
        {...NAV_HEIGHT_STYLES}
      >
        {Icon && (
          <Tooltip isDisabled={hideTooltip} message={label} placement="right">
            <Box color={active ? "lightspeed.400" : "rgba(255, 255, 255, .7)"}>
              <Icon color="currentColor" size={24} />
            </Box>
          </Tooltip>
        )}
        <Box
          as={Text}
          color="inherit"
          display={["none", "none", "none", "flex"]}
          fontFamily="'Sharp Sans Display No 1'"
          fontSize="13px"
          fontWeight="semibold"
          whiteSpace="nowrap"
        >
          {label}
        </Box>
      </Box>
    </Box>
  );
};
