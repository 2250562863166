import { FC, useState } from "react";

import { Column, useToast, Heading, TextInput, FormField, Row, Text, Box } from "@hightouchio/ui";
import { yupResolver } from "@hookform/resolvers/yup";
import { captureException } from "@sentry/react";
import Helmet from "react-helmet";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";

import { SourceForm } from "src/components/sources/setup";
import { eventWarehouseDestinationDefinitions, EventWarehouseDestinationType } from "src/events/types";
import { useCreateEventWarehouseDestinationMutation } from "src/graphql";
import { TrackView } from "src/lib/analytics";
import { Selectable } from "src/ui/selectable";
import { Wizard } from "src/ui/wizard";
import { Step } from "src/ui/wizard/wizard";
import { SlugResourceType, useResourceSlug } from "src/utils/slug";
import { useWizardStepper } from "src/utils/use-wizard-stepper";

type FormState = {
  name: string;
  type: EventWarehouseDestinationType;
};

export const CreateEventWarehouseDestination: FC = () => {
  const { toast } = useToast();
  const navigate = useNavigate();
  const [step, setStep] = useWizardStepper(0);
  const { getSlug } = useResourceSlug(SlugResourceType.EventWarehouseDestinations);
  const [config, setConfig] = useState<Record<string, unknown>>();
  const [tunnelId, setTunnelId] = useState<string | null>();
  const [credentialId, setCredentialId] = useState<string>();

  const createMutation = useCreateEventWarehouseDestinationMutation();

  const form = useForm<FormState>({
    resolver: yupResolver(validationSchema),
  });

  const type = form.watch("type");
  const definition = eventWarehouseDestinationDefinitions[type];

  const submit = async (data) => {
    try {
      const slug = await getSlug(data.name);
      const destination = await createMutation.mutateAsync({
        input: {
          slug,
          type: data.type,
          name: data.name,
          config,
          tunnel_id: tunnelId ?? undefined,
          credential_id: credentialId ?? undefined,
        },
      });
      toast({
        id: "create-event-destination",
        title: "Event destination was created",
        variant: "success",
      });
      navigate(`/events/destinations/${destination.createEventWarehouseDestination.id}`);
    } catch (error) {
      captureException(error);
      toast({
        id: "create-event-destination",
        title: "Event destination could not be created",
        variant: "error",
      });
    }
  };

  const steps: Step[] = [
    {
      title: "Select a destination",
      header: <Heading>Select an event destination</Heading>,
      continue: "Click a destination to continue",
      render: () => (
        <Controller
          name="type"
          render={({ field }) => (
            <Box display="grid" gridTemplateColumns="repeat(auto-fit, minmax(200px, 1fr))" gap={4} maxWidth="800px">
              {Object.entries(eventWarehouseDestinationDefinitions).map(([type, definition]) => (
                <Selectable
                  key={type}
                  selected={false}
                  onSelect={() => {
                    field.onChange(type);
                    setStep((step) => step + 1);
                  }}
                >
                  <Row align="center" p={5} gap={4}>
                    <Box as="img" src={definition.icon} width="36px" />
                    <Text fontWeight="medium" size="lg">
                      {definition.name}
                    </Text>
                  </Row>
                </Selectable>
              ))}
            </Box>
          )}
        />
      ),
    },
    {
      title: `Connect destination`,
      continueProps: { form: "source-form", type: "submit" },
      disabled: tunnelId === null,
      onContinue: () => {},
      header: (
        <Row sx={{ alignItems: "center", gap: 4 }}>
          <Heading>{`Connect to ${definition?.name}`}</Heading>
        </Row>
      ),
      render: () => {
        if (!definition) {
          return null;
        }

        return (
          <Column flex={1} gap={8}>
            <SourceForm
              config={config}
              credentialId={credentialId}
              definition={definition as any}
              disableAuthMethod={true}
              hasSetupLightning={false}
              isSetup={true}
              lightningEnabled={false}
              plannerDatabase={undefined}
              setConfig={setConfig}
              setCredentialId={setCredentialId}
              setLightningEnabled={() => {}}
              setPlannerDatabase={() => {}}
              setTunnelId={setTunnelId}
              sourceId={undefined}
              tunnelId={tunnelId}
              onConnectClick={() => {}}
              onSubmit={async () => {
                setStep(step + 1);
              }}
            />
          </Column>
        );
      },
    },
    {
      title: "Finalize",
      header: <Heading>Finalize settings</Heading>,
      render: () => {
        return (
          <Column gap={8} maxWidth="576px" width="100%">
            <Controller
              name="name"
              render={({ field, fieldState: { error } }) => (
                <FormField label="Name">
                  <TextInput {...field} isInvalid={Boolean(error)} autoFocus />
                </FormField>
              )}
            />
          </Column>
        );
      },
    },
  ];

  return (
    <>
      <Helmet>
        <title>New event destination</title>
      </Helmet>
      <TrackView name="New Event Destination Page" />
      <FormProvider {...form}>
        <Wizard
          setStep={setStep}
          step={step}
          steps={steps}
          title="New event destination"
          onCancel={() => {
            navigate("/event/destinations");
          }}
          onSubmit={form.handleSubmit(submit)}
        />
      </FormProvider>
    </>
  );
};

const validationSchema = yup.object().shape({
  name: yup.string().required(),
  type: yup.string().required(),
});
