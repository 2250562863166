import { FC, ReactNode } from "react";

import {
  Box,
  ChevronRightIcon,
  ChakraAccordion,
  ChakraAccordionButton,
  ChakraAccordionItem,
  ChakraAccordionPanel,
  Pill,
  Text,
} from "@hightouchio/ui";

interface Props {
  children: ReactNode;
  label: string;
  count?: number;
}

export const AccordionSection: FC<Readonly<Props>> = ({ children, label, count }) => (
  <ChakraAccordion allowToggle>
    <ChakraAccordionItem border="none">
      {({ isExpanded }) => {
        return (
          <>
            <ChakraAccordionButton cursor="pointer" outline="none !important" pl={2} borderRadius="md">
              <Box
                as={ChevronRightIcon}
                fontSize="18px"
                transform={isExpanded ? "rotate(90deg)" : ""}
                transition="transform 150ms ease-in-out"
              />
              <Text ml={2} fontWeight="medium">
                {label}
              </Text>
              {count && <Pill ml={2}>{count}</Pill>}
            </ChakraAccordionButton>
            <ChakraAccordionPanel>{children}</ChakraAccordionPanel>
          </>
        );
      }}
    </ChakraAccordionItem>
  </ChakraAccordion>
);
