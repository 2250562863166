import { FC, useState } from "react";

import { TraitConfig, TraitType } from "@hightouch/lib/query/visual/types";
import { Box, Button, Column, Row, Text } from "@hightouchio/ui";

import { TraitQuery } from "src/graphql";
import { Table, TableColumn, Pagination } from "src/ui/table";
import { getColumnName } from "src/utils/models";

import { usePreviewTrait } from "./use-preview-trait";

type Props = {
  parentModel: NonNullable<TraitQuery["trait_definitions_by_pk"]>["parent_model"];
  trait: {
    name?: string;
    type: TraitType;
    config: TraitConfig;
    relationshipId: string;
  };
  isDisabled?: boolean;
};

const ROWS_PER_PAGE = 5;

export const PreviewTrait: FC<Props> = ({ parentModel, trait, isDisabled }) => {
  const [currentPage, setCurrentPage] = useState(0);

  const {
    previewTrait,
    isLoading: isPreviewTraitLoading,
    data: traitPreviewData,
  } = usePreviewTrait({
    parentModel: parentModel,
    trait,
  });

  const primaryLabel = parentModel.visual_query_primary_label ?? "";
  const secondaryLabel = parentModel.visual_query_secondary_label ?? "";

  const primaryLabelName = traitPreviewData.columns ? getColumnName(primaryLabel, traitPreviewData.columns) : primaryLabel;
  const secondaryLabelName = traitPreviewData.columns
    ? getColumnName(secondaryLabel, traitPreviewData.columns)
    : secondaryLabel;

  const columns: TableColumn[] = [
    {
      name: primaryLabelName,
      key: primaryLabel,
    },
    {
      name: secondaryLabelName,
      key: secondaryLabel,
    },
    {
      header: () => (
        <Row width="100%">
          <Text isTruncated color="text.secondary">
            {trait.name || "Trait Preview"}
          </Text>
        </Row>
      ),
      cell: ({ trait_preview }) => (
        <Row
          bg="primary.background"
          height="100%"
          width="100%"
          alignItems="center"
          justifyContent="right"
          px={4}
          whiteSpace="break-spaces"
        >
          <Text>{trait_preview}</Text>
        </Row>
      ),
    },
  ];

  const rows = traitPreviewData?.rows ?? [];

  return (
    <Column gap={2}>
      <Box
        as={Button}
        isDisabled={isDisabled || isPreviewTraitLoading}
        isLoading={isPreviewTraitLoading}
        size="md"
        width="fit-content"
        onClick={previewTrait}
      >
        Preview results
      </Box>
      {rows.length > 0 && (
        <Column gap={2} alignItems="center">
          <Table
            columns={columns}
            data={rows.slice(0 + currentPage * ROWS_PER_PAGE, currentPage * ROWS_PER_PAGE + ROWS_PER_PAGE)}
          />
          <Pagination count={rows.length} page={currentPage} rowsPerPage={ROWS_PER_PAGE} setPage={setCurrentPage} />
        </Column>
      )}
    </Column>
  );
};
