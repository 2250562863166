export enum TimeOptions {
  OneWeek = "1-week",
  OneMonth = "1-month",
  ThreeMonths = "1-months",
  SixMonths = "6-month",
  OneYear = "1-year",
  All = "all",
}

export const timeOptions = [
  { label: "Past 1 week", value: TimeOptions.OneWeek },
  { label: "Past 1 month", value: TimeOptions.OneMonth },
  { label: "Past 3 months", value: TimeOptions.ThreeMonths },
  { label: "Past 6 months", value: TimeOptions.SixMonths },
  { label: "Past 1 year", value: TimeOptions.OneYear },
  { label: "Since tracking began", value: TimeOptions.All },
];
