import { FC, useEffect } from "react";

import {
  Box,
  Button,
  Row,
  Column,
  SectionHeading,
  Text,
  ButtonGroup,
  PlusIcon,
  NumberInput,
  Combobox,
  IconButton,
  DeleteIcon,
  MenuItem,
  MenuActionsButton,
  MenuList,
  DescriptionAddIcon,
  Menu,
  MultiSelect,
} from "@hightouchio/ui";
import { Controller, FormProvider, SubmitHandler, useFieldArray, useForm, useFormContext, useWatch } from "react-hook-form";
import { useOutletContext } from "react-router-dom";

import { ActionBar } from "src/components/action-bar";
import { colors } from "src/components/explore/visual/colors";
import { AndOrToggleButton } from "src/components/explore/visual/condition-buttons";
import { GroupIndicatorBar } from "src/components/explore/visual/group-indicator-bar";
import { useUpdateIdentityResolutionGraphMutation } from "src/graphql";

import { OutletContext } from ".";
import { BlockRule, MergeRule, transformationOptionsByIdentifier, operatorOptions, allTransformationOptions } from "../types";

type MergeRuleCondition = {
  type: "and" | "or";
  rules: MergeRule[];
};

type FormState = {
  merge_rules: {
    type: "and" | "or";
    conditions: MergeRuleCondition[];
  };
  block_rules: BlockRule[];
  resolution_rules: any[];
};

const getDefaultMergeRule = (): MergeRule => ({
  identifier: "",
  operator: "eq",
  transformations: [],
});

const getDefaultBlockRule = (): BlockRule => ({
  identifier: "",
  limit: 1,
});

const getDefaultValues = (graph: OutletContext["graph"]): FormState => ({
  merge_rules: graph.merge_rules ?? { type: "and", conditions: [] },
  block_rules: graph.block_rules ?? [],
  resolution_rules: graph.resolution_rules ?? [],
});

export const Rules: FC = () => {
  const { graph } = useOutletContext<OutletContext>();

  const form = useForm<FormState>({
    defaultValues: getDefaultValues(graph),
  });

  const updateMutation = useUpdateIdentityResolutionGraphMutation();

  const { isDirty } = form.formState;

  const submit: SubmitHandler<FormState> = async (data) => {
    await updateMutation.mutateAsync({
      id: graph.id,
      input: {
        merge_rules: data.merge_rules,
        block_rules: data.block_rules,
        resolution_rules: data.resolution_rules,
      },
    });
  };

  useEffect(() => {
    form.reset(getDefaultValues(graph));
  }, [graph]);

  return (
    <FormProvider {...form}>
      <Column gap={6} pb={24}>
        <MergeRules />
        <BlockRules />
        <ResolutionRules />
      </Column>
      <ActionBar>
        <ButtonGroup>
          <Button
            size="lg"
            variant="primary"
            isDisabled={!isDirty}
            isLoading={updateMutation.isLoading}
            onClick={() => {
              form.handleSubmit(submit)();
            }}
          >
            Save changes
          </Button>
          <Button
            size="lg"
            isDisabled={!isDirty}
            onClick={() => {
              form.reset();
            }}
          >
            Discard changes
          </Button>
        </ButtonGroup>
      </ActionBar>
    </FormProvider>
  );
};

const MergeRules: FC = () => {
  const { watch } = useFormContext<FormState>();
  const { fields, append, remove } = useFieldArray<FormState>({ name: "merge_rules.conditions" });
  const type = watch("merge_rules.type");

  return (
    <Column align="flex-start" gap={4}>
      <Column>
        <SectionHeading> Merge records when:</SectionHeading>
        <Text>Define what identifiers to use to merge profiles and associate events with each profile.</Text>
      </Column>
      {fields.map((field, index) => {
        return (
          <MergeRuleGroup
            key={field.id}
            index={index}
            type={type}
            removeGroup={() => {
              remove(index);
            }}
          />
        );
      })}
      <AddIdentifierButton
        type={fields.length ? type : undefined}
        onClick={() => append({ type: "and", rules: [getDefaultMergeRule()] })}
      />
    </Column>
  );
};

const AddIdentifierButton: FC<Readonly<{ type: string | undefined; onClick: () => void }>> = ({ type, onClick }) => {
  return (
    <Box
      sx={
        type
          ? {
              button: {
                bg: colors.base[type],
                border: "none",
                _hover: {
                  bg: colors.hover[type],
                },
                _active: {
                  bg: colors.hover[type],
                },
                svg: { color: "text.primary" },
              },
            }
          : {}
      }
    >
      <Button icon={PlusIcon} onClick={onClick}>
        Identifier
      </Button>
    </Box>
  );
};

const MergeRuleGroup: FC<{ index: number; type: string; removeGroup: () => void }> = ({ index, type, removeGroup }) => {
  const { identifiers } = useOutletContext<OutletContext>();
  const { watch, setValue } = useFormContext<FormState>();
  const conditions = watch(`merge_rules.conditions`);
  const rules = watch(`merge_rules.conditions.${index}.rules`);
  const innerType = type === "and" ? "or" : "and";
  const { fields, remove, append } = useFieldArray<FormState>({ name: `merge_rules.conditions.${index}.rules` });
  const nested = fields.length > 1;

  const ungroup = () => {
    const newConditions = conditions.filter((_, i) => i !== index);
    rules.forEach((rule) => {
      newConditions.push({
        type: "and",
        rules: [rule],
      });
    });
    setValue(`merge_rules.conditions`, newConditions);
  };

  const ruleElements = fields.map((field, nestedIndex) =>
    nested ? (
      <>
        <Row key={field.id}>
          <GroupIndicatorBar conditionType={innerType as any} />
          <MergeRule
            name={`merge_rules.conditions.${index}.rules.${nestedIndex}`}
            identifiers={identifiers}
            type={innerType}
            remove={() => {
              if (fields.length === 1) {
                removeGroup();
              } else {
                remove(nestedIndex);
              }
            }}
          />
        </Row>
        <Row>
          <AndOrToggleButton
            conditionType={innerType as any}
            onClick={() => {
              setValue("merge_rules.type", type === "and" ? "or" : "and");
            }}
          />
          <Box sx={{ button: { color: "text.secondary" } }}>
            <Button variant="tertiary" onClick={ungroup}>
              Ungroup
            </Button>
          </Box>
        </Row>
        {nestedIndex === fields.length - 1 && (
          <AddIdentifierButton
            type={innerType}
            onClick={() => {
              append(getDefaultMergeRule());
            }}
          />
        )}
      </>
    ) : (
      <MergeRule
        key={field.id}
        name={`merge_rules.conditions.${index}.rules.${nestedIndex}`}
        type={innerType}
        identifiers={identifiers}
        append={() => append(getDefaultMergeRule())}
        remove={() => {
          if (fields.length === 1) {
            removeGroup();
          } else {
            remove(nestedIndex);
          }
        }}
      />
    ),
  );

  return (
    <Column gap={4} width="100%">
      {fields.length === 1 ? (
        <Row width="100%">
          <GroupIndicatorBar conditionType={type as any} />
          {ruleElements}
        </Row>
      ) : (
        <Row gap={4} width="100%">
          <GroupIndicatorBar conditionType={type as any} />
          <Column gap={4} width="100%">
            {ruleElements}
          </Column>
        </Row>
      )}

      <AndOrToggleButton
        conditionType={type as any}
        onClick={() => {
          setValue("merge_rules.type", type === "and" ? "or" : "and");
        }}
      />
    </Column>
  );
};

const MergeRule: FC<
  Readonly<{ append?: () => void; remove: () => void; type: string; name: string; identifiers: string[] }>
> = ({ append, remove, type, name, identifiers }) => {
  const identifier = useWatch({ name: `${name}.identifier` });

  return (
    <Row
      flex={1}
      px={4}
      py={2}
      align="center"
      gap={4}
      bg="white"
      border="1px"
      borderLeft="none"
      borderColor="base.border"
      borderTopRightRadius="md"
      borderBottomRightRadius="md"
    >
      <Row align="center" gap={4} flex={1}>
        <Row align="center" gap={4} flex={1}>
          <Controller
            name={`${name}.identifier`}
            render={({ field, fieldState: { error } }) => (
              <Combobox
                {...field}
                variant="heavy"
                placeholder="Identifier..."
                isInvalid={Boolean(error)}
                width="auto"
                options={identifiers}
                optionValue={(o) => o}
                optionLabel={(o) => o}
              />
            )}
          />
          <Text color="text.secondary">is matching</Text>
          <Controller
            name={`${name}.operator`}
            render={({ field, fieldState: { error } }) => (
              <Combobox {...field} variant="heavy" isInvalid={Boolean(error)} width="auto" options={operatorOptions} />
            )}
          />
          {identifier && (
            <Controller
              name={`${name}.transformations`}
              render={({ field }) => {
                const identifierTransformationOptions = transformationOptionsByIdentifier[identifier];
                if (identifierTransformationOptions?.length === 0) {
                  return <></>;
                }
                return (
                  <>
                    {field.value?.length > 0 && <Text color="text.secondary">and</Text>}
                    <MultiSelect
                      {...field}
                      width="auto"
                      options={identifierTransformationOptions ?? allTransformationOptions}
                      placeholder="+ option"
                    />
                  </>
                );
              }}
            />
          )}
        </Row>

        <Row align="center">
          {append && (
            <Menu>
              <MenuActionsButton />
              <MenuList>
                <Box
                  as={MenuItem}
                  color="text.secondary"
                  icon={DescriptionAddIcon}
                  sx={{
                    svg: {
                      height: "24px",
                      width: "24px",
                    },
                  }}
                  onClick={append}
                >
                  <Column>
                    <Text fontWeight="medium">Add group</Text>
                    <Text color="text.secondary">Create a nested {type} group from this filter</Text>
                  </Column>
                </Box>
              </MenuList>
            </Menu>
          )}
          <Box sx={{ svg: { color: "danger.base" } }}>
            <IconButton aria-label="Remove" icon={DeleteIcon} variant="tertiary" onClick={remove} />
          </Box>
        </Row>
      </Row>
    </Row>
  );
};

const BlockRules: FC = () => {
  const { identifiers } = useOutletContext<OutletContext>();
  const { fields, append, remove } = useFieldArray<FormState>({ name: "block_rules" });

  return (
    <Column align="flex-start" gap={4}>
      <Column>
        <SectionHeading>Limit merging when:</SectionHeading>
        <Text>
          Define the limits of allowed identifiers per profile record. If these limits are exceeded, these merges will be
          flagged as conflicts, to be resolved in the next step.
        </Text>
      </Column>
      {fields.map((field, index) => {
        return (
          <Row
            key={field.id}
            gap={4}
            align="center"
            px={4}
            py={2}
            border="1px"
            borderColor="base.border"
            borderRadius="md"
            bg="white"
            width="100%"
            justify="space-between"
          >
            <Row align="center" gap={2}>
              <Text color="text.secondary">Limit of</Text>
              <Controller
                name={`block_rules.${index}.limit`}
                render={({ field, fieldState: { error } }) => (
                  <Box width="50px">
                    <NumberInput {...field} isInvalid={Boolean(error)} />
                  </Box>
                )}
              />
              <Controller
                name={`block_rules.${index}.identifier`}
                render={({ field, fieldState: { error } }) => (
                  <Combobox
                    {...field}
                    variant="heavy"
                    placeholder="Identifier..."
                    width="auto"
                    isInvalid={Boolean(error)}
                    options={identifiers}
                    optionValue={(o) => o}
                    optionLabel={(o) => o}
                  />
                )}
              />
              <Text color="text.secondary">per record</Text>
            </Row>
            <Box sx={{ svg: { color: "danger.base" } }}>
              <IconButton
                aria-label="Remove"
                icon={DeleteIcon}
                variant="tertiary"
                onClick={() => {
                  remove(index);
                }}
              />
            </Box>
          </Row>
        );
      })}
      <Button
        icon={PlusIcon}
        onClick={() => {
          append(getDefaultBlockRule());
        }}
      >
        Limit
      </Button>
    </Column>
  );
};

const ResolutionRules = () => {
  // TODO: Mitch can do this :)
  return null;

  // const { identifiers } = useOutletContext<OutletContext>();
  // const { fields, append, remove } = useFieldArray<FormState>({ name: "resolution_rules" });

  // return (
  //   <Column align="flex-start" gap={4}>
  //     <Column>
  //       <SectionHeading>Resolve conflicts by:</SectionHeading>
  //       <Text>Define what priority to resolve conflicts by.</Text>
  //     </Column>
  //     <Reorder onChange={() => {}} items={[]}>
  //       {fields.map((field, index) => {
  //         return <Row key={field.id}>potato</Row>;
  //       })}
  //     </Reorder>
  //   </Column>
  // );
};
