import { FC, useMemo } from "react";

import { Column, Row, Box, Spinner, Button, EmptyState, Text } from "@hightouchio/ui";

import { QueryColumn, SuccessfulQueryResponse } from "src/graphql";
import { Pagination, Table, useTableConfig } from "src/ui/table";
import ErrorSrc from "src/ui/table/error.svg";

import PlaceholderSrc from "./placeholder.svg";

export interface ResultsProps {
  columns: QueryColumn[] | undefined;
  rows: SuccessfulQueryResponse["rows"] | undefined;
  rowsPerPage?: number;
  numRowsWithoutLimit?: number;
  isResultTruncated: boolean;
  error?: string;
  page?: number;
  onPageChange?: (page: number) => void;
  rowsCount?: number | null;
  asyncPagination?: boolean;
  isLoading?: boolean;
  onCancel: () => void;
  isPlaceholder?: boolean;
}

export type SortInput = { property: string } | { aggregation: number };

export const Results: FC<Readonly<ResultsProps>> = ({
  columns,
  rows,
  rowsPerPage,
  numRowsWithoutLimit,
  isResultTruncated,
  error,
  onPageChange,
  page: pageProp,
  rowsCount,
  asyncPagination,
  isLoading,
  onCancel,
  isPlaceholder = true,
}) => {
  const { offset, limit, page, setPage } = useTableConfig({
    limit: rowsPerPage,
    page: pageProp,
    setPage: onPageChange,
  });
  const count = rowsCount ?? rows?.length ?? 0;

  const tableColumns = useMemo(
    () =>
      (columns || []).map(({ name }) => ({
        key: name,
        name,
        cell: (value) =>
          typeof value === "object" || typeof value === "boolean" ? (
            <Box
              sx={{
                whiteSpace: "nowrap",
                ":first-letter": {
                  textTransform: typeof value === "boolean" ? "capitalize" : undefined,
                },
              }}
            >
              {JSON.stringify(value)}
            </Box>
          ) : (
            value
          ),
      })),
    [columns],
  );

  if (isLoading) {
    return (
      <Column
        sx={{
          justifyContent: "center",
          alignItems: "center",
          border: "1px solid",
          borderColor: "base.border",
          borderRadius: "md",
          gap: 4,
          py: 16,
        }}
      >
        <Spinner size="lg" />
        <Button size="sm" variant="secondary" onClick={onCancel}>
          Cancel query
        </Button>
      </Column>
    );
  }

  if (!rows && !error) {
    if (isPlaceholder) {
      return (
        <EmptyState
          title="Ready to test your query?"
          message="Preview your query to see the resulting rows"
          imageUrl={PlaceholderSrc}
        />
      );
    } else {
      return null;
    }
  }

  if (error) {
    return <EmptyState imageUrl={ErrorSrc} title="Error" message={error} />;
  }

  if (!rows || !rows.length) {
    return (
      <EmptyState imageUrl={ErrorSrc} title="No results" message={<Text>Modify the query to return at least one row.</Text>} />
    );
  }

  let numTotalResults: number | undefined;
  let isNumTotalResultsExact: boolean | undefined;

  if (rows) {
    if (numRowsWithoutLimit != null) {
      numTotalResults = numRowsWithoutLimit;
      isNumTotalResultsExact = true;
    } else {
      // If we don't have an explicit count for the total number of rows, we
      // only know the exact total row count if the results aren't truncated
      // (since if there were more rows in the result, they would've been
      // returned by the preview).
      numTotalResults = rows.length;
      isNumTotalResultsExact = !isResultTruncated;
    }
  }

  return (
    <Column flex={1} overflow="hidden">
      <Table isDense scrollable columns={tableColumns} data={asyncPagination ? rows : rows?.slice(offset, offset + limit)} />
      <Row width="100%" mt={4} justify="flex-end">
        <Pagination
          alwaysShow
          count={count}
          disabledTooltip={isResultTruncated ? `Preview is limited to ${rows?.length} rows` : undefined}
          page={page}
          rowsPerPage={limit}
          setPage={setPage}
          text={
            numTotalResults
              ? `${numTotalResults}${isNumTotalResultsExact ? "" : "+"} row${
                  typeof numTotalResults === "number" && numTotalResults > 1 ? "s" : ""
                }`
              : undefined
          }
        />
      </Row>
    </Column>
  );
};
