import { ReactElement, useEffect, useMemo, useState } from "react";

import {
  Button,
  Checkbox,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Pill,
  Row,
  SearchInput,
  StatusBadge,
  Text,
} from "@hightouchio/ui";
import moment from "moment";
import { useQueryClient } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { isPresent } from "ts-extras";

import { SyncRequestErrorModal } from "src/components/syncs/error-modals";
import { useUser } from "src/contexts/user-context";
import { FormkitModel, FormkitSync } from "src/formkit/components/formkit-context";
import { AttemptedRowsByPrimaryKeyQuery, AttemptedRowsQuery, SyncOp, useAttemptedRowsQuery } from "src/graphql";
import { SyncRequestErrorInfo } from "src/types/sync-errors";
import { ErrorOriginInfoModal, Modal } from "src/ui/modal";
import { InfoModal } from "src/ui/modal/info-modal";
import { SimplePagination, Table, TableColumn } from "src/ui/table";
import { downloadCsv, downloadJson } from "src/utils/download";
import { DEPRECATED_ERROR, SyncStatus } from "src/utils/syncs";
import { openUrl } from "src/utils/urls";

import ShipImage from "../../../components/syncs/ship.svg";

type AttemptedRow = NonNullable<AttemptedRowsQuery["getAttemptedRows"]["rows"][0]>;

type RowsProps = {
  addedRows?: number | null;
  attemptedRowsByPKData?: AttemptedRowsByPrimaryKeyQuery;
  attemptedRowsByPKLoading: boolean;
  attemptedRowsData?: AttemptedRowsQuery;
  attemptedRowsLoading: boolean;
  attemptedRowsQueryError: Error | null;
  changedRows?: number | null;
  page: number;
  pages: number;
  plannerType?: string | null;
  primaryKey?: string | null;
  removedRows?: number | null;
  search: string;
  searchInput: string;
  setPage: (page) => void;
  setPageKeys: (keys) => void;
  setSearch: (search) => void;
  setSearchInput: (input) => void;
  setShowAdded: (added) => void;
  setShowChanged: (changed) => void;
  setShowRemoved: (removed) => void;
  showAdded: boolean;
  showChanged: boolean;
  showRejected: boolean;
  showRemoved: boolean;
  source?: FormkitModel["connection"];
  sync?: FormkitSync;
  syncError: any;
  syncRequest: any;
};

export const Rows = ({
  addedRows,
  attemptedRowsByPKData,
  attemptedRowsByPKLoading,
  attemptedRowsData,
  attemptedRowsLoading,
  attemptedRowsQueryError,
  changedRows,
  page,
  pages,
  plannerType,
  primaryKey,
  removedRows,
  search,
  searchInput,
  setPage,
  setPageKeys,
  setSearch,
  setSearchInput,
  setShowAdded,
  setShowChanged,
  setShowRemoved,
  showAdded,
  showChanged,
  showRejected,
  showRemoved,
  source,
  sync,
  syncError,
  syncRequest,
}: RowsProps) => {
  const client = useQueryClient();
  const navigate = useNavigate();
  const { run_id: runId, sync_id: syncId } = useParams<{ run_id: string; sync_id: string }>();

  const [nextLoading, setNextLoading] = useState<boolean>(false);
  const [previousLoading, setPreviousLoading] = useState<boolean>(false);
  const [runError, setRunError] = useState<SyncRequestErrorInfo>();
  const [rowError, setRowError] = useState<string>("");
  const [errorRowId, setErrorRowId] = useState<string>("");
  const [showErrorExport, setShowErrorExport] = useState<boolean>(false);
  const [showExport, setShowExport] = useState<boolean>(false);
  const [exportLoading, setExportLoading] = useState<boolean>(false);
  const [showUnsupported, setShowUnsupported] = useState<boolean>(false);

  // XXX: As a hack, we disable showing row-level information for regular
  // viewers for one of our customers. This is not meant to be secure, and
  // just prevents accidentally viewing PII data. We should remove this
  // once we have first-class support for this via our permissioning
  // system.
  const { user } = useUser();
  const userId = user?.id;

  const isDataProtected =
    // We don't populate the results-index for clean room sources anyway,
    // but we can show a better UI for those sources.
    Boolean(source?.definition.cleanRoomType) ||
    // This is a hack for a specific customer.
    // See https://github.com/hightouchio/hightouch/pull/2887.
    (syncId === "27126" && userId !== 63366);

  const data = search ? attemptedRowsByPKData?.getAttemptedRowsByPrimaryKey?.rows : attemptedRowsData?.getAttemptedRows?.rows;

  const rows = useMemo(
    () =>
      (data || []).filter(isPresent).map(({ id, opType, rejectionReason, fields, batchId, requestInfoKeys, isBatchError }) => ({
        hightouchRowId: id,
        opType,
        rejectionReason,
        batchId,
        requestInfoKeys,
        isBatchError,
        fields,
        ...JSON.parse(fields),
      })),
    [data],
  );

  // Rows are no longer available when the run is older than a week
  const isRowsDataExpired = useMemo(() => {
    if (!syncRequest || rows.length > 0) {
      return false;
    }

    const weekAgo = moment().subtract(1, "week");
    return moment(syncRequest.created_at).isBefore(weekAgo);
  }, [syncRequest, rows]);

  const columns: TableColumn[] = [
    {
      key: "opType",
      name: "Type",
      max: "max-content",
      cell: (opType) => getOpTypeBadge(opType),
    },
  ];

  if (showRejected && plannerType !== "all") {
    columns.push({
      name: "Error",
      max: "150px",
      cell: ({ rejectionReason, hightouchRowId }) => {
        return rejectionReason ? (
          <Button
            size="sm"
            onClick={(event) => {
              event.stopPropagation();
              setRowError(rejectionReason);
              setErrorRowId(hightouchRowId);
            }}
          >
            View error
          </Button>
        ) : null;
      },
    });
  }

  const row = data?.[0];

  if (row) {
    const fields = JSON.parse(row.fields);
    Object.keys(fields).forEach((key, i) => {
      columns.push({
        key,
        name: key,
        cell: (value) => (typeof value === "object" ? JSON.stringify(value) : String(value)),
        divider: i === 0,
      });
    });
  }

  const downloadErrors = async (format, rejectedOnly) => {
    let pageKey;
    let rows: AttemptedRow[] = [];
    setExportLoading(true);

    // eslint-disable-next-line no-constant-condition
    while (true) {
      const variables = {
        destinationInstanceId: Number(syncId),
        syncRequestId: Number(runId),
        opTypes: [SyncOp.Added, SyncOp.Changed, SyncOp.Removed],
        onlyRejected: rejectedOnly,
        onlySuccessful: false,
        pageKey,
        limit: 100,
        plannerType: String(plannerType),
      };

      try {
        const { getAttemptedRows } = await client.fetchQuery(
          useAttemptedRowsQuery.getKey(variables),
          useAttemptedRowsQuery.fetcher(variables),
        );

        if (getAttemptedRows) {
          rows = [...rows, ...getAttemptedRows.rows].filter(isPresent);
          pageKey = getAttemptedRows.nextPageKey;
        }

        if (!pageKey || !getAttemptedRows) {
          break;
        }
      } catch (e) {
        break;
      }
    }

    if (rows.length && format === "json") {
      const rowsToDownload = rows.map(({ opType, rejectionReason, fields }) => ({
        type: opType,
        error: rejectionReason,
        fields: JSON.parse(fields),
      }));

      downloadJson(rowsToDownload, `export-${moment(syncRequest?.created_at).format()}.json`);
    }

    if (rows.length && format === "csv") {
      const rowsToDownload = rows.map(({ opType, rejectionReason, fields }) => ({
        __ht_type: opType,
        __ht_error: rejectionReason,
        ...JSON.parse(fields),
      }));

      downloadCsv(rowsToDownload, `export-${moment(syncRequest?.created_at).format()}.csv`);
    }

    setExportLoading(false);
    setShowErrorExport(false);
    setShowExport(false);
  };

  const redirectBackToSync = () => {
    navigate(`/syncs/${syncId}`);
  };

  useEffect(() => {
    if (nextLoading) {
      setPage((page) => page + 1);
      setNextLoading(false);
    }
    if (previousLoading) {
      setPage((page) => page - 1);
      setPreviousLoading(false);
    }
  }, [rows, setPage, setNextLoading, setPreviousLoading]);

  return (
    <>
      <Row sx={{ alignItems: "center", justifyContent: "space-between", width: "100%", flexWrap: "wrap", mb: 3, gap: 4 }}>
        <Row gap={8}>
          <Row align="center" gap={2}>
            <Checkbox
              isDisabled={!addedRows}
              isChecked={addedRows ? showAdded : false}
              onChange={(event) => setShowAdded(event.target.checked)}
            />
            <Text>Added</Text>
            <Pill>{addedRows}</Pill>
          </Row>
          <Row align="center" gap={2}>
            <Checkbox
              isDisabled={!changedRows}
              isChecked={changedRows ? showChanged : false}
              onChange={(event) => setShowChanged(event.target.checked)}
            />
            <Text>Changed</Text>
            <Pill>{changedRows}</Pill>
          </Row>

          <Row align="center" gap={2}>
            <Checkbox
              isDisabled={!removedRows}
              isChecked={removedRows ? showRemoved : false}
              onChange={(event) => setShowRemoved(event.target.checked)}
            />
            <Text>Removed</Text>
            <Pill>{removedRows}</Pill>
          </Row>
        </Row>
        {!isDataProtected && (
          <Row align="center" mt={4} gap={4}>
            {syncError && ![DEPRECATED_ERROR, "Error: " + DEPRECATED_ERROR].includes(syncError.message) && (
              <Button size="sm" variant="warning" onClick={() => setRunError(syncError)}>
                View run error
              </Button>
            )}
            <Menu>
              <MenuButton>Export</MenuButton>
              <MenuList>
                <MenuItem
                  onClick={() => {
                    setShowExport(true);
                  }}
                >
                  Export rows
                </MenuItem>
                {plannerType !== "all" && (
                  <MenuItem
                    onClick={() => {
                      setShowErrorExport(true);
                    }}
                  >
                    Export row errors
                  </MenuItem>
                )}
              </MenuList>
            </Menu>
            {plannerType !== "all" && (
              <form
                onSubmit={(event) => {
                  event.preventDefault();
                  setSearch(searchInput);
                }}
              >
                <Row align="center" gap={2}>
                  <SearchInput
                    placeholder={primaryKey ? `Search by ${primaryKey}` : ""}
                    value={searchInput}
                    onChange={(event) => {
                      const value = event.target.value;
                      setSearchInput(value);
                      if (value === "") {
                        setSearch("");
                      }
                    }}
                  />
                  <Button type="submit">Search</Button>
                </Row>
              </form>
            )}
          </Row>
        )}
      </Row>
      <Table
        scrollable
        columns={columns}
        data={isDataProtected ? [] : rows}
        error={!!attemptedRowsQueryError}
        loading={attemptedRowsLoading || attemptedRowsByPKLoading}
        placeholder={{
          image: isRowsDataExpired ? ShipImage : undefined,
          title: search
            ? `No rows match your search`
            : isRowsDataExpired
            ? "Sorry, that ship has sailed"
            : isDataProtected
            ? "The run data is protected"
            : `No rows`,
          body: search ? (
            <Text>
              No rows with <strong>{primaryKey}</strong> equal to <strong>{search}</strong>
            </Text>
          ) : isRowsDataExpired ? (
            `We don't store row data for sync runs older than 7 days`
          ) : undefined,
          button: isRowsDataExpired ? (
            <Button variant="secondary" onClick={redirectBackToSync}>
              Go back to sync
            </Button>
          ) : undefined,
          error: attemptedRowsQueryError?.message,
        }}
        onRowClick={(row, event) =>
          !row.requestInfoKeys || !row.requestInfoKeys?.length
            ? setShowUnsupported(true)
            : openUrl(`/syncs/${syncId}/runs/${runId}/debug/${row?.hightouchRowId}`, navigate, event)
        }
      />
      <SimplePagination
        nextLoading={nextLoading}
        page={page}
        pages={pages}
        previousLoading={previousLoading}
        onNext={() => {
          setNextLoading(true);
          setPageKeys((pageKeys) => [...pageKeys, attemptedRowsData?.getAttemptedRows?.nextPageKey].filter(isPresent));
        }}
        onPrevious={() => {
          setPreviousLoading(true);
          setPageKeys((pageKeys) => {
            if (page === 1) {
              return [];
            } else {
              return pageKeys.slice(0, -1);
            }
          });
        }}
      />

      {/* Modal for sync-level errors */}
      <SyncRequestErrorModal
        isOpen={Boolean(runError)}
        onClose={() => setRunError(undefined)}
        sync={sync}
        syncRequestError={runError}
        syncStatus={sync?.status as SyncStatus}
      />
      {/* Modal for row errors */}
      <ErrorOriginInfoModal
        errorType="row"
        isOpen={Boolean(rowError)}
        onClose={() => {
          setRowError("");
          setErrorRowId("");
        }}
        originInfo={{
          scope: "destination",
          operation: "add",
        }}
        rowLevelError={{
          rowId: errorRowId,
          message: rowError,
        }}
        sync={sync}
      />
      <Modal
        footer={
          <>
            <Button onClick={() => setShowErrorExport(false)}>Close</Button>
            <Button
              isLoading={exportLoading}
              variant="primary"
              onClick={() => {
                downloadErrors("json", true);
              }}
            >
              Export as JSON
            </Button>
            <Button
              variant="primary"
              isLoading={exportLoading}
              onClick={() => {
                downloadErrors("csv", true);
              }}
            >
              Export as CSV
            </Button>
          </>
        }
        isOpen={showErrorExport}
        title="Export errors"
        onClose={() => setShowErrorExport(false)}
      >
        <Text>This is an experimental feature to allow for custom analysis of sync errors per row.</Text>
      </Modal>
      <Modal
        footer={
          <>
            <Button onClick={() => setShowExport(false)}>Close</Button>
            <Button
              variant="primary"
              isLoading={exportLoading}
              onClick={() => {
                downloadErrors("json", false);
              }}
            >
              Export as JSON
            </Button>
            <Button
              variant="primary"
              isLoading={exportLoading}
              onClick={() => {
                downloadErrors("csv", false);
              }}
            >
              Export as CSV
            </Button>
          </>
        }
        isOpen={showExport}
        title="Export rows"
        onClose={() => setShowExport(false)}
      >
        <Text>This is an experimental feature to allow for custom analysis of synced rows.</Text>
      </Modal>
      <InfoModal isOpen={showUnsupported} title="Run debugger unsupported" onClose={() => setShowUnsupported(false)}>
        <Text>Run debugger is not supported for this destination, or no requests were made for this row.</Text>
      </InfoModal>
    </>
  );
};

export const getOpTypeBadge = (opType: string): ReactElement | null => {
  switch (opType) {
    case "ADDED":
      return <StatusBadge variant="success">Added</StatusBadge>;
    case "CHANGED":
      return <StatusBadge variant="processing">Changed</StatusBadge>;
    case "REMOVED":
      return <StatusBadge variant="error">Removed</StatusBadge>;
    default:
      return null;
  }
};
