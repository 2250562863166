import { FC, useEffect } from "react";

import { Column } from "@hightouchio/ui";
import { Outlet, useLocation } from "react-router-dom";

import { Nav } from "src/components/layout/nav";
import { useUser } from "src/contexts/user-context";
import * as analytics from "src/lib/analytics";

export const Layout: FC = () => {
  const { user } = useUser();

  const location = useLocation();
  useEffect(() => {
    analytics.track("Page Viewed", { path: location.pathname });
  }, [location.pathname]);

  return (
    <>
      {user && <Nav />}

      <Column flex={1} minWidth={0}>
        <Outlet />
      </Column>
    </>
  );
};
