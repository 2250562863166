import { FC, useState } from "react";

import { EditableDescription, useToast } from "@hightouchio/ui";
import * as Sentry from "@sentry/react";

import { useUpdateTraitMutation } from "src/graphql";

type Props = {
  id: string;
  description: string | null;
};

export const EditTraitDescription: FC<Readonly<Props>> = ({ id, description: originalDescription }) => {
  const [description, setDescription] = useState(originalDescription);
  const { toast } = useToast();

  const traitMutation = useUpdateTraitMutation();

  const updateDescription = async (description: string) => {
    try {
      if (description === originalDescription) {
        return;
      }

      await traitMutation.mutateAsync({ id, input: { description } });
      toast({
        id: "update-trait-description",
        title: "Trait description was successfully updated",
        variant: "success",
      });
    } catch (error) {
      setDescription(originalDescription);
      toast({
        id: "update-trait-description",
        title: "Trait description was not updated",
        message: error.message,
        variant: "error",
      });
      Sentry.captureException(error);
    }
  };

  return <EditableDescription placeholder="Add a description..." value={description ?? ""} onChange={updateDescription} />;
};
