import { FC, useCallback, useMemo, useState } from "react";

import { Box, Button, Column, Paragraph, Row, SparkleIcon, Switch, Text, Textarea } from "@hightouchio/ui";
import { useQueryClient } from "react-query";

import { GenerateVisualQueryQuery, useGenerateVisualQueryQuery, useGenerateAudienceSuggestionsQuery } from "src/graphql";
import { AndCondition, OrCondition } from "src/types/visual";

type MagicProps = {
  parentModelId: string;
  updateFilter: (filter: AndCondition | OrCondition) => void;
};

export const Magic: FC<Readonly<MagicProps>> = ({ parentModelId, updateFilter }) => {
  const [prompt, setPrompt] = useState<string>("");
  const [generating, setGenerating] = useState<boolean>(false);
  const [error, setError] = useState<string>();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const client = useQueryClient();

  const random = useMemo(() => {
    return Math.random();
  }, [isOpen]);

  const { data: suggestions } = useGenerateAudienceSuggestionsQuery(
    { parentModelId },
    {
      enabled: Boolean(parentModelId) && isOpen,
      select: (data) =>
        data.generateAudienceSuggestions.suggestions
          ?.filter(Boolean)
          ?.sort(() => 0.5 - random)
          ?.slice(0, 3) ?? [],
    },
  );

  const getQuery = useCallback(async (parentModelId: string, userPrompt: string): Promise<void> => {
    setGenerating(true);
    setError(undefined);
    const variables = { parentModelId, userPrompt };
    const queryKey = useGenerateVisualQueryQuery.getKey(variables);
    const queryFn = useGenerateVisualQueryQuery.fetcher(variables);

    try {
      const response = await client.fetchQuery<GenerateVisualQueryQuery>(queryKey, {
        queryFn,
        cacheTime: 0,
      });

      if (response.generateVisualQuery.__typename === "FailedModification") {
        setGenerating(false);
        setError("Error generating audience");
        return;
      }

      updateFilter(response.generateVisualQuery.generatedVisualQuery.conditions?.[0]);
    } catch (e) {
      setError("Error generating audience");
    } finally {
      setGenerating(false);
    }
  }, []);

  return (
    <Column
      p={4}
      width="100%"
      alignItems="start"
      backgroundColor="electric.50"
      borderColor="electric.border"
      borderWidth="1px"
      borderRadius="md"
      mb={6}
      gap={4}
    >
      <Column gap={1}>
        <Row
          alignItems="center"
          gap={2}
          sx={{ "& > label > span[data-checked]": { background: "var(--chakra-colors-electric-base) !important" } }}
        >
          <Switch isChecked={isOpen} onChange={setIsOpen} />
          <Text fontWeight="semibold" color="electric.base" size="lg">
            Generate an audience with AI
          </Text>
          <Box as={SparkleIcon} fontSize="22px" color="electric.base" />
        </Row>
        <Paragraph color="text.secondary">Hightouch will help you generate an audience based on your data schema.</Paragraph>
      </Column>
      {isOpen && Boolean(suggestions?.length) && (
        <Column>
          <Text fontWeight="semibold" color="electric.base" mb={1}>
            Try these prompts:
          </Text>
          {suggestions?.map((s, index) => (
            <Box
              key={index}
              color="text.secondary"
              cursor="pointer"
              _hover={{ color: "electric.base" }}
              onClick={() => {
                if (!s?.title) {
                  return;
                }
                setPrompt(s.title);
                getQuery(parentModelId, s.title);
              }}
            >
              {s?.title}
            </Box>
          ))}
        </Column>
      )}

      <Column gap={4} alignItems="start" display={isOpen ? "flex" : "none"}>
        <Textarea
          resize="none"
          width="lg"
          value={prompt}
          onChange={(e) => setPrompt(e.target.value)}
          placeholder="Describe an audience that you want to create (e.g. 'Parisian bird owners who have made three purchases in the last year')..."
        />
        {error && <Text color="text.danger">{error}</Text>}
        <Button
          isLoading={generating}
          variant="secondary"
          isDisabled={!prompt}
          onClick={() => {
            getQuery(parentModelId, prompt);
          }}
        >
          Generate audience
        </Button>
      </Column>
    </Column>
  );
};
