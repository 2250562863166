import { FC } from "react";

import { LockClosedIcon } from "@heroicons/react/24/outline";
import { Box } from "@hightouchio/ui";

type Props = {
  name: string | undefined;
  src: string | undefined;
  size?: number;
};

export const IntegrationIcon: FC<Readonly<Props>> = ({ name, src, size = 6 }) => {
  return src ? (
    <Box as="img" alt={name} src={src} objectFit="contain" width={size} height={size} flexShrink={0} />
  ) : (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      width={size}
      height={size}
      borderRadius="50%"
      bg="gray.100"
      flexShrink={0}
      title="Private"
    >
      <LockClosedIcon color="var(--chakra-colors-gray-500)" width={size / 2} height={size / 2} strokeWidth="3px" />
    </Box>
  );
};
