import { FC, ReactElement, useState } from "react";

import { Box } from "@hightouchio/ui";
import { format as formatDate } from "date-fns";
import {
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip as RechartsTooltip,
  XAxis,
  YAxis,
  Legend as RechartsLegend,
} from "recharts";

import CustomLegend from "./legend";
import CustomTooltip from "./tooltip";
import { Graph } from "./types";
import { valueFormatter } from "./utils";

const ASPECT_RATIO = (9 / 16) * 100;

const axisStyles = {
  stroke: "#9AA6B2",
  fontFamily: "Inter",
  fontWeight: 600,
  letterSpacing: "0.03em",
};

type Props = {
  graph: Graph;
  metricName: string;
  placeholder: ReactElement;
};

const CrossAudienceGraph: FC<Props> = ({ graph, metricName, placeholder }) => {
  const [hoveredLine, setHoveredLine] = useState<string>();

  if (!graph.series.length) {
    return placeholder;
  }

  return (
    // Using the classic aspect ratio trick to get responsivness working.
    // Without these wrapper divs, the chart has a hard time resizing itself to use the appropriate amount of space.
    <Box position="relative" width="100%" pb={`${ASPECT_RATIO}%`}>
      <Box position="absolute" left={0} right={0} top={0} bottom={0}>
        <ResponsiveContainer>
          <LineChart
            margin={{
              top: 30,
              right: 60,
              left: 0,
            }}
          >
            <CartesianGrid vertical={false} stroke="#E5E9ED" />
            <XAxis
              axisLine={{ stroke: "#E5E9ED" }}
              dataKey="calculatedAt"
              allowDuplicatedCategory={false}
              minTickGap={50}
              tickFormatter={(timestamp: string) => {
                return formatDate(new Date(timestamp), "LLL d");
              }}
              tickLine={false}
              {...axisStyles}
            />
            <YAxis axisLine={false} dataKey="metricValue" tickFormatter={valueFormatter} tickLine={false} {...axisStyles} />
            <RechartsTooltip
              active
              content={
                <CustomTooltip normalizeAudiences={graph.normalized} metricName={metricName} hoveredLine={hoveredLine} />
              }
              cursor={{ strokeWidth: 2, stroke: "#252D36" }}
            />
            <Line data={graph.dates} />
            {graph.series.map(({ key, name, data, color }) => (
              <Line
                key={key}
                data={data}
                name={name}
                dataKey="metricValue"
                dot={false}
                activeDot={{ r: 6 }}
                strokeWidth={2}
                stroke={color}
                onMouseEnter={() => setHoveredLine(key)}
                onMouseLeave={() => setHoveredLine(undefined)}
                opacity={hoveredLine ? (hoveredLine === key ? 1 : 0.2) : 1}
              />
            ))}
            <RechartsLegend
              verticalAlign="bottom"
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore -- overload type for payload so we can pass more information to Legend
              payload={graph.series}
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore -- overload type for payload so we can pass more information to Legend
              content={({ payload }) => <CustomLegend payload={payload} />}
            />
          </LineChart>
        </ResponsiveContainer>
      </Box>
    </Box>
  );
};

export default CrossAudienceGraph;
