import { FC } from "react";

import { Badge, Box, Column, Heading, Link, Paragraph, Row } from "@hightouchio/ui";
import { useFlags } from "launchdarkly-react-client-sdk";

import { TraitQuery } from "src/graphql";

import { EditTraitName } from "./edit-trait-name";
import { EditTraitDescription } from "./edt-trait-description";

type Props = {
  id: string;
  name: string;
  description: string | null;
  parentModel: NonNullable<TraitQuery["trait_definitions_by_pk"]>["parent_model"];
  isTemplate: boolean;
  isEditable?: boolean;
};

export const Metadata: FC<Readonly<Props>> = ({ id, name, description, parentModel, isEditable, isTemplate }) => {
  const { schemaV2 } = useFlags();

  const traitName = isEditable ? <EditTraitName id={id} isTemplate={isTemplate} name={name} /> : <Heading>{name}</Heading>;

  const traitDescription = isEditable ? (
    <EditTraitDescription id={id} description={description} />
  ) : (
    description && <Paragraph>{description}</Paragraph>
  );

  return (
    <Column p={6} borderBottom="1px solid" borderBottomColor="base.border">
      {traitName}
      {traitDescription}
      <Row
        sx={{
          mt: 2,
          "& > *:not(:last-child)": {
            pr: 2,
            mr: 2,
            borderRight: "1px solid",
            borderColor: "base.border",
          },
        }}
      >
        <Row alignItems="center" gap={1}>
          <Box as="img" width="12px" height="12px" src={parentModel?.connection?.definition.icon} />
          <Link
            href={
              schemaV2
                ? `/schema-v2/view?source=${parentModel?.connection?.id}&id=${parentModel.id}`
                : `/schema/parent-models/${parentModel?.id}`
            }
          >
            {parentModel?.name}
          </Link>
        </Row>
        {isTemplate && <Badge>Template</Badge>}
      </Row>
    </Column>
  );
};
