import { Row, Text } from "@hightouchio/ui";
import { components } from "react-select";
import { Box } from "theme-ui";

export const SelectOption = (props) => {
  const { isSelected, isDisabled, isFocused } = props;

  const newProps = {
    ...props,
    innerProps: {
      ...props.innerProps,
      onClick: (event) => {
        event.stopPropagation();
        if (props.innerProps.onClick && !props.innerProps.disabled) {
          props.innerProps.onClick(event);
        }
      },
      onMouseMove: undefined,
      onMouseOver: undefined,
    },
  };

  let bg = "white";

  if (isSelected) {
    bg = "avocado";
  } else if (isFocused) {
    bg = "base.1";
  }

  return (
    <Box
      sx={{
        px: 1,
        py: "2px",
        cursor: isDisabled ? "auto" : "pointer",
        ":hover": { "> div": { bg: isSelected ? "avocado" : "base.1" } },
      }}
    >
      <Box
        sx={{
          borderRadius: 1,
          bg,
          color: isSelected ? "forest" : isDisabled ? "base.3" : "black",
          ...props?.data?.sx,
        }}
      >
        <components.Option {...newProps}>
          <Row gap={1}>
            <Text>{props.data.label}</Text>
            {props.data.sublabel && <Text color="text.secondary">{props.data.sublabel}</Text>}
          </Row>
        </components.Option>
      </Box>
    </Box>
  );
};
