import { FC, ReactNode, useEffect, useState } from "react";

import { useFlags } from "launchdarkly-react-client-sdk";
import { uniqueId } from "lodash";
import { BrowserRouter, Navigate, Route, useLocation } from "react-router-dom";

import { SentryRoutes } from "src";
import { Passwordless } from "src/components/auth/passwordless";
import { Layout } from "src/components/layout";
import { useUser } from "src/contexts/user-context";
import Approval from "src/pages/approval";
import { Expired } from "src/pages/expired";
import { Invalid } from "src/pages/invalid";
import Login from "src/pages/login";
import { NotFound } from "src/pages/not-found";
import PartnerConnect from "src/pages/partner-connect";
import RedirectPage from "src/pages/redirect";
import { Restricted } from "src/pages/restricted";
import { Signup } from "src/pages/signup";
import SSO from "src/pages/sso";
import { Unauthorized } from "src/pages/unauthorized";
import { Welcome, WelcomeWizard, WelcomeWorkspaces } from "src/pages/welcome";
import { NewWorkspace } from "src/pages/workspaces/new";
import { WorkspacesPage } from "src/pages/workspaces/workspaces";
import { PartnerRoutes } from "src/partner";
import { adminSwitchWorkspace, switchWorkspace } from "src/utils/workspaces";

import { ErrorBoundary } from "../error-boundary";
import { config } from "./config";
import { CustomRoute } from "./custom-route";
import { DEPRECATED_ROUTES } from "./deprecated-routes";
import { ProtectedRoute } from "./protected-route";

export const topLevelRoutes = [
  "onboarding",
  "syncs",
  "models",
  "destinations",
  "sources",
  "settings",
  "audiences",
  "extensions",
  "sequences",
  "partner-connect",
  "schema",
  "schema-v2",
  "analytics",
  "traits",
  "events",
  "partner",
];

export const authRoutes = ["login", "signup", "sso"];

const preworkspaceRoutes = ["workspaces", "welcome", "partner"];

interface Props {
  children: ReactNode;
}

const slugRoutes = [...config, ...DEPRECATED_ROUTES].map((props) => (
  <Route
    key={props.path}
    element={
      <ErrorBoundary>
        <CustomRoute {...props} />
      </ErrorBoundary>
    }
    path={props.path}
  />
));

const isPreworkspaceRoute = () => {
  const path = window.location.pathname.split("/")[1];
  if (path) {
    return preworkspaceRoutes.includes(path);
  }
  return false;
};

const isAuthRoute = () => {
  const path = window.location.pathname.split("/")[1];
  if (path) {
    return authRoutes.includes(path);
  }
  return false;
};

export const Router: FC<Props> = ({ children }) => {
  const { slug, user, getWorkspaceIdFromSlug, onboarding } = useUser();
  const [key, setKey] = useState("router");
  const { partnerUser } = useFlags();
  const [notFound, setNotFound] = useState(false);

  useEffect(() => {
    const redirect = async () => {
      const root = window.location.pathname.split("/")[1] ?? "";

      if (partnerUser && !slug) {
        window.history.replaceState("", "", `/partner`);
        return;
      }

      if (isPreworkspaceRoute()) {
        return;
      }

      if (user && isAuthRoute()) {
        window.history.replaceState("", "", `/workspaces`);
        return;
      }

      if (slug !== root) {
        if (slug) {
          if (topLevelRoutes.includes(root) || root === "") {
            window.history.replaceState("", "", `/${slug}${window.location.pathname}${window.location.search}`);
            setKey(uniqueId());
            return;
          }
          if (authRoutes.includes(root)) {
            window.history.replaceState("", "", `/${slug}${onboarding ? "/onboarding" : "/syncs"}${window.location.search}`);
            setKey(uniqueId());
            return;
          }
        }
        if (user) {
          const workspaceId = getWorkspaceIdFromSlug(root);
          if (workspaceId) {
            const isSwitching = await switchWorkspace(workspaceId, `${window.location.pathname}${window.location.search}`);
            if (!isSwitching) setNotFound(true);
          } else if (user?.is_admin) {
            const isSwitching = await adminSwitchWorkspace(root, `${window.location.pathname}${window.location.search}`);
            if (!isSwitching) setNotFound(true);
          } else if (slug) {
            setNotFound(true);
          }
        }
      }
    };

    redirect();
  }, []);

  useEffect(() => {
    setKey(uniqueId());
  }, [slug]);

  const isSlugless = !slug || isPreworkspaceRoute();

  if (notFound) {
    return <NotFound />;
  }

  return (
    <BrowserRouter key={key} basename={isSlugless ? undefined : slug}>
      <SentryRoutes>
        {isSlugless ? (
          <>
            <Route element={<Passwordless />} path="/signup/email" />
            <Route element={<Signup />} path="/signup" />
            <Route element={<Passwordless />} path="/login/email" />
            <Route element={<Login />} path="/login" />
            <Route element={<SSO />} path="/sso" />
            <Route element={<SSO />} path="/sso/:org" />

            <Route path="/welcome">
              <Route
                index
                element={
                  <ProtectedRoute>
                    <Welcome />
                  </ProtectedRoute>
                }
              />
              <Route
                element={
                  <ProtectedRoute>
                    <WelcomeWorkspaces />
                  </ProtectedRoute>
                }
                path="/welcome/workspaces"
              />
              <Route
                element={
                  <ProtectedRoute>
                    <WelcomeWizard />
                  </ProtectedRoute>
                }
                path="/welcome/:step"
              />
            </Route>
          </>
        ) : (
          <Route element={<Layout />}>{slugRoutes}</Route>
        )}
        {/* Routes that optionally use slugs */}
        <Route element={<Unauthorized />} path="/unauthorized" />
        <Route element={<Approval />} path="/approval" />
        <Route element={<Expired />} path="/expired" />
        <Route element={<Invalid />} path="/invalid" />
        <Route element={<Restricted />} path="/restricted" />
        <Route element={<RedirectPage />} path="/redirect" />
        <Route
          element={
            <ProtectedRoute>
              <PartnerConnect />
            </ProtectedRoute>
          }
          path="/partner-connect/:uuid"
        />
        <Route
          element={
            <ProtectedRoute>
              <WorkspacesPage />
            </ProtectedRoute>
          }
          path="/workspaces"
        />
        <Route
          element={
            <ProtectedRoute>
              <NewWorkspace />
            </ProtectedRoute>
          }
          path="/workspaces/new"
        />
        <Route
          element={
            <ProtectedRoute>
              <PartnerRoutes />
            </ProtectedRoute>
          }
          path="/partner/*"
        />

        <Route index element={<RootRedirect />} />
        <Route element={<Wildcard />} path="*" />
      </SentryRoutes>

      {children}
    </BrowserRouter>
  );
};

const Wildcard: FC = () => {
  const { user } = useUser();
  const location = useLocation();

  if (user) {
    return <NotFound />;
  }

  return <Navigate state={location?.state} to={`/login?returnTo=${encodeURI(location?.pathname)}`} />;
};

const RootRedirect: FC = () => {
  const { user, slug, onboarding } = useUser();

  if (!user) {
    return <Navigate to="/signup" />;
  }
  if (!slug) {
    return <Navigate to="/workspaces" />;
  }
  return <Navigate to={onboarding ? "/onboarding" : "/syncs"} />;
};
