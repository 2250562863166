import { FC, useState } from "react";

import { EditableHeading, useToast } from "@hightouchio/ui";
import * as Sentry from "@sentry/react";

import { TraitDefinitionsConstraint, useUpdateTraitMutation } from "src/graphql";

type Props = {
  id: string;
  isTemplate: boolean;
  name: string;
};

export const EditTraitName: FC<Readonly<Props>> = ({ id, isTemplate, name: originalName }) => {
  const [name, setName] = useState(originalName);
  const { toast } = useToast();

  const traitMutation = useUpdateTraitMutation();

  const updateName = async (name: string) => {
    try {
      if (name === originalName) {
        return;
      }

      await traitMutation.mutateAsync({ id, input: { name } });
      toast({
        id: "update-trait-name",
        title: "Trait name was successfully updated",
        variant: "success",
      });
    } catch (error) {
      setName(originalName);
      toast({
        id: "update-trait-name",
        title: "Trait name was not updated",
        message: error.message.includes(TraitDefinitionsConstraint.TraitDefinitionsNameParentModelIdKey)
          ? `There is an existing ${
              isTemplate ? "template" : "trait"
            } named "${name}" associated with this parent model. Please choose a different name and try again.`
          : error.message,
        variant: "error",
      });
      Sentry.captureException(error);
    }
  };

  return <EditableHeading value={name} onChange={setName} onSubmit={updateName} />;
};
