import { createContext, FC, useContext, ReactNode } from "react";

import { groupBy } from "lodash";

import { useUser } from "src/contexts/user-context";
import {
  DestinationDefinitionFragment as DestinationDefinition,
  MakeOptional,
  Maybe,
  SourceDefinitionFragment as SourceDefinition,
  SyncQuery,
} from "src/graphql";
import { QueryType } from "src/types/models";
import { useModelRun, useUpdateQuery } from "src/utils/models";

export type ColumnOption = {
  label: string;
  value: string | Record<string, unknown>;
  type: string;
  options?: ColumnOption[];
  metadata?: {
    properties: Record<string, string[] | null>;
  };
};

export type FormkitSync = SyncQuery["syncs"][0];

export type FormkitModel = MakeOptional<
  Pick<
    NonNullable<FormkitSync["segment"]>,
    | "columns"
    | "syncable_columns"
    | "connection"
    | "custom_query"
    | "id"
    | "name"
    | "parent"
    | "primary_key"
    | "query_dbt_model_id"
    | "query_looker_look_id"
    | "query_integrations"
    | "query_raw_sql"
    | "query_table_name"
    | "query_type"
    | "visual_query_filter"
    | "visual_query_parent_id"
    | "matchboosting_enabled"
  >,
  | "custom_query"
  | "parent"
  | "query_dbt_model_id"
  | "query_looker_look_id"
  | "query_integrations"
  | "query_raw_sql"
  | "query_table_name"
  | "visual_query_filter"
  | "visual_query_parent_id"
>;

export type FormkitDestination = NonNullable<FormkitSync["destination"]>;

export type ExternalSegment = {
  id: string;
  external_id: Maybe<string>;
  created_at: string;
};

export type DraftChange = { key: string; op: "add" | "replace" };

export type BaseFormkitContextType = {
  destination: FormkitDestination | undefined;
  destinationDefinition: DestinationDefinition | undefined;
  sourceDefinition: SourceDefinition | undefined;
  model: FormkitModel | undefined;
  sync: FormkitSync | undefined;
  supportsMatchboosting: boolean | undefined;
  reloadModel: () => void;
  reloadRows: () => void;
  queryRowsError: string | undefined;
  loadingModel: boolean;
  loadingRows: boolean;
  rows: any;
  slug: string | undefined;
  validate: any;
  /**Used for drafts */
  isModelDraft?: boolean;
  draftChanges?: DraftChange[];
  useHightouchUi: boolean;
};

export type FormkitContextType = BaseFormkitContextType & {
  columns: ColumnOption[];
  sourceId: string | undefined;
  workspaceId: string;
  isSetup: boolean;
  tunnel: any;
  credentialId: string | undefined;
  externalSegment: ExternalSegment | undefined;
  destinationConfig: Record<string, unknown> | undefined;
};

export const FormkitContext = createContext<FormkitContextType>({} as FormkitContextType);

export const useFormkitContext = () => useContext<FormkitContextType>(FormkitContext);

type FormkitProviderProps = {
  children: ReactNode;
  model?: FormkitModel;
  destination?: FormkitDestination;
  destinationConfig?: Record<string, unknown>;
  sync?: FormkitSync;
  destinationDefinition?: DestinationDefinition;
  sourceDefinition?: SourceDefinition;
  externalSegment?: ExternalSegment;
  supportsMatchboosting?: boolean;
  validate: any;
  sourceId?: string;
  credentialId?: string;
  /**Used within formkit to determine how to render secret fields */
  isSetup?: boolean;
  tunnel?: any;
  /**Used for drafts */
  isModelDraft?: boolean;
  draftChanges?: DraftChange[];
  useHightouchUi?: boolean;
};

const getColumnValue = (column: any) => {
  if (column.column_reference) {
    if (column.column_reference.type === "raw") {
      return column.column_reference.name;
    } else {
      return column.column_reference;
    }
  }
  return column.name;
};

const mapColumn = (column) => {
  const label = column.alias || column.name;
  const columnValue = getColumnValue(column);
  const option: ColumnOption = {
    value: columnValue,
    label,
    type: column.type,
    metadata: column.metadata,
  };
  return option;
};

export const MATCHBOOSTED_IDENTIFIER_KEY = "Boosted column name";

const mapColumns = (
  columns,
  isModelDraft: boolean | undefined,
  destination: FormkitDestination | undefined,
  supportsMatchboosting: boolean | undefined,
) => {
  if (columns?.length) {
    const groups = groupBy(columns, "model_name");
    if (destination && !supportsMatchboosting) {
      delete groups[MATCHBOOSTED_IDENTIFIER_KEY];
    }
    const modelNames = Object.keys(groups);
    const sortedModelNames = [
      ...modelNames.filter((name) => name === MATCHBOOSTED_IDENTIFIER_KEY),
      ...modelNames.filter((name) => name !== MATCHBOOSTED_IDENTIFIER_KEY),
    ];

    if (modelNames.length) {
      return sortedModelNames.map((label) => ({
        // XXX: All syncable columns will be grouped into draft models because
        // drafts do not currently work with audiences
        label: isModelDraft ? `${label} (draft)` : label,
        options: groups[label]?.map(mapColumn) ?? [],
      }));
    }
    return columns.map(mapColumn);
  }
  return [];
};

export const FormkitProvider: FC<Readonly<FormkitProviderProps>> = ({
  children,
  model,
  destination,
  destinationDefinition,
  sourceDefinition,
  supportsMatchboosting,
  sync,
  externalSegment,
  validate,
  sourceId,
  isSetup,
  tunnel,
  credentialId,
  draftChanges,
  isModelDraft,
  useHightouchUi,
  destinationConfig,
}) => {
  const { workspace } = useUser();

  const update = useUpdateQuery();

  const columns = mapColumns(model?.syncable_columns, isModelDraft, destination, supportsMatchboosting);

  const {
    rows,
    error: queryRowsError,
    runQuery,
    getSchema,
    schemaLoading,
    loading: rowsLoading,
  } = useModelRun(model?.query_type as QueryType, model?.columns, {
    modelId: model?.id,
    variables: {
      sourceId: model?.connection?.id,
      dbtModel: { id: model?.query_dbt_model_id },
      lookerLook: { id: model?.query_looker_look_id },
      parentModelId: model?.parent?.id ?? model?.visual_query_parent_id,
      sql: model?.query_raw_sql,
      conditions: model?.visual_query_filter?.conditions,
      table: model?.query_table_name,
      customQuery: model?.custom_query,
      sigma: model?.query_integrations,
    },
    onCompleted: async ({ columns, rows }, error) => {
      if (error || !columns) {
        return;
      }

      if (rows) {
        const jsonColumnsMetadata = extractJsonColumnsAndTheirProperties(rows);

        if (jsonColumnsMetadata) {
          const keys = Object.keys(jsonColumnsMetadata);
          keys.forEach((key) => {
            columns.forEach((column, index) => {
              if (column.name === key) {
                columns[index].metadata = {
                  properties: jsonColumnsMetadata[key] ? Array.from(jsonColumnsMetadata[key] || []) : undefined,
                };
              }
            });
          });
        }
      }

      await update({ model, columns, overwriteMetadata: rows ? true : false });
    },
  });

  const columnsLoading = sourceDefinition?.supportsResultSchema ? schemaLoading : rowsLoading;

  const reloadRows = () => {
    return runQuery({
      limit: true,
      // No reason to add this here since we only care about the columns.
      disableRowCounter: true,
    });
  };

  const reloadColumns = () => {
    if (sourceDefinition?.supportsResultSchema) {
      getSchema();
    } else {
      reloadRows();
    }
  };

  return (
    <FormkitContext.Provider
      value={{
        isSetup: Boolean(isSetup),
        model,
        destination,
        validate,
        sync: sync,
        destinationDefinition,
        destinationConfig,
        sourceDefinition,
        externalSegment,
        columns,
        supportsMatchboosting,
        loadingModel: columnsLoading,
        loadingRows: rowsLoading,
        reloadModel: reloadColumns,
        reloadRows,
        queryRowsError,
        rows,
        slug: destination?.type,
        sourceId,
        tunnel,
        workspaceId: String(workspace?.id),
        credentialId,
        draftChanges,
        isModelDraft,
        useHightouchUi: Boolean(useHightouchUi),
      }}
    >
      {children}
    </FormkitContext.Provider>
  );
};

function extractJsonColumnsAndTheirProperties(rows: Record<string, any>[]): { [column: string]: Set<string> } {
  const jsonColumns: { [column: string]: Set<string> } = {};
  rows.forEach((row) => {
    const columns = Object.keys(row);
    columns.forEach((column) => {
      if (Array.isArray(row[column])) {
        if (!jsonColumns[column]) {
          jsonColumns[column] = new Set();
        }
        const firstEntry = row[column][0];
        if (typeof firstEntry === "object" && !Array.isArray(firstEntry) && firstEntry !== null) {
          const props = Object.keys(firstEntry);
          props.forEach((prop) => jsonColumns[column]?.add(prop));
        }
      }
    });
  });

  return jsonColumns;
}
