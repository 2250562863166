import { FC, ReactNode, useEffect, useState } from "react";

import { ArrowLeftIcon, ArrowRightIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { Button, Column, ConfirmationDialog, IconButton, Paragraph, Row, SectionHeading, Spinner, Text } from "@hightouchio/ui";
import { Helmet } from "react-helmet";

export type SimpleWizardStep = {
  render: FC;
  title?: string;
  loading?: boolean;
  submitting?: boolean;
  continue?: ReactNode;
  continueLabel?: string;
  continueTooltip?: string;
  previousHidden?: boolean;
  onContinue?: () => void | Promise<void>;
  actions?: ReactNode;
  disabled?: boolean;
};

export interface SimpleWizardProps {
  title: string;
  step: number;
  steps: SimpleWizardStep[];
  setStep: (step: number) => void;
  previousDisabled?: boolean;
  onCancel: () => void;
  onSubmit: () => void;
}

export const SimpleWizard: FC<Readonly<SimpleWizardProps>> = ({
  title,
  step,
  steps,
  setStep,
  onCancel,
  onSubmit,
  previousDisabled,
}) => {
  const data = steps[step];
  const [submitting, setSubmitting] = useState(false);
  const [exiting, setExiting] = useState(false);

  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, [step]);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "Esc" || event.key === "Escape") {
        setExiting(true);
      }
    };
    window.addEventListener("keydown", handleKeyDown, false);
    return () => {
      window.removeEventListener("keydown", handleKeyDown, false);
    };
  }, [setExiting]);

  const isLastStep = step === steps.length - 1;
  const hasStepTitles = steps.every((step) => step.title);

  return (
    <Column minHeight={0} height="100%">
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <Row
        flexShrink={0}
        height="64px"
        align="center"
        gap={4}
        px={6}
        justify="space-between"
        pos="sticky"
        top={0}
        bg="white"
        borderBottom="1px"
        borderColor="base.border"
      >
        <SectionHeading>{title}</SectionHeading>
        <IconButton
          aria-label="Exit"
          onClick={() => {
            setExiting(true);
          }}
          icon={XMarkIcon}
        />
      </Row>

      {hasStepTitles && (
        <Row px={6} py={4} borderBottom="1px" borderColor="base.border" gap={6}>
          {steps.map((s, index) => {
            const isSelected = step === index;
            return (
              <Row gap={2} alignItems="center" key={index}>
                <Row
                  alignItems="center"
                  justifyContent="center"
                  p={4}
                  backgroundColor={isSelected ? "forest.dark" : "base.background"}
                  height={8}
                  width={8}
                  borderRadius={4}
                >
                  <Text fontWeight="medium" color={isSelected ? "white" : "base.text"}>
                    {index + 1}
                  </Text>
                </Row>
                <Text fontWeight="medium" color={isSelected ? "text.primary" : "text.secondary"}>
                  {s.title}
                </Text>
              </Row>
            );
          })}
        </Row>
      )}

      <Column flex={1} p={6} overflow="auto">
        {data?.loading ? <Spinner m="auto" size="lg" /> : data?.render({})}
      </Column>

      <Row py={4} px={6} borderTop="1px" borderColor="base.border" bg="white" flexShrink={0}>
        {step > 0 && !data?.previousHidden && (
          <Button
            icon={ArrowLeftIcon}
            isDisabled={data?.loading || previousDisabled}
            size="lg"
            onClick={() => setStep(step - 1)}
          >
            Go back
          </Button>
        )}
        <Row gap={4} ml="auto">
          {data?.actions || null}
          {data?.continue ?? (
            <Button
              directionIcon={isLastStep ? undefined : ArrowRightIcon}
              isDisabled={data?.disabled}
              isLoading={data?.submitting || submitting}
              size="lg"
              variant="primary"
              onClick={async () => {
                setSubmitting(true);
                if (isLastStep) {
                  await onSubmit();
                }
                if (data?.onContinue) {
                  await data?.onContinue();
                } else if (!isLastStep) {
                  setStep(step + 1);
                }
                setSubmitting(false);
              }}
            >
              {data?.continueLabel ?? "Continue"}
            </Button>
          )}
        </Row>
      </Row>
      <ConfirmationDialog
        confirmButtonText="Exit"
        isOpen={exiting}
        title="Are you sure?"
        variant="warning"
        onClose={() => setExiting(false)}
        onConfirm={() => {
          setExiting(false);
          onCancel();
        }}
      >
        <Paragraph>Your progress will be lost.</Paragraph>
      </ConfirmationDialog>
    </Column>
  );
};
