import { FC, useEffect, useMemo } from "react";

import { Row, Select, Text } from "@hightouchio/ui";
import { v4 as uuidv4 } from "uuid";

import { useFormErrorContext } from "src/contexts/form-error-context";
import { useColumnSuggestionsQuery } from "src/graphql";
import {
  AudienceParent,
  isColumnReference,
  OperatorOptions,
  PropertyCondition,
  shouldResetTimeType,
  shouldResetValue,
  TimeType,
} from "src/types/visual";

import { HStack } from "./condition";
import { validatePropertyCondition } from "./condition-validation";
import { Filter } from "./filter";
import { PropertyInput } from "./property-input";
import { formatValue, getModelIdFromColumn, getPropertyNameFromColumn } from "./utils";

export type ParameterizedPropertyFilter = {
  condition: PropertyCondition;
  parent: AudienceParent;
  onChange: (updates: PropertyCondition) => void;
};

export const ParameterizedPropertyFilter: FC<Readonly<ParameterizedPropertyFilter>> = (props) => {
  const { condition, parent, onChange } = props;
  const filterId = useMemo<string>(uuidv4, []);

  const { getErrors, setFieldError, removeErrors } = useFormErrorContext();

  const filterErrors = getErrors(filterId);
  const operatorError = filterErrors?.operator;
  const valueError = filterErrors?.value;

  useEffect(() => {
    setFieldError(filterId, validatePropertyCondition(condition));

    return () => {
      removeErrors([filterId]);
    };
  }, [condition.property, condition.operator, condition.value, filterId, condition.propertyOptions]);

  const modelId = isColumnReference(condition.property) ? getModelIdFromColumn(condition.property) : parent?.id;
  const columnName = isColumnReference(condition.property) ? getPropertyNameFromColumn(condition.property) : condition.property;

  const { data: columnSuggestionsData, isLoading: loadingSuggestions } = useColumnSuggestionsQuery(
    {
      modelIds: [String(modelId)],
      columnNames: [columnName],
    },
    {
      enabled: modelId !== null && columnName !== null,
    },
  );

  const suggestions = columnSuggestionsData?.getTopK?.columns?.find?.(
    (column) => column.modelId === String(modelId) && column.name === columnName,
  )?.values;

  const connectionType = props.parent?.connection?.definition?.type;
  const operatorOptions = condition.propertyType
    ? OperatorOptions[condition.propertyType].filter(
        (op) => !("supportedSources" in op) || op.supportedSources.includes(connectionType),
      )
    : undefined;

  const operatorLabel = operatorOptions?.find((option) => option.value === condition.operator)?.label;
  const formattedValue = formatValue(condition, condition.value);

  // Taken from <PropertyFilter />
  const handleChangeOperator = (value) => {
    if (shouldResetTimeType(condition.operator, value)) {
      onChange({ ...condition, operator: value, timeType: TimeType.Relative, value: null });
    } else if (shouldResetValue(condition.operator, value)) {
      onChange({ ...condition, operator: value, value: null });
    } else {
      onChange({ ...condition, operator: value });
    }
  };

  const handleChangeValue = (updates) => {
    onChange({ ...condition, value: updates.value });
  };

  return (
    <>
      <HStack gap={2} sx={{ alignItems: "flex-start" }}>
        {condition.property && (
          <Filter
            content={
              <>
                <Select
                  options={operatorOptions ?? []}
                  placeholder="Filter on"
                  value={condition.operator}
                  width="auto"
                  onChange={handleChangeOperator}
                />
                <PropertyInput
                  {...props}
                  error={valueError}
                  loading={loadingSuggestions}
                  suggestions={suggestions}
                  onChange={handleChangeValue}
                />
              </>
            }
            error={operatorError || valueError}
          >
            <Row gap={1} overflow="hidden">
              <Text color="text.secondary">{operatorLabel}</Text> <Text fontWeight="medium">{formattedValue}</Text>
            </Row>
          </Filter>
        )}
      </HStack>
    </>
  );
};
