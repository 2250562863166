import { FC } from "react";

import { ArrowsUpDownIcon, CheckIcon } from "@heroicons/react/24/outline";
import { Box, ChakraMenu, ChakraMenuButton, ChakraMenuItem, ChakraMenuList } from "@hightouchio/ui";
import { useSearchParams } from "react-router-dom";

import { SortOption } from "src/ui/table/use-table-config";

type Props = {
  options: SortOption<any>[] | undefined;
};

export const SortMenu: FC<Readonly<Props>> = ({ options = [] }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const key = searchParams.get("sort");
  const direction = searchParams.get("dir");

  const onChange = (option: SortOption<any>) => {
    searchParams.set("sort", option.key);
    searchParams.set("dir", option.direction);
    setSearchParams(searchParams);
  };

  return (
    <ChakraMenu>
      <ChakraMenuButton
        pos="relative"
        p={2}
        borderRadius="sm"
        _hover={{ bg: "gray.100" }}
        _focus={{ outline: "none", bg: "gray.100" }}
      >
        <ArrowsUpDownIcon width="18px" height="18px" />
        {key && <Box width="8px" height="8px" borderRadius="4px" bg="forest.base" pos="absolute" top="2px" right="2px" />}
      </ChakraMenuButton>
      <ChakraMenuList>
        {options.map((option) => {
          const isSelected = option.key === key && option.direction === direction;
          return (
            <ChakraMenuItem
              key={option.label}
              onClick={() => {
                onChange(option);
              }}
              bg={isSelected ? "var(--chakra-colors-primary-background)" : undefined}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              {option.label}
              <CheckIcon
                width="16px"
                opacity={isSelected ? 1 : 0}
                aria-hidden="true"
                color="var(--chakra-colors-primary-base)"
              />
            </ChakraMenuItem>
          );
        })}
      </ChakraMenuList>
    </ChakraMenu>
  );
};
