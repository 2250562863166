import { FC, ReactNode, useEffect, useState } from "react";

import { IconButton, PublishIcon, Tooltip, ChakraDrawer, ChakraDrawerContent, ChakraDrawerOverlay } from "@hightouchio/ui";
import { useFlags } from "launchdarkly-react-client-sdk";

import { updateIntercomPosition } from "src/lib/intercom";

import { NewFeatureDialog } from "../new-feature-modal";
import { Deployment } from "./common";
import { ModelDeploymentWizard } from "./model-deployment-wizard";
import { SyncDeploymentWizard } from "./sync-deployment-wizard";

interface DeployButtonProps {
  isDisabled: boolean;
  deployment: Deployment;
}

export const DeployButton: FC<DeployButtonProps> = ({ deployment, isDisabled }) => {
  const { deployments: deploymentsEnabled } = useFlags();
  const [isOpen, setIsOpen] = useState(false);
  const onClose = () => {
    setIsOpen(false);
  };

  const resourceName = deployment.resourceName.toLowerCase();

  if (!deploymentsEnabled) {
    return null;
  }

  return (
    <>
      <Tooltip message={deployment.isDraft ? `You cannot deploy a draft ${resourceName}` : `Deploy your ${resourceName}`}>
        <IconButton
          variant="secondary"
          aria-label="deploy"
          icon={PublishIcon}
          onClick={() => setIsOpen(true)}
          isDisabled={isDisabled}
        />
      </Tooltip>
      <DeploymentDrawer isOpen={isOpen}>
        <ChakraDrawerOverlay />
        <ChakraDrawerContent>
          {deployment.resourceType === "segments" ? (
            <ModelDeploymentWizard deployment={deployment} onClose={onClose} />
          ) : (
            <SyncDeploymentWizard deployment={deployment} onClose={onClose} />
          )}
        </ChakraDrawerContent>
      </DeploymentDrawer>
      <NewFeatureDialog
        docsLink="https://hightouch.com/docs/workspace-management/environments"
        featureKey="app-environments-modal"
        confirmButtonText="Got it"
        pages={[
          {
            src: "https://cdn.sanity.io/images/pwmfmi47/production/a55b9a36eeaa59ef102b4686cfd8c011c41c0195-1326x550.png",
            description: "Keep your sync and model configurations synchronized across workspace environments",
            altText: "Deploy.",
          },
          {
            src: "https://cdn.sanity.io/images/pwmfmi47/production/0c5189ac9f4dba06bf59da9e33b6b6e2af95a0ae-1320x582.png",
            description: "Deploy across dev, staging, and production",
            altText: "Choose environment.",
          },
          {
            src: "https://cdn.sanity.io/images/pwmfmi47/production/5c59ca6fde6bdcb0bf9ca3c6fceb53857e2d7af5-1342x876.png",
            description: "Easily understand any changes between environments",
            altText: "Resource diff.",
          },
        ]}
        title="⬆️ Deploy your models & syncs between environments"
      />
    </>
  );
};

const DeploymentDrawer: FC<{ children: ReactNode; isOpen: boolean }> = ({ children, isOpen }) => {
  useEffect(() => {
    if (isOpen) {
      updateIntercomPosition(672);
    } else {
      updateIntercomPosition(0);
    }
    return () => {
      updateIntercomPosition(0);
    };
  }, [isOpen]);

  // TODO: Add support for this drawer type to Hightouch UI
  // Global styles are a workaround for this bug https://github.com/chakra-ui/chakra-ui/issues/2893
  return (
    <ChakraDrawer
      size="lg"
      isOpen={isOpen}
      onClose={() => {}}
      placement="right"
      closeOnEsc={false}
      closeOnOverlayClick={false}
      trapFocus={false}
    >
      <ChakraDrawerOverlay />
      <ChakraDrawerContent>{children}</ChakraDrawerContent>
    </ChakraDrawer>
  );
};
