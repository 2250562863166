import { Route, Routes } from "react-router-dom";

import { CreateIdentityResolutionGraph } from "./create";
import { IdentityResolutionGraph } from "./graph";
import { IdentityResolutionGraphs } from "./graphs";

export const IdentityResolutionRoutes = () => {
  return (
    <Routes>
      <Route index element={<IdentityResolutionGraphs />} />
      <Route path="/new" element={<CreateIdentityResolutionGraph />} />
      <Route path="/:id/*" element={<IdentityResolutionGraph />} />
    </Routes>
  );
};
