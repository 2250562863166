import { EventSourceType, EventWarehouseDestinationType } from "@hightouch/core/server/resources/events/types";

export const eventSourceDefinitions = {
  [EventSourceType.JAVASCRIPT]: {
    name: "Javascript",
    type: EventSourceType.JAVASCRIPT,
    icon: "https://simpleicons.org/icons/javascript.svg",
  },
  [EventSourceType.HTTP]: {
    name: "HTTP",
    type: EventSourceType.HTTP,
    icon: "https://simpleicons.org/icons/curl.svg",
  },
  [EventSourceType.NODE]: {
    name: "Node",
    type: EventSourceType.NODE,
    icon: "https://simpleicons.org/icons/nodedotjs.svg",
  },
  [EventSourceType.PYTHON]: {
    name: "Python",
    type: EventSourceType.PYTHON,
    icon: "https://simpleicons.org/icons/python.svg",
  },
  [EventSourceType.IOS]: {
    name: "iOS",
    type: EventSourceType.IOS,
    icon: "https://simpleicons.org/icons/ios.svg",
  },
  [EventSourceType.ANDROID]: {
    name: "Android",
    type: EventSourceType.ANDROID,
    icon: "https://simpleicons.org/icons/android.svg",
  },
};

export const eventWarehouseDestinationDefinitions = {
  [EventWarehouseDestinationType.SNOWFLAKE]: {
    name: "Snowflake",
    type: EventWarehouseDestinationType.SNOWFLAKE,
    icon: "https://simpleicons.org/icons/snowflake.svg",
  },
  [EventWarehouseDestinationType.REDSHIFT]: {
    name: "Redshift",
    type: EventWarehouseDestinationType.REDSHIFT,
    icon: "https://simpleicons.org/icons/amazonaws.svg",
  },
  [EventWarehouseDestinationType.BIGQUERY]: {
    name: "BigQuery",
    type: EventWarehouseDestinationType.BIGQUERY,
    icon: "https://simpleicons.org/icons/googlecloud.svg",
  },
  [EventWarehouseDestinationType.DATABRICKS]: {
    name: "Databricks",
    type: EventWarehouseDestinationType.DATABRICKS,
    icon: "https://simpleicons.org/icons/databricks.svg",
  },
};

export { EventSourceType, EventWarehouseDestinationType };
