import {
  Bars3BottomRightIcon,
  Cog6ToothIcon,
  FolderIcon,
  QuestionMarkCircleIcon,
  TableCellsIcon,
  ViewColumnsIcon,
} from "@heroicons/react/24/solid";

import { commonActivityMappings } from "src/components/resource-activity/common-mappings";
import { ResourceActivityMapper } from "src/components/resource-activity/timeline";

export const modelActivityMappers: ResourceActivityMapper[] = [
  ...commonActivityMappings,
  {
    accessor: "primary_key",
    parser: (_, { parsedDiff, oldValue }) => {
      if (parsedDiff.type !== "value") {
        return null;
      }

      return {
        message: `updated the primary key from \`${oldValue}\` to \`${parsedDiff.value}\``,
        icon: <ViewColumnsIcon />,
      };
    },
  },
  {
    accessor: "query_table_name",
    parser: (_, { parsedDiff }) => {
      if (parsedDiff.type !== "value") {
        return null;
      }

      return {
        message: `updated the query source table to \`${parsedDiff.value}\``,
        icon: <TableCellsIcon />,
      };
    },
  },
  {
    accessor: "query_raw_sql",
    parser: (_, { parsedDiff }) => {
      if (parsedDiff.type !== "value") {
        return null;
      }

      return {
        message: `updated the model SQL query`,
        icon: <Bars3BottomRightIcon />,
      };
    },
  },
  {
    accessor: "folder_id",
    parser: (_, { parsedDiff }) => {
      if (parsedDiff.type !== "value") {
        return null;
      }

      return {
        message: `updated the model folder`,
        icon: <FolderIcon />,
      };
    },
  },
];

export const modelColumnMappers: ResourceActivityMapper[] = [
  {
    accessor: "custom_type",
    parser: (metadata, { parsedDiff, oldValue }) => {
      if (parsedDiff.type !== "value") {
        return null;
      }

      if (parsedDiff.value === null) {
        return {
          message: `removed custom type \`${oldValue}\` from \`${metadata.new.type}\` column \`${metadata.new.name}\``,
          icon: <Cog6ToothIcon />,
        };
      }

      return {
        message: `updated the type of column \`${metadata.new.name}\` to \`${parsedDiff.value}\``,
        icon: <Cog6ToothIcon />,
      };
    },
  },
  {
    accessor: "type",
    parser: (metadata, { parsedDiff }) => {
      if (parsedDiff.type !== "value") {
        return null;
      }

      return {
        message: `updated the type of column \`${metadata.new.name}\` to \`${parsedDiff.value}\``,
        icon: <Cog6ToothIcon />,
      };
    },
  },
  {
    accessor: "alias",
    parser: (metadata, { parsedDiff, oldValue }) => {
      if (parsedDiff.type !== "value") {
        return null;
      }

      if (parsedDiff.value === null || parsedDiff.value === "") {
        return {
          message: `removed alias \`${oldValue}\` from \`${metadata.new.type}\` column \`${metadata.new.name}\``,
          icon: <QuestionMarkCircleIcon />,
        };
      }

      return {
        message: `updated the alias of column \`${metadata.new.name}\` to \`${parsedDiff.value}\``,
        icon: <QuestionMarkCircleIcon />,
      };
    },
  },
  {
    accessor: "disable_preview",
    parser: (metadata, { parsedDiff }) => {
      if (parsedDiff.type !== "value") {
        return null;
      }

      return {
        message: `${parsedDiff.value ? "redacted" : "unredacted"} column \`${metadata.new.name}\``,
        icon: <QuestionMarkCircleIcon />,
      };
    },
  },
];

export const modelColumnIgnoreFields = ["top_k_sync_interval", "top_k_enabled", "raw_type"];
