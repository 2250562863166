import { FC, useEffect, useState } from "react";

import { ArrowPathIcon } from "@heroicons/react/24/outline";
import { Row, Column, useToast, Button, EditableHeading } from "@hightouchio/ui";
import { isEqual, omit, omitBy } from "lodash";
import { useParams } from "react-router-dom";

import { IntegrationIcon } from "src/components/integrations/integration-icon";
import { SourceForm } from "src/components/sources/setup";
import { Testing } from "src/components/sources/testing";
import { useSourceTesting } from "src/components/sources/testing/hooks";
import { Warning } from "src/components/warning";
import { useUser } from "src/contexts/user-context";
import { ListSourceTestStepsQueryVariables, SourceQuery, useSourceQuery, useUpdateSourceV2Mutation } from "src/graphql";
import { BottomBar } from "src/partner/bottom-bar";
import { Watermark } from "src/partner/watermark";
import { Modal } from "src/ui/modal";
import { SourceConfig } from "src/utils/sources";

const Loader: FC = () => {
  const { id } = useParams<{ id: string }>();

  const { data: source } = useSourceQuery(
    { id: String(id) },
    { enabled: Boolean(id), select: (data) => data.connections_by_pk, suspense: true },
  );

  if (!id) {
    return null;
  }

  if (!source) {
    return <Warning subtitle="It may have been deleted" title="Source not found" />;
  }

  return <Source source={source} />;
};

const Source: FC<Readonly<{ source: NonNullable<SourceQuery["connections_by_pk"]> }>> = ({ source }) => {
  const { user } = useUser();
  const { toast } = useToast();
  const [name, setName] = useState("");
  const [config, setConfig] = useState<SourceConfig | undefined>();
  const [tunnelId, setTunnelId] = useState<string | null>();
  const [credentialId, setCredentialId] = useState<string | undefined>();
  const [testConnectionModalOpen, setTestConnectionModalOpen] = useState(false);

  const { results: testResults, steps: testSteps, getTestSteps, runTest, timeElapsed } = useSourceTesting();
  const { isLoading: updateLoading, mutateAsync: updateSource } = useUpdateSourceV2Mutation();

  const reset = () => {
    setName(source?.name ?? "");
    setConfig(source?.config ?? undefined);
    setTunnelId(source?.tunnel?.id ?? undefined);
    setCredentialId(source?.credential_id ?? undefined);
  };

  const onUpdate = () => {
    toast({
      id: "update-source",
      title: "Source was updated",
      variant: "success",
    });
  };

  const saveName = async (name: string) => {
    await updateSource({
      id: source.id,
      object: {
        name,
        updated_by: user?.id != null ? String(user?.id) : undefined,
      },
    });

    onUpdate();
  };

  const save = async () => {
    let updatedConfig = config;

    if (tunnelId) {
      updatedConfig = {
        ...updatedConfig,
        host: null,
        server: null,
        port: null,
      };
    }

    await updateSource({
      id: source.id,
      object: {
        tunnel_id: tunnelId ? tunnelId : null,
        credential_id: credentialId != null ? String(credentialId) : undefined,
        config: updatedConfig,
      },
    });
  };

  const configChanged = !isEqual(
    config
      ? omit(
          omitBy(config, (v) => v === undefined),
          ["methodKey"],
        )
      : undefined,
    omit(source.config, ["methodKey"]) ?? undefined,
  );
  const tunnelChanged = !isEqual(tunnelId, source.tunnel?.id ?? undefined);
  const credentialChanged = !isEqual(credentialId, source.credential_id ?? undefined);

  const dirty = tunnelChanged || configChanged || credentialChanged;
  const complete = tunnelId !== null;

  const variables: ListSourceTestStepsQueryVariables = {
    sourceType: source.definition?.type,
    sourceId: String(source.id),
    configuration: config,
    credentialId: credentialId ? Number(credentialId) : undefined,
    tunnelId: tunnelId ? String(tunnelId) : undefined,
  };

  useEffect(() => {
    reset();
  }, [source]);

  return (
    <>
      <Watermark />

      <Column flex={1} width="100%">
        <Column flex={1} maxW="800px" width="100%" p={6} pb={10} gap={6}>
          <Row gap={4} align="center">
            <IntegrationIcon src={source.definition.icon} name={source.definition.name} />
            <EditableHeading size="lg" value={name} onChange={setName} onSubmit={saveName} />
          </Row>

          <Column flex={1}>
            {source.definition && (
              <SourceForm
                //Cheeky way to reset child componenents to initial state after save. (EX: Reseting senstive field edit state)
                key={`${updateLoading}`}
                hideSecret
                config={config}
                credentialId={credentialId}
                definition={source.definition}
                hasSetupLightning={false}
                isSetup={false}
                lightningEnabled={false}
                showSyncEngine={false}
                plannerDatabase={undefined}
                setConfig={setConfig}
                setCredentialId={setCredentialId}
                setLightningEnabled={() => {}}
                setPlannerDatabase={() => {}}
                setTunnelId={setTunnelId}
                sourceId={source.id}
                tunnelId={tunnelId}
              />
            )}
          </Column>
        </Column>
      </Column>

      <BottomBar>
        <Button size="lg" variant="primary" isDisabled={!dirty || !complete} isLoading={updateLoading} onClick={save}>
          Save changes
        </Button>
        {!source.definition?.disableTest && config ? (
          <Button
            size="lg"
            isDisabled={!complete}
            onClick={async () => {
              await getTestSteps(variables);
              runTest(variables);
              setTestConnectionModalOpen(true);
            }}
          >
            Test connection
          </Button>
        ) : null}
      </BottomBar>

      <Modal
        footer={
          <>
            <Button
              variant={testResults?.success !== false && !dirty ? undefined : "secondary"}
              onClick={() => {
                setTestConnectionModalOpen(false);
              }}
            >
              Close
            </Button>
            {testResults?.success === false ? (
              <Button
                icon={ArrowPathIcon}
                onClick={() => {
                  runTest(variables);
                }}
              >
                Test again
              </Button>
            ) : dirty ? (
              <Button
                isDisabled={!testResults?.success}
                isLoading={updateLoading}
                onClick={async () => {
                  await save();
                  setTestConnectionModalOpen(false);
                }}
              >
                Save changes
              </Button>
            ) : null}
          </>
        }
        isOpen={testConnectionModalOpen}
        sx={{ maxWidth: "800px", width: "90%" }}
        title={`Test connection to ${source.definition?.name}`}
        onClose={() => {
          setTestConnectionModalOpen(false);
        }}
      >
        <Testing
          config={config}
          credentialId={credentialId}
          isSetup={false}
          plannerDatabase={undefined}
          results={testResults}
          sourceDefinition={source.definition}
          steps={testSteps}
          timeElapsed={timeElapsed}
        />
      </Modal>
    </>
  );
};

export default Loader;
