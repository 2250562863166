import { FC } from "react";

import { Box, Paragraph, Skeleton, SkeletonBox, Text as HightouchUiText } from "@hightouchio/ui";
import _ from "lodash";
import { DollarSignIcon } from "lucide-react";
import { Container, Grid, Text } from "theme-ui";

import { Column, Row } from "src/ui/box";
import { Editor } from "src/ui/editor";
import { VariableIcon } from "src/ui/icons";
import { flattenOptions } from "src/ui/select/select";

import { useFormkitContext } from "./formkit-context";
import { FormProps, JsonColumnProps } from "./types";

const rowSx = {
  minHeight: "36px",
  alignItems: "center",
  fontSize: 0,
  fontWeight: "semi",
  px: 1,
  borderBottom: "small",
  pointerEvents: undefined,
  ":hover": { bg: "base.0" },
};

export const TemplateInput: FC<Readonly<FormProps & { jsonColumnProperties: JsonColumnProps }>> = ({
  value,
  onChange,
  templates,
  overwriteColumnsWithArrayProps,
  columnOptions,
  useHightouchUi,
}) => {
  const formkitColumns = useFormkitContext().columns;
  const columns = overwriteColumnsWithArrayProps ? columnOptions ?? [] : formkitColumns;
  const { loadingRows } = useFormkitContext();

  const flatColumns = flattenOptions(columns);
  // TODO: Properly support merge columns
  // Boosted columns are intentionally unsupported
  const standardColumns = flatColumns.filter((c) => typeof c.value === "string");
  const normalizedStandardColumns = _.uniqBy(
    standardColumns.map((option) => ({ label: option.label, value: option.value })),
    "value",
  );

  const traitEnrichmentColumns = flatColumns.filter(
    (c) => typeof c.value === "object" && c.value.type === "additionalColumnReference",
  );
  const normalizedTraitColumns = _.uniqBy(
    traitEnrichmentColumns.map((option) => ({ label: option.label, value: `traits['${option.value.columnAlias}']` })),
    "value",
  );

  const contexts = [
    // XXX these are defined provided as context in
    // `/packages/backend/sdk/lib/liquid-template/liquid.ts`
    { label: "Model name", value: "context['model_name']" },
    { label: "Model ID", value: "context['model_id']" },
    { label: "Sync ID", value: "context['sync_id']" },
    { label: "Sync Run ID", value: "context['sync_run_id']" },
    { label: "Timestamp", value: "context['timestamp']" },
  ];

  const sortedColumns = _.orderBy(normalizedStandardColumns, "label");
  const columnsWithContext = [...sortedColumns, ...normalizedTraitColumns, ...contexts];
  const unique = _.uniq(columnsWithContext);
  const sortedTemplates = _.orderBy(templates, "name");

  const insertVar = (val: string) => {
    let template = value.template;
    const isContext = contexts.find(({ value }) => val === value);
    const isTraits = normalizedTraitColumns.find(({ value }) => val === value);
    if (!template || template.length === 0) {
      template = isContext || isTraits ? `{{ ${val} }}` : `{{ row['${val}'] }}`;
      onChange({ ...value, template: template });
      return;
    }

    // if template already has closing braces, insert variable on its own
    template = template.trimRight();
    if (template.endsWith("}}")) {
      template = isContext || isTraits ? `${template} {{ ${val} }}` : `${template} {{ row['${val}'] }}`;
      onChange({ ...value, template: template });
      return;
    }

    //if template ends with row, append field
    if (template.endsWith("row") || template.endsWith("traits")) {
      template = isContext || isTraits ? `${template.substring(0, template.length - 3)}${val} }}` : `${template}['${val}'] }}`;
    } else if (template.endsWith("row.") || template.endsWith("traits.")) {
      template =
        isContext || isTraits
          ? `${template.substring(0, template.length - 4)}${val} }}`
          : template.substring(0, template.length - 1);
      template = `${template}['${val}'] }}`;
    } else if (template.endsWith("{{")) {
      template = isContext || isTraits ? `${template} ${val} }}` : `${template} row['${val}'] }}`;
    } else {
      //add closing braces and append field
      template = isContext || isTraits ? `${template} }} {{ ${val} }}` : `${template} }} {{ row['${val}'] }}`;
    }
    onChange({ ...value, template: template });
  };

  const insertFunction = (val: string, placeholders?: string[]) => {
    let template = value.template;
    //function with placeholders i.e. includes: 'substring'
    const insertVal = placeholders ? `${val} : ${placeholders.join(", ")}` : `${val}`;

    //if template editor is empty fill with placeholder variable and append function
    if (!template || template.length === 0) {
      template = `{{ row['<field>'] | ${insertVal} }}`;
      onChange({ ...value, template: template });
      return;
    }
    template = template.trimRight();

    //if template ends with opening brackets, add placeholder var and append function
    if (template.endsWith("{{")) {
      template = `${template} row['<field>'] | ${insertVal} }}`;
      onChange({ ...value, template: template });
      return;
    }

    //if template is closed, pipe function at end
    if (template.endsWith("}}")) template = template.substring(0, template.length - 2);
    template = template.trimRight();
    if (!template.endsWith("|")) {
      template = `${template} |`;
    }
    template = `${template} ${insertVal} }}`;
    onChange({ ...value, template: template });
  };

  if (useHightouchUi) {
    return (
      <Box display="flex" flex="1" flexDirection="column" gap={3} minWidth={0} p={3}>
        <Box borderColor="gray.300" borderWidth="1px" flex="none" height="120px">
          <Editor
            code={value.template || ""}
            language="liquid"
            sx={{ height: "100%" }}
            onChange={(val) => onChange({ ...value, template: val })}
          />
        </Box>

        <Box display="flex" flex="1" gap={2} minHeight={0}>
          <Box display="flex" flex="none" flexDirection="column" width="140px">
            <Box borderBottomWidth="2px" borderColor="gray.300" flex="none">
              <HightouchUiText>Variables</HightouchUiText>
            </Box>

            <Box display="flex" flex="1" flexDirection="column" minHeight={0} overflowY="auto" overscrollBehaviorY="contain">
              {overwriteColumnsWithArrayProps && loadingRows ? (
                <ShimmerLoadingState />
              ) : (
                unique.map(({ label, value }) => (
                  <Box
                    key={label}
                    _hover={{
                      bg: "gray.100",
                    }}
                    alignItems="center"
                    borderBottomWidth="1px"
                    borderColor="gray.300"
                    cursor="pointer"
                    display="flex"
                    flex="none"
                    gap={1}
                    height={8}
                    px={1}
                    onClick={() => {
                      insertVar(value);
                    }}
                  >
                    <Box as={DollarSignIcon} boxSize={3} />
                    <HightouchUiText isTruncated size="sm">
                      {label}
                    </HightouchUiText>
                  </Box>
                ))
              )}
            </Box>
          </Box>

          <Box display="flex" flex="1" flexDirection="column" minWidth={0}>
            <Box borderBottomWidth="2px" borderColor="gray.300" flex="none">
              <HightouchUiText>Functions</HightouchUiText>
            </Box>

            <Box display="flex" flex="1" flexDirection="column" minHeight={0} overflowY="auto" overscrollBehaviorY="contain">
              {sortedTemplates.map(({ name, placeholders, description }) => (
                <Box
                  key={name}
                  _hover={{
                    bg: "gray.100",
                  }}
                  borderBottomWidth="1px"
                  borderColor="gray.300"
                  cursor="pointer"
                  display="flex"
                  flex="none"
                  p={1}
                  onClick={() => {
                    insertFunction(name, placeholders);
                  }}
                >
                  <Box flex="none" width="120px">
                    <HightouchUiText isTruncated size="sm">
                      {name}
                    </HightouchUiText>
                  </Box>

                  <Box flex="1" minWidth={0}>
                    <Paragraph size="sm">{description}</Paragraph>
                  </Box>
                </Box>
              ))}
            </Box>
          </Box>
        </Box>
      </Box>
    );
  }

  return (
    <Grid gap={2} sx={{ flex: "1 1 0", width: "100%", overflow: "hidden", p: 4 }}>
      <Row sx={{ padding: "3px", border: "1px solid #E9ECF5" }}>
        <Editor
          code={value.template || ""}
          language="liquid"
          sx={{ height: "120px" }}
          onChange={(val) => onChange({ ...value, template: val })}
        />
      </Row>
      <Row sx={{ flex: 1, overflow: "hidden" }}>
        <Column sx={{ mr: 2, width: "140px" }}>
          <Row
            sx={{
              borderBottom: "medium",
              alignItems: "center",
              fontWeight: "semi",
              fontSize: 1,
            }}
            onClick={() => onChange({ type: "template" })}
          >
            <Text>Variables</Text>
          </Row>
          {overwriteColumnsWithArrayProps && loadingRows ? (
            <ShimmerLoadingState />
          ) : (
            <Column sx={{ overflow: "auto", scrollbarWidth: "none" }}>
              {unique.map(({ label, value }) => {
                return (
                  <Row
                    key={`template-${label}`}
                    sx={{
                      ...rowSx,
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      insertVar(value);
                    }}
                  >
                    <VariableIcon color="base.5" size={18}></VariableIcon>
                    <Text sx={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>{label}</Text>
                  </Row>
                );
              })}
            </Column>
          )}
        </Column>
        <Column>
          <Row
            sx={{
              fontWeight: "semi",
              borderBottom: "medium",
              cursor: "default",
              fontSize: 1,
              alignItems: "center",
            }}
            onClick={() => onChange({ type: "template" })}
          >
            <Text>Functions</Text>
          </Row>
          <Column sx={{ overflow: "auto", scrollbarWidth: "none" }}>
            {sortedTemplates.map((template) => {
              return (
                <Row
                  key={template.name}
                  sx={{
                    minHeight: "36px",
                    alignItems: "center",
                    fontSize: 0,
                    cursor: "pointer",
                    p: 1,
                    borderBottom: "small",
                    pointerEvents: undefined,
                    ":hover": { bg: "base.0" },
                  }}
                  onClick={() => {
                    insertFunction(template.name, template.placeholders);
                  }}
                >
                  <Text
                    sx={{
                      fontWeight: "semi",
                      pr: 2,
                      flex: 1,
                      minWidth: "80px",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {template.name}
                  </Text>
                  <Text sx={{ color: "base.5", flex: 3, pl: 2 }}>{template.description}</Text>
                </Row>
              );
            })}
          </Column>
        </Column>
      </Row>
    </Grid>
  );
};

const ShimmerLoadingState = () => (
  <Skeleton isLoading={true}>
    {Array(4).map((index) => (
      <Row key={index} sx={rowSx}>
        <Container sx={{ width: "100%", margin: 0 }}>
          <SkeletonBox borderRadius="sm" height="18px" width="100%" />
        </Container>
      </Row>
    ))}
  </Skeleton>
);
