import { FC, useState } from "react";

import { Row, Box, ButtonGroup, Button, SectionHeading, Link, Paragraph, Text, useToast } from "@hightouchio/ui";
import * as Sentry from "@sentry/browser";
import moment from "moment";
import pluralize from "pluralize";

import { CreateCredential } from "src/components/credentials";
import { BulkDeleteConfirmationModal } from "src/components/modals/bulk-delete-confirmation-modal";
import { Settings } from "src/components/settings";
import { useDeleteCloudCredentialsMutation, useCloudCredentialsV2Query } from "src/graphql";
import { AWSIcon } from "src/ui/icons/logo-aws";
import { AzureIcon } from "src/ui/icons/logo-azure";
import { GCPIcon } from "src/ui/icons/logo-gcp";
import { Table } from "src/ui/table";
import { useRowSelect } from "src/ui/table/use-row-select";

const columns = [
  {
    name: "Name",
    cell: ({ name, provider }) => {
      let Icon;

      switch (provider) {
        case "aws":
          Icon = AWSIcon;
          break;
        case "azure":
          Icon = AzureIcon;
          break;
        case "gcp":
          Icon = GCPIcon;
          break;
      }

      return (
        <Row align="center" gap={2}>
          <Icon position="relative" top="0.5" />
          <Text fontWeight="medium" isTruncated>
            {name}
          </Text>
        </Row>
      );
    },
  },
  {
    name: "Created at",
    cell: ({ created_at }) => moment(created_at).calendar(),
    max: "240px",
  },
];

export const CloudProviders: FC = () => {
  const { toast } = useToast();
  const [addingCredential, setAddingCredential] = useState<boolean>(false);
  const [confirmingDelete, setConfirmingDelete] = useState<boolean>(false);
  const { selectedRows, onRowSelect } = useRowSelect();

  const { data, isLoading } = useCloudCredentialsV2Query();
  const { mutateAsync: bulkDelete } = useDeleteCloudCredentialsMutation();

  const bulkDeleteCloudProviders = async () => {
    onRowSelect([]);

    const count = selectedRows.length;
    const pluralizedLabel = pluralize("cloud provider", count);

    try {
      await bulkDelete({ ids: selectedRows.map(String) });

      toast({
        id: "bulk-delete-cloud-providers",
        title: `Deleted ${count} ${pluralizedLabel}`,
        variant: "success",
      });

      onRowSelect([]);
    } catch (error) {
      toast({
        id: "bulk-delete-cloud-providers",
        title: `Failed to delete ${pluralizedLabel}`,
        variant: "error",
      });

      Sentry.captureException(error);
    }
  };

  return (
    <Settings route="cloud-providers" title="Cloud providers">
      <Row justify="space-between" mb={8}>
        <Box maxWidth="2xl">
          <SectionHeading>Cloud providers</SectionHeading>
          <Paragraph mt={1}>
            Authorize access to resources in your AWS or GCP account. These credentials can be used to set up data sources like
            S3 and BigQuery, or to store Hightouch's sync logs in a bucket you control. Learn more in our{" "}
            <Link href="https://hightouch.com/docs/security/storage">docs</Link>.
          </Paragraph>
        </Box>

        <ButtonGroup>
          {selectedRows.length > 0 && (
            <Button variant="warning" onClick={() => setConfirmingDelete(true)}>
              Delete selected
            </Button>
          )}

          <Button variant="primary" onClick={() => setAddingCredential(true)}>
            Add cloud provider
          </Button>
        </ButtonGroup>
      </Row>

      <Table
        columns={columns}
        data={data?.getCloudCredentials}
        loading={isLoading}
        placeholder={placeholder}
        selectedRows={selectedRows}
        onSelect={onRowSelect}
      />

      {addingCredential && <CreateCredential onClose={() => setAddingCredential(false)} />}

      <BulkDeleteConfirmationModal
        count={selectedRows.length}
        isOpen={confirmingDelete}
        label="cloud provider"
        onClose={() => setConfirmingDelete(false)}
        onDelete={bulkDeleteCloudProviders}
      />
    </Settings>
  );
};

const placeholder = {
  title: "No cloud credentials",
  error: "Credentials failed to load, please try again.",
};
