import { useMemo, useState, FC, useRef, useEffect } from "react";

import {
  ChevronDownIcon,
  Column,
  Box,
  Text,
  Avatar,
  Link,
  LightningBoltIcon,
  SelectorIcon,
  Tooltip,
  Row,
  ArrowLeftIcon,
} from "@hightouchio/ui";
import { useFlags } from "launchdarkly-react-client-sdk";
import { partition } from "lodash";
import { useLocation } from "react-router-dom";
import { isPresent } from "ts-extras";

import grain from "src/assets/backgrounds/grain.png";
import { FeedbackMenu } from "src/components/app/feedback-menu";
import { UserMenu } from "src/components/layout/header/user-menu";
import { InviteFormModal } from "src/components/modals/invite-modal";
import { useUser } from "src/contexts/user-context";
import { ResourceToPermission, ResourcePermissionGrant } from "src/graphql";
import { useEntitlements } from "src/hooks/use-entitlement";
import { Logo } from "src/ui/brand";
import {
  AddUserIcon,
  AudienceIcon,
  AudienceSchemaIcon,
  ChatIcon,
  DestinationIcon,
  DocsIcon,
  ExtensionsIcon,
  HightouchLogo,
  ModelIcon,
  OnboardingIcon,
  PriorityListIcon,
  SequencesIcon,
  SettingIcon,
  SourceIcon,
  SyncIcon,
  IdentityResolutionIcon,
} from "src/ui/icons";
import { AnalyticsIcon } from "src/ui/icons/analytics";
import { TraitIcon } from "src/ui/icons/trait";
// eslint-disable-next-line no-restricted-imports
import { Menu, MenuOption } from "src/ui/menu";
import { navigateToWorkspaces } from "src/utils/navigate";
import { switchWorkspace } from "src/utils/workspaces";

import { Permission } from "../permission";
import { CollapsibleSection } from "./collapsible-section";
import { ITEM_GAP, NAV_COLLAPSED_WIDTH, NAV_EXPANDED_WIDTH, NAV_HEIGHT_STYLES, NAV_WIDTHS_PER_BREAKPOINT } from "./constants";
import { NavButton, NavLink, NavItem } from "./nav-item";

export const Nav: FC = () => {
  const navRef = useRef<HTMLDivElement | null>(null);
  const { user, onboarding, workspaces, workspace } = useUser();
  const { data: entitlementsData } = useEntitlements(false);
  const {
    appPerformancePage,
    syncSequencesWorkspace,
    schemaV2,
    appAnalyticsLaunched,
    traitsV2,
    identityResolution,
    eventCollection,
  } = useFlags();
  const [openInvite, setOpenInvite] = useState(false);
  const [navWidth, setNavWidth] = useState(NAV_EXPANDED_WIDTH);
  const [isWorkspaceSelectorOpen, setIsWorkspaceSelectorOpen] = useState(false);
  const location = useLocation();

  const hideTooltips = navWidth > NAV_COLLAPSED_WIDTH;

  useEffect(() => {
    // Watch width so that tooltips can be disabled
    const navObserver = new ResizeObserver((entries) => {
      entries.forEach((entry) => {
        setNavWidth(entry.contentRect.width);
      });
    });

    if (navRef.current) {
      navObserver.observe(navRef.current);
    }

    return () => {
      navObserver.disconnect();
    };
  }, []);

  const menuOptions: MenuOption[] = useMemo(() => {
    const [activeWorkspaces, inactiveWorkspaces] = partition(workspaces, ({ slug }) => slug === workspace?.slug);

    const activeWorkspace = activeWorkspaces?.[0];
    const orderedWorkspaces = [activeWorkspace].concat(inactiveWorkspaces).filter(isPresent);

    const options: MenuOption[] = [
      {
        title: "Actions",
        label: "Manage workspaces",
        onClick: () => {
          navigateToWorkspaces();
        },
        stickToTop: true,
        divider: "bottom",
      },
      ...(orderedWorkspaces?.map(({ name, id, slug }, index) => ({
        active: slug === workspace?.slug,
        title: index === 0 ? "Workspaces" : undefined,
        label: name,
        onClick: () => switchWorkspace(id, `/${slug}`),
      })) ?? []),
    ];

    return options;
  }, [workspaces]);

  const canInviteUsers = workspace?.organization?.can_invite_users ?? true;
  const hasCustomerStudio = entitlementsData.entitlements.audiences;
  const isEvents = location.pathname.startsWith("/events");

  const topNav = (
    <Column width="100%" gap={3} py={3}>
      <Box
        display={["none", "none", "none", "block"]}
        width="100%"
        mx="auto"
        px={3}
        sx={{
          a: { display: "block", p: 2, pl: 1 },
        }}
      >
        <Link href="/">
          <HightouchLogo width={145} height="100%" />
        </Link>
      </Box>

      <Box
        display={["block", "block", "block", "none"]}
        mx="auto"
        px="auto"
        sx={{
          a: { display: "block", p: 2 },
        }}
      >
        <Link href="/">
          <Logo size="22px" />
        </Link>
      </Box>

      <Menu
        portal
        options={menuOptions}
        sx={{ width: "100%", px: 3, ">div>span": { width: "100%" } }}
        width="300px"
        onClick={() => setIsWorkspaceSelectorOpen(true)}
        onClose={() => setIsWorkspaceSelectorOpen(false)}
      >
        <Box as={Tooltip} isDisabled={hideTooltips} message="Switch workspace" placement="right">
          <Box
            _focus={{
              borderColor: isWorkspaceSelectorOpen ? "white" : "rgba(255,255,255,.5)",
              bg: isWorkspaceSelectorOpen ? "rgba(255, 255, 255, 0.08)" : "transparent",
            }}
            _focusVisible={{
              borderColor: "transparent",
              boxShadow: "0 0 0 4px rgba(255, 255, 255, 0.7)",
            }}
            _hover={{
              borderColor: isWorkspaceSelectorOpen ? "white" : "rgba(255,255,255,.5)",
              bg: "rgba(255, 255, 255, 0.08)",
            }}
            alignItems="center"
            justifyContent="space-between"
            bg="transparent"
            border="1px solid rgba(255,255,255,.3)"
            borderRadius="6px"
            display="flex"
            gap={2}
            outline="none"
            tabIndex={0}
            px={2}
            height="48px"
            transition="150ms all"
          >
            <Box
              flex={1}
              whiteSpace="nowrap"
              textOverflow="ellipsis"
              overflow="hidden"
              color="white"
              fontWeight="semibold"
              display={["none", "none", "none", "block"]}
              title={workspace?.name}
              fontSize="13px"
              fontFamily="'Sharp Sans Display No 1'"
            >
              {workspace?.name}
            </Box>

            <Box as={SelectorIcon} color="rgba(255,255,255,.7)" fontSize="16px" />
          </Box>
        </Box>
      </Menu>
    </Column>
  );

  const bottomNav = (
    <Column borderTop="1px solid #FFFFFF4D" gap={1} py={3} px={2}>
      {canInviteUsers && (
        <Permission permissions={[{ resource: "workspace", grants: [ResourcePermissionGrant.Update] }]}>
          <NavButton onClick={() => setOpenInvite(true)}>
            <NavItem fontSize={12} hideTooltip={hideTooltips} icon={AddUserIcon} label="Invite a teammate" />
          </NavButton>
        </Permission>
      )}

      <FeedbackMenu>
        <NavButton>
          <NavItem fontSize={12} hideTooltip={hideTooltips} icon={ChatIcon} label="Get in touch" />
        </NavButton>
      </FeedbackMenu>

      <NavLink
        newTab
        fontSize={12}
        hideTooltip={hideTooltips}
        href={import.meta.env.VITE_DOCS_URL as string}
        icon={DocsIcon}
        label="Documentation"
      />

      <UserMenu user={user || { name: "unknown user", email: "<no email>" }}>
        <NavButton
          _hover={{ bg: "rgba(255, 255, 255, 0.08)" }}
          display="grid"
          gap={2}
          gridTemplateColumns={["1fr", "1fr", "1fr", "min-content 1fr min-content"]}
          px={2}
          {...NAV_HEIGHT_STYLES}
        >
          <Box as={Avatar} size="xs" name={user?.name || "unknown user"} />

          <Box
            as={Text}
            color="rgba(255,255,255,.8)"
            display={["none", "none", "none", "inline-block"]}
            fontFamily="'Sharp Sans Display No 1'"
            fontSize="12px"
            fontWeight="medium"
            overflow="hidden"
            size="sm"
            textAlign="initial"
            textOverflow="ellipsis"
            whiteSpace="nowrap"
          >
            {user?.email || "<no email>"}
          </Box>

          <Box color="rgba(255, 255, 255, 0.7)" display={["none", "none", "none", "inline-block"]} transform="rotate(-90deg)">
            <Box as={ChevronDownIcon} height="24px" width="24px" />
          </Box>
        </NavButton>
      </UserMenu>
    </Column>
  );

  const mainNav = (
    <Column gap={ITEM_GAP} pt={1} px={3} pb={3}>
      {onboarding && <NavLink href="/onboarding" icon={OnboardingIcon} label="Get started" />}
      <NavLink
        hideTooltip={hideTooltips}
        href="/syncs"
        icon={SyncIcon}
        label="Syncs"
        permissions={[{ resource: ResourceToPermission.Sync, grants: [ResourcePermissionGrant.Read] }]}
      />
      <NavLink
        hideTooltip={hideTooltips}
        href="/models"
        icon={ModelIcon}
        label="Models"
        permissions={[{ resource: ResourceToPermission.Model, grants: [ResourcePermissionGrant.Read] }]}
      />
      {syncSequencesWorkspace && (
        <NavLink
          hideTooltip={hideTooltips}
          href="/sequences"
          icon={SequencesIcon}
          label="Sequences"
          permissions={[{ resource: ResourceToPermission.Sync, grants: [ResourcePermissionGrant.Read] }]}
        />
      )}
      {identityResolution && (
        <NavLink hideTooltip={hideTooltips} href="/idr" icon={IdentityResolutionIcon} label="Identity resolution" />
      )}
      {eventCollection && <NavLink hideTooltip={hideTooltips} href="/events" icon={SyncIcon} label="Events" />}
      {!hasCustomerStudio && <NavLink href="/audiences" icon={AudienceIcon} label="Customer studio" />}
      {hasCustomerStudio && (
        <CollapsibleSection hideTooltip={hideTooltips} title="Customer studio">
          <NavLink
            hideTooltip={hideTooltips}
            href="/audiences"
            icon={AudienceIcon}
            label="Audiences"
            permissions={[{ resource: ResourceToPermission.Audience, grants: [ResourcePermissionGrant.Read] }]}
          />
          {appAnalyticsLaunched && (
            <NavLink
              hideTooltip={hideTooltips}
              href="/analytics"
              icon={AnalyticsIcon}
              label="Analytics"
              permissions={[{ resource: ResourceToPermission.Audience, grants: [ResourcePermissionGrant.Read] }]}
            />
          )}
          <NavLink
            hideTooltip={hideTooltips}
            href="/priority-lists"
            icon={PriorityListIcon}
            label="Priority lists"
            permissions={[{ resource: ResourceToPermission.Audience, grants: [ResourcePermissionGrant.Read] }]}
          />
          {appPerformancePage && (
            <NavLink
              hideTooltip={hideTooltips}
              href="/performance"
              icon={LightningBoltIcon}
              label="Performance"
              permissions={[{ resource: ResourceToPermission.Audience, grants: [ResourcePermissionGrant.Read] }]}
            />
          )}
          {traitsV2 && (
            <NavLink
              hideTooltip={hideTooltips}
              href="/traits"
              icon={TraitIcon}
              label="Traits"
              permissions={[{ resource: ResourceToPermission.Audience, grants: [ResourcePermissionGrant.Read] }]}
            />
          )}
          <NavLink
            isSelected={location.pathname.startsWith("/schema")}
            hideTooltip={hideTooltips}
            href={schemaV2 ? "/schema-v2" : "/schema"}
            icon={AudienceSchemaIcon}
            label="Schema"
            permissions={[{ resource: ResourceToPermission.AudienceSchema, grants: [ResourcePermissionGrant.Read] }]}
          />
        </CollapsibleSection>
      )}
      <CollapsibleSection hideTooltip={hideTooltips} title="Setup">
        <NavLink
          hideTooltip={hideTooltips}
          href="/sources"
          icon={SourceIcon}
          label="Sources"
          permissions={[{ resource: ResourceToPermission.Source, grants: [ResourcePermissionGrant.Read] }]}
        />
        <NavLink
          hideTooltip={hideTooltips}
          href="/destinations"
          icon={DestinationIcon}
          label="Destinations"
          permissions={[{ resource: ResourceToPermission.Destination, grants: [ResourcePermissionGrant.Read] }]}
        />
        <NavLink hideTooltip={hideTooltips} href="/extensions" icon={ExtensionsIcon} label="Extensions" />
        <NavLink hideTooltip={hideTooltips} href="/settings" icon={SettingIcon} label="Settings" />
      </CollapsibleSection>
    </Column>
  );

  const eventsNav = (
    <Column>
      <Link href="/syncs">
        <Row
          align="center"
          gap={4}
          color="white"
          fontSize="xl"
          p={4}
          px={5}
          mb={2}
          borderBottom="1px"
          borderColor="base.border"
          opacity={0.8}
        >
          <ArrowLeftIcon />
          <Text color="white" fontWeight="semibold">
            Events
          </Text>
        </Row>
      </Link>
      <Column gap={ITEM_GAP} pt={1} px={3} pb={3}>
        <NavLink hideTooltip={hideTooltips} isSub href="/events/syncs" icon={SyncIcon} label="Syncs" />
        <NavLink hideTooltip={hideTooltips} isSub href="/events/sources" icon={DestinationIcon} label="Sources" />
        <NavLink hideTooltip={hideTooltips} isSub href="/events/destinations" icon={SourceIcon} label="Destinations" />
      </Column>
    </Column>
  );

  return (
    <>
      <Box
        ref={navRef}
        alignItems="center"
        background={`url(${grain}), linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), linear-gradient(175.93deg, #044747 0%, #065655 11.46%, #002B2E 82.29%)`}
        display="flex"
        flexDirection="column"
        height="100vh"
        overflowX="hidden"
        position="sticky"
        sx={{
          colorScheme: "dark",
          a: {
            _focusVisible: {
              boxShadow: "0 0 0 4px rgba(255, 255, 255, 0.7)",
            },
            borderRadius: "6px",
          },
        }}
        top={0}
        zIndex={100}
        transition="100ms width ease-in-out"
        width={NAV_WIDTHS_PER_BREAKPOINT}
        flexShrink={0}
      >
        {topNav}
        <Column flex={1} justifyContent="space-between" minHeight={0} overflowY="auto" width="100%">
          {isEvents ? eventsNav : mainNav}
          {bottomNav}
        </Column>
      </Box>

      <InviteFormModal close={() => setOpenInvite(false)} name="Hightouch" open={openInvite} />
    </>
  );
};
