import { FC, useEffect, useState, useRef, useMemo } from "react";

import { TestDestinationStepStatus } from "@hightouch/core/server/graphql/types";
import { Heading, useToast } from "@hightouchio/ui";
import { useInfiniteHits } from "react-instantsearch-hooks-web";
import { useNavigate } from "react-router-dom";
import { Image } from "theme-ui";

import { DestinationsCatalog } from "src/components/destinations/catalog/destinations-catalog";
import { SetupForm } from "src/components/destinations/destination-form";
import { TestConnectionTable } from "src/components/destinations/test-connection-status";
import {
  TestDestinationBadge,
  TestNewDestinationButton,
  TestResult,
  TestUpdatedDestinationButton,
} from "src/components/destinations/test-destination";
import { SidebarForm } from "src/components/page";
import { SearchProvider } from "src/components/search/search-provider";
import { useUser } from "src/contexts/user-context";
import {
  DestinationDefinitionFragment as DestinationDefinition,
  useCreateDestinationV2Mutation,
  useDestinationDefinitionsQuery,
  useDestinationQuery,
  useFormkitDestinationDefinitionQuery,
  useUpdateDestinationV2Mutation,
} from "src/graphql";
import * as analytics from "src/lib/analytics";
import { Column, Row } from "src/ui/box";
import { Field } from "src/ui/field";
import { Input } from "src/ui/input";
import { Step, Wizard } from "src/ui/wizard/wizard";
import { SlugResourceType, useResourceSlug } from "src/utils/slug";
import * as storage from "src/utils/storage";
import { useQueryString } from "src/utils/use-query-string";
import { useWizardStepper } from "src/utils/use-wizard-stepper";

type CreateDestinationWizardProps = {
  initialDestinationDefinition?: DestinationDefinition;
  onConnectClick?: (defintion: DestinationDefinition) => void;
  onCancel: () => void;
  onSubmit: ({ id, definition }: { id: string; definition: DestinationDefinition }) => void;
};

type Config = Record<string, unknown>;

export const CreateDestinationWizard: FC<Readonly<CreateDestinationWizardProps>> = (props) => {
  return (
    <SearchProvider>
      <CreateDestinationWizardInternal {...props} />
    </SearchProvider>
  );
};

const CreateDestinationWizardInternal: FC<Readonly<CreateDestinationWizardProps>> = ({
  initialDestinationDefinition,
  onConnectClick,
  onCancel,
  onSubmit,
}) => {
  const { user } = useUser();
  const { toast } = useToast();
  const navigate = useNavigate();
  const {
    data: { id },
  } = useQueryString();
  const [name, setName] = useState("");
  const { getSlug } = useResourceSlug(SlugResourceType.Destinations);
  const [config, setConfig] = useState<Config>();
  const [credentialId, setCredentialId] = useState<string>();
  const [testResult, setTestResult] = useState<TestResult>(TestResult.Unknown);
  const [testConnectionResult, setTestConnectionResult] = useState<TestDestinationStepStatus[] | null>(null);
  const [testing, setTesting] = useState<boolean>(false);
  const [testError, setTestError] = useState<Error | null>(null);
  const [definition, setDefinition] = useState<DestinationDefinition>();

  const [step, setStep] = useWizardStepper(0);

  const { mutateAsync: createDestination, isLoading: creating } = useCreateDestinationV2Mutation();
  const { mutateAsync: updateDestination, isLoading: updating } = useUpdateDestinationV2Mutation();

  const { data: formkitMethods } = useFormkitDestinationDefinitionQuery(
    { type: definition?.type || "" },
    { enabled: Boolean(definition), select: (data) => data.formkitDestinationDefinition },
  );

  const isOAuth = useMemo(() => {
    if (Array.isArray(formkitMethods)) {
      if (formkitMethods.length === 1) {
        return formkitMethods[0]?.method === "oauth";
      }
      return formkitMethods.find((s) => config?.methodKey === s.key)?.method === "oauth";
    }
    return false;
  }, [formkitMethods, config?.methodKey]);

  // Algolia hit selected when creating the destination (Step 1)
  const hit = useRef<any>(null);
  const { sendEvent } = useInfiniteHits();

  const { data: destinationDefinitions } = useDestinationDefinitionsQuery(undefined, {
    select: (data) => data.getDestinationDefinitions,
  });

  const { data: destination } = useDestinationQuery(
    { id: String(id) },
    {
      enabled: Boolean(id),
      select: (data) => data.destinations_by_pk,
      onSuccess: (data) => {
        if (data) {
          setDefinition(data.definition);
          setConfig((prev) => ({ ...prev, ...data.config }));
        }
      },
    },
  );

  useEffect(() => {
    if (testResult !== TestResult.Unknown) {
      analytics.track("Destination Config Tested", {
        test_successful: testResult === TestResult.Success,
        error_message: `${testError}`,
      });
    }
  }, [testResult, testConnectionResult]);

  useEffect(() => {
    if (id) {
      if (storage.load("onboarding")) {
        storage.remove("onboarding");
        navigate(`/onboarding/destination/new?id=${id}`, { replace: true });
      }
      if (initialDestinationDefinition) {
        setStep(0);
      } else {
        setStep(1);
      }
    }
  }, [id]);

  useEffect(() => {
    if (definition) {
      setName(definition.name);
    }
  }, [definition]);

  useEffect(() => {
    setDefinition(initialDestinationDefinition);
  }, [initialDestinationDefinition]);

  const submit = async () => {
    let newId;
    const slug = await getSlug(name);

    if (id) {
      await updateDestination({
        id,
        object: {
          slug,
          name,
          config,
          setup_complete: true,
          created_by: String(user?.id),
        },
      });
    } else {
      const object = {
        slug,
        name,
        config,
        setup_complete: true,
        created_by: String(user?.id),
        type: definition?.type,
      };

      if (credentialId) {
        object["credential_id"] = credentialId;
        if (!config) {
          object["config"] = {};
        }
      }

      const { createDestinationWithSecrets } = await createDestination({
        object: object,
      });

      newId = createDestinationWithSecrets?.id;
    }

    const destinationId = newId ?? id;

    toast({
      id: "create-destination",
      title: `Destination "${name || definition?.name}" was created`,
      variant: "success",
    });

    analytics.track("Destination Created", {
      destination_id: destinationId,
      destination_name: name,
      destination_type: definition?.name,
    });

    if (definition) {
      onSubmit?.({ id: destinationId, definition });
    }
  };

  const steps: Step[] = [
    {
      title: "Connect destination",
      disabled: isOAuth ? !id : !config && !credentialId,
      submitting: testing,
      //Use original validation and continue button for special forms on frontend, EX: (Google Sheets SA)
      //Will delete as soon as frontend forms get converted to backend destination configuration.
      onContinue: () => {
        setStep((step) => step + 1);
      },
      continue: isOAuth && !id ? "Authorize connection to continue" : undefined,
      continueProps: !id ? { form: "destination-form", type: "submit" } : undefined,
      actions:
        definition?.testConnection &&
        !isOAuth &&
        (id ? (
          <TestUpdatedDestinationButton
            credentialId={credentialId}
            destinationId={id}
            newConfiguration={{ ...(config || {}) }}
            size="lg"
            onError={setTestError}
            onLoading={setTesting}
            onResult={setTestResult}
            onTestResult={setTestConnectionResult}
          />
        ) : (
          <TestNewDestinationButton
            configuration={{ ...(config || {}) }}
            credentialId={credentialId}
            definition={definition}
            result={testResult}
            size="lg"
            onError={setTestError}
            onResult={setTestResult}
            onTestResult={setTestConnectionResult}
          />
        )),
      header: (
        <Row sx={{ alignItems: "center", gap: 4 }}>
          <Image src={definition?.icon} sx={{ width: "32px", objectFit: "contain" }} />
          <Heading>{`Connect to ${definition?.name}`}</Heading>
          <TestDestinationBadge key={0} result={testResult} testing={testing} />
        </Row>
      ),
      render: () => (
        <>
          {definition && (
            <Row sx={{ alignItems: "flex-start", gap: 8 }}>
              <Column sx={{ width: "100%", gap: 6 }}>
                <SetupForm
                  destination={destination}
                  config={config}
                  credentialId={credentialId}
                  definition={definition}
                  disableAuthMethod={Boolean(id)}
                  error={testError}
                  isSetup={true}
                  setConfig={setConfig}
                  setCredentialId={setCredentialId}
                  onConnectClick={onConnectClick}
                  onSubmit={() => {
                    setStep(step + 1);
                    return Promise.resolve();
                  }}
                />
                {(testConnectionResult || testing) && <TestConnectionTable result={testConnectionResult} testing={testing} />}
              </Column>
              <SidebarForm docsUrl={definition.docs ?? ""} name={definition.name} />
            </Row>
          )}
        </>
      ),
    },
    {
      title: "Finalize destination",
      disabled: !name,
      submitting: creating || updating,
      header: <Heading>Finalize settings for this destination</Heading>,
      render: () => (
        <Column sx={{ gap: 8, maxWidth: "600px" }}>
          <Field description="Including details about the destination's business purpose and owners" label="Destination name">
            <Input value={name} onChange={(value) => setName(value)} />
          </Field>
        </Column>
      ),
    },
  ];

  if (!initialDestinationDefinition) {
    steps.unshift({
      title: "Select destination",
      disabled:
        !definition ||
        definition?.status === "alpha" ||
        definition?.status === "coming_soon" ||
        definition?.status === "shadow",
      header: <Heading>Select a destination</Heading>,
      render: () =>
        destinationDefinitions ? (
          <DestinationsCatalog
            definitions={destinationDefinitions}
            error={null}
            selection={definition}
            onSelect={(selectedDefinition, selectedHit) => {
              setDefinition(selectedDefinition);
              hit.current = selectedHit;
            }}
            onClear={() => setDefinition(undefined)}
          />
        ) : null,
      onContinue: () => {
        if (hit.current) {
          sendEvent("conversion", hit.current, "Destination Catalog Continue Clicked");
        }
        if (definition) {
          analytics.track("Destination Catalog Continue Clicked", {
            destination_name: definition.name,
            destination_slug: definition.type,
            destination_status: definition.status,
          });
        }
        setStep(1);
      },
    });
  }

  return (
    <Wizard title="Create destination" step={step} steps={steps} setStep={setStep} onSubmit={submit} onCancel={onCancel} />
  );
};
