import { FC } from "react";

import { Box, Button, CloseIcon, Column, IconButton, Row, Text } from "@hightouchio/ui";
import { Controller, useFieldArray, useFormContext } from "react-hook-form";

import { CreatableSelect, Option } from "src/ui/select";

export interface LabelData {
  key: string;
  value: string;
}

interface Props {
  heading: string;
  hint?: string;
  loading?: boolean;
  keyOptions?: { label: string; value: string | null }[];
  getExistingLabelOptions: (fieldIndex: number) => Option[];
}

export const LabelForm: FC<Readonly<Props>> = ({
  heading,
  getExistingLabelOptions,
  keyOptions = [],
  hint,
  loading = false,
}) => {
  const { control } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    control,
    name: "labels",
  });

  return (
    <Column gap={1}>
      <Text fontWeight="medium">{heading}</Text>
      <Text color="gray.500" size="sm">
        {hint}
      </Text>
      <Column gap={4} mt={4}>
        {fields.map((field, index) => (
          <Row key={field.id} align="center" gap={2}>
            <Controller
              control={control}
              name={`labels.${index}.key` as const}
              render={({ field }) => (
                <CreatableSelect
                  {...field}
                  formatCreateLabel={(string) => {
                    return `Use new key: ${string}`;
                  }}
                  isLoading={loading}
                  noOptionsMessage={() => "Type to create a new key"}
                  options={keyOptions}
                  placeholder="Enter a key"
                  onChange={(option) => {
                    field.onChange(option.value);
                  }}
                />
              )}
            />
            <Text color="gray.400">=</Text>
            <Controller
              control={control}
              name={`labels.${index}.value` as const}
              render={({ field }) => (
                <CreatableSelect
                  {...field}
                  formatCreateLabel={(string) => {
                    return `Use new value: ${string}`;
                  }}
                  isLoading={loading}
                  noOptionsMessage={() => "Type to create a new value"}
                  options={getExistingLabelOptions(index)}
                  placeholder="Enter a value"
                  onChange={(option) => {
                    field.onChange(option.value);
                  }}
                />
              )}
            />
            <IconButton aria-label="Remove label" icon={CloseIcon} onClick={() => remove(index)} />
          </Row>
        ))}
        <Box>
          <Button variant="secondary" size="sm" onClick={() => append({ key: "", value: "" }, { shouldFocus: false })}>
            Add label
          </Button>
        </Box>
      </Column>
    </Column>
  );
};
