import { FC, useEffect, useState } from "react";

import { Row, Column, Button, ButtonGroup, useToast } from "@hightouchio/ui";
import { captureException } from "@sentry/react";
import { isEqual } from "lodash";
import { FormProvider, useForm } from "react-hook-form";
import { useOutletContext } from "react-router-dom";

import { ActionBar } from "src/components/action-bar";
import { SidebarForm } from "src/components/page";
import {
  SyncSequenceMembersInsertInput,
  useCreateSequenceMembersMutation,
  useDeleteSequenceMembersMutation,
} from "src/graphql";
import * as analytics from "src/lib/analytics";
import { SequenceForm } from "src/pages/sequences/sequence-form";

import { SequenceContext } from ".";

export const SequenceConfiguration: FC = () => {
  const { sequence } = useOutletContext<SequenceContext>();
  const { toast } = useToast();
  const [updating, setUpdating] = useState(false);

  const formMethods = useForm({ defaultValues: { members: sequence.members } });
  const { watch, handleSubmit, reset, formState } = formMethods;

  const members = watch("members");

  const isDirty = formState.isDirty || !isEqual(members, sequence.members);

  const { mutateAsync: createMembers } = useCreateSequenceMembersMutation();
  const { mutateAsync: deleteMembers } = useDeleteSequenceMembersMutation({
    onSuccess: () => {
      // prevents sequence query from invalidating
    },
  });

  const update = async (data) => {
    setUpdating(true);
    const members = data.members.map(({ sync, abort_seq_on_failure, abort_seq_on_rejects }, index) => {
      const member: SyncSequenceMembersInsertInput = {
        sequence_id: sequence.id,
        sync_id: sync.id,
        reference_id: index + 1,
        abort_seq_on_failure,
        abort_seq_on_rejects,
        links: {
          predecessors: index > 0 ? [index] : [],
          successors: index !== data.members.length - 1 ? [index + 2] : [],
        },
      };

      return member;
    });

    try {
      await deleteMembers({ id: sequence.id });
      await createMembers({
        objects: members,
      });

      analytics.track("Sequence Edited", {
        sync_sequence_id: sequence.id,
      });

      toast({
        id: "update-sequence",
        title: "Sequence was updated",
        variant: "success",
      });
    } catch (e) {
      captureException(e);
      toast({
        id: "update-sequence-error",
        title: "There was a problem updating your sequence",
        variant: "error",
      });
    }

    setUpdating(false);
  };

  useEffect(() => {
    reset(sequence);
  }, [sequence]);

  return (
    <Row width="100%" justify="space-between" gap={8}>
      <Column width="100%">
        <FormProvider {...formMethods}>
          <SequenceForm />
        </FormProvider>
        <ActionBar>
          <ButtonGroup>
            <Button
              variant="primary"
              isDisabled={!isDirty}
              isLoading={updating}
              onClick={() => {
                handleSubmit(update)();
              }}
            >
              Save changes
            </Button>
            <Button
              onClick={() => {
                reset();
              }}
              isDisabled={!isDirty}
            >
              Discard changes
            </Button>
          </ButtonGroup>
        </ActionBar>
      </Column>
      <SidebarForm
        hideInviteTeammate
        hideSendMessage
        docsUrl={`${import.meta.env.VITE_DOCS_URL}/syncs/schedule-sync-ui/`}
        name="scheduling syncs"
      />
    </Row>
  );
};
