import { FC, useEffect, useState } from "react";

import {
  Box,
  Column,
  FormField,
  Heading,
  Link,
  Paragraph,
  Switch,
  Text,
  Textarea,
  TextInput,
  Tooltip,
  useToast,
} from "@hightouchio/ui";
import * as Sentry from "@sentry/react";
import { useFlags } from "launchdarkly-react-client-sdk";
import Helmet from "react-helmet";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";

import { AccordionSection } from "src/components/accordion-section";
import { AudienceExplore } from "src/components/audiences/audience-explore";
import { Destinations } from "src/components/clone/destinations";
import { createObjectFromOptions } from "src/components/labels/create-object-from-options";
import { LabelData, LabelForm } from "src/components/labels/label-form";
import { useLabels } from "src/components/labels/use-labels";
import { ParameterizedAudienceConditionsForm } from "src/components/performance/parameterized-audience-conditions/form";
import { ParameterizedModel, getParameterizedModels } from "src/components/performance/parameterized-audience-conditions/utils";
import { FormErrorProvider } from "src/contexts/form-error-context";
import { useUser } from "src/contexts/user-context";
import {
  ResourcePermissionGrant,
  useAudienceQuery,
  useCreateAudienceMutation,
  useDestinationDefinitionsQuery,
} from "src/graphql";
import { QueryType } from "src/types/models";
import { PageSpinner } from "src/ui/loading";
import { Option } from "src/ui/select";
import { Wizard } from "src/ui/wizard";
import { Step } from "src/ui/wizard/wizard";
import { useQueryState } from "src/utils/models";

export const CloneAudience: FC = () => {
  const { id } = useParams<{ id: string }>();
  const { appEnableGoals, appMetricParameterization } = useFlags();
  const { labels } = useLabels();
  const { toast } = useToast();
  const navigate = useNavigate();
  const { hasPermissions } = useUser();
  const [step, setStep] = useState<number>(0);
  const [name, setName] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [selectedSyncs, setSelectedSyncs] = useState<Set<string>>(new Set());

  const canUpdateSource = hasPermissions([
    {
      resource: "source",
      grants: [ResourcePermissionGrant.Update],
    },
  ]);

  const {
    queryState,
    initQueryState,
    setVisualQueryFilter,
    canRedoVisualQueryFilterChange,
    canUndoVisualQueryFilterChange,
    redoVisualQueryFilterChange,
    undoVisualQueryFilterChange,
  } = useQueryState();

  const { data: audienceData, isLoading: modelLoading } = useAudienceQuery(
    {
      id: String(id),
    },
    { enabled: Boolean(id) },
  );

  const { isLoading: creating, mutateAsync: createAudience } = useCreateAudienceMutation();

  const audience = audienceData?.segments_by_pk;
  const parentModel = audience?.parent;
  const source = parentModel?.connection;
  const syncs = audience?.syncs;

  const parameterizedModels = getParameterizedModels(parentModel?.goals, parentModel?.relationships);

  const [isPerformancesEnabled, setIsPeformancesEnabled] = useState((appEnableGoals && source?.plan_in_warehouse) || false);

  useEffect(() => {
    setName(audience?.name ?? "");
  }, [audience?.name]);

  const { data: destinationDefinitions, isLoading: destinationDefinitionsLoading } = useDestinationDefinitionsQuery(undefined, {
    select: (data) => data.getDestinationDefinitions,
  });

  const formProps = useForm<{
    labels: LabelData[];
    parameterizedModels: ParameterizedModel[];
  }>({
    defaultValues: {
      labels: [{ key: "", value: "" }],
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore - Circular type problem with Column and Condition
      // TODO: react-hook-form v7 does not support circular references, upgrade to v8
      parameterizedModels,
    },
  });
  const { getValues, watch } = formProps;

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore - Circular type problem with Column and Condition
  // TODO: react-hook-form v7 does not support circular references, upgrade to v8
  const parameterizedModelFields = watch("parameterizedModels");
  const formLabels = watch("labels");
  const validLabels = formLabels.filter(({ key, value }) => key !== "" && value !== "");

  const keyOptions: Array<{ label: string; value: string | null }> = labels
    ?.filter(({ key }) => !formLabels.find((label) => label.key === key))
    ?.map(({ key }) => ({ value: key, label: key! }));

  const getOptionsForExistingLabelValues = (fieldIndex: number): Option[] => {
    const key = getValues().labels[fieldIndex]?.key;

    if (!key) {
      return [];
    }
    const existingLabelValues = labels.find((option) => option.key === key)?.values || [];
    return existingLabelValues?.map((value) => ({ value, label: value }));
  };

  const create = async () => {
    try {
      const result = await createAudience({
        input: {
          query_type: QueryType.Visual,
          visual_query_parent_id: parentModel?.id,
          visual_query_filter: queryState?.visualQueryFilter,
          name,
          description,
          primary_key: parentModel?.primary_key,
          connection_id: source?.id,
          tags: validLabels.length > 0 ? createObjectFromOptions(validLabels) : undefined,
          destination_instances: {
            data: selectedSyncs.size
              ? syncs
                  ?.filter(({ id }) => selectedSyncs.has(String(id)))
                  ?.map(({ destination, config, schedule, sync_alerts, row_threshold_attempted, row_threshold_total }) => ({
                    destination_id: destination!.id,
                    config,
                    schedule,
                    schedule_paused: true,
                    row_threshold_attempted,
                    row_threshold_total,
                    alert_instances: {
                      data: sync_alerts.map((alert) => ({
                        alert_id: alert.id,
                        fatal_error: alert.fatal_error,
                        row_error: alert.row_error,
                      })),
                    },
                  })) ?? []
              : [],
          },
          parameterized_conditions: {
            data: parameterizedModelFields.map(({ id: filteredModelId, propertyConditions }) => ({
              parent_model_id: parentModel?.id,
              filtered_model_id: filteredModelId,
              parameterized_conditions: propertyConditions,
            })),
          },
        },
      });

      toast({
        id: "clone-audience",
        title: `Cloned ${audience?.name} and ${selectedSyncs?.size} syncs`,
        variant: "success",
      });

      navigate(`/audiences/${result.insert_segments_one?.id}`);
    } catch (error) {
      toast({
        id: "clone-audience",
        title: `"${audience?.name}" and ${selectedSyncs?.size} syncs could not be cloned.`,
        variant: "error",
      });

      Sentry.captureException(error);
    }
  };

  useEffect(() => {
    initQueryState(audience);
  }, [audience]);

  useEffect(() => {
    if (syncs) {
      setSelectedSyncs(new Set(syncs.map((sync) => String(sync.id))));
    }
  }, [syncs]);

  if (modelLoading || destinationDefinitionsLoading) {
    return <PageSpinner />;
  }

  const showMetricParameters = appMetricParameterization && isPerformancesEnabled && parameterizedModelFields.length > 0;

  const steps: Step[] = [
    {
      title: "Change audience",
      render: () => (
        <FormErrorProvider>
          <AudienceExplore
            audience={audience}
            canRedo={canRedoVisualQueryFilterChange}
            canUndo={canUndoVisualQueryFilterChange}
            parentModel={parentModel}
            queryState={queryState}
            source={source}
            onRedo={redoVisualQueryFilterChange}
            onUndo={undoVisualQueryFilterChange}
            onVisualQueryFilterChange={setVisualQueryFilter}
          />
        </FormErrorProvider>
      ),
    },
  ];

  if (syncs?.length) {
    steps.push({
      title: "Select syncs",
      header: (
        <Column gap={2}>
          <Heading>Connect destinations</Heading>
          <Text fontWeight="semibold" color="text.secondary">
            Select all the destinations you would like to sync to. Cloned syncs are disabled by default, review the cloned
            configuration before enabling.
          </Text>
        </Column>
      ),
      render: () => (
        <Destinations
          definitions={destinationDefinitions}
          selected={selectedSyncs}
          setSelected={setSelectedSyncs}
          syncs={syncs}
        />
      ),
    });
  }

  steps.push({
    title: "Finalize",
    disabled: !name,
    submitting: creating,
    header: <Heading>Finalize your settings</Heading>,
    render: () => (
      <Column gap={8}>
        <FormField label="Audience name">
          <TextInput placeholder={audience?.name} value={name} onChange={(event) => setName(event.target.value)} />
        </FormField>
        <FormField isOptional label="Description">
          <Textarea placeholder="Enter a description..." value={description} onChange={(e) => setDescription(e.target.value)} />
        </FormField>

        <AccordionSection label="Advanced configuration">
          <>
            {appEnableGoals && (
              <Box mb={8}>
                <Box
                  as={FormField}
                  isOptional
                  display="flex"
                  alignItems="center"
                  label="Enable performance tracking"
                  sx={{ div: { mt: 0 } }}
                >
                  <Tooltip
                    isDisabled={source?.plan_in_warehouse}
                    message="Performance tracking for this source is not enabled."
                  >
                    <Switch
                      isChecked={isPerformancesEnabled}
                      isDisabled={!source?.plan_in_warehouse}
                      onChange={setIsPeformancesEnabled}
                    />
                  </Tooltip>
                </Box>
                <Text color="text.secondary">
                  View performance tracking of this audience and its split groups against metrics defined in your schema for{" "}
                  <Link href={`/schema/parent-models/${parentModel?.id}`}>{parentModel?.name}</Link>.
                </Text>
                {!source?.plan_in_warehouse && (
                  <>
                    <Paragraph color="text.secondary">
                      Performance tracking requires using the{" "}
                      <Link href={`${import.meta.env.VITE_DOCS_URL}/syncs/warehouse-sync-logs/#get-the-most-common-sync-error`}>
                        Lightning sync engine
                      </Link>
                      .{" "}
                      {canUpdateSource ? (
                        <>
                          Please go to the source configuration for <Link href={`/sources/${source?.id}`}>{source?.name}</Link>{" "}
                          to turn it on.
                        </>
                      ) : (
                        <>
                          If you are interested in enabling performance tracking, please ask your workspace admin to enable the
                          lightning sync engine.
                        </>
                      )}
                    </Paragraph>
                  </>
                )}
              </Box>
            )}

            {showMetricParameters && parentModel && (
              <Column my={4}>
                <Text>Metric parameters</Text>
                <Text color="text.secondary" mb={3}>
                  Metric parameters filter performance data to ensure only relevant events are counted for this audience.
                </Text>
                <ParameterizedAudienceConditionsForm parentModel={parentModel!} />
              </Column>
            )}

            <LabelForm
              heading="Add labels"
              hint="Example keys: team, project, region, env."
              keyOptions={keyOptions}
              getExistingLabelOptions={getOptionsForExistingLabelValues}
            />
          </>
        </AccordionSection>
      </Column>
    ),
  });

  return (
    <>
      <Helmet>
        <title>{audience?.name ? `Clone "${audience.name}" audience` : "Clone audience"}</title>
      </Helmet>

      <FormProvider {...formProps}>
        <Wizard
          fullscreen={step === 0}
          setStep={setStep}
          step={step}
          steps={steps}
          title="Clone audience"
          onCancel={() => {
            navigate(`/audiences/${id}`);
          }}
          onSubmit={create}
        />
      </FormProvider>
    </>
  );
};
