import { FC, useState } from "react";

import { Button, ButtonGroup, Column, useToast } from "@hightouchio/ui";
import { captureException } from "@sentry/react";
import { useOutletContext } from "react-router-dom";

import { ActionBar } from "src/components/action-bar";
import { SourceForm } from "src/components/sources/setup";
import { eventWarehouseDestinationDefinitions } from "src/events/types";
import { useUpdateEventWarehouseDestinationMutation } from "src/graphql";

import { OutletContext } from ".";

export const Configuration: FC = () => {
  const { toast } = useToast();
  const { destination } = useOutletContext<OutletContext>();
  const definition = eventWarehouseDestinationDefinitions[destination.type];
  const [config, setConfig] = useState<Record<string, unknown> | undefined>(destination.config);
  const [tunnelId, setTunnelId] = useState<string | null>();
  const [credentialId, setCredentialId] = useState<string>();

  const updateMutation = useUpdateEventWarehouseDestinationMutation();

  const submit = async () => {
    const updatedConfig = { ...config };

    if (tunnelId) {
      updatedConfig.host = null;
      updatedConfig.server = null;
      updatedConfig.port = null;
    }

    try {
      await updateMutation.mutateAsync({
        id: destination.id,
        input: {
          tunnel_id: tunnelId ? tunnelId : null,
          credential_id: credentialId != null ? String(credentialId) : undefined,
          config: updatedConfig,
        },
      });
      toast({
        id: "save-destination",
        title: "Your destination has been saved.",
        variant: "success",
      });
    } catch (error) {
      captureException(error);
      toast({
        id: "save-destination",
        title: "There was a problem saving your destination.",
        variant: "error",
      });
    }
  };

  return (
    <>
      <Column flex={1} gap={8} pb={16}>
        <SourceForm
          config={config}
          credentialId={credentialId}
          definition={definition}
          disableAuthMethod={true}
          hasSetupLightning={false}
          isSetup={true}
          lightningEnabled={false}
          plannerDatabase={undefined}
          setConfig={setConfig}
          setCredentialId={setCredentialId}
          setLightningEnabled={() => {}}
          setPlannerDatabase={() => {}}
          setTunnelId={setTunnelId}
          sourceId={undefined}
          tunnelId={tunnelId}
          onConnectClick={() => {}}
        />
      </Column>
      <ActionBar>
        <ButtonGroup>
          <Button size="lg" variant="primary" onClick={submit} isLoading={updateMutation.isLoading}>
            Save changes
          </Button>
        </ButtonGroup>
      </ActionBar>
    </>
  );
};
