import { FC, useEffect } from "react";

import { Button, ButtonGroup, useToast } from "@hightouchio/ui";
import { captureException } from "@sentry/react";
import { Controller, useForm } from "react-hook-form";
import { useOutletContext } from "react-router-dom";

import { ActionBar } from "src/components/action-bar";
import { ScheduleManager } from "src/components/schedule";
import { useUpdateEventWarehouseSyncMutation } from "src/graphql";

import { OutletContext } from ".";

export const Schedule: FC = () => {
  const { toast } = useToast();
  const { sync } = useOutletContext<OutletContext>();

  const form = useForm({ defaultValues: { schedule: sync.schedule } });

  const updateMutation = useUpdateEventWarehouseSyncMutation();

  const submit = async (data) => {
    try {
      await updateMutation.mutateAsync({
        id: sync.id,
        input: {
          schedule: data.schedule,
        },
      });
      toast({
        id: "save-schedule",
        title: "Your schedule has been saved.",
        variant: "success",
      });
    } catch (error) {
      captureException(error);
      toast({
        id: "save-schedule",
        title: "There was a problem saving your schedule.",
        variant: "error",
      });
    }
  };

  useEffect(() => {
    form.reset({ schedule: sync.schedule });
  }, [sync.schedule]);

  return (
    <>
      <Controller
        control={form.control}
        name="schedule"
        render={({ field }) => <ScheduleManager schedule={field.value} setSchedule={field.onChange} />}
      />
      <ActionBar>
        <ButtonGroup>
          <Button
            size="lg"
            variant="primary"
            onClick={form.handleSubmit(submit)}
            isLoading={updateMutation.isLoading}
            isDisabled={!form.formState.isDirty}
          >
            Save changes
          </Button>
          <Button
            size="lg"
            onClick={() => {
              form.reset();
            }}
            isLoading={updateMutation.isLoading}
            isDisabled={!form.formState.isDirty}
          >
            Discard changes
          </Button>
        </ButtonGroup>
      </ActionBar>
    </>
  );
};
