import { PencilIcon } from "@heroicons/react/24/outline";
import { TagIcon } from "@heroicons/react/24/solid";

import { ResourceActivityMapper } from "./timeline";

export const commonActivityMappings: ResourceActivityMapper[] = [
  {
    accessor: "description",
    parser: (_, { parsedDiff, newValue, oldValue }) => {
      if (parsedDiff.type !== "value") {
        return null;
      }
      let text;
      switch (parsedDiff.operation) {
        case "added":
          text = "added a description";
          break;
        case "removed":
          text = "removed the description";
          break;
        case "updated":
          text = `updated the description from \`${oldValue}\` to \`${newValue}\``;
          break;
      }
      return {
        message: text,
        icon: <PencilIcon />,
      };
    },
  },
  {
    accessor: "tags",
    parser: (_, { parsedDiff }) => {
      if (parsedDiff.type !== "nested") {
        return null;
      }
      const changes: string[] = [];
      for (const [tag, change] of Object.entries(parsedDiff.nested)) {
        if (change.type !== "value") {
          continue;
        }
        switch (change.operation) {
          case "added":
            changes.push(`Added label \`${tag}\`:\`${change.value}\``);
            break;
          case "removed":
            changes.push(`Removed label \`${tag}\``);
            break;
          case "updated":
            changes.push(`Updated label \`${tag}\` to \`${change.value}\``);
            break;
        }
      }

      return {
        message: `updated labels`,
        icon: <TagIcon />,
        changes,
      };
    },
  },
];
