import { FC, useState, useMemo, ReactNode } from "react";

import { PencilIcon, TrashIcon } from "@heroicons/react/24/outline";
import {
  Link,
  Menu,
  MenuList,
  MenuDivider,
  MenuItem,
  useToast,
  MenuActionsButton,
  Tooltip,
  Spinner,
  Column,
  TextInput,
  FormField,
  Combobox,
  Select,
  Button,
  Text,
  Row,
  EmptyState,
  Textarea,
  SectionHeading,
  Alert,
} from "@hightouchio/ui";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Sentry from "@sentry/browser";
import { Controller, useForm } from "react-hook-form";
import { lazy, object, string } from "yup";

import placeholder from "src/assets/placeholders/generic.svg";
import {
  ObjectQuery,
  RelationshipFragment,
  ResourcePermissionGrant,
  TraitDefinitionFragment,
  TraitDefinitionsConstraint,
  useAudiencesWithTraitQuery,
  useCreateTraitMutation,
  useDeleteTraitMutation,
  useUpdateTraitMutation,
} from "src/graphql";
import { TraitType, TraitTypeOptions, ColumnType } from "src/types/visual";
import { Modal } from "src/ui/modal";
import { Table } from "src/ui/table";

import useHasPermission from "../../hooks/use-has-permission";

const traitValidationSchema = lazy(({ type }: any) => {
  let configShape;

  switch (type) {
    case TraitType.RawSql:
      configShape = {
        aggregation: string().nullable().required("SQL is required"),
        defaultValue: string().nullable(),
        resultingType: string().nullable().required("Property type is required"),
      };
      break;
    case TraitType.Count:
    case TraitType.Sum:
    case TraitType.Average:
      configShape = { column: object().nullable().required("Column is required") };
      break;
    case TraitType.MostFrequent:
    case TraitType.LeastFrequent:
      configShape = { toSelect: object().nullable().required("Column is required") };
      break;
    case TraitType.First:
    case TraitType.Last:
      configShape = {
        toSelect: object().nullable().required("Column is required"),
        orderBy: object().nullable().required("Order by is required"),
      };
      break;
    default:
      configShape = {};
      break;
  }

  return object()
    .shape({
      name: string().nullable().required("Trait name is required"),
      type: string().nullable().required("Trait aggregation is required"),
      relationship_id: string().nullable().required("Related model is required"),
      config: object(configShape).nullable().default(undefined).required(),
    })
    .default(undefined)
    .required();
});

type Model = NonNullable<ObjectQuery["segments_by_pk"]>;

type Config = {
  aggregation?: string;
  column?: any;
  defaultValue?: string;
  orderBy?: any;
  resultingType?: string;
  toSelect?: any;
};

type Props = {
  model: Model;
};

type NewTrait = {
  name: string;
  type: string;
  relationship_id: any;
  config: Config;
};

export const ParentTraits: FC<Readonly<Props>> = ({ model }) => {
  const { traits } = model;

  const { toast } = useToast();

  const { mutate: deleteTrait, isLoading: isDeleting } = useDeleteTraitMutation();
  const { hasPermission: hasUpdatePermission } = useHasPermission([
    { resource: "audience_schema", grants: [ResourcePermissionGrant.Update] },
  ]);
  const { hasPermission: hasDeletePermission, isLoading: deletePermissionIsLoading } = useHasPermission([
    { resource: "audience_schema", grants: [ResourcePermissionGrant.Delete] },
  ]);

  const [trait, setTrait] = useState<TraitDefinitionFragment | null>(null);
  const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);
  const [createModalIsOpen, setCreateModalIsOpen] = useState(false);

  const {
    data: traitReferences,
    error: traitReferencesError,
    isLoading: traitReferencesAreLoading,
    refetch: refetchTraitReferences,
  } = useAudiencesWithTraitQuery(
    { traitId: trait?.id ?? "" },
    {
      // Only make this query when we're deleting a trait.
      enabled: Boolean(trait) && deleteModalIsOpen && !isDeleting,
      onError: (error: Error) => {
        Sentry.captureException(error);
      },
    },
  );

  const tableColumns = [
    {
      key: "name",
      name: "Name",
      cell: (name) => (
        <Text isTruncated fontWeight="medium">
          {name}
        </Text>
      ),
    },
    {
      key: "relationship.to_model.name",
      name: "Related model",
      cell: (name) => (
        <Text isTruncated fontWeight="medium">
          {name}
        </Text>
      ),
    },
    {
      key: "type",
      name: "Type",
      max: "max-content",
      cell: (type) => (
        <Text fontWeight="medium">{TraitTypeOptions.find((option) => option.value === type)?.label ?? null}</Text>
      ),
    },
    {
      max: "max-content",
      cell: (trait) => {
        return (
          <Menu>
            <MenuActionsButton />
            <MenuList>
              <MenuItem
                icon={PencilIcon}
                isDisabled={!hasUpdatePermission}
                onClick={() => {
                  setTrait(trait);
                }}
              >
                Edit
              </MenuItem>
              {!deletePermissionIsLoading && hasDeletePermission && (
                <>
                  <MenuDivider />
                  <MenuItem
                    icon={TrashIcon}
                    variant="danger"
                    onClick={() => {
                      setTrait(trait);
                      setDeleteModalIsOpen(true);
                    }}
                  >
                    Delete
                  </MenuItem>
                </>
              )}
            </MenuList>
          </Menu>
        );
      },
    },
  ];

  const handleDeleteTrait = async (): Promise<void> => {
    if (!trait || !hasDeletePermission) {
      return;
    }

    try {
      await deleteTrait({ id: trait.id });

      toast({
        id: "delete-parent-trait",
        title: `${trait.name} was deleted`,
        variant: "success",
      });

      setTrait(null);
      setDeleteModalIsOpen(false);
    } catch (error) {
      Sentry.captureException(error);

      toast({
        id: "delete-parent-trait",
        title: "Couldn't delete this trait",
        variant: "error",
      });
    }
  };

  return (
    <>
      {traits?.length ? (
        <>
          <Row align="center" mb={6} justify="space-between">
            <SectionHeading>Traits</SectionHeading>
            <Tooltip isDisabled={hasUpdatePermission} message="You do not have permisson to perform this action">
              <Button isDisabled={!hasUpdatePermission} variant="primary" onClick={() => setCreateModalIsOpen(true)}>
                Add trait
              </Button>
            </Tooltip>
          </Row>
          <Table columns={tableColumns} data={traits} />
        </>
      ) : (
        <EmptyState
          imageUrl={placeholder}
          title="You haven’t added any traits"
          message="Traits allow you to define and sync specific data from this model"
          actions={
            <Tooltip isDisabled={hasUpdatePermission} message="You do not have permisson to perform this action">
              <Button variant="primary" isDisabled={!hasUpdatePermission} onClick={() => setCreateModalIsOpen(true)}>
                Add trait
              </Button>
            </Tooltip>
          }
        />
      )}
      {(createModalIsOpen || (trait && !deleteModalIsOpen)) && (
        <TraitForm
          modelId={model.id}
          relationships={model.relationships}
          trait={trait}
          onClose={() => {
            setTrait(null);
            setCreateModalIsOpen(false);
          }}
        />
      )}
      <DeleteTraitModal
        isOpen={Boolean(trait) && deleteModalIsOpen}
        references={traitReferences?.listAllTraitReferences.audiences}
        referencesAreLoading={traitReferencesAreLoading}
        referencesError={traitReferencesError ? traitReferencesError : undefined}
        traitName={trait?.name}
        onClose={() => {
          setTrait(null);
          setDeleteModalIsOpen(false);
        }}
        onDelete={handleDeleteTrait}
        onRetryReferences={refetchTraitReferences}
      />
    </>
  );
};

const DeleteTraitModal = ({
  isOpen,
  referencesError,
  references,
  referencesAreLoading,
  traitName,
  onClose,
  onDelete,
  onRetryReferences,
}: {
  isOpen: boolean;
  referencesError?: Error;
  references?: {
    id: string;
    name: string;
  }[];
  referencesAreLoading: boolean;
  traitName?: string;
  onClose: () => void;
  onDelete: () => Promise<void>;
  onRetryReferences: () => void;
}) => {
  const [isDeleting, setIsDeleting] = useState(false);

  const handleDelete = async () => {
    setIsDeleting(true);
    await onDelete();
    setIsDeleting(false);
  };

  const getBody = (): ReactNode => {
    if (referencesAreLoading) {
      return <Spinner size="lg" m="auto" />;
    }
    if (referencesError) {
      return (
        <Alert type="error" title="Error" message={`Failed to check where this trait is used: ${referencesError.toString()}`} />
      );
    }
    if (references && references.length > 0) {
      return (
        <>
          <Alert type="error" title="Trait in use" message="Cannot delete a trait that is used in an audience." />
          <Text mt={8} mb={4} fontWeight="medium">
            This trait is used in the following audiences:
          </Text>
          <Column gap={1}>
            {references.map(({ name, id }) => (
              <Text key={id} isTruncated>
                <Link href={`/audiences/${id}`}>{name}</Link>
              </Text>
            ))}
          </Column>
        </>
      );
    }
    return (
      <>
        <Text mb={6}>
          Are you sure you want to delete the trait "<strong>{traitName}</strong>
          "?
        </Text>
        <Alert type="success" title="Unused trait" message="This trait is not used in any audiences, so it's safe to delete." />
      </>
    );
  };

  const getFooter = (): ReactNode => {
    if (referencesError) {
      // Prevent deletion if there was a failure, and allow user to retry.
      return (
        <>
          <Button onClick={onClose}>Cancel</Button>
          <Button variant="primary" onClick={onRetryReferences}>
            Retry
          </Button>
        </>
      );
    }
    if (references && references.length > 0) {
      // Prevent deletion if there are any references.
      return <Button onClick={onClose}>Close</Button>;
    }
    // Normal delete dialog.
    return (
      <>
        <Button isDisabled={isDeleting} onClick={onClose}>
          Cancel
        </Button>
        <Button isLoading={isDeleting} variant="danger" onClick={handleDelete}>
          Delete
        </Button>
      </>
    );
  };

  return (
    <Modal
      bodySx={{ pb: 6 }}
      footer={getFooter()}
      isOpen={isOpen}
      sx={{ maxWidth: "556px", width: "100%" }}
      title={`Delete trait: ${traitName}`}
      onClose={onClose}
    >
      {getBody()}
    </Modal>
  );
};

export const TraitForm: FC<
  Readonly<{
    modelId: string;
    trait?: TraitDefinitionFragment | null;
    relationships: RelationshipFragment[];
    onClose: () => void;
  }>
> = ({ trait, onClose, relationships, modelId }) => {
  const { toast } = useToast();
  const { control, handleSubmit, watch } = useForm({
    resolver: yupResolver(traitValidationSchema),
    defaultValues: {
      name: trait?.name || "",
      type: trait?.type || "",
      relationship_id: trait?.relationship?.id || "",
      config: trait?.config ?? {},
    } as NewTrait,
  });

  const { mutateAsync: updateTrait, isLoading: updating } = useUpdateTraitMutation();
  const { mutateAsync: createTrait, isLoading: creating } = useCreateTraitMutation();

  const type = watch("type");
  const relationshipId = watch("relationship_id");

  const relationshipOptions = useMemo(
    () =>
      relationships.map(({ id, name, to_model: { name: relatedModelName } }) => ({
        label: name || relatedModelName,
        value: id,
      })),
    [relationships],
  );
  const propertyOptions = useMemo(() => {
    const columns = relationships.find(({ id }) => id === relationshipId)?.to_model?.filterable_audience_columns;
    if (columns) {
      const validColumns = columns?.filter((column) => (type === TraitType.Sum ? column.type === ColumnType.Number : true));
      return validColumns.map(({ alias, name, column_reference }) => ({
        label: alias || name,
        value: column_reference,
      }));
    }
    return [];
  }, [relationshipId, relationships, type]);

  const submit = async (data) => {
    if (trait) {
      try {
        await updateTrait({ id: trait.id, input: data });

        toast({
          id: "update-parent-trait",
          title: "Trait was updated",
          variant: "success",
        });

        onClose();
      } catch (error) {
        toast({
          id: "update-parent-trait",
          title: "Trait was not updated",
          message: error.message.includes(TraitDefinitionsConstraint.TraitDefinitionsNameParentModelIdKey)
            ? `There is an existing trait named "${data.name}" associated with this parent model. Please choose a different name and try again.`
            : error.message,
          variant: "error",
        });

        Sentry.captureException(error);
      }
    } else {
      try {
        await createTrait({ input: { ...data, parent_model_id: modelId } });

        toast({
          id: "create-parent-trait",
          title: "Trait was created",
          variant: "success",
        });

        onClose();
      } catch (error) {
        toast({
          id: "create-parent-trait",
          title: "Trait was not created",
          message: error.message.includes(TraitDefinitionsConstraint.TraitDefinitionsNameParentModelIdKey)
            ? `There is an existing trait named "${data.name}" associated with this parent model. Please choose a different name and try again.`
            : error.message,
          variant: "error",
        });

        Sentry.captureException(error);
      }
    }
  };

  const submitting = updating || creating;

  return (
    <Modal
      footer={
        <>
          <Button onClick={onClose}>Cancel</Button>
          <Button variant="primary" isLoading={submitting} onClick={handleSubmit(submit)}>
            {trait ? "Save" : "Add trait"}
          </Button>
        </>
      }
      sx={{ maxWidth: "554px", width: "100%" }}
      title={trait ? "Edit trait" : "Add trait"}
      onClose={onClose}
    >
      <Column gap={6}>
        <Controller
          control={control}
          name="name"
          render={({ field, fieldState: { error } }) => (
            <FormField label="Name" error={error?.message}>
              <TextInput width="100%" isInvalid={Boolean(error)} {...field} />
            </FormField>
          )}
        />
        <Controller
          control={control}
          name="relationship_id"
          render={({ field, fieldState: { error } }) => (
            <FormField label="Related model" error={error?.message}>
              <Combobox width="100%" {...field} options={relationshipOptions} isInvalid={Boolean(error)} />
            </FormField>
          )}
        />
        <TraitAggregationFields
          control={control}
          disabled={!relationshipId}
          propertyOptions={propertyOptions}
          type={type as TraitType}
        />
      </Column>
    </Modal>
  );
};

export const TraitAggregationFields: FC<
  Readonly<{
    control: any;
    disabled: boolean;
    propertyOptions: any;
    type: TraitType;
  }>
> = ({ type, control, propertyOptions, disabled }) => (
  <>
    <Controller
      control={control}
      name="type"
      render={({ field, fieldState: { error } }) => (
        <FormField description="How should the rows be aggregated?" label="Aggregation" error={error?.message}>
          <Select width="100%" {...field} isInvalid={Boolean(error)} options={TraitTypeOptions} />
        </FormField>
      )}
    />
    {type === TraitType.RawSql && (
      <>
        <Controller
          control={control}
          name="config.aggregation"
          render={({ field, fieldState: { error } }) => (
            <FormField
              description="Rows will be aggregated according to a custom SQL aggregation."
              label="SQL"
              error={error?.message}
            >
              <Textarea
                width="100%"
                onChange={field.onChange}
                value={field.value}
                isInvalid={Boolean(error)}
                placeholder={"CASE WHEN SUM({{column \"price\"}}) > 100 THEN 'high' ELSE 'low' END`"}
              />
            </FormField>
          )}
        />
        <Controller
          control={control}
          name="config.defaultValue"
          render={({ field, fieldState: { error } }) => (
            <FormField
              description="Defines the value when the above SQL returns no rows."
              label="Default value"
              error={error?.message}
            >
              <TextInput width="100%" isInvalid={Boolean(error)} {...field} />
            </FormField>
          )}
        />
        <Controller
          control={control}
          name="config.resultingType"
          render={({ field, fieldState: { error } }) => (
            <FormField description="Defines the type of the resulting value" label="Property type" error={error?.message}>
              <Select
                width="100%"
                {...field}
                isInvalid={Boolean(error)}
                options={[
                  {
                    value: ColumnType.Boolean,
                    label: "Boolean",
                  },
                  {
                    value: ColumnType.Number,
                    label: "Number",
                  },
                  {
                    value: ColumnType.String,
                    label: "String",
                  },
                  {
                    value: ColumnType.Timestamp,
                    label: "Timestamp",
                  },
                  {
                    value: ColumnType.Date,
                    label: "Date",
                  },
                  {
                    value: ColumnType.JsonArrayNumbers,
                    label: "JSON Array (Numbers)",
                  },
                  {
                    value: ColumnType.JsonArrayStrings,
                    label: "JSON Array (Strings)",
                  },
                ]}
              />
            </FormField>
          )}
        />
      </>
    )}
    {type === TraitType.Count && (
      <Controller
        control={control}
        name="config.column"
        render={({ field, fieldState: { error } }) => (
          <FormField
            description="Rows will be counted according to the distinct values of this column. If not specified, all rows will be counted."
            label="Count by"
            error={error?.message}
          >
            <Combobox
              width="100%"
              {...field}
              value={field.value ?? []}
              isDisabled={disabled}
              isInvalid={Boolean(error)}
              options={propertyOptions ?? []}
            />
          </FormField>
        )}
      />
    )}
    {type === TraitType.Sum && (
      <Controller
        control={control}
        name="config.column"
        render={({ field, fieldState: { error } }) => (
          <FormField description="The column that will be summed for all rows" label="Sum by" error={error?.message}>
            <Combobox
              width="100%"
              {...field}
              value={field.value ?? []}
              isDisabled={disabled}
              isInvalid={Boolean(error)}
              options={propertyOptions ?? []}
            />
          </FormField>
        )}
      />
    )}
    {type === TraitType.Average && (
      <Controller
        control={control}
        name="config.column"
        render={({ field, fieldState: { error } }) => (
          <FormField description="The column that will be averaged for all rows" label="Average by" error={error?.message}>
            <Combobox
              width="100%"
              {...field}
              value={field.value ?? []}
              isDisabled={disabled}
              isInvalid={Boolean(error)}
              options={propertyOptions ?? []}
            />
          </FormField>
        )}
      />
    )}
    {(type === TraitType.MostFrequent || type === TraitType.LeastFrequent) && (
      <>
        <Controller
          control={control}
          name="config.toSelect"
          render={({ field, fieldState: { error } }) => (
            <FormField description="Frequency will be based on this column" label="Frequency column" error={error?.message}>
              <Combobox width="100%" {...field} isDisabled={disabled} isInvalid={Boolean(error)} options={propertyOptions} />
            </FormField>
          )}
        />
      </>
    )}
    {(type === TraitType.First || type === TraitType.Last) && (
      <>
        <Controller
          control={control}
          name="config.toSelect"
          render={({ field, fieldState: { error } }) => (
            <FormField description="The column that represents the value" label="Trait value" error={error?.message}>
              <Combobox width="100%" {...field} isDisabled={disabled} isInvalid={Boolean(error)} options={propertyOptions} />
            </FormField>
          )}
        />
        <Controller
          control={control}
          name="config.orderBy"
          render={({ field, fieldState: { error } }) => (
            <FormField description="Rows will be ordered according to this column" label="Order by" error={error?.message}>
              <Combobox width="100%" {...field} isDisabled={disabled} isInvalid={Boolean(error)} options={propertyOptions} />
            </FormField>
          )}
        />
      </>
    )}
  </>
);
