import { FC, ReactNode, useEffect } from "react";

import { Global } from "@emotion/react";
import { Box, ChakraDrawer, ChakraDrawerContent, CloseIcon, IconButton } from "@hightouchio/ui";

import { updateIntercomPosition } from "src/lib/intercom";

import { Indices } from "../../../../design";

type Props = {
  children: ReactNode;
  isOpen: boolean;
  onClose: () => void;
};

const DRAWER_WIDTTH_PX = 728;

export const Drawer: FC<Readonly<Props>> = ({ children, isOpen, onClose }) => {
  useEffect(() => {
    updateIntercomPosition(DRAWER_WIDTTH_PX);

    return () => {
      updateIntercomPosition(0);
    };
  }, []);

  return (
    <>
      <ChakraDrawer
        size="xl"
        isOpen={isOpen}
        onClose={onClose}
        placement="right"
        closeOnEsc
        closeOnOverlayClick
        trapFocus={false}
      >
        <ChakraDrawerContent borderLeft="1px" borderTop="1px" borderColor="base.border" maxWidth={DRAWER_WIDTTH_PX}>
          <Box position="absolute" top={4} right={4} zIndex={Indices.Modal}>
            <IconButton icon={CloseIcon} aria-label="Close" onClick={onClose} />
          </Box>
          {children}
        </ChakraDrawerContent>
      </ChakraDrawer>

      {/* Chakra's drawer uses an internal modal with a higher z-index than ours so it will be above other elements (e.g., Filter popover), so we adjust it here. */}
      <Global
        styles={{
          ".chakra-modal__content-container": { zIndex: Indices.Modal },
          ".chakra-modal__content": { transform: "unset !important" },
        }}
      />
    </>
  );
};
