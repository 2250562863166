import { useEffect } from "react";

import { useParams } from "react-router-dom";
import * as rudderstack from "rudder-sdk-js";

const noop = () => {};

const WRITE_KEY = import.meta.env.VITE_ANALYTICS_WRITE_KEY?.toString() ?? "";
const DATA_PLANE_URL = import.meta.env.VITE_ANALYTICS_DATA_PLANE_URL?.toString() ?? "";

rudderstack.load(WRITE_KEY, DATA_PLANE_URL);

type Data = { [key in string]?: string | number | boolean | undefined | null | Data | Data[] };

export const track = (event: string, properties: Data = {}) => {
  rudderstack.track(event, properties as rudderstack.apiObject, noop);
};

export const identify = (userId: string, traits: Data) => {
  rudderstack.identify(userId, traits as rudderstack.apiObject, {}, noop);
};

export const group = (groupId: string) => {
  rudderstack.group(groupId);
};

export const TrackView = ({ name }: { name: string }) => {
  const params = useParams();

  useEffect(() => {
    // send data to analytics when route renders
    const { id, ...otherParams } = params ?? {};

    const dataToSend = {
      ...otherParams,
    };

    // 'id' is not an allowed key for analytics
    if (id) {
      dataToSend["resource_id"] = id;
    }

    track(`${name} Viewed`, dataToSend);
  }, []);

  return null;
};
