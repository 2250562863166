import { Route, Routes } from "react-router-dom";

import { CreateSequence } from "./create-sequence";
import { Sequence } from "./sequence";
import { Sequences } from "./sequences";

export const SequenceRoutes = () => {
  return (
    <Routes>
      <Route index element={<Sequences />} />
      <Route path="/new" element={<CreateSequence />} />
      <Route path="/:id/*" element={<Sequence />} />
    </Routes>
  );
};
