import { FC, useState } from "react";

import {
  useToast,
  Column,
  Row,
  EditableHeading,
  MenuActionsButton,
  MenuList,
  MenuItem,
  Tabs,
  TabList,
  Tab,
  Menu,
  DeleteIcon,
  Box,
} from "@hightouchio/ui";
import { Routes, Route, Navigate, useParams, Outlet, useLocation, useNavigate, useOutletContext } from "react-router-dom";

import { Page } from "src/components/layout";
import { DeleteConfirmationModal } from "src/components/modals/delete-confirmation-modal";
import { Warning } from "src/components/warning";
import { eventSourceDefinitions } from "src/events/types";
import {
  EventSourceQuery,
  EventSourcesSetInput,
  useDeleteEventSourceMutation,
  useEventSourceQuery,
  useUpdateEventSourceMutation,
} from "src/graphql";
import { PageSpinner } from "src/ui/loading";

import { Setup } from "./setup";

const tabs = ["configuration"];

export type OutletContext = { source: NonNullable<EventSourceQuery["event_sources_by_pk"]> };

export const EventSourceRouter: FC = () => {
  return (
    <Routes>
      <Route element={<Loader />}>
        <Route element={<Layout />}>
          <Route path="/setup" element={<Setup />} />
          <Route index element={<Navigate to={{ pathname: "setup", search: location.search }} replace />} />
        </Route>
      </Route>
    </Routes>
  );
};

const Loader: FC = () => {
  const { id } = useParams<{ id: string }>();

  const { data: source, isLoading } = useEventSourceQuery(
    { id: String(id) },
    { enabled: Boolean(id), select: (data) => data.event_sources_by_pk },
  );

  if (isLoading) {
    return <PageSpinner />;
  }

  if (!id) {
    return null;
  }

  if (!source) {
    return <Warning subtitle="It may have been deleted" title="Event source not found" />;
  }

  return <Outlet context={{ source }} />;
};

const Layout: FC = () => {
  const { source } = useOutletContext<OutletContext>();
  const { toast } = useToast();
  const location = useLocation();
  const navigate = useNavigate();
  const [isDeleting, setIsDeleting] = useState(false);
  const [name, setName] = useState(source.name ?? "");

  const deleteMutation = useDeleteEventSourceMutation();
  const updateMutation = useUpdateEventSourceMutation();

  const update = async (input: EventSourcesSetInput) => {
    try {
      await updateMutation.mutateAsync({
        id: source.id,
        input,
      });

      toast({
        id: "update-event-source",
        title: `Event source was updated`,
        variant: "success",
      });
    } catch (error) {
      toast({
        id: "update-event-source-error",
        title: `There was a problem updating your event source.`,
        variant: "error",
      });
    }
  };

  return (
    <Page title={source.name} crumbs={[{ label: "Sources", link: "/events/sources" }, { label: source.name }]}>
      <Column minWidth={0} mb={4} gap={2}>
        <Row flex={1} justify="space-between" minWidth={0} pt={1}>
          <Row gap={4} align="center">
            <Box as="img" src={eventSourceDefinitions[source.type].icon} width="32px" />
            <EditableHeading
              size="lg"
              value={name}
              onChange={setName}
              onSubmit={(name) => {
                update({ name });
              }}
            />
          </Row>

          <Row align="center" gap={4}>
            <Menu>
              <MenuActionsButton />
              <MenuList>
                <MenuItem
                  icon={DeleteIcon}
                  variant="danger"
                  onClick={() => {
                    setIsDeleting(true);
                  }}
                >
                  Delete
                </MenuItem>
              </MenuList>
            </Menu>
          </Row>
        </Row>
      </Column>
      <Tabs
        onChange={(index) => navigate(tabs[index]!)}
        index={tabs.findIndex((tab) => location.pathname.endsWith(tab))}
        mb={6}
      >
        <TabList>
          <Tab>Setup</Tab>
        </TabList>
      </Tabs>
      <Outlet context={{ source }} />

      <DeleteConfirmationModal
        isOpen={isDeleting}
        label="source"
        onClose={() => {
          setIsDeleting(false);
        }}
        onDelete={async () => {
          await deleteMutation.mutateAsync({ id: source.id });
          navigate("/events/sources");
        }}
      />
    </Page>
  );
};
