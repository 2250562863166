import { FC, useMemo } from "react";

import { Text, Heading, Row, Column, StatusBadge } from "@hightouchio/ui";
import { formatDistanceToNowStrict } from "date-fns";
import { useNavigate } from "react-router-dom";

import genericPlaceholder from "src/assets/placeholders/generic.svg";
import { IntegrationIcon } from "src/components/integrations/integration-icon";
import { PermissionedLinkButton } from "src/components/permissioned-button";
import { ConnectionsOrderBy, ResourcePermissionGrant, useIdentityResolutionGraphsQuery } from "src/graphql";
import { EventStats, ProfileStats } from "src/pages/identity-resolution/graph/runs";
import { IdentityResolutionStatusBadge } from "src/pages/identity-resolution/status-badge";
import { PageTable, TableColumn, useTableSort, OrderBy, SortOption } from "src/ui/table";
import { LastUpdatedColumn } from "src/ui/table/columns/last-updated";
import { useRowSelect } from "src/ui/table/use-row-select";
import { openUrl } from "src/utils/urls";

const initialSort: SortOption<keyof ConnectionsOrderBy> = {
  key: "updated_at",
  direction: OrderBy.Desc,
  label: "Recently updated",
};
const sortOptions: SortOption<keyof ConnectionsOrderBy>[] = [
  { key: "name", direction: OrderBy.Asc, label: "Name A -> Z" },
  { key: "name", direction: OrderBy.Desc, label: "Name Z -> A" },
  initialSort,
  { key: "created_at", direction: OrderBy.Desc, label: "Newest" },
  { key: "created_at", direction: OrderBy.Asc, label: "Oldest" },
];

const placeholder = {
  image: genericPlaceholder,
  title: "No identity graphs",
  body: "Create a new identity graph to start resolving profiles.",
  error: "Identity graphs failed to load, please try again.",
};

export const IdentityResolutionGraphs: FC = () => {
  const navigate = useNavigate();
  const { selectedRows, onRowSelect } = useRowSelect();

  const orderBy = useTableSort<ConnectionsOrderBy>(initialSort, sortOptions);

  const { data: graphs, isLoading, error } = useIdentityResolutionGraphsQuery({ orderBy }, { select: (data) => data.idr });

  const columns = useMemo(
    (): TableColumn[] => [
      {
        name: "Last run",
        cell: ({ runs }) => {
          const lastRun = runs[0];
          if (lastRun) {
            return (
              <Column gap={1}>
                <IdentityResolutionStatusBadge status={lastRun.status} />
                {lastRun.finished_at && (
                  <Text size="sm" color="text.secondary">{`${formatDistanceToNowStrict(new Date(lastRun.finished_at), {
                    addSuffix: true,
                  })}`}</Text>
                )}
              </Column>
            );
          }
          return <StatusBadge variant="inactive">Pending</StatusBadge>;
        },
      },
      {
        name: "Profiles",
        cell: ({ runs }) => {
          const lastRun = runs[0];
          return <ProfileStats stats={lastRun?.stats} />;
        },
      },
      {
        name: "Events",
        cell: ({ runs }) => {
          const lastRun = runs[0];
          return <EventStats stats={lastRun?.stats} />;
        },
      },
      {
        name: "Identity graph",
        cell: ({ name }) => (
          <Text isTruncated fontWeight="medium">
            {name ?? "Private graph"}
          </Text>
        ),
      },
      {
        name: "Source",
        cell: ({ source }) => (
          <Row align="center" gap={2} overflow="hidden">
            <IntegrationIcon src={source.definition.icon} name={source.definition.name} />
            <Text isTruncated fontWeight="medium">
              {source.name}
            </Text>
          </Row>
        ),
      },
      {
        ...LastUpdatedColumn,
        breakpoint: "md",
      },
    ],
    [],
  );

  return (
    <PageTable
      header={
        <>
          <Heading size="xl">Identity resolution</Heading>
          <PermissionedLinkButton
            href="/idr/new"
            variant="primary"
            permissions={[{ resource: "source", grants: [ResourcePermissionGrant.Create] }]}
            onClick={() => {}}
          >
            Add identity graph
          </PermissionedLinkButton>
        </>
      }
      columns={columns}
      data={graphs}
      error={Boolean(error)}
      loading={isLoading}
      placeholder={placeholder}
      selectedRows={selectedRows}
      onRowClick={({ id }, event) => openUrl(`/idr/${id}`, navigate, event)}
      onSelect={onRowSelect}
      sortOptions={sortOptions}
    />
  );
};

const Loader = () => {
  // TODO: add check

  return <IdentityResolutionGraphs />;

  // return (
  //   <Page fullWidth title="Identity resolution">
  //     <Heading mb={8} size="xl">
  //       Identity resolution
  //     </Heading>
  //     <Placeholder
  //       content={{
  //         image: sourcePlaceholder,
  //         title: "Identity resolution is not setup in this workspace",
  //         body: rap,
  //         button: (
  //           <PermissionedLinkButton
  //             href="/idr/new"
  //             variant="primary"
  //             permissions={[{ resource: "source", grants: [ResourcePermissionGrant.Create] }]}
  //           >
  //             Add graph
  //           </PermissionedLinkButton>
  //         ),
  //       }}
  //     />
  //   </Page>
  // );
};

export default Loader;
