import { FC } from "react";

import { AttributionWindow, EventFilter } from "@hightouch/lib/query/visual/types/goals";
import { Box, Column, Row, Text, FilterIcon, TimeIcon } from "@hightouchio/ui";
import get from "lodash/get";
import pluralize from "pluralize";

import { formatValue, getOperatorLabel, getPropertyNameFromColumn } from "../explore/visual/utils";

type Props = {
  condition: EventFilter;
  attributionWindow: AttributionWindow;
};

const MetricDefinition: FC<Readonly<Props>> = ({ condition, attributionWindow }) => {
  const subconditions = get(condition, "filter.subconditions", []);

  const topLevelSubcondition = subconditions?.[0];
  const andOrLabel = topLevelSubcondition?.type.toUpperCase();

  return (
    <Box width="100%">
      {topLevelSubcondition?.conditions?.map((condition, index) => (
        <Column
          key={index}
          gap={1}
          _notFirst={{
            borderTop: "1px solid",
            borderTopColor: "base.border",
          }}
        >
          <Box px={2}>
            <Row mt={2} pb={3} fontSize="16px">
              <FilterIcon mr={1} color="text.secondary" />
              <Text color="text.secondary" fontWeight="semibold">
                {index === 0 ? "Filter events" : andOrLabel}
              </Text>
            </Row>
            <Row as={Text} mb={2} gap={1} color="text.secondary" flexWrap="wrap">
              {getPropertyNameFromColumn(condition.property)}{" "}
              {getOperatorLabel(condition.operator, condition.propertyType, Boolean(condition.propertyOptions?.parameterize))}{" "}
              {formatValue(condition, condition.value, { showParameterizedLabel: true })}
            </Row>
          </Box>
        </Column>
      ))}
      <Column borderTop="1px solid" borderTopColor="base.border" py={2}>
        <Box px={2}>
          <Row pb={3} fontSize="15px">
            <TimeIcon mr={1} color="text.secondary" />
            <Text color="text.secondary" fontWeight="semibold">
              Attribution window
            </Text>
          </Row>
          <Text mt={3} color="text.secondary">
            {displayAttributionWindow(attributionWindow)}
          </Text>
        </Box>
      </Column>
    </Box>
  );
};

const displayAttributionWindow = (attributionWindow: AttributionWindow) => {
  if (!attributionWindow) return;
  return pluralize(attributionWindow.unit, attributionWindow.quantity, true);
};

export default MetricDefinition;
