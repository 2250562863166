import { FC } from "react";

import {
  Box,
  BoxProps,
  Button,
  Row,
  Text,
  useDisclosure,
  ChakraPopover,
  ChakraPopoverContent,
  ChakraPopoverTrigger,
} from "@hightouchio/ui";
import { format } from "date-fns";

import { Calendar, Props as CalendarProps } from "src/ui/calendar";
import { TimeSelect } from "src/ui/time-select";

import { Indices } from "../../../../design";

export type Props = {
  hideTime?: boolean;
  onSelectClose?: (elementId: string) => void;
  onSelectOpen?: (elementId: string) => void;
} & CalendarProps &
  BoxProps;

export const DateTimeSelect: FC<Readonly<Props>> = ({ hideTime, value, onChange, onSelectClose, onSelectOpen, ...props }) => {
  const { isOpen, onToggle, onClose } = useDisclosure();

  return (
    <Row align="center" flexShrink={0} {...props}>
      <ChakraPopover isLazy isOpen={isOpen} onClose={onClose} placement="bottom">
        <ChakraPopoverTrigger>
          <Box
            as={Button}
            _hover={{
              borderColor: isOpen ? "primary.base" : "gray.border",
              bg: "base.lightBackground",
            }}
            _active={{
              border: "1px solid",
              borderColor: "primary.base",
            }}
            px={2}
            gap={1}
            alignItems="center"
            height="32px"
            fontWeight="normal"
            border="1px solid"
            borderColor={isOpen ? "primary.base" : "base.border"}
            boxShadow={isOpen ? "outline" : "none"}
            borderRadius="6px"
            overflow="hidden"
            transition="all 120ms ease-in"
            whiteSpace="nowrap"
            onClick={(event) => {
              event.preventDefault();
              event.stopPropagation();
              onToggle();
            }}
          >
            {format(value, "PPP")}
          </Box>
        </ChakraPopoverTrigger>

        <Box sx={{ ">div": { zIndex: Indices.Content } }}>
          <ChakraPopoverContent p={2} width="auto">
            <Calendar
              value={value}
              onChange={(value) => {
                onChange(value);
                onClose();
              }}
            />
          </ChakraPopoverContent>
        </Box>
      </ChakraPopover>
      {!hideTime && (
        <>
          <Text mx={2}>at</Text>
          <TimeSelect value={value} onChange={onChange} onSelectClose={onSelectClose} onSelectOpen={onSelectOpen} />
        </>
      )}
    </Row>
  );
};
