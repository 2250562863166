import { FC, useState, ReactNode } from "react";

import { Text, Box, Column, Link } from "@hightouchio/ui";

import { Sidebar } from "src/components/page";

import { InviteFormModal } from "../modals/invite-modal";

type Props = {
  buttons?: ReactNode;
  name: string;
  docsUrl: string;
  hideInviteTeammate?: boolean;
  hideSendMessage?: boolean;
  invite?: string;
  message?: string;
  top?: string;
};

export const SidebarForm: FC<Readonly<Props>> = ({
  buttons,
  name,
  docsUrl,
  hideSendMessage,
  hideInviteTeammate,
  invite,
  message,
  top,
}) => {
  const [openInvite, setOpenInvite] = useState(false);

  let correctedDocsUrl = docsUrl;

  // Some docs pages have different URLs than the slugs stored in Sanity, see https://github.com/hightouchio/docs/blob/d4559b38e5014847c1a0bfdad037fc7cfe9a7019/next.config.mjs#L14
  const docsURLOverrides = {
    "hightouch-personalization-api": "personalization-api",
    "webhook-v2": "http-request",
  };

  // Replace the incorrect URL with the overwritten one
  const incorrectSlugs = Object.keys(docsURLOverrides);
  incorrectSlugs.forEach((slug) => {
    if (docsUrl.includes(slug)) {
      correctedDocsUrl = docsUrl.replace(slug, docsURLOverrides[slug]);
    }
  });

  // Another common overwrite is to remove the "-destination" or "-source" from the slug in Sanity
  correctedDocsUrl = correctedDocsUrl.replace("-destination", "");
  correctedDocsUrl = correctedDocsUrl.replace("-source", "");

  const docsLink = correctedDocsUrl.startsWith("http")
    ? correctedDocsUrl
    : `${import.meta.env.VITE_DOCS_URL}/${correctedDocsUrl}`;

  return (
    <Sidebar top={top}>
      {buttons && (
        <Column gap={3} mb={10}>
          {buttons}
        </Column>
      )}
      <Column sx={{ gap: 8 }}>
        <Block
          content={
            <>
              Not sure where to start? Check out the <Link href={docsLink}>docs for {name}</Link> for step-by-step instructions.
            </>
          }
          title="Read our docs"
        />
        <Block
          content={
            <>
              {invite || "If you’re missing credentials or connection info"},{" "}
              <Box
                display="inline-block"
                onClick={() => {
                  setOpenInvite(true);
                }}
              >
                <Link href="">invite a teammate</Link>
              </Box>{" "}
              to join you in this Hightouch workspace.
            </>
          }
          hide={hideInviteTeammate}
          title="Invite a teammate"
        />
        <Block
          content={
            <>
              We’re here to help!{" "}
              <Box
                display="inline-block"
                onClick={() => window["Intercom"]?.("showNewMessage", message || "I could use some help")}
              >
                <Link href="">Chat with us</Link>
              </Box>{" "}
              if you feel stuck or have any questions.
            </>
          }
          hide={hideSendMessage}
          title="Contact support"
        />
      </Column>
      <InviteFormModal close={() => setOpenInvite(false)} name="Hightouch" open={openInvite} />
    </Sidebar>
  );
};

const Block: FC<Readonly<{ title: string; content: ReactNode; hide?: boolean }>> = ({ title, content, hide }) => {
  if (hide) return null;

  return (
    <Box>
      <Text fontWeight="medium" color="text.secondary">
        {title}
      </Text>
      <Box pt={2} mt={2} borderTop="1px solid" borderColor="base.border">
        {content}
      </Box>
    </Box>
  );
};
