import { FC, ReactNode, useRef } from "react";

import { Box, Column, Row, Tab, TabList, Tabs } from "@hightouchio/ui";
import { Helmet } from "react-helmet";
import { useLocation, useNavigate } from "react-router-dom";
import { useScroll } from "react-use";

import { useHeaderHeight } from "src/contexts/header-height-context";
import { useMeasureHeightOnce } from "src/hooks/use-measured-height-once";
import { SIZES } from "src/ui/box/container";

import { Props as HeaderProps, PageHeader } from "./header/page-header";
import { DefaultPageContainerPadding } from "./page-container";

export type Props = {
  bg?: string;
  children: ReactNode;
  contentFullWidth?: boolean;
  header?: ReactNode;
  sidebar?: ReactNode;
  size?: keyof typeof SIZES;
  title: string;
  tabs?: { render?: () => ReactNode; path: string; title: string }[];
} & HeaderProps;

const headerBarDefaultHeight = 112;
const tabBarDefaultHeight = 40;

// distance from top of window
export const cssVariableTopOffset = "--top-section-offset";
// height of header content
export const cssVariableTopSectionHeight = "--top-section-height";
// height of footer content
export const cssVariableBottomSectionHeight = "--bottom-section-height";

export const DetailPage: FC<Readonly<Props>> = ({
  bg = "white",
  children,
  contentFullWidth,
  header,
  crumbs,
  outsideTopbar,
  size = "page",
  sync,
  tabs,
  title,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const containerRef = useRef<HTMLDivElement>(null);
  const headerRef = useRef<HTMLDivElement>(null);
  const tabBarRef = useRef<HTMLDivElement>(null);

  const headerBarHeight = useMeasureHeightOnce(headerRef, headerBarDefaultHeight);
  const tabBarHeight = useMeasureHeightOnce(tabBarRef, tabBarDefaultHeight);
  const { headerHeight: pageHeaderHeight } = useHeaderHeight();

  const scrollPosition = useScroll(containerRef);
  const visibleTopSectionHeight = Math.max(
    0,
    pageHeaderHeight + headerBarHeight + (tabs ? tabBarHeight : 0) - scrollPosition.y,
  );

  const currentTabIndex = tabs ? tabs.findIndex((tab) => location.pathname.endsWith(tab.path)) : null;

  return (
    <Column
      ref={containerRef}
      height="100vh"
      position="relative"
      overflowY="auto"
      bg={bg}
      sx={{
        [cssVariableTopOffset]: "0px",
        [cssVariableTopSectionHeight]: `${visibleTopSectionHeight}px`,
        [cssVariableBottomSectionHeight]: "0px",
      }}
    >
      <Helmet>
        <title>{title}</title>
      </Helmet>

      <Box bg="white">
        <PageHeader crumbs={crumbs} outsideTopbar={outsideTopbar} stickToTop={false} sync={sync} />

        <Column ref={headerRef} bg="white" maxWidth={SIZES[size]} mx="auto" px={DefaultPageContainerPadding.X} width="100%">
          {header}
        </Column>

        <Row ref={tabBarRef} bg="white" flex={1} maxHeight={`${tabs ? tabBarDefaultHeight : 0}px`} height="auto" minWidth={0}>
          <BorderLine />
          {currentTabIndex !== null && tabs && (
            <Box maxWidth={`calc(${SIZES.page} - ${DefaultPageContainerPadding.X * 4 * 2}px)`} width="100%">
              <Tabs
                index={currentTabIndex}
                onChange={(index) => {
                  if (index === currentTabIndex) return;
                  navigate(tabs[index]?.path ?? "");
                }}
              >
                <TabList>
                  {tabs.map(({ title, render }, index) => (
                    <Tab key={index}>{render ? render() : title}</Tab>
                  ))}
                </TabList>
              </Tabs>
            </Box>
          )}
          <BorderLine />
        </Row>
      </Box>

      <Column
        justifyContent="flex-start"
        maxWidth={contentFullWidth ? undefined : SIZES[size]}
        mt={contentFullWidth ? undefined : 8}
        mx={contentFullWidth ? undefined : "auto"}
        position="relative"
        px={contentFullWidth ? undefined : DefaultPageContainerPadding.X}
        width="100%"
      >
        {children}
      </Column>
    </Column>
  );
};

const BorderLine = () => (
  <Box borderBottom="1px solid" borderColor="base.border" flex={1} minWidth={DefaultPageContainerPadding.X} />
);
