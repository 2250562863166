import { useEffect, useMemo, FC } from "react";

import { AddBoxIcon, Column, FilterIcon, NumberInput, Row, Select, Text, TimeIcon } from "@hightouchio/ui";
import pluralize from "pluralize";
import { isPresent } from "ts-extras";
import { v4 as uuidv4 } from "uuid";

import { useFormErrorContext } from "src/contexts/form-error-context";
import {
  EventCondition,
  eventOperatorOptions,
  initialFunnelCondition,
  initialPropertyCondition,
  initialEventWindow,
  ConditionType,
  PropertyCondition,
} from "src/types/visual";

import { useQueryBuilderContext } from "../context/query-builder-context";
import { FilterPopover } from "../filter-popover";
import { removePropertySubcondition, updatePropertySubcondition } from "../utils/condition-builders";
import { formatSubconditions } from "../utils/format-subconditions";
import { isAndOrCondition } from "../utils/type-guards";
import { FilterProps, HStack } from "./condition";
import { DetailButton } from "./condition-buttons";
import { validateEventCondition } from "./condition-validation";
import { ErrorMessage } from "./error-message";
import { Filter } from "./filter";
import { FunnelFilter } from "./funnel-filter";
import { NestedPropertyFilter } from "./nested-property-filter";
import { WindowFilter } from "./window-filter";

type EventFilterProps = {
  disableEventSelect?: boolean;

  // Temporary for v1 of the metrics form
  hideFunnelCondition?: boolean;
  hideWindowCondition?: boolean;
  hideOperatorFilter?: boolean;
  allowParameterization?: boolean;
};

export const EventFilter: FC<Readonly<FilterProps<EventCondition> & EventFilterProps>> = ({
  disableEventSelect,
  hideFunnelCondition,
  hideWindowCondition,
  hideOperatorFilter,
  allowParameterization,
  events: eventsOverride,
  ...props
}) => {
  const { condition: unformattedCondition, onChange, isEventTrait } = props;
  const filterId = useMemo<string>(uuidv4, []);

  const { events: allEvents } = useQueryBuilderContext();

  const events = eventsOverride ?? allEvents;

  // Wrap subconditions in one and/or condition
  const condition = formatSubconditions(unformattedCondition);
  const topLevelSubcondition = condition.subconditions?.[0];
  // Assumption is that event condition will be formatted to always have one And/Or subcondition as a child (if there are subconditions)
  const subconditions = isAndOrCondition(topLevelSubcondition) ? topLevelSubcondition.conditions : [];

  const { getErrors, setFieldError, removeErrors } = useFormErrorContext();

  const filterErrors = getErrors(filterId);
  const eventError = filterErrors?.eventModelId;
  const valueError = filterErrors?.value;

  useEffect(() => {
    setFieldError(filterId, validateEventCondition(condition));

    return () => {
      removeErrors([filterId]);
    };
  }, [condition.eventModelId, condition.value, filterId]);

  const operatorLabel = eventOperatorOptions.find(({ value }) => value === condition.operator)?.label;

  const updateWrappingConditionType = (type: ConditionType.And | ConditionType.Or) => {
    if (!isAndOrCondition(topLevelSubcondition)) {
      return;
    }

    onChange({
      ...condition,
      subconditions: [
        {
          type: type,
          conditions: topLevelSubcondition?.conditions ?? [],
        },
      ],
    });
  };

  const addSubcondition = () => {
    if (!isAndOrCondition(topLevelSubcondition)) {
      return;
    }

    onChange({
      ...condition,
      subconditions: [
        {
          type: topLevelSubcondition.type,
          conditions: [...topLevelSubcondition.conditions, initialPropertyCondition],
        },
      ],
    });
  };

  const updateSubcondition = (updates: Partial<PropertyCondition>, index: number) => {
    onChange(updatePropertySubcondition(index, condition, updates));
  };

  const removeSubcondition = (index: number) => {
    onChange(removePropertySubcondition(index, condition));
  };

  const noWindowCondition = !hideWindowCondition && !condition.window;

  return (
    <>
      <HStack gap={2} sx={{ alignItems: "flex-start" }}>
        <Text color="text.secondary" mt={1.5} ml={4}>
          Performed
        </Text>
        <FilterPopover
          {...props}
          condition={condition}
          isDisabled={disableEventSelect}
          hasError={Boolean(eventError)}
          onChange={(updates) => onChange(updates)}
        />
        {hideOperatorFilter
          ? null
          : !isEventTrait && (
              <>
                <Filter
                  content={
                    <Row gap={2}>
                      <Select
                        options={eventOperatorOptions}
                        placeholder="Filter on"
                        value={condition.operator}
                        width="auto"
                        onChange={(operator) => {
                          onChange({ operator });
                        }}
                      />
                      <Column>
                        <NumberInput
                          isInvalid={Boolean(valueError)}
                          width="auto"
                          value={condition.value ?? undefined}
                          onChange={(value) => {
                            onChange({ value });
                          }}
                        />
                        {valueError && <ErrorMessage>{valueError}</ErrorMessage>}
                      </Column>
                    </Row>
                  }
                  error={valueError}
                >
                  <Text fontWeight="medium">
                    <Text color="text.secondary" fontWeight="normal">
                      {operatorLabel}
                    </Text>{" "}
                    {isPresent(condition.value)
                      ? `${condition.value} ${pluralize("time", Number(condition.value))}`
                      : "___ times"}
                  </Text>
                </Filter>
              </>
            )}
      </HStack>

      {!hideWindowCondition && condition.window && <WindowFilter condition={condition} onChange={onChange} />}
      {subconditions.map((subcondition, index) => {
        if (subcondition.type !== ConditionType.Property) {
          return null;
        }

        return (
          <NestedPropertyFilter
            key={index}
            {...props}
            showParameterizeCheckbox={allowParameterization}
            columns={events?.find(({ id }) => id === condition.relationshipId)?.to_model?.filterable_audience_columns}
            condition={subcondition}
            isFirstCondition={index === 0}
            isWrappedWithAndCondition={topLevelSubcondition?.type === ConditionType.And}
            traits={[]}
            onChange={(updates) => updateSubcondition(updates, index)}
            onRemove={() => removeSubcondition(index)}
            onToggleWrappingConditionType={() =>
              updateWrappingConditionType(
                topLevelSubcondition?.type === ConditionType.And ? ConditionType.Or : ConditionType.And,
              )
            }
            containerSx={{ align: "flex-start", pl: 10, width: "100%" }}
          />
        );
      })}

      <Row gap={4} pl={10}>
        <DetailButton icon={AddBoxIcon} size="sm" onClick={addSubcondition}>
          Where event property is...
        </DetailButton>
        {noWindowCondition && (
          <DetailButton
            icon={TimeIcon}
            size="sm"
            onClick={() => {
              onChange({ window: initialEventWindow });
            }}
          >
            Time window
          </DetailButton>
        )}
        {!hideFunnelCondition && !condition.funnelCondition ? (
          <DetailButton
            icon={FilterIcon}
            size="sm"
            onClick={() => {
              onChange({
                funnelCondition: { ...initialFunnelCondition, didPerform: true },
              });
            }}
          >
            Then performed...
          </DetailButton>
        ) : null}
      </Row>

      {!hideFunnelCondition && isPresent(condition.funnelCondition) && (
        <FunnelFilter
          {...props}
          condition={condition.funnelCondition}
          eventCondition={condition}
          onChange={(updates) => {
            onChange({
              funnelCondition: {
                ...condition.funnelCondition!,
                ...updates,
              },
            });
          }}
          onRemove={() => onChange({ funnelCondition: undefined })}
        />
      )}
    </>
  );
};
