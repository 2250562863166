import { useEffect, useState, FC } from "react";

import { PlayIcon, TrashIcon } from "@heroicons/react/24/outline";
import {
  EditableHeading,
  useToast,
  Switch,
  Menu,
  MenuList,
  MenuItem,
  MenuActionsButton,
  Spinner,
  Button,
  Column,
  Row,
  Text,
  Tooltip,
  TabList,
  Tabs,
  Tab,
} from "@hightouchio/ui";
import { captureException } from "@sentry/react";
import { Outlet, useNavigate, useOutletContext, useParams, Routes, Route, Navigate, useLocation } from "react-router-dom";

import { Page } from "src/components/layout";
import { MetadataBar, MetadataLabel } from "src/components/metadata-bar";
import { DeleteConfirmationModal } from "src/components/modals/delete-confirmation-modal";
import { Permission } from "src/components/permission";
import { Schedule } from "src/components/schedule";
import { DisplaySlug } from "src/components/slug/display-slug";
import { Warning } from "src/components/warning";
import { PermissionProvider } from "src/contexts/permission-context";
import {
  ResourcePermissionGrant,
  useSequenceQuery,
  useUpdateSequenceMutation,
  useDeleteSequenceMutation,
  useUpdateSequenceRunMutation,
  SequenceQuery,
} from "src/graphql";
import { useEntitlements } from "src/hooks/use-entitlement";
import useHasPermission from "src/hooks/use-has-permission";
import { TrackView, track } from "src/lib/analytics";
import { PageSpinner } from "src/ui/loading";
import { SyncStatus, syncStatusIsTerminal } from "src/utils/syncs";

import { SequenceConfiguration } from "./configuration";
import { SequenceRuns } from "./runs";
import { SequenceSchedule } from "./schedule";

const tabs = ["runs", "configuration", "schedule"];

export type SequenceContext = {
  sequence: NonNullable<SequenceQuery["sync_sequences_by_pk"]>;
};

export const Sequence: FC = () => {
  return (
    <Routes>
      <Route element={<Loader />}>
        <Route element={<Layout />}>
          <Route path="/runs" element={<SequenceRuns />} />
          <Route path="/configuration" element={<SequenceConfiguration />} />
          <Route path="/schedule" element={<SequenceSchedule />} />
          <Route index element={<Navigate to={{ pathname: "runs", search: location.search }} replace />} />
        </Route>
      </Route>
    </Routes>
  );
};

const Loader: FC = () => {
  const { id } = useParams<{ id: string }>();
  const location = useLocation();

  const { data: sequence, isLoading } = useSequenceQuery(
    {
      id: id ?? "",
    },
    {
      enabled: Boolean(id),
      refetchInterval: location.pathname.endsWith("configuration") ? undefined : 5000,
      select: (data) => data.sync_sequences_by_pk,
    },
  );

  if (isLoading) {
    return <PageSpinner />;
  }

  if (!id) {
    return null;
  }

  if (!sequence) {
    return <Warning subtitle="It may have been deleted" title="Sequence not found" />;
  }

  return <Outlet context={{ sequence }} />;
};

export const Layout: FC = () => {
  const { sequence } = useOutletContext<SequenceContext>();
  const location = useLocation();
  const navigate = useNavigate();
  const { toast } = useToast();
  const [cancelling, setCancelling] = useState<boolean>(false);
  const [deleting, setDeleting] = useState<boolean>(false);
  const [enabled, setEnabled] = useState<boolean>(true);
  const [showRun, setShowRun] = useState(true);
  const [name, setName] = useState(sequence?.name ?? "");
  const updatePermission = useHasPermission([{ resource: "sync", grants: [ResourcePermissionGrant.Update] }]);

  const { mutateAsync: updateSequence } = useUpdateSequenceMutation();
  const { mutateAsync: deleteSequence } = useDeleteSequenceMutation();
  const { mutateAsync: updateSequenceRun } = useUpdateSequenceRunMutation();

  const lastRun = sequence?.runs[0];
  const status = lastRun?.status;
  const running = status === "running";

  const { data: entitlementsData, isLoading: _loadingEntitlements } = useEntitlements(true);
  const overageLockout: boolean = entitlementsData.overage?.overageLockout;
  const overageText = entitlementsData.overage?.destinationOverageText; // @TODO: hookup once there are more than one overage.

  const toggleEnabled = async (enabled: boolean) => {
    try {
      await updateSequence({
        id: sequence.id,
        object: {
          schedule_paused: !enabled,
        },
      });

      toast({
        id: "toggle-sequence",
        title: `Sequence was ${enabled ? "enabled" : "disabled"}`,
        variant: "success",
      });
    } catch (error) {
      captureException(error);
      toast({
        id: "toggle-sequence",
        title: `Couldn't ${enabled ? "enable" : "disable"} this sequence`,
        message: error.message,
        variant: "error",
      });
    }
  };

  const cancelRun = async () => {
    if (!lastRun) return;

    setCancelling(true);

    try {
      await updateSequenceRun({
        id: lastRun.id,
        object: {
          trigger_cancel: true,
        },
      });
    } catch (error) {
      captureException(error);
      toast({
        id: "cancel-sequence-run",
        title: "Couldn't cancel this sequence run",
        variant: "error",
      });
      setCancelling(false);
    }
  };

  const startRun = async () => {
    try {
      await updateSequence({ id: sequence.id, object: { force_run: true } });
      track("Sequence Manually Started", {
        sync_sequence_id: sequence.id,
        schedule_type: sequence.schedule?.type,
      });
      toast({
        id: "start-sequence-run",
        title: "Manual run will begin shortly",
        variant: "success",
      });
    } catch (error) {
      captureException(error);
      toast({
        id: "start-sequence-run",
        title: "Couldn't start this sequence run",
        variant: "error",
      });
    }
  };

  const saveName = async () => {
    try {
      await updateSequence({
        id: sequence.id,
        object: {
          name,
        },
      });
      toast({
        id: "update-sequence",
        title: "Sequence name updated",
        variant: "success",
      });
    } catch (error) {
      captureException(error);
      toast({
        id: "update-sequence",
        title: "Couldn't update this sequence",
        variant: "error",
      });
    }
  };

  const { hasPermission: userCanDelete } = useHasPermission([{ resource: "sync", grants: [ResourcePermissionGrant.Delete] }]);
  const { hasPermission: userCanEnable } = useHasPermission([{ resource: "sync", grants: [ResourcePermissionGrant.Enable] }]);

  useEffect(() => {
    setName(sequence?.name);
    setEnabled(!sequence?.schedule_paused);
  }, [sequence]);

  useEffect(() => {
    if (syncStatusIsTerminal(status as SyncStatus)) {
      setShowRun(true);
    }
    if (status === "cancelled") {
      setCancelling(false);
    }
  }, [status]);

  useEffect(() => {
    setName(sequence?.name ?? "");
  }, [sequence?.name]);

  return (
    <>
      <TrackView name="Sequence Details Page" />

      <PermissionProvider permissions={[{ resource: "sync", grants: [ResourcePermissionGrant.Update] }]}>
        <Page
          crumbs={[{ label: "Sequences", link: "/sequences" }, { label: sequence.name }]}
          title={`${sequence.name} - Sequences`}
        >
          <Row sx={{ justifyContent: "space-between", mb: 5, width: "100%", borderBottom: "small", pb: 2 }}>
            <EditableHeading
              isDisabled={!updatePermission.hasPermission}
              size="lg"
              value={name}
              onChange={setName}
              onSubmit={saveName}
            />

            <Row gap={4}>
              <Row align="center" gap={2}>
                <Text textTransform="uppercase" size="sm" fontWeight="semibold" color="text.tertiary">
                  {enabled ? "Enabled" : "Disabled"}
                </Text>
                <Switch
                  isChecked={enabled}
                  isDisabled={!userCanEnable}
                  onChange={(value) => {
                    setEnabled(value);
                    toggleEnabled(value);
                  }}
                />
              </Row>

              {userCanDelete && (
                <Menu>
                  <MenuActionsButton />
                  <MenuList>
                    <MenuItem
                      icon={TrashIcon}
                      isDisabled={running}
                      variant="danger"
                      onClick={() => {
                        setDeleting(true);
                      }}
                    >
                      Delete
                    </MenuItem>
                  </MenuList>
                </Menu>
              )}

              <Permission permissions={[{ resource: "sync", grants: [ResourcePermissionGrant.Start] }]}>
                {running || cancelling ? (
                  <Button isDisabled={cancelling} onClick={cancelRun}>
                    <Spinner size="sm" mr={2} />
                    {cancelling ? "Canceling..." : "Cancel run"}
                  </Button>
                ) : (
                  <Tooltip message={overageText} isDisabled={!overageLockout}>
                    <Button
                      isDisabled={!showRun || overageLockout}
                      icon={PlayIcon}
                      onClick={() => {
                        setShowRun(false);
                        startRun();
                      }}
                    >
                      Run
                    </Button>
                  </Tooltip>
                )}
              </Permission>
            </Row>
          </Row>

          <MetadataBar>
            <Column>
              <MetadataLabel>Schedule</MetadataLabel>
              <Schedule schedule={sequence.schedule} />
            </Column>
            <Column>
              <MetadataLabel mb="-2px">Slug</MetadataLabel>
              <DisplaySlug currentSlug={sequence.slug} />
            </Column>
          </MetadataBar>

          <Column gap={6} width="100%">
            <Tabs onChange={(index) => navigate(tabs[index]!)} index={tabs.findIndex((tab) => location.pathname.endsWith(tab))}>
              <TabList>
                <Tab>Runs</Tab>
                <Tab>Configuration</Tab>
                <Tab>Schedule</Tab>
              </TabList>
            </Tabs>

            <Outlet context={{ sequence }} />
          </Column>
        </Page>
      </PermissionProvider>

      <DeleteConfirmationModal
        isOpen={deleting}
        label="sequence"
        onClose={() => {
          setDeleting(false);
        }}
        onDelete={async () => {
          try {
            await deleteSequence({
              id: sequence.id,
            });
            track("Sequence Deleted", {
              sync_sequence_id: sequence.id,
            });

            navigate("/sequences");
          } catch (error) {
            captureException(error);
            toast({
              id: "delete-sequence",
              title: "Couldn't delete this sequence",
              variant: "error",
            });
          }
        }}
      />
    </>
  );
};
