import { FC } from "react";

import { Box, Column, Row, Text } from "@hightouchio/ui";
import { format as formatDate } from "date-fns";
import { TooltipProps } from "recharts";

import { truncateNumber } from "src/utils/numbers";

type Props = {
  metricName: string;
  normalizeAudiences: boolean;
  hoveredLine: string | undefined;
} & TooltipProps<number, string>;

const Tooltip: FC<Readonly<Props>> = ({ payload, normalizeAudiences, metricName, hoveredLine }) => {
  if (!payload) return null;

  const tooltipsToShow = hoveredLine ? payload.filter(({ payload: metric }) => metric.seriesKey === hoveredLine) : payload;

  return (
    <Column bg="gray.900" padding={2} borderRadius="md" gap={2}>
      {tooltipsToShow.map(({ payload: metric, color, name, value }) => (
        <Row gap={2} key={metric.id}>
          <Box width={1} borderRadius="lg" bg={color} />
          <Column>
            <Text color="white" fontWeight="semibold">
              {metricName}
            </Text>
            <Text color="text.tertiary">{name}</Text>
            <Text color="text.tertiary">{formatDate(new Date(metric.calculatedAt), "LLL d, yyyy")}</Text>
            {value != undefined && (
              <Box bg="gray.800" borderRadius="md" py={0.5} px={1} gap={1} width="fit-content">
                <Text color="white">{truncateNumber(value)}</Text>
                {normalizeAudiences && <Text color="text.tertiary"> per 100 users</Text>}
              </Box>
            )}
          </Column>
        </Row>
      ))}
    </Column>
  );
};

export default Tooltip;
