import { ConditionType, PropertyCondition } from "@hightouch/lib/query/visual/types";
import { GoalConfig } from "@hightouch/lib/query/visual/types/goals";
import { AudienceVisitors, visitConditionList } from "@hightouch/lib/query/visual/util";
import isEqual from "lodash/isEqual";
import keyBy from "lodash/keyBy";
import merge from "lodash/merge";
import uniqWith from "lodash/uniqWith";

import { AudienceParentFragment, ParameterizedAudienceConditions } from "src/graphql";
import { OperatorsWithoutValue } from "src/types/visual";

export type ParameterizedModel = {
  id: string;
  name: string;
  propertyConditions: PropertyCondition[];
  goalIds: string[];
};

export const getParameterizedModels = (
  goals: Array<{ id: string; config: GoalConfig }> = [],
  parentModelRelationships: AudienceParentFragment["relationships"] = [],
): ParameterizedModel[] => {
  const modelNamesById = parentModelRelationships.reduce((accum, relationship) => {
    const { id, name } = relationship.to_model;
    accum[id] = name;
    return accum;
  }, {});

  const parametersByModelId = {};

  goals.forEach((goal) => {
    const config = goal.config as GoalConfig;
    const modelId = config.eventModelId.toString();

    const subconditions: GoalConfig["filter"]["subconditions"] = config?.filter?.subconditions || [];

    if (!subconditions.length) {
      // No conditions, so we can move on to the next goal
      return;
    }

    // Get all conditions that are parameterized
    const parameterizedConditions = getParameterizedConditions(subconditions);
    if (parameterizedConditions.length) {
      if (modelId in parametersByModelId) {
        parametersByModelId[modelId].parameterizedConditions.push(...parameterizedConditions);
        parametersByModelId[modelId].goalIds.push(goal.id);
      } else {
        parametersByModelId[modelId] = {
          parameterizedConditions,
          goalIds: [goal.id],
        };
      }
    }
  });

  return Object.keys(parametersByModelId).map((modelId) => ({
    id: modelId,
    name: modelNamesById[modelId],
    // Multiple goals may have parameterized the same properties from this model, so filter out duplicates.
    propertyConditions: uniqWith(parametersByModelId[modelId].parameterizedConditions, (condition1, condition2) =>
      isEqual(condition1.property, condition2.property),
    ),
    goalIds: parametersByModelId[modelId].goalIds,
  }));
};

const getParameterizedConditions = (conditions: GoalConfig["filter"]["subconditions"] = []): PropertyCondition[] => {
  const parameterizedConditions: PropertyCondition[] = [];

  const recordParameterizedPropertyCondition = (propertyCondition: PropertyCondition) => {
    const isParameterized = Boolean(propertyCondition.propertyOptions?.parameterize);
    if (isParameterized) {
      parameterizedConditions.push(propertyCondition);
    }
  };

  const visitors: AudienceVisitors = {
    [ConditionType.Property]: recordParameterizedPropertyCondition,
  };

  visitConditionList(conditions, visitors);

  return parameterizedConditions;
};

export const getParameterizedModelsWithSubstitutions = (
  parentParameterizedModels: ParameterizedModel[] = [],
  audienceParameterizedModels: ParameterizedModel[] = [],
): ParameterizedModel[] => {
  // Merge mutates the object being merged into, so make it an empty object
  const merged = merge({}, keyBy(parentParameterizedModels, "id"), keyBy(audienceParameterizedModels, "id"));

  return Object.values(merged);
};

export const allParameterizedConditionsHaveValue = (
  parentGoals: Array<{ id: string; config: GoalConfig }> = [],
  audienceGoals: Array<{ id: string; name: string }> = [],
  audienceConditions: Array<{
    filtered_model: { id: string; name: string } | null;
    conditions: ParameterizedAudienceConditions["parameterized_conditions"];
  }> = [],
): boolean => {
  // For each parent goal, go through all of its conditions
  // and check that there is a matching audience condition
  if (!parentGoals.length) {
    return true;
  }

  // We only care about parent goals that are also enabled for the audience
  const filteredParentGoals = parentGoals.filter(({ id: parentGoalId }) =>
    audienceGoals.some(({ id: audienceGoalId }) => audienceGoalId === parentGoalId),
  );

  return filteredParentGoals.every((parentGoal) => {
    const config = parentGoal.config as GoalConfig;
    const modelId = config.eventModelId;

    const subconditions: GoalConfig["filter"]["subconditions"] = config?.filter?.subconditions || [];

    // Get all parameterized conditions for this goal
    const parameterizedConditions = getParameterizedConditions(subconditions);

    return parameterizedConditions.every((parameterizedCondition) => {
      // See if there is a matching audience condition
      return audienceConditions.some(({ filtered_model, conditions }) => {
        if (modelId.toString() !== filtered_model?.id?.toString()) {
          return false;
        }

        return (
          conditions.filter((condition) => {
            // Match on the same column
            if (isEqual(condition.property, parameterizedCondition.property)) {
              // Check that the operator and value are valid
              if (OperatorsWithoutValue.includes(condition.operator)) {
                return true;
              }

              return condition.operator != undefined && condition.value != undefined;
            }

            return false;
          }).length > 0
        );
      });
    });
  });
};
