import { QueryKey, UseQueryOptions, useQuery } from "react-query";

import { fetcher, FetcherOptions } from "./fetcher";

type UseQueryHook<QueryVariables> = { getKey: (variables: QueryVariables) => QueryKey; document: string };

export const getQueryWithOpts = <Query, QueryVariables>(
  query: UseQueryHook<QueryVariables>,
  fetcherOptions?: FetcherOptions,
) => {
  return <QueryData = Query>(variables: QueryVariables, options?: UseQueryOptions<Query, Error, QueryData>) =>
    useQuery<Query, Error, QueryData>(query.getKey(variables), fetcher(query.document, variables, fetcherOptions), options);
};
