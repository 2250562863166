import { FC } from "react";

import { Column, Spinner } from "@hightouchio/ui";

import { NotFound } from "src/pages/not-found";

import { Drawer } from "./drawer";

type Props = {
  isLoading: boolean;
  isMissing: boolean;
  onClose: () => void;
};

export const PlaceholderDrawer: FC<Readonly<Props>> = ({ isLoading, isMissing, onClose }) => {
  const drawerProps = { isOpen: true, onClose };

  if (isLoading) {
    return (
      <Drawer {...drawerProps}>
        <Column height="100%" alignItems="center" justifyContent="center">
          <Spinner size="lg" />
        </Column>
      </Drawer>
    );
  }

  if (!isMissing) {
    return (
      <Drawer {...drawerProps}>
        <NotFound />
      </Drawer>
    );
  }

  return null;
};
