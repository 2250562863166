import { FC, useEffect, useMemo, useState } from "react";

import { PencilIcon, TrashIcon } from "@heroicons/react/24/outline";
import { TestDestinationStepStatus } from "@hightouch/core/server/graphql/types";
import {
  ConfirmationDialog,
  Paragraph,
  useToast,
  Tooltip,
  Pill,
  Text,
  Column,
  Row,
  Button,
  Menu,
  MenuActionsButton,
  MenuList,
  MenuItem,
  Avatar,
} from "@hightouchio/ui";
import { useFlags } from "launchdarkly-react-client-sdk";
import _, { get } from "lodash";
import { useParams, useNavigate } from "react-router-dom";

import placeholderImage from "src/assets/placeholders/sync.svg";
import { FullResourceLinkForm } from "src/components/deployments/linking";
import { SetupForm } from "src/components/destinations/destination-form";
import { TestConnectionTable } from "src/components/destinations/test-connection-status";
import { TestDestinationBadge, TestResult, TestUpdatedDestinationButton } from "src/components/destinations/test-destination";
import { DetailBar } from "src/components/detail-bar";
import { IntegrationIcon } from "src/components/integrations/integration-icon";
import { EditLabelModal } from "src/components/labels/edit-label-modal";
import { Labels } from "src/components/labels/labels";
import { useLabels } from "src/components/labels/use-labels";
import { Page } from "src/components/layout";
import { SaveWarning } from "src/components/modals/save-warning";
import { Header, SidebarForm } from "src/components/page";
import { Permission } from "src/components/permission";
import { DisplaySlug } from "src/components/slug/display-slug";
import { Warning } from "src/components/warning";
import { PermissionProvider } from "src/contexts/permission-context";
import { useUser } from "src/contexts/user-context";
import {
  DestinationInstancesOrderBy,
  DestinationSyncsQuery,
  OrderBy,
  ResourcePermissionGrant,
  useDeleteDestinationMutation,
  useDestinationQuery,
  useDestinationSyncsQuery,
  useUpdateDestinationV2Mutation,
} from "src/graphql";
import { useEntitlements } from "src/hooks/use-entitlement";
import useHasPermission from "src/hooks/use-has-permission";
import * as analytics from "src/lib/analytics";
import { PageSpinner } from "src/ui/loading";
import { Pagination, Table, useTableConfig } from "src/ui/table";
import { Tabs } from "src/ui/tabs";
import { getDifference } from "src/utils/get-difference";
import { formatDate, formatDatetime } from "src/utils/time";

enum Tab {
  CONFIGURATION = "Configuration",
  SYNCS = "Syncs",
  LINKING = "Linking",
}

type Config = Record<string, unknown>;
type Sync =
  | NonNullable<DestinationSyncsQuery["destinations_by_pk"]>["destination_instances"][0]
  | DestinationSyncsQuery["sync_templates"][0]["destination_instances"][0];
type SyncCompareFunction = (args: { syncA: Sync; syncB: Sync; sortKey: string; sortDirection: OrderBy }) => number;

enum SortKeys {
  ModelName = "segment.name",
  SourceName = "segment.connection.name",
  CreatedAt = "created_at",
}

/**
 * A comparing function used to sort syncs.
 *
 * @param arguments arguments
 * @param arguments.syncA The first destination to compare
 * @param arguments.destinationb The second destination to compare
 * @param arguments.sortKey The path to the value to sort on
 * @param arguments.sortDirection The sort direction
 * @returns
 */
const syncCompareFunction: SyncCompareFunction = ({ syncA, syncB, sortKey, sortDirection }) => {
  if (!sortDirection || (!sortDirection.startsWith("asc") && !sortDirection.startsWith("desc"))) {
    return 0;
  }

  let result;
  let aValue = get(syncA, sortKey);
  let bValue = get(syncB, sortKey);

  switch (sortKey) {
    // string sorting
    case "status":
    case "segment.name":
    case "destination.name":
      result = aValue.trim().localeCompare(bValue.trim());
      break;
    // date sorting
    case "created_at":
      aValue = new Date(aValue);
      bValue = new Date(bValue);
      result = aValue - bValue;
      break;
    default:
      // syncs columns are all string based except for date columns
      return 0;
  }

  return sortDirection.startsWith("asc") ? result : -result;
};

export const Destination: FC = () => {
  const navigate = useNavigate();
  const { user } = useUser();

  // `user.id` should be a string but fixing that is a larger change
  const userId = user?.id != null ? String(user?.id) : undefined;

  const { destination_id: id } = useParams<{ destination_id?: string }>();
  const idAsInt = id ? parseInt(id, 10) : undefined;
  const { toast } = useToast();
  const [config, setConfig] = useState<Config | undefined>();
  const [credentialId, setCredentialId] = useState<string | undefined>();
  const [name, setName] = useState<string | undefined>();
  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const [tab, setTab] = useState<Tab>(Tab.CONFIGURATION);
  const [isEditLabelModalOpen, setIsEditLabelModalOpen] = useState(false);

  const { isLoading: updating, mutateAsync: updateDestination } = useUpdateDestinationV2Mutation();
  const { isLoading: deleting, mutateAsync: deleteDestination } = useDeleteDestinationMutation();

  const [testing, setTesting] = useState(false);
  const [testResult, setTestResult] = useState<TestResult>(TestResult.Unknown);
  const [testConnectionResult, setTestConnectionResult] = useState<TestDestinationStepStatus[] | null>(null);
  const [testError, setTestError] = useState<Error | null>(null);

  const updatePermission = useHasPermission([
    { resource: "destination", grants: [ResourcePermissionGrant.Update], resource_id: id },
  ]);
  const deletePermission = useHasPermission([
    { resource: "destination", grants: [ResourcePermissionGrant.Delete], resource_id: id },
  ]);

  const { limit, offset, page, orderBy, sortKey, sortDirection, setPage, onSort } = useTableConfig<DestinationInstancesOrderBy>(
    {
      defaultSortKey: "updated_at",
      sortOptions: Object.values(SortKeys),
    },
  );

  const { data: entitlementsData, isLoading: _loadingEntitlements } = useEntitlements(true);
  const { overageLockout, destinationOverageText } = entitlementsData.overage;
  const overageText = destinationOverageText + " To create a sync, upgrade your plan.";

  const {
    data: destination,
    isLoading: destinationLoading,
    refetch,
  } = useDestinationQuery(
    { id: String(id) },
    {
      enabled: Boolean(id),
      select: (data) => data.destinations_by_pk,
    },
  );

  const definition = destination?.definition;
  const updatedByUsername = destination?.updated_by_user?.name || destination?.created_by_user?.name;

  const { labels: destinationLabels } = useLabels();

  const {
    data: syncsData,
    isLoading: syncsLoading,
    isRefetching: syncsRefetching,
  } = useDestinationSyncsQuery(
    {
      destinationId: id ?? "",
      sameDestinationId: Number(idAsInt),
    },
    {
      keepPreviousData: true,
    },
  );

  const labels = destination?.tags ?? {};
  const labelKeys = Object.keys(labels);

  // Need both syncs from a destination and syncs from sync_templates
  const destinationSyncs = getDifference<Sync>(
    syncsData?.destinations_by_pk?.destination_instances,
    syncsData?.sync_templates.flatMap(({ destination_instances }) => destination_instances),
  );

  const sortedSyncs = useMemo(() => {
    if (!sortKey || !sortDirection) {
      return destinationSyncs;
    }

    const sortedSyncs = [...destinationSyncs];

    sortedSyncs.sort((syncA, syncB) => syncCompareFunction({ syncA, syncB, sortKey, sortDirection }));

    return sortedSyncs;
  }, [destinationSyncs, sortKey, sortDirection]);

  const paginatedSyncs = sortedSyncs.slice(offset, offset + limit);

  useEffect(() => {
    setName(destination?.name ?? undefined);
    setConfig(destination?.config ?? undefined);
    setCredentialId(destination?.credential_id ?? undefined);
  }, [destination]);

  if (!id || destinationLoading || updatePermission.isLoading || deletePermission.isLoading) {
    return <PageSpinner />;
  }

  if (!destination) {
    return <Warning subtitle="It may have been deleted" title="Destination not found" />;
  }

  const onUpdate = () => {
    refetch();
    toast({
      id: "update-destination",
      title: "Destination was updated",
      variant: "success",
    });
  };

  const updateName = async (name: string) => {
    await updateDestination({
      id,
      object: {
        name,
        updated_by: userId,
      },
    });
    onUpdate();
  };

  const update = async () => {
    await updateDestination({
      id,
      object: {
        credential_id: credentialId,
        updated_by: userId,
        config: {
          ...destination?.config,
          ...config,
        },
      },
    });
    onUpdate();
  };

  const updateLabels = async (labels: Record<string, string | number>) => {
    try {
      await updateDestination({
        id,
        object: {
          tags: labels,
          config: {
            ...destination?.config,
            ...config,
          },
        },
      });

      onUpdate();
      setIsEditLabelModalOpen(false);
    } catch (error) {
      toast({
        id: "update-destination-labels",
        title: "Couldn't update labels",
        message: error.message,
        variant: "error",
      });
    }
  };

  const dirty =
    name !== destination?.name ||
    !_.isEqual(config, destination?.config) ||
    !_.isEqual(credentialId, destination?.credential_id);

  const setConfigDirty = (config: Config) => {
    setConfig(config);
    setTestResult(TestResult.Unknown);
    setTestConnectionResult(null);
  };

  const { deployments: deploymentsEnabled } = useFlags();

  const TABS = [
    Tab.CONFIGURATION,
    deploymentsEnabled && Tab.LINKING,
    {
      render: () => (
        <Row gap={2} align="center">
          <Text>Syncs</Text>
          {destinationSyncs?.length > 0 && <Pill>{destinationSyncs?.length}</Pill>}
        </Row>
      ),
      value: Tab.SYNCS,
    },
  ].filter(Boolean);

  const columns = [
    {
      name: "Model",
      sortDirection: orderBy?.segment?.name,
      onClick: () => onSort(SortKeys.ModelName),
      cell: ({ segment }) => {
        const name = segment?.name;
        const definition = segment?.connection?.definition;
        return (
          <Text fontWeight="medium" isTruncated>
            {name || definition?.name}
          </Text>
        );
      },
    },
    {
      name: "Source",
      sortDirection: orderBy?.segment?.connection?.name,
      onClick: () => onSort(SortKeys.SourceName),
      cell: ({ segment }) => {
        const definition = segment?.connection?.definition;
        return (
          <Row gap={2} align="center">
            <IntegrationIcon name={definition?.name} src={definition?.icon} />
            <Text fontWeight="medium" isTruncated>
              {definition?.name}
            </Text>
          </Row>
        );
      },
    },
    {
      name: "Created at",
      sortDirection: orderBy?.created_at,
      onClick: () => onSort(SortKeys.CreatedAt),
      cell: ({ created_at }) => (created_at ? formatDatetime(created_at) : "-"),
    },
  ];

  return (
    <>
      <PermissionProvider permissions={[{ resource: "destination", grants: [ResourcePermissionGrant.Update] }]}>
        <Page
          crumbs={[{ label: "Destinations", link: "/destinations" }, { label: destination?.name || definition?.name || "" }]}
          title={`${destination.name || definition?.name || "Unnamed destination"} - Destinations`}
        >
          <Column width="100%" mb={6}>
            <Header
              actions={
                <Row gap={4}>
                  {definition?.testConnection && <TestDestinationBadge key={0} result={testResult} testing={testing} />}
                  {(updatePermission.hasPermission || deletePermission.hasPermission) && (
                    <Menu>
                      <MenuActionsButton variant="secondary" />
                      <MenuList>
                        {updatePermission.hasPermission && (
                          <MenuItem
                            icon={PencilIcon}
                            onClick={() => {
                              setIsEditLabelModalOpen(true);
                            }}
                          >
                            Edit labels
                          </MenuItem>
                        )}
                        {deletePermission.hasPermission && (
                          <MenuItem
                            icon={TrashIcon}
                            variant="danger"
                            onClick={() => {
                              setDeleteModal(true);
                            }}
                          >
                            Delete
                          </MenuItem>
                        )}
                      </MenuList>
                    </Menu>
                  )}
                </Row>
              }
              title={destination?.name ?? ""}
              onNameChange={updateName}
            />
            <DetailBar>
              <Row align="center" gap={2} flexShrink={0}>
                <IntegrationIcon src={definition?.icon} name={definition?.name ?? ""} />
                <Text fontWeight="medium">{definition?.name}</Text>
              </Row>
              <Row align="center" gap={2} flexShrink={0}>
                <Text>Last updated:</Text>
                <Row gap={1} align="center">
                  {formatDate((destination.updated_at || destination.created_at)!)}
                  {updatedByUsername && (
                    <>
                      <Text>by</Text>
                      <Avatar size="xs" name={updatedByUsername} />
                    </>
                  )}
                </Row>
              </Row>
              <Row align="center" gap={2} flexShrink={0}>
                <Text>Slug:</Text>
                <DisplaySlug currentSlug={destination.slug} />
              </Row>
              {labelKeys.length > 0 && (
                <Row>
                  <Labels labels={labels} />
                </Row>
              )}
            </DetailBar>
          </Column>
          <Column sx={{ width: "100%" }}>
            <Tabs setTab={(tab) => setTab(tab as Tab)} sx={{ mb: 8 }} tab={tab} tabs={TABS} />

            {tab === Tab.CONFIGURATION && (
              <Row align="flex-start">
                <Column gap={8} flexGrow={1} minWidth={0}>
                  <Permission
                    permissions={[{ resource: "destination", grants: [ResourcePermissionGrant.Update], resource_id: id }]}
                  >
                    {definition && (
                      <SetupForm
                        config={config}
                        credentialId={credentialId}
                        definition={definition}
                        destination={destination}
                        error={testError}
                        isSetup={false}
                        setConfig={setConfigDirty}
                        setCredentialId={setCredentialId}
                      />
                    )}
                    {(testConnectionResult || testing) && (
                      <TestConnectionTable result={testConnectionResult} testing={testing} />
                    )}
                  </Permission>
                </Column>
                <SidebarForm
                  buttons={
                    <>
                      {definition?.testConnection ? (
                        <Permission
                          permissions={[{ resource: "destination", grants: [ResourcePermissionGrant.Update], resource_id: id }]}
                        >
                          <TestUpdatedDestinationButton
                            credentialId={credentialId}
                            destinationId={destination?.id?.toString()}
                            newConfiguration={config}
                            onError={setTestError}
                            onLoading={setTesting}
                            onResult={setTestResult}
                            onTestResult={setTestConnectionResult}
                          />
                        </Permission>
                      ) : null}
                      <Permission
                        permissions={[{ resource: "destination", grants: [ResourcePermissionGrant.Update], resource_id: id }]}
                      >
                        <Button variant="primary" isDisabled={!dirty} isLoading={updating} onClick={update}>
                          Save changes
                        </Button>
                      </Permission>
                    </>
                  }
                  docsUrl={definition?.docs ?? ""}
                  name={definition?.name ?? ""}
                />
              </Row>
            )}

            {tab === Tab.LINKING && (
              <Permission
                permissions={[{ resource: "destination", grants: [ResourcePermissionGrant.Create], resource_id: id }]}
              >
                <Row justifyContent="space-between">
                  <FullResourceLinkForm sourceResourceId={id} resourceType="destinations" />
                  <SidebarForm name="destination linking" docsUrl="" />
                </Row>
              </Permission>
            )}

            {tab === Tab.SYNCS && (
              <>
                <Table
                  columns={columns}
                  data={paginatedSyncs}
                  loading={syncsLoading || syncsRefetching}
                  placeholder={{
                    image: placeholderImage,
                    title: "No models are synced to this destination",
                    body: "Still working on this? Add a sync when you’re ready",
                    button: (
                      <Permission permissions={[{ resource: "sync", grants: [ResourcePermissionGrant.Create] }]}>
                        <Tooltip message={overageText} isDisabled={!overageLockout}>
                          <Button
                            variant="primary"
                            isDisabled={overageLockout}
                            onClick={() => {
                              navigate("/syncs/new");
                            }}
                          >
                            Add a sync
                          </Button>
                        </Tooltip>
                      </Permission>
                    ),
                  }}
                  onRowClick={({ id }) => navigate(`/syncs/${id}`)}
                />
                <Row sx={{ justifyContent: "flex-end", width: "100%", mt: 4 }}>
                  <Pagination count={destinationSyncs.length} label="syncs" page={page} rowsPerPage={limit} setPage={setPage} />
                </Row>
              </>
            )}
          </Column>
        </Page>
      </PermissionProvider>

      <ConfirmationDialog
        confirmButtonText="Delete destination"
        isOpen={deleteModal}
        title="Delete destination"
        variant="danger"
        onClose={() => {
          setDeleteModal(false);
        }}
        onConfirm={async () => {
          const { delete_destinations_by_pk } = await deleteDestination({ id });

          if (delete_destinations_by_pk) {
            analytics.track("Destination Deleted", {
              destination_id: destination?.id,
              destination_name: destination?.name,
              destination_type: definition?.name,
            });

            toast({
              id: "delete-destination",
              title: `Destination ${destination?.name || definition?.name} was deleted`,
              variant: "success",
            });

            navigate("/destinations");
          } else {
            toast({
              id: "delete-destination",
              title: "Couldn't delete this destination",
              variant: "error",
            });
          }
        }}
      >
        <Paragraph>Are you sure you want to delete this destination? You won't be able to undo this.</Paragraph>
      </ConfirmationDialog>

      <EditLabelModal
        description="You can label destinations that have similar properties"
        existingLabelOptions={destinationLabels}
        hint="Example keys: team, project, region, env."
        isOpen={isEditLabelModalOpen}
        labels={labels ?? {}}
        loading={updating}
        saveLabel="Save"
        title="Edit labels"
        onClose={() => setIsEditLabelModalOpen(false)}
        onSave={updateLabels}
      />

      <SaveWarning dirty={dirty && !deleting} />
    </>
  );
};
