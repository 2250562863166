import { FC } from "react";

import { TraitConfig, TraitType } from "@hightouch/lib/query/visual/types";
import { Column, Row, Text, Button, Spinner, useToast } from "@hightouchio/ui";
import * as Sentry from "@sentry/react";
import { useNavigate, useParams } from "react-router-dom";

import { useRestoreTraitMutation, useTraitQuery } from "src/graphql";
import { NotFound } from "src/pages/not-found";

import { ArchiveTrait } from "./archive-trait";
import { CalculationSummary } from "./calculation-summary";
import { Drawer } from "./drawer";
import { Metadata } from "./metadata";
import { PreviewTrait } from "./preview-trait";

export const ViewTrait: FC = () => {
  const { traitId } = useParams();
  const navigate = useNavigate();
  const { toast } = useToast();

  const { data, isLoading } = useTraitQuery({ id: traitId ?? "" }, { enabled: traitId != null });
  const restoreTrait = useRestoreTraitMutation();

  const trait = data?.trait_definitions_by_pk;

  const drawerProps = { isOpen: true, onClose: () => navigate("/traits/active") };

  const handleRestoreTrait = async () => {
    if (!trait?.id) return;

    try {
      await restoreTrait.mutateAsync({ id: trait.id });
      navigate(`/traits/${trait.is_template ? "templates" : "active"}/${trait.id}`);

      toast({
        id: "restore-trait",
        title: "Trait was restored successfully",
        variant: "success",
      });
    } catch (error) {
      toast({
        id: "restore-trait",
        title: "Trait failed to be restored",
        variant: "error",
      });
      Sentry.captureException(error);
    }
  };

  if (isLoading) {
    return (
      <Drawer {...drawerProps}>
        <Column height="100%" alignItems="center" justifyContent="center">
          <Spinner size="lg" />
        </Column>
      </Drawer>
    );
  }

  if (!trait) {
    return (
      <Drawer {...drawerProps}>
        <NotFound />
      </Drawer>
    );
  }

  return (
    <Drawer {...drawerProps}>
      <Column position="sticky" top={0}>
        <Metadata
          id={trait.id}
          name={trait.name}
          description={trait.description}
          parentModel={trait.parent_model}
          isTemplate={trait.is_template}
          isEditable
        />
      </Column>
      <Column flex={1} overflowY="auto" p={4}>
        <Row justifyContent="space-between">
          <Text fontWeight="medium">Calculation summary</Text>
          <Button size="sm">Edit</Button>
        </Row>
        <Row mt={2} borderRadius="6px" border="1px solid" borderColor="base.border" padding={4}>
          <CalculationSummary
            type={trait.type as TraitType}
            config={trait.config as TraitConfig}
            model={trait.relationship.to_model}
          />
        </Row>
        <Column mt={4} gap={2}>
          <PreviewTrait
            parentModel={trait.parent_model}
            trait={{
              name: trait.name,
              type: trait.type as TraitType,
              config: trait.config,
              relationshipId: trait.relationship.id,
            }}
          />
        </Column>
        <Column mt={6}>
          <Text fontWeight="medium" mb={4}>
            Usage
          </Text>
        </Column>
      </Column>
      <Row p={4} borderTop="solid 1px" borderTopColor="base.border" justifyContent="space-between">
        {trait.archived_at ? (
          <Button isDisabled={restoreTrait.isLoading} isLoading={restoreTrait.isLoading} onClick={handleRestoreTrait}>
            Restore trait
          </Button>
        ) : (
          <>
            <Button>Duplicate</Button>
            <ArchiveTrait trait={trait} />
          </>
        )}
      </Row>
    </Drawer>
  );
};
