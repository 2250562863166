import { useEffect, useMemo, FC } from "react";

import { Alert, Box, Column, Link, SectionHeading, useToast } from "@hightouchio/ui";
import * as Sentry from "@sentry/browser";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { Outlet, Route, Routes, useLocation, useOutletContext, useParams, useNavigate } from "react-router-dom";
import { Text } from "theme-ui";

import { KeyValueMapping } from "src/components/destinations/key-value-mapping";
import dbtModelsImage from "src/components/extensions/assets/dbt-models.png";
import { Overview } from "src/components/extensions/overview";
import { SyncStatusBadge } from "src/components/extensions/sync-status-badge";
import { GitBranchSelector } from "src/components/git/git-branch-selector";
import { GitChecksToggle } from "src/components/git/git-checks-toggle";
import { GitCredentialsFields } from "src/components/git/git-credentials-fields";
import { GitRepositorySelector } from "src/components/git/git-repository-selector";
import { GitSyncableResourceToggle } from "src/components/git/git-syncable-resource-toggle";
import { Page } from "src/components/layout";
import { SidebarForm } from "src/components/page";
import { PermissionProvider } from "src/contexts/permission-context";
import {
  DbtSyncConfig,
  GitCredentials,
  ResourcePermissionGrant,
  SourcesQuery,
  useCreateDbtSyncConfigMutation,
  useDbtSyncConfigsQuery,
  useGitCredentialsQuery,
  useSourcesQuery,
  useUpdateDbtSyncConfigMutation,
} from "src/graphql";
import { useEntitlements } from "src/hooks/use-entitlement";
import { QueryType } from "src/types/models";
import { Container, Row } from "src/ui/box";
import { Button } from "src/ui/button";
import { Card } from "src/ui/card";
import { Field } from "src/ui/field";
import { CheckCircleIcon, DBTIcon } from "src/ui/icons";
import { Input } from "src/ui/input";
import { PageSpinner } from "src/ui/loading";
import { Select } from "src/ui/select";
import { Tabs } from "src/ui/tabs";

import { Permission } from "../../components/permission";

type Sources = SourcesQuery["connections"];

enum Tab {
  Overview = "Overview",
  Configuration = "Configuration",
}

const SUPPORTED_DBT_VERSIONS = [
  { label: "0.19", value: "0.19" },
  { label: "0.20", value: "0.20" },
  { label: "0.21", value: "0.21" },
  { label: "1.0", value: "1.0" },
  { label: "1.1", value: "1.1" },
  { label: "1.2", value: "1.2" },
  { label: "1.3", value: "1.3" },
  { label: "1.4", value: "1.4" },
  { label: "1.5", value: "1.5" },
];

function getSourceSupportedDbtVersions(sourceType: string) {
  if (sourceType === "athena") {
    return SUPPORTED_DBT_VERSIONS.filter(({ value }) => value && Number(value) > 1.2);
  }
  return SUPPORTED_DBT_VERSIONS;
}

const TABS = [Tab.Overview, Tab.Configuration];

export const DbtModels: FC = () => {
  return (
    <Routes>
      <Route element={<Layout />}>
        <Route
          element={
            <Overview
              description="This integration works by connecting to the Git repository containing your dbt project. As models and analyses are added, they will automatically become available in Hightouch. Optionally, you can also write exposures back to dbt."
              icon={DBTIcon}
              image={dbtModelsImage}
              subtitle="Import models and analyses from dbt"
              title="dbt models"
            />
          }
          path="/"
        />
        <Route element={<Configuration />} path="configuration" />
        <Route element={<SourceForm />} path="configuration/:sourceId" />
      </Route>
    </Routes>
  );
};

const Layout: FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { sourceId } = useParams();

  const path = location.pathname.split("/").pop();
  const tab = path === "configuration" ? Tab.Configuration : Tab.Overview;

  const { data: credentials, isLoading: credsLoading } = useGitCredentialsQuery(undefined, {
    select: (data) => data.git_credentials?.[0],
  });

  const { data: configs, isLoading: configsLoading } = useDbtSyncConfigsQuery(undefined, {
    refetchInterval: 3000,
    select: (data) => data.dbt_sync_config,
  });

  const { data: allSources, isLoading: sourcesLoading } = useSourcesQuery(
    {
      limit: 1000,
    },
    { select: (data) => data.connections },
  );

  const sources = allSources?.filter(({ definition }) => definition?.supportedQueries.includes(QueryType.DbtModel));

  const source = sources?.find(({ id }) => id === Number(sourceId));

  const crumbs = source
    ? [
        { label: "Extensions", link: "/extensions" },
        { label: "dbt models", link: "/extensions/dbt-models" },
        {
          label: source.name,
        },
      ]
    : [{ label: "Extensions", link: "/extensions" }, { label: "dbt models" }];

  return (
    <Page crumbs={crumbs} title="dbt models - Extensions">
      {!sourceId && (
        <Tabs
          setTab={(tab) => {
            if (tab === Tab.Overview) {
              navigate("/extensions/dbt-models");
            } else {
              navigate("configuration");
            }
          }}
          sx={{ mb: 10 }}
          tab={tab}
          tabs={TABS}
        />
      )}
      <Outlet
        context={{
          credentials,
          configs,
          sources,
          source,
          loading: configsLoading || credsLoading || sourcesLoading,
        }}
      />
    </Page>
  );
};

interface OutletContext {
  loading: boolean;
  credentials: GitCredentials;
  configs: Array<DbtSyncConfig>;
  sources: Sources;
  source: Sources[0] | undefined;
}

const Configuration: FC = () => {
  const { loading, credentials, configs, sources } = useOutletContext<OutletContext>();
  const { data: entitlementsData } = useEntitlements(true);
  const { overageLockout, destinationOverageText } = entitlementsData.overage;
  const overageText = destinationOverageText + " To create a source, upgrade your plan.";

  if (loading) {
    return <PageSpinner />;
  }

  return (
    <PermissionProvider permissions={[{ resource: "workspace", grants: [ResourcePermissionGrant.Update] }]}>
      <Row sx={{ flex: 1, justifyContent: "space-between" }}>
        <Container center={false} size="small">
          <Column gap={12}>
            <Column>
              <SectionHeading mb={6}>Credentials</SectionHeading>
              <Column gap={8}>
                <GitCredentialsFields credentials={credentials} isSetup={Boolean(credentials?.id)} page="dbt-models" />
              </Column>
            </Column>
            <Column>
              <SectionHeading mb={6}>dbt connected sources</SectionHeading>
              <Column gap={6}>
                {!sources?.length && (
                  <Card>
                    <SectionHeading>No sources</SectionHeading>
                    <Text sx={{ my: 4, color: "base.5" }}>Add a compatible source to start using dbt models</Text>
                    <Link href="/sources/new">
                      <Button disabled={overageLockout} tooltip={overageLockout ? overageText : undefined}>
                        Add a source
                      </Button>
                    </Link>
                  </Card>
                )}
                {sources?.length > 0 && !credentials?.id && (
                  <Text sx={{ fontSize: 2, color: "base.5" }}>Set up your git credentials before configuring dbt models</Text>
                )}
                {sources.map(({ id, name, definition }) => {
                  const config = configs?.find((config) => String(config.connection_id) === String(id));
                  const content = (
                    <Card
                      footer={
                        <Row sx={{ justifyContent: "flex-end", flex: 1 }}>
                          <Text
                            sx={{
                              color: credentials?.id ? "primaries.8" : "base.4",
                            }}
                          >
                            {config ? "Manage" : "Connect to dbt"}
                          </Text>
                        </Row>
                      }
                      sx={{
                        color: credentials?.id ? "black" : "base.5",
                        bg: credentials?.id ? "white" : "base.1",
                      }}
                    >
                      <Row
                        sx={{
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <Row sx={{ alignItems: "center" }}>
                          <Box as="img" src={definition?.icon} sx={{ mr: 4, width: "24px" }} />
                          <Text sx={{ fontWeight: "bold", fontSize: 2 }}>{name}</Text>
                        </Row>

                        <CheckCircleIcon color={config ? "green" : "base.3"} />
                      </Row>
                      <Row
                        sx={{
                          justifyContent: "space-between",
                          alignItems: "center",
                          mt: 6,
                          display: config ? "flex" : "none",
                        }}
                      >
                        <Text sx={{ color: "base.7" }}>dbt to Hightouch</Text>
                        <SyncStatusBadge
                          error={config?.error}
                          lastAttemptedAt={config?.last_run_at}
                          setup={!config?.last_run_at}
                        />
                      </Row>
                      {config?.exposure_sync_enabled && (
                        <Row
                          sx={{
                            justifyContent: "space-between",
                            alignItems: "center",
                            mt: 3,
                            display: config ? "flex" : "none",
                          }}
                        >
                          <Text sx={{ color: "base.7" }}>Hightouch to dbt</Text>
                          <SyncStatusBadge
                            error={config?.exposure_sync_error}
                            lastAttemptedAt={config?.exposure_sync_last_run_at}
                            setup={!config?.exposure_sync_last_run_at}
                          />
                        </Row>
                      )}
                      <Row
                        sx={{
                          justifyContent: "space-between",
                          alignItems: "center",
                          mt: 3,
                          display: config ? "flex" : "none",
                        }}
                      >
                        <Text sx={{ color: "base.7" }}>dbt Schema</Text>
                        <Text sx={{ color: "base.7" }}>{config?.default_schema}</Text>
                      </Row>
                    </Card>
                  );

                  if (credentials?.id) {
                    return (
                      <Link key={id} href={`/extensions/dbt-models/configuration/${id}`}>
                        {content}
                      </Link>
                    );
                  }

                  return content;
                })}
              </Column>
            </Column>
          </Column>
        </Container>
        <SidebarForm docsUrl="models/dbt-models" name="dbt models" />
      </Row>
    </PermissionProvider>
  );
};

const SourceForm: FC = () => {
  const { loading, configs, credentials, source } = useOutletContext<OutletContext>();
  const { toast } = useToast();
  const formMethods = useForm();
  const { sourceId } = useParams();

  const { mutateAsync: update, isLoading: updateLoading } = useUpdateDbtSyncConfigMutation();
  const { mutateAsync: create } = useCreateDbtSyncConfigMutation();

  const config = useMemo(
    () => configs?.find(({ connection_id }) => String(connection_id) === String(sourceId)),
    [sourceId, configs],
  );

  const {
    register,
    reset,
    handleSubmit,
    watch,
    formState: { isDirty, isSubmitting, errors },
  } = formMethods;

  const modelSyncEnabled = watch("enabled");
  const exposureSyncEnabled = watch("exposure_sync_enabled");

  const submit = async (data) => {
    try {
      if (!config?.id) {
        await create({
          object: {
            ...data,
            git_credentials_id: credentials?.id,
            connection_id: sourceId,
          },
        });
      } else {
        await update({
          id: config.id,
          object: data,
        });
      }

      toast({
        id: "save-dbt-config",
        title: "Configuration was saved",
        variant: "success",
      });
    } catch (e) {
      toast({
        id: "save-dbt-config",
        title: "Couldn't save your configuration",
        variant: "error",
      });

      Sentry.captureException(e);
    }
  };

  const fullResync = async (data) => {
    if (!config?.id) {
      return;
    }
    const resyncData = {
      ...data,
      exposure_sync_last_run_at: null,
      last_run_at: null,
      error: null,
      exposure_sync_error: null,
      full_resync: true,
      metadata: {},
      commit: null,
      failed_attempts: 0,
    };
    await update({
      id: config.id,
      object: resyncData,
    });
  };

  useEffect(() => {
    reset({
      repository: config?.repository ?? "",
      branch: config?.branch ?? "",
      exposure_sync_branch: config?.exposure_sync_branch ?? "",
      path: config?.path ?? "",
      target: config?.target ?? "",
      selector: config?.selector ?? "",
      version: config?.version ?? "",
      default_schema: config?.default_schema ?? "",
      enabled: config?.enabled ?? false,
      checks_enabled: config?.checks_enabled ?? false,
      exposure_sync_enabled: config?.exposure_sync_enabled ?? false,
      exposure_sync_path: config?.exposure_sync_path ?? "",
      connection_id: config?.connection_id,
      env_vars: config?.env_vars,
    });
  }, [config]);

  if (loading) {
    return <PageSpinner />;
  }

  const modelSyncError = (error: any) => {
    return (
      <Row sx={{ pt: "24px", width: "100%" }}>
        <Alert
          type="error"
          title="dbt error"
          message={
            <>
              <Text as="b">dbt command: {error.stage}</Text>
              <Text as="pre" sx={{ whiteSpace: "pre-wrap", fontSize: "10px", pt: 2 }}>
                {error.stdout}
              </Text>
              {error.stderr && (
                <Text as="pre" sx={{ whiteSpace: "pre-wrap", fontSize: "10px", pt: 2 }}>
                  {error.stderr}
                </Text>
              )}
            </>
          }
        />
      </Row>
    );
  };

  return (
    <FormProvider {...formMethods}>
      <PermissionProvider permissions={[{ resource: "workspace", grants: [ResourcePermissionGrant.Update] }]}>
        <Row sx={{ justifyContent: "space-between" }}>
          <Container center={false} size="small">
            <Column gap={12}>
              <Column gap={4}>
                <GitSyncableResourceToggle
                  description="Use your dbt models and analyses in Hightouch Syncs"
                  error={config?.error}
                  fieldName="enabled"
                  icon={<DBTIcon />}
                  last_run_at={config?.last_run_at?.toString()}
                  learnMoreUrl={import.meta.env.VITE_DOCS_URL + "/models/dbt-models"}
                  renderError={config?.error?.stdout && config?.error?.stage ? modelSyncError : undefined}
                  stdout={
                    config?.metadata?.success_stdout && {
                      text: config?.metadata?.success_stdout,
                      title: "dbt model sync is running successfully with the below output",
                      status: "success",
                    }
                  }
                  title="dbt Model Sync"
                  onToggle={(enabled) => {
                    if (!enabled) {
                      formMethods.setValue("exposure_sync_enabled", false);
                    }
                  }}
                />
                {modelSyncEnabled && (
                  <Card>
                    <GitChecksToggle
                      credentials={credentials}
                      description="Check if changed dbt models will impact downstream Hightouch Syncs."
                    />
                  </Card>
                )}
                <GitSyncableResourceToggle
                  blocked={!config?.enabled}
                  description={
                    "See which Hightouch Syncs are using your dbt models in your lineage graph. Hightouch must have write access to your dbt branch." +
                    (config?.enabled ? "" : " - dbt Model sync must be enabled.")
                  }
                  error={config?.exposure_sync_error}
                  fieldName="exposure_sync_enabled"
                  icon={<DBTIcon />}
                  last_run_at={config?.exposure_sync_last_run_at?.toString()}
                  learnMoreUrl="https://docs.getdbt.com/docs/building-a-dbt-project/exposures"
                  title="dbt Exposures Sync"
                />
              </Column>

              <Column>
                <SectionHeading mb={6}>Configuration</SectionHeading>
                <Column gap={8}>
                  <GitRepositorySelector credentials={credentials} />
                  <GitBranchSelector credentials={credentials} />
                  {exposureSyncEnabled && (
                    <>
                      <GitBranchSelector
                        credentials={credentials}
                        description="If dbt Exposure Sync is enabled, Hightouch will attempt to sync the generated dbt Exposures file to this branch. Defaults to your dbt Model Sync branch."
                        label="Exposure sync branch"
                        name="exposure_sync_branch"
                      />
                      <Field
                        optional
                        description="If dbt Exposure Sync is enabled, Hightouch can use a different directory for your exposures file"
                        label="Exposure sync directory"
                      >
                        <Input {...register("exposure_sync_path")} />
                      </Field>
                    </>
                  )}
                  <Field label="dbt Version">
                    <Controller
                      name="version"
                      render={({ field }) => {
                        return (
                          <Select
                            options={getSourceSupportedDbtVersions(source?.type || "")}
                            placeholder="Select a version..."
                            value={field.value}
                            onChange={(selected) => {
                              field.onChange(selected?.value);
                            }}
                          />
                        );
                      }}
                    />
                  </Field>
                  <Field
                    description="The default schema is the schema where dbt would normally materialize your tables to unless otherwise specified in project file. Generally, this is something like public or production or dbt_production."
                    label="Default schema"
                  >
                    <Input {...register("default_schema")} placeholder="production" />
                  </Field>
                  <Field
                    description="Specify the path to your dbt_project.yml file relative to the root of your repository."
                    error={errors.path ? "Enter a valid project path" : undefined}
                    label="dbt project path"
                  >
                    <Input {...register("path", { required: modelSyncEnabled })} placeholder="./dbt_project.yml" />
                  </Field>
                  <Field
                    optional
                    description="By default, Hightouch will select all the models. Specify a dbt selector here such as tag:hightouch or *."
                    label="dbt Selector"
                  >
                    <Input {...register("selector")} />
                  </Field>
                  <Field
                    optional
                    description='By default, Hightouch uses the target name "prod" with the database credentials from the source. This is useful if you use a specific target.name variable in your dbt models.'
                    label="Custom target"
                  >
                    <Input {...register("target")} />
                  </Field>
                  <Field
                    optional
                    description="If set, Hightouch will run dbt commands with these environment variables."
                    label="Custom environment variables"
                  >
                    <Controller
                      name="env_vars"
                      render={({ field }) => (
                        <KeyValueMapping
                          mapping={field.value}
                          setMapping={(map) => {
                            field.onChange(map);
                          }}
                        />
                      )}
                    />
                  </Field>
                </Column>
              </Column>
            </Column>
          </Container>

          <SidebarForm
            buttons={
              <Permission>
                <Button disabled={!isDirty} loading={isSubmitting} sx={{ width: "240px" }} onClick={handleSubmit(submit)}>
                  Save
                </Button>
                <Button
                  disabled={!config?.enabled}
                  loading={updateLoading || !!config?.full_resync}
                  tooltip="Re-compile the latest commit of your dbt repository using your configuration"
                  sx={{ width: "240px" }}
                  variant="secondary"
                  onClick={handleSubmit(fullResync)}
                >
                  Full resync
                </Button>
              </Permission>
            }
            docsUrl="models/dbt-models"
            name="dbt models"
          />
        </Row>
      </PermissionProvider>
    </FormProvider>
  );
};
