import { FC } from "react";

import { IconProps, Icon } from "./icon";

export const IdentityResolutionIcon: FC<Readonly<IconProps>> = (props) => {
  return (
    <Icon {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.95959 1.50164C7.13391 1.4875 7.30295 1.56548 7.40535 1.70726L10.6207 6.1593C11.0636 6.05512 11.5253 6 12 6C13.3429 6 14.5827 6.44116 15.5825 7.18646L20.8494 5.52321C21.0074 5.47334 21.1797 5.50491 21.3098 5.60751C21.4398 5.71011 21.5105 5.87043 21.4987 6.03563L20.4987 20.0356C20.4823 20.2661 20.3101 20.4552 20.0822 20.4932L8.08221 22.4932C7.87714 22.5274 7.67232 22.4311 7.5678 22.2514C7.46327 22.0717 7.48085 21.8461 7.61195 21.6847L10.7174 17.8626C8.01997 17.2752 6 14.8735 6 12C6 11.7448 6.01594 11.4932 6.04687 11.2464L2.15057 12.4768C1.9591 12.5373 1.75004 12.4773 1.61969 12.3246C1.48934 12.1719 1.463 11.956 1.55279 11.7764L6.55279 1.7764C6.63101 1.61997 6.78527 1.51578 6.95959 1.50164ZM6.3013 10.1174C6.8401 8.48553 8.06025 7.16361 9.62489 6.48844L7.07802 2.96201L2.97517 11.1677L6.3013 10.1174ZM11.895 17.9991L9.22151 21.2895L19.1227 19.6393L16.4844 15.9863C15.3854 17.2217 13.7836 18 12 18C11.9649 18 11.9299 17.9997 11.895 17.9991ZM17.1105 15.1452L19.5994 18.5913L20.3883 7.54575L17.8516 10.6679C17.9487 11.0963 18 11.5422 18 12C18 13.1534 17.6746 14.2307 17.1105 15.1452ZM17.4761 9.54427L19.5604 6.97897L16.44 7.96433C16.8635 8.43 17.2148 8.96254 17.4761 9.54427ZM17 12C17 14.7614 14.7614 17 12 17C9.23858 17 7 14.7614 7 12C7 9.23858 9.23858 7 12 7C14.7614 7 17 9.23858 17 12Z"
        fillOpacity="0.3"
      />
      <path d="M15 12C15 13.6569 13.6569 15 12 15C10.3431 15 9 13.6569 9 12C9 10.3431 10.3431 9 12 9C13.6569 9 15 10.3431 15 12Z" />
      <path d="M22 6C22 6.55228 21.5523 7 21 7C20.4477 7 20 6.55228 20 6C20 5.44772 20.4477 5 21 5C21.5523 5 22 5.44772 22 6Z" />
      <path d="M21 20C21 20.5523 20.5523 21 20 21C19.4477 21 19 20.5523 19 20C19 19.4477 19.4477 19 20 19C20.5523 19 21 19.4477 21 20Z" />
      <path d="M8 2C8 2.55228 7.55228 3 7 3C6.44772 3 6 2.55228 6 2C6 1.44772 6.44772 1 7 1C7.55228 1 8 1.44772 8 2Z" />
      <path d="M3 12C3 12.5523 2.55228 13 2 13C1.44772 13 1 12.5523 1 12C1 11.4477 1.44772 11 2 11C2.55228 11 3 11.4477 3 12Z" />
      <path d="M9 22C9 22.5523 8.55228 23 8 23C7.44772 23 7 22.5523 7 22C7 21.4477 7.44772 21 8 21C8.55228 21 9 21.4477 9 22Z" />
    </Icon>
  );
};
