import { FC, useEffect } from "react";

import { Column, useToast, Heading, TextInput, FormField } from "@hightouchio/ui";
import { yupResolver } from "@hookform/resolvers/yup";
import { captureException } from "@sentry/react";
import { useFlags } from "launchdarkly-react-client-sdk";
import Helmet from "react-helmet";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";

import { ScheduleManager } from "src/components/schedule";
import { ScheduleType } from "src/components/schedule/types";
import { useUser } from "src/contexts/user-context";
import { useCreateSequenceMutation, SyncSequenceMembersInsertInput, ResourcePermissionGrant } from "src/graphql";
import useHasPermission from "src/hooks/use-has-permission";
import { TrackView } from "src/lib/analytics";
import { SequenceForm, FormState } from "src/pages/sequences/sequence-form";
import { Wizard } from "src/ui/wizard";
import { Step } from "src/ui/wizard/wizard";
import { isScheduleComplete } from "src/utils/schedule";
import { SlugResourceType, useResourceSlug } from "src/utils/slug";
import { useWizardStepper } from "src/utils/use-wizard-stepper";

export const CreateSequence: FC = () => {
  const { toast } = useToast();
  const navigate = useNavigate();
  const [step, setStep] = useWizardStepper(0);
  const { getSlug } = useResourceSlug(SlugResourceType.SyncSequences);
  const flags = useFlags();
  const { workspace } = useUser();
  const { hasPermission } = useHasPermission([{ resource: "sync", grants: [ResourcePermissionGrant.Create] }]);

  const { mutateAsync: createSequence, isLoading: creating } = useCreateSequenceMutation();

  const formMethods = useForm<FormState>({
    defaultValues: {
      name: "",
      members: [],
      schedule: { type: ScheduleType.MANUAL },
    },
    resolver: yupResolver(validationSchema),
  });
  const { handleSubmit, control, watch } = formMethods;
  const schedule = watch("schedule");
  const members = watch("members");

  const submit = async (data) => {
    const members = data.members.map(({ sync, abort_seq_on_failure, abort_seq_on_rejects }, index) => {
      const member: SyncSequenceMembersInsertInput = {
        sync_id: sync.id,
        reference_id: index + 1,
        abort_seq_on_failure,
        abort_seq_on_rejects,
        links: {
          predecessors: index > 0 ? [index] : [],
          successors: index !== data.members.length - 1 ? [index + 2] : [],
        },
      };

      return member;
    });

    try {
      const slug = await getSlug(data.name);
      const sequence = await createSequence({
        object: {
          slug,
          name: data.name,
          schedule: data.schedule?.type === "manual" ? null : data.schedule,
          members: { data: members },
        },
      });
      toast({
        id: "create-sequence",
        title: "Sequence was created",
        variant: "success",
      });
      navigate(`/sequences/${sequence?.insert_sync_sequences_one?.id}`);
    } catch (error) {
      captureException(error);
      toast({
        id: "create-sequence",
        title: "Sequence could not be created",
        variant: "error",
      });
    }
  };

  const steps: Step[] = [
    {
      title: "Configure sequence",
      disabled: !(members.length > 1),
      header: <Heading>Add syncs to your sequence</Heading>,
      render: () => (
        <FormProvider {...formMethods}>
          <SequenceForm />
        </FormProvider>
      ),
    },
    {
      title: "Finalize sequence",
      disabled: !schedule || !isScheduleComplete({ schedule, flags, workspace }),
      submitting: creating,
      header: <Heading>Finalize settings for this sequence</Heading>,
      render: () => {
        return (
          <Column gap={8} maxWidth="576px" width="100%">
            <Controller
              control={control}
              name="name"
              render={({ field, fieldState: { error } }) => (
                <FormField
                  description="Including details about the sequence's business purpose"
                  label="Sequence name"
                  error={error?.message}
                >
                  <TextInput {...field} isInvalid={Boolean(error)} autoFocus />
                </FormField>
              )}
            />
            <Controller
              control={control}
              name="schedule"
              render={({ field }) => (
                <ScheduleManager resource="sequence" schedule={field.value} setSchedule={field.onChange} />
              )}
            />
          </Column>
        );
      },
    },
  ];

  useEffect(() => {
    if (!hasPermission) {
      navigate("/sequences");
    }
  }, [hasPermission]);

  return (
    <>
      <Helmet>
        <title>New sequence</title>
      </Helmet>

      <TrackView name="New Sequence Page" />

      <Wizard
        setStep={setStep}
        step={step}
        steps={steps}
        title="New sequence"
        onCancel={() => {
          navigate("/sequences");
        }}
        onSubmit={handleSubmit(submit)}
      />
    </>
  );
};

const validationSchema = yup.object().shape({
  name: yup.string().required("Sequence name is required"),
});
