import { FC } from "react";

import { Box, Heading, Button, PlusIcon, Column, Row, Text, ArrowRightIcon } from "@hightouchio/ui";

import { Terms } from "src/components/auth/terms";
import { usePartner } from "src/partner/context";
import { PartnerLogo } from "src/partner/partner-logo";
import { Logo } from "src/ui/brand";

import fastIcon from "./assets/fast-icon.svg";
import scaleIcon from "./assets/scale-icon.svg";
import secureIcon from "./assets/secure-icon.svg";

export const Welcome: FC<Readonly<{ onContinue: () => void }>> = ({ onContinue }) => {
  const { brand } = usePartner();

  return (
    <Column mx="auto" p={10} gap={10} align="center" justify="center" height="100vh">
      <Column
        border={["none", "none", "1px solid var(--chakra-colors-base-border)"]}
        borderRadius="md"
        bg="white"
        gap={10}
        boxShadow={["none", "none", "lg"]}
        maxW="576px"
        p={[0, 0, 10]}
      >
        <Row gap={6} align="center" justify="center">
          <PartnerLogo />
          <Box fontSize="2xl" color="text.secondary">
            <PlusIcon />
          </Box>
          <Logo variant="full" size="120px" textColor="#03484D" />
        </Row>

        <Box px={12}>
          <Heading size="xl" textAlign="center">
            {brand.name} uses Hightouch to connect to your data source
          </Heading>
        </Box>

        <Column gap={6} mx="auto">
          <Row gap={4} align="flex-start">
            <Box as="img" src={fastIcon} height="40px" />
            <Column>
              <Text fontWeight="medium">Fast and flexible</Text>
              <Text color="text.secondary">
                Connect to 30+ sources, including data warehouses, transactional databases, and object storage.
              </Text>
            </Column>
          </Row>

          <Row gap={4} align="flex-start">
            <Box as="img" src={secureIcon} height="40px" />
            <Column>
              <Text fontWeight="medium">Secure by default</Text>
              <Text color="text.secondary">
                Data transfer is encrypted, and credentials are never shared with {brand.name}. Hightouch does not store your
                data after a sync is complete.
              </Text>
            </Column>
          </Row>

          <Row gap={4} align="flex-start">
            <Box as="img" src={scaleIcon} height="40px" />
            <Column>
              <Text fontWeight="medium">Built for scale</Text>
              <Text color="text.secondary">
                Sync billions of records at the click of a button. Hightouch monitors your data and syncs records only when they
                change.
              </Text>
            </Column>
          </Row>
        </Column>

        <Button size="lg" variant="primary" directionIcon={ArrowRightIcon} onClick={onContinue}>
          Continue
        </Button>
      </Column>
      <Terms variant="light" />
    </Column>
  );
};
