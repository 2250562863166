import { FC } from "react";

import { Column, BoxProps, Paragraph, Text } from "@hightouchio/ui";
import * as Sentry from "@sentry/browser";

import {
  AndCondition,
  AndOrCondition,
  Audience,
  AudienceParent,
  ConditionType,
  OrCondition,
  Condition,
  RootCondition,
} from "src/types/visual";

import { QueryBuilderProvider } from "./context/query-builder-context";
import { AndOrConditionField } from "./visual/condition";

export interface QueryBuilderProps {
  audience?: Audience;
  parent: AudienceParent | undefined | null;
  filter: AndOrCondition<AndCondition | OrCondition> | undefined;
  setConditions: (filter: AndCondition | OrCondition | null) => void;
  limit?: number;
  setLimit?: (limit: number) => void;
  sx?: BoxProps["sx"];
}

export const QueryBuilder: FC<Readonly<QueryBuilderProps>> = ({
  audience,
  parent,
  filter = { type: ConditionType.And, conditions: [] as RootCondition[] },
  setConditions,
}) => {
  /* top level conditions may only have one element. There the type may be defined so that the query builder
    is aware of whether the conditions should be `AND`'d or `OR`'d */

  const updateFilter = (newCondition: Condition | AndCondition | OrCondition) => {
    if (newCondition.type !== ConditionType.And && newCondition.type !== ConditionType.Or) {
      Sentry.captureException(
        new Error("The top level condition in the query builder is neither an AND condition nor an OR condition"),
        {
          extra: {
            condition: newCondition,
          },
        },
      );
      return;
    }

    return setConditions({
      ...filter,
      ...newCondition,
    });
  };

  return (
    <QueryBuilderProvider audience={audience} parent={parent}>
      <Column mb={4}>
        <Text fontWeight="semibold" size="lg">
          Define your audience
        </Text>
        <Paragraph color="text.secondary">Filter down customers using anything in your underlying data.</Paragraph>
      </Column>
      <Column minWidth={0} pb={6} fontSize={0}>
        <AndOrConditionField
          audience={audience}
          condition={filter}
          level={0}
          parent={parent}
          onChange={updateFilter}
          // Top level onRemove and onGroup do nothing
          onRemove={() => updateFilter({ ...filter, conditions: [] })}
        />
      </Column>
    </QueryBuilderProvider>
  );
};
