import { FC, useMemo, useState } from "react";

import { Row, Text } from "@hightouchio/ui";
import { isEmpty } from "lodash";

import { IntegrationIcon } from "src/components/integrations/integration-icon";
import { Labels } from "src/components/labels/labels";
import { ResourceSelect } from "src/components/resource-select";
import { ConnectionsBoolExp, ConnectionsOrderBy, useSourcesQuery, SourcesQuery } from "src/graphql";
import { QueryType } from "src/types/models";
import { TableColumn, useTableConfig } from "src/ui/table";
import { LastUpdatedColumn } from "src/ui/table/columns/last-updated";
import { SourceBadges } from "src/utils/sources";

export type Source = SourcesQuery["connections"][0];

enum SortKeys {
  Name = "name",
  Type = "type",
  UpdatedAt = "updated_at",
}

type Props = {
  onSelect: (source: Source) => void;
  queryType?: QueryType;
  filter?: ConnectionsBoolExp;
};

export const SourceSelect: FC<Readonly<Props>> = ({ onSelect, queryType, filter = {} }) => {
  const [search, setSearch] = useState("");

  const { onSort, orderBy } = useTableConfig<ConnectionsOrderBy>({
    defaultSortKey: "updated_at",
    sortOptions: Object.values(SortKeys),
  });

  const hasuraFilters: ConnectionsBoolExp = useMemo(() => {
    if (search) {
      return {
        ...filter,
        name: {
          _ilike: `%${search}%`,
        },
      };
    }
    return filter;
  }, [search, filter]);

  const {
    data: sources,
    error,
    isLoading: loading,
    isRefetching,
  } = useSourcesQuery({ filters: hasuraFilters, orderBy }, { select: (data) => data.connections });

  const availableSources = useMemo(() => {
    return queryType ? sources?.filter((source) => source?.definition?.supportedQueries.includes(queryType)) : sources;
  }, [sources, queryType]);

  const columns: TableColumn[] = [
    {
      name: "Name",
      sortDirection: orderBy?.name,
      onClick: () => onSort(SortKeys.Name),
      cell: (source) => (
        <Row align="center" gap={2} overflow="hidden">
          <Text isTruncated fontWeight="medium">
            {source.name ?? "Private source"}
          </Text>
          <SourceBadges isSampleDataSource={source.definition.isSampleDataSource} />
        </Row>
      ),
    },
    {
      name: "Type",
      sortDirection: orderBy?.type,
      onClick: () => onSort(SortKeys.Type),
      max: "max-content",
      cell: (source) => (
        <Row align="center" gap={2} overflow="hidden">
          <IntegrationIcon name={source.definition.name} src={source.definition.icon} />
          <Text isTruncated fontWeight="medium">
            {source.definition.name ?? "Private source"}
          </Text>
        </Row>
      ),
    },
    {
      name: "Labels",
      key: "tags",
      max: ".5fr",
      cell: (tags) => {
        if (isEmpty(tags)) {
          return "--";
        }
        return <Labels labels={tags} />;
      },
      breakpoint: "md",
    },
    {
      ...LastUpdatedColumn,
      max: "max-content",
      breakpoint: "md",
      sortDirection: orderBy?.updated_at,
      onClick: () => onSort(SortKeys.UpdatedAt),
    },
  ];

  return (
    <ResourceSelect
      columns={columns}
      data={availableSources}
      error={Boolean(error)}
      label="source"
      loading={loading || isRefetching}
      search={search}
      onSearch={setSearch}
      onSelect={onSelect}
    />
  );
};
