import { FC, useCallback } from "react";

import { Heading, Row, LinkButton, StatusBadge } from "@hightouchio/ui";
import { useNavigate } from "react-router-dom";

import genericPlaceholder from "src/assets/placeholders/generic.svg";
import { IntegrationIcon } from "src/components/integrations/integration-icon";
import { eventWarehouseDestinationDefinitions, eventSourceDefinitions } from "src/events/types";
import { useEventWarehouseSyncsQuery } from "src/graphql";
import { PageTable, TableColumn } from "src/ui/table";
import { LastUpdatedColumn } from "src/ui/table/columns/last-updated";
import { TextWithTooltip } from "src/ui/text";
import { openUrl } from "src/utils/urls";

export const EventWarehouseSyncs: FC = () => {
  const navigate = useNavigate();
  const {
    data: sources,
    isFetching,
    error,
  } = useEventWarehouseSyncsQuery(undefined, { select: (data) => data.event_warehouse_syncs });

  const onRowClick = useCallback(({ id }, event) => openUrl(`/events/syncs/${id}`, navigate, event), [navigate]);

  return (
    <PageTable
      header={
        <>
          <Heading isTruncated size="xl">
            Event syncs
          </Heading>
          <Row>
            <LinkButton href="/events/syncs/new" variant="primary" onClick={() => {}}>
              Create sync
            </LinkButton>
          </Row>
        </>
      }
      columns={columns}
      data={sources}
      error={Boolean(error)}
      loading={isFetching}
      placeholder={placeholder}
      onRowClick={onRowClick}
    />
  );
};

const columns: TableColumn[] = [
  {
    name: "Status",
    cell: ({ runs }) => {
      const lastRun = runs?.[0];
      const status = lastRun?.status ?? "pending";
      return <StatusBadge variant={status}>{status}</StatusBadge>;
    },
  },
  {
    name: "Source",
    cell: ({ event_source: source }) => {
      const definition = eventSourceDefinitions[source.type];
      return (
        <Row overflow="hidden" gap={2} align="center">
          <IntegrationIcon name={definition.name} src={definition.icon} />
          <TextWithTooltip message={source.name} isTruncated fontWeight="medium">
            {source.name}
          </TextWithTooltip>
        </Row>
      );
    },
  },
  {
    name: "Destination",
    cell: ({ event_warehouse_destination: destination }) => {
      const definition = eventWarehouseDestinationDefinitions[destination.type];
      return (
        <Row overflow="hidden" gap={2} align="center">
          <IntegrationIcon name={definition.name} src={definition.icon} />
          <TextWithTooltip message={destination.name} isTruncated fontWeight="medium">
            {destination.name}
          </TextWithTooltip>
        </Row>
      );
    },
  },
  LastUpdatedColumn,
];

const placeholder = {
  image: genericPlaceholder,
  title: "No event syncs",
  body: "Create a new event sync",
  error: "Event syncs failed to load, please try again.",
};
