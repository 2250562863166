export enum EventSourceType {
  JAVASCRIPT = "javascript",
  HTTP = "http",
  NODE = "node",
  PYTHON = "python",
  IOS = "ios",
  ANDROID = "android",
}

export enum EventWarehouseDestinationType {
  SNOWFLAKE = "snowflake",
  REDSHIFT = "redshift",
  BIGQUERY = "bigquery",
  DATABRICKS = "databricks",
}
