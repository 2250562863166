import { FC, useEffect, useState } from "react";

import { Column, Row, Heading, FormField, Combobox, TextInput, Box, Button, Text, PlusIcon, Tooltip } from "@hightouchio/ui";
import { useFlags } from "launchdarkly-react-client-sdk";

import { DestinationForm } from "src/components/destinations/sync-form";
import { Explore } from "src/components/explore";
import { IntegrationIcon } from "src/components/integrations/integration-icon";
import { ScheduleManager } from "src/components/schedule";
import { ScheduleType } from "src/components/schedule/types";
import { SourceForm } from "src/components/sources/setup";
import { Source } from "src/components/sources/source-catalog";
import { useUser } from "src/contexts/user-context";
import {
  useCreateSyncMutation,
  useCreateModelMutation,
  useModelQuery,
  SourceDefinition,
  useCreateSourceV2Mutation,
  useSourceQuery,
  useDestinationQuery,
  useSourceDefinitionsQuery,
  useUpdateSourceV2Mutation,
  useUpdateModelMutation,
  useSourcesQuery,
} from "src/graphql";
import { usePartner } from "src/partner/context";
import { Success } from "src/partner/success";
import { Welcome } from "src/partner/welcome";
import { PartnerWizard, Step } from "src/partner/wizard";
import { QueryType } from "src/types/models";
import { Selectable } from "src/ui/selectable";
import { useModelRun, useModelState, useQueryState, useUpdateQuery } from "src/utils/models";
import { isScheduleComplete } from "src/utils/schedule";
import { SlugResourceType, useResourceSlug } from "src/utils/slug";
import { useWizardStepper } from "src/utils/use-wizard-stepper";

import { CreateSyncConfig } from "./types";

export const CreateSync: FC<Readonly<{ config: CreateSyncConfig }>> = ({ config: partnerConfig }) => {
  const { brand } = usePartner();
  const { workspace } = useUser();
  const [modelId, setModelId] = useState<string>();
  const { modelState, setName: setModelName, setPrimaryKey } = useModelState();
  const [schedule, setSchedule] = useState<any>({ type: ScheduleType.MANUAL });
  const [syncConfig, setSyncConfig] = useState<any>();
  const [hasQueryColumns, setHasQueryColumns] = useState(false);
  const { getSlug: getSyncSlug } = useResourceSlug(SlugResourceType.Syncs);
  const { getSlug: getModelSlug } = useResourceSlug(SlugResourceType.Segments);
  const { getSlug: getSourceSlug } = useResourceSlug(SlugResourceType.Connections);
  const [sourceDefinition, setSourceDefinition] = useState<SourceDefinition>();
  const [tunnelId, setTunnelId] = useState<string | null>();
  const [credentialId, setCredentialId] = useState<string>();
  const [sourceConfig, setSourceConfig] = useState<any>({});
  const [sourceId, setSourceId] = useState(partnerConfig.sourceId ?? "");
  const [sourceName, setSourceName] = useState("");
  const [isWelcome, setIsWelcome] = useState(true);
  const [isSuccess, setIsSuccess] = useState(false);
  const [step, setStep] = useWizardStepper(0);
  const [isCreatingSource, setIsCreatingSource] = useState(false);
  const flags = useFlags();

  const sourceDefinitionsQuery = useSourceDefinitionsQuery(undefined, {
    select: (data) => data.getSourceDefinitions,
    suspense: true,
  });
  const sourcesQuery = useSourcesQuery(undefined, { select: (data) => data.connections, suspense: true });

  const { data: model } = useModelQuery(
    {
      id: String(modelId),
    },
    { enabled: Boolean(modelId), select: (data) => data.segments_by_pk },
  );
  const { data: source } = useSourceQuery(
    { id: String(sourceId) },
    {
      enabled: Boolean(sourceId),
      select: (data) => data.connections_by_pk,
    },
  );
  const { data: destination } = useDestinationQuery(
    { id: String(partnerConfig.destinationId) },
    {
      select: (data) => data.destinations_by_pk,
    },
  );

  const createSourceMutation = useCreateSourceV2Mutation({
    onSuccess: () => {
      // skip query invalidation
    },
  });
  const updateSourceMutation = useUpdateSourceV2Mutation({
    onSuccess: () => {
      // skip query invalidation
    },
  });
  const { mutateAsync: createSync } = useCreateSyncMutation();
  const createModelMutation = useCreateModelMutation({
    onSuccess: () => {
      // skip query invalidation
    },
  });
  const updateModelMutation = useUpdateModelMutation({
    onSuccess: () => {
      // skip query invalidation
    },
  });
  const updateQuery = useUpdateQuery();

  const {
    queryState,
    resetQueryState,
    isQueryDefined,
    setSQL,
    setTable,
    setDBTModel,
    setLookerLook,
    setSigma,
    setCustomQuery,
  } = useQueryState();

  const {
    runQuery,
    cancelQuery,
    rows,
    numRowsWithoutLimit,
    isResultTruncated,
    columns,
    rawColumns,
    loading: queryLoading,
    error: queryError,
    errorAtLine: queryErrorAtLine,
    resetRunState,
  } = useModelRun(QueryType.RawSql, undefined, {
    variables: { sourceId: source?.id, dbtModelId: queryState?.dbtModel?.id, ...queryState },
  });

  const createModel = async () => {
    if (modelId) {
      await updateModelMutation.mutateAsync({
        id: modelId,
        input: {
          name: modelState.name,
          description: modelState.description,
          primary_key: modelState.primaryKey,
        },
      });
      await updateQuery({ model, queryState, columns: columns ?? rawColumns, overwriteMetadata: true });
      return;
    }

    const slug = await getModelSlug(`partner-${modelState.name}`);

    const data = await createModelMutation.mutateAsync({
      input: {
        slug,
        query_type: QueryType.RawSql,
        name: modelState.name,
        description: modelState.description,
        primary_key: modelState.primaryKey,
        connection_id: source?.id,
        query_raw_sql: queryState?.sql,
        columns: { data: columns },
        destination_instances: { data: [] },
        git_sync_metadata: null,
        draft: false,
      },
    });
    const id = data.insert_segments_one?.id;
    setModelId(id);
  };

  const createSource = async () => {
    if (sourceId) {
      await updateSourceMutation.mutateAsync({
        id: sourceId,
        object: {
          name: sourceDefinition?.name,
          config: sourceConfig,
          type: sourceDefinition?.type,
          credential_id: credentialId ? String(credentialId) : undefined,
        },
      });
      return;
    }

    const slug = await getSourceSlug(`partner-${sourceDefinition?.type}`);
    const { createSourceWithSecrets } = await createSourceMutation.mutateAsync({
      object: {
        slug,
        name: sourceDefinition?.name,
        config: sourceConfig,
        type: sourceDefinition?.type,
        setup_complete: true,
        tunnel_id: null,
        credential_id: credentialId ? String(credentialId) : undefined,
        plan_in_warehouse: false,
        sample_data_source_id: null,
        plan_in_warehouse_config: null,
      },
    });
    const id = createSourceWithSecrets?.id.toString();
    setSourceId(id);
  };

  const submit = async () => {
    const slug = await getSyncSlug(`${model?.name} to ${destination?.name}`);
    await createSync({
      object: {
        slug,
        destination_id: destination?.id,
        segment_id: model?.id,
        config: { ...syncConfig, configVersion: destination?.definition?.configVersion },
        schedule: null,
      },
    });
    setIsSuccess(true);
  };

  const steps: Array<Step | boolean | undefined> = [
    !partnerConfig.sourceId &&
      sourcesQuery.data &&
      sourcesQuery.data?.length > 0 && {
        label: "Select a data source",
        description: "Choose an existing data source or set up a new connection.",
        next: "Select a data source to continue",
        render: () => {
          return (
            <Box flex={1} display="grid" gridTemplateColumns="repeat(auto-fit, minmax(200px, 1fr))" gridAutoRows="64px" gap={4}>
              {sourcesQuery.data?.map((source) => {
                return (
                  <Selectable
                    bg="white"
                    key={source.id}
                    selected={false}
                    p={4}
                    overflow="visible"
                    height="64px"
                    onSelect={() => {
                      setIsCreatingSource(false);
                      setSourceName(source.name);
                      setSourceDefinition(source.definition);
                      setSourceId(source.id);
                      setStep((step) => step + 1);
                    }}
                  >
                    <Row align="center" gap={4}>
                      <IntegrationIcon src={source.definition.icon} name={source.definition.name} />
                      <Text fontWeight="medium">{source.name}</Text>
                    </Row>
                  </Selectable>
                );
              })}
              <Selectable
                selected={false}
                p={4}
                overflow="visible"
                height="64px"
                onSelect={() => {
                  setIsCreatingSource(true);
                  setStep((step) => step + 1);
                }}
                bg="forest.100"
              >
                <Row align="center" gap={4} fontSize="3xl">
                  <PlusIcon />
                  <Text fontWeight="semibold">Add a new source</Text>
                </Row>
              </Selectable>
            </Box>
          );
        },
      },
    isCreatingSource && {
      label: "Select a data source type",
      description: "Connect to your data warehouse, transactional database, object storage, etc.",
      next: "Select a data source to continue",
      render: () => {
        return (
          <Box display="grid" gridTemplateColumns="repeat(auto-fit, minmax(200px, 1fr))" gap={4}>
            {partnerConfig.sources?.map((type) => {
              const definition = sourceDefinitionsQuery.data?.find((d) => d.type === type);
              if (definition) {
                return (
                  <Source
                    definition={definition}
                    onSelect={(definition) => {
                      setSourceId("");
                      setSourceDefinition(definition);
                      setStep((step) => step + 1);
                    }}
                    selected={false}
                  />
                );
              }
              return null;
            })}
          </Box>
        );
      },
    },
    isCreatingSource && {
      label: "Configure your data source",
      description: "Provide credentials and connection details for your chosen data source.",
      onNext: async () => {
        await createSource();
        setStep((step) => step + 1);
      },
      render: () => (
        <Column bg="white" borderRadius="md" border="1px" borderColor="base.border" p={6}>
          <SourceForm
            showSyncEngine={false}
            config={sourceConfig}
            credentialId={credentialId}
            definition={sourceDefinition!}
            disableAuthMethod={false}
            isSetup={true}
            lightningEnabled={false}
            setConfig={setSourceConfig}
            setCredentialId={setCredentialId}
            setTunnelId={setTunnelId}
            sourceId={undefined}
            tunnelId={tunnelId}
            plannerDatabase={undefined}
            setPlannerDatabase={() => {}}
            setLightningEnabled={() => {}}
            hasSetupLightning={false}
            onConnectClick={() => {}}
          />
        </Column>
      ),
    },
    {
      isDisabled:
        !isQueryDefined(QueryType.RawSql) ||
        !hasQueryColumns ||
        Boolean(queryError) ||
        !modelState.name ||
        !modelState.primaryKey,
      label: "Define your data model",
      description: "Use SQL to transform or filter the data you want to sync.",
      onNext: async () => {
        await createModel();
        setStep((step) => step + 1);
      },
      tooltip: queryError
        ? "Fix the error and preview again to continue"
        : !hasQueryColumns
        ? "Preview results to continue"
        : undefined,
      render: () => {
        const columnOptions = columns?.map(({ name }) => ({ value: name, label: name })) ?? [];

        return (
          <>
            <Row
              gap={4}
              mb={4}
              p={4}
              bg="white"
              borderRadius="md"
              border="1px"
              borderColor="base.border"
              flexDir={["column", "column", "row"]}
            >
              <FormField label="Model name" description="Describe the business purpose of your query">
                <TextInput
                  width="100%"
                  value={modelState?.name}
                  onChange={(event) => setModelName(event.target.value)}
                  placeholder="Enter a name..."
                />
              </FormField>
              <FormField label="Primary key" description="The column that uniquely identifies each row.">
                <Box sx={{ "& > span": { width: "100%" } }}>
                  <Tooltip
                    message="Preview results before selecting a primary key column"
                    isDisabled={Boolean(columnOptions?.length)}
                  >
                    <Combobox
                      width="100%"
                      isDisabled={!columnOptions?.length}
                      optionLabel={(co) => co.label}
                      optionValue={(co) => co.value}
                      options={columnOptions as { value: string; label: string }[]}
                      placeholder="Select a column..."
                      value={columnOptions?.find((co) => co.value === modelState?.primaryKey)?.value}
                      onChange={(column) => {
                        setPrimaryKey(column);
                      }}
                    />
                  </Tooltip>
                </Box>
              </FormField>
            </Row>
            {(source || sourceDefinition) && (
              <Explore
                limit
                enableRowCounter
                isPlaceholder={false}
                cancelQuery={cancelQuery}
                columns={columns}
                isResultTruncated={Boolean(isResultTruncated)}
                numRowsWithoutLimit={numRowsWithoutLimit}
                rows={rows}
                runQuery={runQuery}
                source={
                  source ??
                  ({
                    type: sourceDefinition?.type,
                    definition: sourceDefinition,
                    name: sourceName ?? sourceDefinition?.name,
                  } as any)
                }
                type={QueryType.RawSql}
                {...queryState}
                error={queryError}
                errorAtLine={queryErrorAtLine}
                isQueryDefined={isQueryDefined}
                loading={queryLoading}
                rowsPerPage={15}
                onCustomQueryChange={setCustomQuery}
                onDBTModelChange={setDBTModel}
                onLookerLookChange={setLookerLook}
                onSQLChange={setSQL}
                onSigmaChange={setSigma}
                onTableChange={setTable}
              />
            )}
          </>
        );
      },
    },
    {
      next: (
        <Button variant="primary" size="lg" form="destination-form" type="submit">
          Continue
        </Button>
      ),
      label: `Configure sync to ${brand.name}`,
      description: `Define how your data model should be ingested by ${brand.name}.`,
      render: () => {
        if (destination && model) {
          return (
            <Column bg="white" borderRadius="md" border="1px" borderColor="base.border" p={6}>
              <DestinationForm
                hideSave
                hideSidebar
                destination={destination}
                destinationDefinition={destination?.definition}
                model={model}
                slug={destination.type}
                sourceDefinition={sourceDefinition}
                onSubmit={(config) => {
                  setSyncConfig(config);
                  setStep((step) => step + 1);
                  return Promise.resolve();
                }}
              />
            </Column>
          );
        }
        return null;
      },
    },
    {
      isDisabled: !isScheduleComplete({ schedule, flags, workspace }),
      label: "Schedule sync",
      description: "Set a recurring interval for your sync.",
      render: () => (
        <Column gap={6} bg="white" borderRadius="md" border="1px" borderColor="base.border" p={6}>
          <ScheduleManager
            schedule={schedule}
            setSchedule={setSchedule}
            types={[ScheduleType.CRON, ScheduleType.CUSTOM, ScheduleType.INTERVAL, ScheduleType.MANUAL]}
          />
        </Column>
      ),
    },
  ].filter(Boolean);

  useEffect(() => {
    setHasQueryColumns(false);
  }, [queryState]);

  useEffect(() => {
    if (columns?.length && !queryError) {
      setHasQueryColumns(true);
    }
  }, [rows, columns]);

  useEffect(() => {
    setSourceConfig(undefined);
    setTunnelId(undefined);
    setCredentialId("");
    resetQueryState();
    resetRunState();
  }, [sourceDefinition]);

  if (isWelcome) {
    return (
      <Welcome
        onContinue={() => {
          setIsWelcome(false);
        }}
      />
    );
  }

  if (isSuccess) {
    return (
      <Success>
        <Heading size="xl" textAlign="center">
          Your new sync to {brand.name} has been created!
        </Heading>
        <Row gap={4}>
          <Button
            size="lg"
            variant="primary"
            onClick={() => {
              window.close();
            }}
          >
            Return to {brand.name}
          </Button>
        </Row>
      </Success>
    );
  }

  return (
    <>
      <PartnerWizard
        step={step}
        steps={steps as any}
        onStepChange={setStep}
        onSubmit={submit}
        onCancel={() => {
          window.close();
        }}
      />
    </>
  );
};
