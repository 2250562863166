import { FC, Fragment, ReactNode } from "react";

import {
  ConditionType,
  CountDedupedTraitConfig,
  CountTraitConfig,
  OrderDedupedTraitConfig,
  RawColumn,
  RawSqlTraitConfig,
  RelatedColumn,
  SumTraitConfig,
  TraitCondition,
  TraitConfig,
  TraitType,
} from "@hightouch/lib/query/visual/types";
import { exhaustiveCheck } from "@hightouch/lib/util/exhaustive-check";
import { Row, Text, Column, Box } from "@hightouchio/ui";

import { EventColumn, RelationColumn } from "../explore/filter-popover/constants";
import { IconBox } from "../explore/filter-popover/icon-box";
import { colors } from "../explore/visual/colors";
import { formatValue, getOperatorLabel, getPropertyNameFromColumn, getPropertyNameFromProperty } from "../explore/visual/utils";
import { TRAIT_TYPE_LABELS } from "./utils";

type Props = {
  type: TraitType;
  config: TraitConfig;
  model: { event: { timestamp_column: string } | null | undefined; name: string };
  hideConditions?: boolean;
};

const parseTraitConfig = (
  type: TraitType,
  rawConfig: TraitConfig,
): {
  aggregatedColumn?: RawColumn | RelatedColumn;
  orderByColumn?: RawColumn | RelatedColumn;
  aggregation?: string;
  conditions?: TraitCondition[];
} => {
  switch (type) {
    case TraitType.Sum: {
      const config = rawConfig as SumTraitConfig;
      return {
        aggregatedColumn: config.column,
        conditions: config.conditions,
      };
    }
    case TraitType.Average: {
      const config = rawConfig as SumTraitConfig;
      return {
        aggregatedColumn: config.column,
        conditions: config.conditions,
      };
    }
    case TraitType.Count: {
      const config = rawConfig as CountTraitConfig;
      return {
        aggregatedColumn: config.column,
        conditions: config.conditions,
      };
    }
    case TraitType.MostFrequent:
    case TraitType.LeastFrequent: {
      const config = rawConfig as CountDedupedTraitConfig;
      return {
        aggregatedColumn: config.toSelect,
        conditions: config.conditions,
      };
    }
    case TraitType.First:
    case TraitType.Last: {
      const config = rawConfig as OrderDedupedTraitConfig;
      return {
        aggregatedColumn: config.toSelect,
        orderByColumn: config.orderBy,
        conditions: config.conditions,
      };
    }
    case TraitType.RawSql: {
      const config = rawConfig as RawSqlTraitConfig;
      return {
        aggregation: config.aggregation,
        conditions: config.conditions,
      };
    }
    default:
      exhaustiveCheck(type);
  }
};

export const CalculationSummary: FC<Readonly<Props>> = ({ type, config: rawConfig, model, hideConditions }) => {
  const { aggregatedColumn, orderByColumn, aggregation, conditions } = parseTraitConfig(type, rawConfig);
  const isEventModel = model.event;

  const ModelWithIcon = () => (
    <Row gap={1} alignItems="center" ml={0.5}>
      <IconBox
        bg={isEventModel ? EventColumn.color : RelationColumn.color}
        boxSize={4}
        icon={isEventModel ? EventColumn.icon : RelationColumn.icon}
        iconSize={3}
      />
      <BoldText>{model.name}</BoldText>
    </Row>
  );

  const summary =
    type === TraitType.RawSql
      ? [{ value: aggregation, Component: BoldText }]
      : [
          {
            value: TRAIT_TYPE_LABELS[type],
            Component: BoldText,
          },
          {
            value: "of",
            Component: Text,
          },
          {
            value: getPropertyNameFromColumn(aggregatedColumn),
            Component: BoldText,
          },
          {
            value: orderByColumn ? "ordered by" : null,
            Component: orderByColumn ? Text : Fragment,
          },
          {
            value: orderByColumn ? getPropertyNameFromColumn(orderByColumn) : null,
            Component: orderByColumn ? BoldText : Fragment,
          },
        ];

  summary.push(
    {
      value: isEventModel ? "of events in" : "of rows in",
      Component: Text,
    },
    {
      value: null,
      Component: ModelWithIcon,
    },
  );

  return (
    <Column gap={4}>
      <Row gap={1} flexWrap="wrap">
        {summary.map(({ value, Component }, index) => (
          <Component key={`${value}-${index}`}>{value}</Component>
        ))}
      </Row>
      {!hideConditions && <Conditions conditions={conditions} />}
    </Column>
  );
};

const BoldText = ({ children }: { children: ReactNode }) => (
  <Text color="text.primary" fontWeight="medium">
    {children}
  </Text>
);

const Conditions = ({ conditions = [] }: { conditions?: TraitCondition[] }) => {
  if (conditions.length === 0) {
    return null;
  }

  const topLevelConditionType = conditions[0]?.type || ConditionType.And;
  const subconditions = conditions[0]?.conditions;

  return (
    <Column pl={8} gap={4}>
      {subconditions?.map((condition, index) => (
        <Row key={index} gap={1} alignItems="baseline">
          {index === 0 ? (
            <Text color="text.secondary">Where</Text>
          ) : (
            <Box
              bg={colors.base[topLevelConditionType]}
              borderRadius={6}
              minWidth={0}
              p={1}
              textTransform="uppercase"
              fontWeight="medium"
            >
              {topLevelConditionType}
            </Box>
          )}

          <Row as={Text} gap={1} color="text.secondary" flexWrap="wrap">
            <BoldText>{getPropertyNameFromProperty(condition.property)}</BoldText>{" "}
            {getOperatorLabel(condition.operator, condition.propertyType, Boolean(condition.propertyOptions?.parameterize))}{" "}
            {formatValue(condition, condition.value, { showParameterizedLabel: true })}
          </Row>
        </Row>
      ))}
    </Column>
  );
};
