import { FC, useEffect, useState } from "react";

import {
  AddBoxIcon,
  Box,
  Column,
  Heading,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Row,
  Tab,
  TabList,
  Tabs,
  Text,
  TraitIcon,
  TraitTemplateIcon,
} from "@hightouchio/ui";
import { useFlags } from "launchdarkly-react-client-sdk";
import { Outlet, Route, Routes, useLocation, useNavigate, useParams } from "react-router-dom";

import { CreateTrait } from "src/components/traits/create-trait";
import { CreateTraitFromTemplate } from "src/components/traits/create-trait-from-template";
import { ViewTrait } from "src/components/traits/view-trait";

import { NotFound } from "../not-found";
import { TraitsTableType, TraitsTable } from "./traits-table";

export const Traits: FC = () => {
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route path="active" element={<TraitsTable type={TraitsTableType.Active} />}>
          <Route path="new" element={<CreateTrait isTemplate={false} />} />
          <Route path="new_from_template" element={<CreateTraitFromTemplate />} />
          <Route path=":traitId" element={<ViewTrait />} />
        </Route>
        <Route path="templates" element={<TraitsTable type={TraitsTableType.Templates} />}>
          <Route path="new" element={<CreateTrait isTemplate />} />s
          <Route path=":traitId" element={<ViewTrait />} />
        </Route>
        <Route path="archived" element={<TraitsTable type={TraitsTableType.Archived} />}>
          <Route path=":traitId" element={<ViewTrait />} />
        </Route>
      </Route>
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

const TABS = [
  {
    name: "Traits",
    path: "active",
  },
  {
    name: "Templates",
    path: "templates",
  },
  {
    name: "Archived",
    path: "archived",
  },
];

const Layout: FC = () => {
  const { traitsV2 } = useFlags();

  const { pathname } = useLocation();
  const { traitId } = useParams();

  const navigate = useNavigate();
  const [tabIndex, setTabIndex] = useState(0);

  useEffect(() => {
    if (!traitsV2) {
      navigate("/", { replace: true });
    }
  }, [traitsV2]);

  // Navigate to the correct tab based on the path
  useEffect(() => {
    const pathArray = pathname.split("/");

    // pathArray looks like
    // [workspaceSlug, "traits", "active" | "templates" | "archived", ...]
    const traitType = pathArray.at(2);

    const index = TABS.findIndex((tab) => tab.path === traitType);
    // Reset to first tab if the tab route doesn't exist
    if (index === -1) {
      navigate("/traits/active", { replace: true });
    } else {
      setTabIndex(index);
    }
  }, [pathname, traitId]);

  const onTabChange = (index: number) => {
    const path = TABS[index]?.path;
    if (path) {
      navigate(path);
    }
  };

  const menuItems = [
    {
      label: "New trait",
      description: "Run a calculation on a related model column and add filtering conditions",
      icon: TraitIcon,
      onClick: () => navigate("/traits/active/new"),
    },
    {
      label: "Start with a template",
      description: "Build a new trait based on a template",
      icon: TraitTemplateIcon,
      onClick: () => navigate("/traits/active/new_from_template"),
    },
    {
      label: "New template",
      description: "Save the calculation method",
      icon: AddBoxIcon,
      onClick: () => navigate("/traits/templates/new"),
    },
  ];

  return (
    <>
      <Row justifyContent="space-between" px={8} pt={6}>
        <Heading size="xl">Traits</Heading>
        <Menu>
          <MenuButton variant="primary">Create</MenuButton>
          <MenuList>
            {menuItems.map((item) => (
              <MenuItem key={item.label} onClick={item.onClick}>
                <Row alignItems="center" gap={3} width="320px">
                  <Box as={item.icon} color="text.secondary" height={4} width={4} />
                  <Column width="248px">
                    <Text>{item.label}</Text>
                    <Text color="text.secondary">{item.description}</Text>
                  </Column>
                </Row>
              </MenuItem>
            ))}
          </MenuList>
        </Menu>
      </Row>
      <Tabs index={tabIndex} onChange={onTabChange}>
        <TabList pl={8}>
          {TABS.map(({ name }) => (
            <Tab key={name}>{name}</Tab>
          ))}
        </TabList>
      </Tabs>

      <Column px={6} pt={4}>
        <Outlet />
      </Column>
    </>
  );
};
