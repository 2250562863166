import { useCallback, useEffect, FC } from "react";

import {
  Button,
  Column,
  ChakraModal,
  ChakraModalBody,
  ChakraModalContent,
  ChakraModalFooter,
  ChakraModalHeader,
  ChakraModalOverlay,
} from "@hightouchio/ui";
import { FormProvider, useForm } from "react-hook-form";

import { Option } from "src/ui/select";

import { createObjectFromOptions } from "./create-object-from-options";
import { createOptionsFromObject } from "./create-options-from-object";
import { LabelForm } from "./label-form";

interface ExistingLabelOption {
  key: string | null;
  values: string[];
}

type Props = {
  description: string;
  existingLabelOptions?: ExistingLabelOption[];
  hint?: string;
  isOpen: boolean;
  labels?: Record<string, string>;
  loading: boolean;
  saveLabel?: string;
  title: string;
  onClose: () => void;
  onSave: (labels: Record<string, string>) => Promise<void>;
};

export const EditLabelModal: FC<Readonly<Props>> = ({
  description,
  isOpen,
  title,
  onClose,
  labels: initialLabels,
  existingLabelOptions = [],
  saveLabel,
  onSave,
  ...props
}) => {
  const getInitialValues = useCallback(() => {
    const initialValues = createOptionsFromObject(initialLabels);

    return initialValues.length ? initialValues : [{ key: "", value: "" }];
  }, [initialLabels]);

  const formProps = useForm<{ labels: { key: string; value: string }[] }>({
    defaultValues: {
      labels: getInitialValues(),
    },
  });
  const { handleSubmit, watch, reset, getValues } = formProps;

  const formLabels = watch("labels");
  const validLabels = formLabels.filter(({ key, value }) => key !== "" && value !== "");

  const keyOptions: Array<{ label: string; value: string | null }> = existingLabelOptions
    ?.filter(({ key }) => !formLabels.find((label) => label.key === key))
    ?.map(({ key }) => ({ value: key, label: key! }));

  // Reset form values when modal is closed
  useEffect(() => {
    if (!isOpen) {
      reset({ labels: getInitialValues() });
    }
  }, [getInitialValues, isOpen]);

  const getOptionsForExistingLabelValues = (fieldIndex: number): Option[] => {
    const key = getValues().labels[fieldIndex]?.key;

    if (!key) {
      return [];
    }
    const existingLabelValues = existingLabelOptions.find((option) => option.key === key)?.values || [];
    return existingLabelValues?.map((value) => ({ value, label: value }));
  };

  return (
    <ChakraModal isOpen={isOpen} onClose={onClose}>
      <ChakraModalOverlay>
        <ChakraModalContent
          w="xl"
          maxW="3xl"
          motionProps={{
            initial: {
              scale: 0.9,
              opacity: 0,
            },
            variants: {
              enter: {
                scale: 1,
                opacity: 1,
                transition: {
                  type: "spring",
                  damping: 30,
                  stiffness: 1000,
                },
              },
              exit: {
                scale: 0.9,
                opacity: 0,
                transition: {
                  type: "spring",
                  duration: 0.15,
                },
              },
            },
          }}
        >
          <Column gap={4}>
            <ChakraModalHeader>{title}</ChakraModalHeader>
            <ChakraModalBody>
              <FormProvider {...formProps}>
                <LabelForm
                  heading={description}
                  getExistingLabelOptions={getOptionsForExistingLabelValues}
                  keyOptions={keyOptions}
                  {...props}
                />
              </FormProvider>
            </ChakraModalBody>
            <ChakraModalFooter>
              <Button onClick={onClose}>Cancel</Button>
              <Button
                variant="primary"
                isLoading={props.loading}
                onClick={handleSubmit(() => onSave(createObjectFromOptions(validLabels)))}
              >
                {saveLabel}
              </Button>
            </ChakraModalFooter>
          </Column>
        </ChakraModalContent>
      </ChakraModalOverlay>
    </ChakraModal>
  );
};
