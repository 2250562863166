import { FC, useEffect, useState } from "react";

import { Column, Row, Heading, TextInput, Box, Button, FormField } from "@hightouchio/ui";
import { captureException } from "@sentry/react";

import { SourceForm } from "src/components/sources/setup";
import { Source } from "src/components/sources/source-catalog";
import { SourceDefinition, useCreateSourceV2Mutation, useSourceDefinitionsQuery } from "src/graphql";
import { usePartner } from "src/partner/context";
import { Success } from "src/partner/success";
import { Welcome } from "src/partner/welcome";
import { PartnerWizard, Step } from "src/partner/wizard";
import { SlugResourceType, useResourceSlug } from "src/utils/slug";
import { useWizardStepper } from "src/utils/use-wizard-stepper";

import { CreateSourceConfig } from "./types";

export const CreateSource: FC<Readonly<{ config: CreateSourceConfig }>> = ({ config: partnerConfig }) => {
  const { brand } = usePartner();
  const { getSlug: getSourceSlug } = useResourceSlug(SlugResourceType.Connections);
  const [sourceDefinition, setSourceDefinition] = useState<SourceDefinition>();
  const [tunnelId, setTunnelId] = useState<string | null>();
  const [credentialId, setCredentialId] = useState<string>();
  const [sourceConfig, setSourceConfig] = useState<any>({});
  const [name, setName] = useState("");
  const [isWelcome, setIsWelcome] = useState(true);
  const [isSuccess, setIsSuccess] = useState(false);
  const [step, setStep] = useWizardStepper(0);

  const sourceDefinitionsQuery = useSourceDefinitionsQuery(undefined, {
    select: (data) => data.getSourceDefinitions,
    suspense: true,
  });

  const createSourceMutation = useCreateSourceV2Mutation({
    onSuccess: () => {
      // skip query invalidation
    },
  });

  const submit = async () => {
    const slug = await getSourceSlug("oem-demo");
    try {
      await createSourceMutation.mutateAsync({
        object: {
          slug,
          name: sourceDefinition?.name,
          config: sourceConfig,
          type: sourceDefinition?.type,
          setup_complete: true,
          tunnel_id: null,
          credential_id: credentialId ? String(credentialId) : undefined,
          plan_in_warehouse: false,
          sample_data_source_id: null,
          plan_in_warehouse_config: null,
        },
      });

      setIsSuccess(true);
    } catch (error) {
      captureException(error);
    }
  };

  const steps: Step[] = [
    {
      label: "Select a source",
      description: "Choose a source type to connect to",
      next: "Select a source to continue",
      render: () => {
        return (
          <Box display="grid" gridTemplateColumns="repeat(auto-fit, minmax(200px, 400px))" gridAutoRows="64px" gap={4}>
            {partnerConfig.sources.map((type) => {
              const definition = sourceDefinitionsQuery.data?.find((d) => d.type === type);
              if (definition) {
                return (
                  <Source
                    definition={definition}
                    onSelect={(definition) => {
                      setSourceDefinition(definition);
                      setStep((step) => step + 1);
                    }}
                    selected={false}
                  />
                );
              }
              return null;
            })}
          </Box>
        );
      },
    },
    {
      label: "Configure",
      description: "Enter your source credentials",
      render: () => (
        <Column gap={6} bg="white" borderRadius="md" border="1px" borderColor="base.border" p={6}>
          <SourceForm
            showSyncEngine={false}
            config={sourceConfig}
            credentialId={credentialId}
            definition={sourceDefinition!}
            disableAuthMethod={false}
            isSetup={true}
            lightningEnabled={false}
            setConfig={setSourceConfig}
            setCredentialId={setCredentialId}
            setTunnelId={setTunnelId}
            sourceId={undefined}
            tunnelId={tunnelId}
            plannerDatabase={undefined}
            setPlannerDatabase={() => {}}
            setLightningEnabled={() => {}}
            hasSetupLightning={false}
            onConnectClick={() => {}}
          />
        </Column>
      ),
    },
    {
      label: "Finalize",
      description: "Give your source a name",
      render: () => (
        <Column gap={6} bg="white" borderRadius="md" border="1px" borderColor="base.border" p={6}>
          <FormField label="Source name">
            <TextInput autoFocus placeholder="Enter a name..." value={name} onChange={(event) => setName(event.target.value)} />
          </FormField>
        </Column>
      ),
    },
  ];

  useEffect(() => {
    setSourceConfig(undefined);
    setTunnelId(undefined);
    setCredentialId("");
  }, [sourceDefinition]);

  if (isWelcome) {
    return (
      <Welcome
        onContinue={() => {
          setIsWelcome(false);
        }}
      />
    );
  }

  if (isSuccess) {
    return (
      <Success>
        <Heading size="xl" textAlign="center">
          Your new source has been created!
        </Heading>
        <Row gap={4}>
          <Button
            size="lg"
            variant="primary"
            onClick={() => {
              window.close();
            }}
          >
            Return to {brand.name}
          </Button>
        </Row>
      </Success>
    );
  }

  return (
    <PartnerWizard
      step={step}
      steps={steps as any}
      onStepChange={setStep}
      onSubmit={submit}
      onCancel={() => {
        window.close();
      }}
    />
  );
};
