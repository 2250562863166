import { FC, useEffect, useMemo } from "react";

import { ColumnType } from "@hightouch/lib/query/visual/types";
import { Checkbox, Column, GroupedCombobox, InformationIcon, Row, Select, Text, Tooltip } from "@hightouchio/ui";
import { uniq } from "lodash";
import { v4 as uuidv4 } from "uuid";

import { useFormErrorContext } from "src/contexts/form-error-context";
import { useColumnSuggestionsQuery } from "src/graphql";
import {
  DefaultOperators,
  isColumnReference,
  isRelatedColumn,
  isTraitColumn,
  OperatorOptions,
  PropertyCondition,
  shouldResetTimeType,
  shouldResetValue,
  TimeType,
} from "src/types/visual";

import { useQueryBuilderContext } from "../context/query-builder-context";
import { FilterPopover } from "../filter-popover/filter-popover";
import { FilterProps, HStack } from "./condition";
import { XButton } from "./condition-buttons";
import { validatePropertyCondition } from "./condition-validation";
import { ErrorMessage } from "./error-message";
import { Filter } from "./filter";
import { PropertyInput } from "./property-input";
import { TraitFilter } from "./trait-filter";
import {
  formatValue,
  getColumnFromValue,
  getColumnOptions,
  getModelIdFromColumn,
  getPropertyNameFromColumn,
  getTraitOptions,
} from "./utils";

export type PropertyFilterProps = FilterProps<PropertyCondition> & {
  prefix?: boolean;
  showSecondaryFilter?: boolean;
  showParameterizeCheckbox?: boolean;
};

export const PropertyFilter: FC<Readonly<PropertyFilterProps>> = ({
  showSecondaryFilter = false,
  showParameterizeCheckbox = false,
  ...props
}) => {
  const { columns: columnsOverride, traits: traitsOverride, condition, onChange, onRemove, parent } = props;
  const filterId = useMemo<string>(uuidv4, []);

  const { columns: allColumns, traits: allTraits } = useQueryBuilderContext();
  const columns = columnsOverride ?? allColumns;
  const traits = traitsOverride ?? allTraits;

  const { getErrors, setFieldError, removeErrors } = useFormErrorContext();

  const filterErrors = getErrors(filterId);
  const propertyError = filterErrors?.property;
  const operatorError = filterErrors?.operator;
  const valueError = filterErrors?.value;

  useEffect(() => {
    setFieldError(filterId, validatePropertyCondition(condition));

    return () => {
      removeErrors([filterId]);
    };
  }, [condition.property, condition.operator, condition.value, filterId, condition.propertyOptions]);

  const isDeprecatedPropertyCondition = !isColumnReference(condition.property);

  const modelId = isColumnReference(condition.property) ? getModelIdFromColumn(condition.property) : parent?.id;
  const columnName = isColumnReference(condition.property) ? getPropertyNameFromColumn(condition.property) : condition.property;

  const { data: columnSuggestionsData, isLoading: loadingSuggestions } = useColumnSuggestionsQuery(
    {
      modelIds: [String(modelId)],
      columnNames: [columnName],
    },
    {
      enabled: modelId !== null && columnName !== null,
    },
  );

  const suggestions = columnSuggestionsData?.getTopK?.columns?.find?.(
    (column) => column.modelId === String(modelId) && column.name === columnName,
  )?.values;

  const mergedModels = uniq([
    ...(columns?.map(({ model_name }) => model_name) || []),
    ...(traits?.map((trait) => trait.relationship.to_model.name) || []),
  ]);
  const isTrait = isRelatedColumn(condition.property) && isTraitColumn(condition.property.column);

  const propertyOptions =
    mergedModels.length > 1
      ? mergedModels.map((model) => {
          const modelColumns = columns?.filter(({ model_name }) => model_name === model);
          const traitsForModel = traits?.filter((trait) => trait.relationship.to_model.name === model);
          const traitOptions = getTraitOptions(condition.property, traitsForModel);
          return {
            label: model === parent?.name ? "Properties" : model,
            options: [...traitOptions, ...getColumnOptions(modelColumns ?? [], isDeprecatedPropertyCondition)],
          };
        })
      : [
          {
            label: "Properties",
            options: [
              ...getTraitOptions(condition.property, traits),
              ...getColumnOptions(columns ?? [], isDeprecatedPropertyCondition),
            ],
          },
        ];

  const isParameterized = Boolean(condition.propertyOptions?.parameterize);

  const connectionType = props.parent?.connection?.definition.type;
  const operatorOptions = condition.propertyType
    ? OperatorOptions[condition.propertyType].filter(
        (op) => !("supportedSources" in op) || op.supportedSources.includes(connectionType),
      )
    : undefined;
  const operatorLabel = isParameterized ? "is" : operatorOptions?.find((option) => option.value === condition.operator)?.label;
  const formattedValue = formatValue(condition, condition.value, { showParameterizedLabel: true });

  return (
    <>
      <HStack gap={2} sx={{ alignItems: "flex-start" }}>
        {showSecondaryFilter ? (
          <Column sx={{ input: { fontWeight: "medium" } }}>
            <GroupedCombobox
              isDisabled={loadingSuggestions}
              isInvalid={Boolean(propertyError)}
              isLoading={loadingSuggestions}
              optionGroups={propertyOptions}
              placeholder="Select a property"
              width="auto"
              value={condition.property ?? undefined}
              onChange={(value) => {
                const column = getColumnFromValue(
                  propertyOptions.flatMap(({ options }) => options),
                  value,
                );

                if (!column) {
                  return;
                }

                onChange({
                  propertyType: column.type,
                  property: value,
                  propertyOptions: {
                    caseSensitive: "case_sensitive" in column ? column.case_sensitive ?? undefined : undefined,
                  },
                  operator: column.type ? DefaultOperators[column.type] : null,
                  value: null,
                });
              }}
            />
            {propertyError && <ErrorMessage>{propertyError}</ErrorMessage>}
          </Column>
        ) : (
          <>
            <Text color="text.secondary" mt={1.5} ml={4}>
              {isTrait ? "Has custom trait" : "Has property"}
            </Text>
            <FilterPopover
              {...props}
              condition={condition}
              isDisabled={loadingSuggestions}
              hasError={Boolean(propertyError)}
              onChange={onChange}
            />
          </>
        )}

        {condition.property && (
          <Filter
            content={
              <Row alignItems="flex-start" gap={2}>
                {showParameterizeCheckbox && (
                  <Row alignSelf="center" gap={1}>
                    <Checkbox
                      label="Parameterize"
                      isChecked={isParameterized}
                      onChange={(event) => {
                        const propertyType = condition.propertyType ?? ColumnType.Unknown;
                        onChange({
                          operator: DefaultOperators[propertyType],
                          value: null,
                          propertyOptions: {
                            ...condition.propertyOptions,
                            parameterize: event.target.checked,
                          },
                        });
                      }}
                    />
                    <Tooltip message="Parameterizing a filter allows users to select a value on the audience level. For example: set a “Revenue” metric with “Brand” as a parametrized filter and users can select the relevant brand for each audience.">
                      <InformationIcon />
                    </Tooltip>
                  </Row>
                )}
                {!isParameterized && (
                  <>
                    <Select
                      options={operatorOptions ?? []}
                      placeholder="Filter on"
                      value={condition.operator}
                      width="auto"
                      onChange={(value) => {
                        if (shouldResetTimeType(condition.operator, value)) {
                          onChange({ operator: value, timeType: TimeType.Relative, value: null });
                        } else if (shouldResetValue(condition.operator, value)) {
                          onChange({ operator: value, value: null });
                        } else {
                          onChange({ operator: value });
                        }
                      }}
                    />
                    <PropertyInput {...props} error={valueError} loading={loadingSuggestions} suggestions={suggestions} />
                  </>
                )}
              </Row>
            }
            error={operatorError || valueError}
          >
            <Row gap={1} overflow="hidden">
              <Text color="text.secondary">{operatorLabel}</Text> <Text fontWeight="medium">{formattedValue}</Text>
            </Row>
          </Filter>
        )}
        {onRemove && <XButton onRemove={onRemove} />}
      </HStack>
      {isTrait && <TraitFilter {...props} condition={condition} />}
    </>
  );
};
