import { Text } from "@hightouchio/ui";

export const ErrorMessage = ({ children }) => {
  // TODO: migrate query builder to use form fields
  // for accessibility.
  return (
    <Text color="text.danger" mt={2} size="sm">
      {children}
    </Text>
  );
};
