import { FC, useEffect } from "react";

import { TraitConfig, TraitType } from "@hightouch/lib/query/visual/types";
import { Button, Column, Combobox, FormField, Heading, Row, Text, TextInput, Textarea, useToast } from "@hightouchio/ui";
import * as Sentry from "@sentry/react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { useNavigate, useSearchParams } from "react-router-dom";

import { TraitDefinitionsConstraint, useCreateTraitMutation, useTraitQuery, useTraitsQuery } from "src/graphql";

import { CalculationSummary } from "./calculation-summary";
import { Drawer } from "./drawer";
import { PlaceholderDrawer } from "./placeholder-drawer";
import { TraitConditions } from "./trait-conditions";
import { validateConfig } from "./utils";

type FormProps = {
  name: string;
  description: string;
  parent_model_id: string;
  relationship_id: string;
  type: TraitType;
  config: TraitConfig;
};

export const CreateTemplatedTrait: FC = () => {
  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();
  const templateId = searchParams.get("template_id");

  const onCloseDrawer = () => navigate("/traits/active");

  const { data, isLoading } = useTraitQuery({ id: templateId ?? "" });
  const trait = data?.trait_definitions_by_pk;

  const traitTemplatesQuery = useTraitsQuery({
    filter: {
      is_template: { _eq: true },
      archived_at: { _eq: null },
    },
  });

  const templateOptions = (traitTemplatesQuery.data?.trait_definitions ?? []).map((template) => ({
    label: template.name,
    value: template.id,
    description: template.description,
  }));

  const { toast } = useToast();
  const form = useForm<FormProps>();
  const { control, handleSubmit, reset, watch } = form;

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore - no circular types on Column
  const name = watch("name");
  const type = watch("type");
  const config = watch("config");
  const createTrait = useCreateTraitMutation();

  useEffect(() => {
    if (!trait) return;

    const initialFormValues = {
      type: trait.type as TraitType,
      parent_model_id: trait.parent_model.id,
      relationship_id: trait.relationship.id,
      config: trait.config as TraitConfig,
      is_template: false,
    };
    reset(initialFormValues);
  }, [trait]);

  const onSubmit = async (formData: FormProps) => {
    try {
      const newTrait = await createTrait.mutateAsync({
        input: {
          name: formData.name,
          description: formData.description,
          parent_model_id: formData.parent_model_id,
          relationship_id: formData.relationship_id,
          type: formData.type,
          config: formData.config,
          is_template: false,
          // TODO: set trait_template_id = templateId -- https://github.com/hightouchio/hightouch/pull/9845
        },
      });

      toast({
        id: "create-trait",
        title: "Trait was created successfully",
        variant: "success",
      });

      const newTraitId = newTrait.insert_trait_definitions_one?.id;
      navigate(`/traits/active/${newTraitId}`);
    } catch (error) {
      toast({
        id: "create-trait",
        title: "Trait failed to be created",
        message: error.message.includes(TraitDefinitionsConstraint.TraitDefinitionsNameParentModelIdKey)
          ? `There is an existing trait named "${formData.name}" associated with this parent model. Please choose a different name and try again.`
          : error.message,
        variant: "error",
      });
      Sentry.captureException(error);
    }
  };

  if (isLoading || !trait) {
    return <PlaceholderDrawer isLoading={isLoading} isMissing={!trait} onClose={onCloseDrawer} />;
  }

  const isSaveDisabled = createTrait.isLoading || !name || !validateConfig(type, config);

  return (
    <Drawer isOpen onClose={onCloseDrawer}>
      <Row position="sticky" top={0} p={6} borderBottom="1px solid" borderBottomColor="base.border" width="100%">
        <Heading>New trait</Heading>
      </Row>

      <Column flex={1} overflowY="auto" p={6} gap={6}>
        <Column gap={2}>
          <Text fontWeight="medium">Create a new trait from a template</Text>
          <Text>
            A trait lets you perform a calculation on a column of a related/event model. It acts as a new column on a parent
            model and can be utilized in associated Audiences.
          </Text>
        </Column>

        <FormField label="Choose a template" tip="This trait will be available in the audiences of this parent model.">
          <Combobox
            options={templateOptions}
            onChange={(templateId) => {
              if (templateId) {
                setSearchParams({ template_id: templateId });
              }
            }}
            value={templateId}
            width="100%"
          />
        </FormField>

        <Column borderRadius="6px" border="1px solid" borderColor="base.border" p={4} gap={4}>
          <CalculationSummary model={trait.relationship.to_model} type={type} config={config} hideConditions />
          <FormProvider {...form}>
            <TraitConditions parentModel={trait.parent_model} relationship={trait.relationship} />
          </FormProvider>
        </Column>

        <FormField label="Name" tip="Shows up in the audience query builder when selecting from a list of traits.">
          <Column gap={6}>
            <Controller
              control={control}
              name="name"
              render={({ field }) => <TextInput placeholder="Enter a name" width="100%" {...field} />}
            />
            <Controller
              control={control}
              name="description"
              render={({ field }) => <Textarea placeholder="Enter a description" width="100%" {...field} />}
            />
          </Column>
        </FormField>
      </Column>
      <Row p={4} borderTop="solid 1px" borderTopColor="base.border" justifyContent="space-between">
        <Button onClick={() => navigate("/traits/active/new_from_template")}>Back</Button>
        <Button isDisabled={isSaveDisabled} variant="primary" onClick={handleSubmit(onSubmit)}>
          Save
        </Button>
      </Row>
    </Drawer>
  );
};
