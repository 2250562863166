import { FC, useEffect, useState } from "react";

import {
  AudienceIcon,
  Box,
  Column,
  Heading,
  IconButton,
  Link,
  PlusIcon,
  Row,
  SectionHeading,
  Select,
  SettingsIcon,
  Text,
  CloseIcon,
  Paragraph,
  ExternalLinkIcon,
  ArrowRightIcon,
  Switch,
  MetricIcon,
  Tooltip,
  ErrorIcon,
  Combobox,
  InformationIcon,
} from "@hightouchio/ui";
import { useFlags } from "launchdarkly-react-client-sdk";
import { Helmet } from "react-helmet";

import analyticsPlaceholder from "src/assets/placeholders/analytics.svg";
import CrossAudienceGraph from "src/components/analytics/cross-audience-graph";
import { transformMetricDataForGraph } from "src/components/analytics/cross-audience-graph/utils";
import MetricDefinition from "src/components/analytics/metric-definition";
import { IconBox } from "src/components/explore/filter-popover/icon-box";
import { PageHeader } from "src/components/layout";
import { PageSidebar, SidebarKey } from "src/components/layout/page-sidebar";
import { PageAlert, PageAlertProps } from "src/components/page-alert";
import { useTimestampFilter } from "src/components/performance/use-timestamp-filter";
import { TimeOptions, timeOptions } from "src/components/performance/utils";
import {
  AudiencesAndGoalsForParentModelQuery,
  useAudiencesAndGoalsForParentModelQuery,
  useGoalMetricsQuery,
  useParentModelsForAnalyticsQuery,
} from "src/graphql";
import { PageSpinner } from "src/ui/loading";

const pageAlert: Record<string, PageAlertProps> = {
  parentModel: {
    title: "First, you need to configure a parent model",
    description:
      "This workspace doesn’t contain any parent models. Before you can view analytics, you’ll need to create a parent model and an audience.",
    link: (
      <Link href="/schema-v2">
        Go to schema setup <ArrowRightIcon />
      </Link>
    ),
    bg: "teal.100",
  },
  audience: {
    title: "First, you need to create an audience",
    description:
      "No audiences have been built from the selected parent model. Before you can view analytics, you’ll need to create an audience.",
    link: (
      <Link href="/audiences">
        Go to audiences <ArrowRightIcon />
      </Link>
    ),
    bg: "#F8FBFC",
  },
  metric: {
    title: "First, you need to define a metric",
    description:
      "The selected parent model doesn’t contain any metrics. Before you can view analytics, you’ll need to define a metric.",
    link: (
      <Link href="/schema-v2/settings/metrics">
        Go to metric setup <ArrowRightIcon />
      </Link>
    ),
    bg: "#F8FBFC",
  },
};

const BlueAudienceIcon: FC = () => <IconBox bg="ocean.400" boxSize="20px" icon={<AudienceIcon />} iconSize="14px" />;

const GreenMetricIcon: FC = () => <IconBox bg="forest.400" boxSize="20px" icon={<MetricIcon />} iconSize="14px" />;

const placeholderAudience = undefined; // We use undefined to display a Select input with no value

const placeholderContentWidthPx = "576px";

export const Analytics: FC = () => {
  const { schemaV2 } = useFlags();

  const [selectedParentModelId, setSelectedParentModelId] = useState<number>();
  const [selectedMetric, setSelectedMetric] = useState<AudiencesAndGoalsForParentModelQuery["goals"][0]>();
  const [selectedAudiences, setSelectedAudiences] = useState<({ id: number; name: string } | undefined)[]>([undefined]);
  const [normalizeAudiences, setNormalizeAudiences] = useState<boolean>(false);

  const definedAudiences = selectedAudiences.filter((audience) => audience !== undefined) as Array<{
    id: number;
    name: string;
  }>;

  // Clear out metric and audiences when parent model changes
  useEffect(() => {
    setSelectedMetric(undefined);
    setSelectedAudiences([placeholderAudience]);
  }, [selectedParentModelId]);

  useEffect(() => {
    if (!selectedMetric?.audiences.length) {
      return;
    }

    // When the selected metric changes, filter out any selected audiences that don't have the associated metric enabled
    setSelectedAudiences((audiences) => {
      const filtered = audiences.filter((audience) => {
        if (audience === undefined) return true;
        return selectedMetric?.audiences.find(({ segment }) => segment.id === audience.id)?.enabled;
      });
      // If all of the selected audiences get filtered out, show a placeholder audience
      return filtered.length ? filtered : [placeholderAudience];
    });
  }, [selectedMetric?.audiences]);

  const { data: parentModelsData, isLoading: parentModelsLoading } = useParentModelsForAnalyticsQuery();

  const parentModelOptions = (parentModelsData?.segments ?? []).map((model) => ({
    label: model.name,
    value: model.id,
    icon: model.connection?.definition.icon ?? "",
  }));

  // Auto-populate parent model select
  useEffect(() => {
    if (!selectedParentModelId && parentModelsData?.segments?.[0]) {
      setSelectedParentModelId(parentModelsData.segments[0].id);
    }
  }, [selectedParentModelId, parentModelsData?.segments]);

  const { data: audiencesAndGoals, isLoading: audiencesAndGoalsLoading } = useAudiencesAndGoalsForParentModelQuery(
    { id: String(selectedParentModelId) },
    { enabled: Boolean(selectedParentModelId) },
  );
  const audiences = audiencesAndGoals?.segments ?? [];
  const goals = audiencesAndGoals?.goals ?? [];

  const { before, since, timeValue, setTimeValue } = useTimestampFilter();

  const { data: goalMetricsData, isLoading: goalMetricsLoading } = useGoalMetricsQuery(
    {
      filter: {
        id: { _eq: selectedMetric?.id },
      },
      audienceIds: definedAudiences.map(({ id }) => String(id)),
      since,
      before,
    },
    {
      enabled: Boolean(selectedMetric) && Boolean(definedAudiences.length),
      keepPreviousData: true,
    },
  );

  const graph = transformMetricDataForGraph({
    metrics: goalMetricsData,
    normalized: normalizeAudiences,
  });

  const missingParentModels = !parentModelsLoading && parentModelOptions.length === 0;

  const missingGoals = selectedParentModelId ? !audiencesAndGoalsLoading && goals.length === 0 : true;

  const missingAudiences = selectedParentModelId ? !audiencesAndGoalsLoading && audiences.length === 0 : false;

  const header = (
    <Row
      align="center"
      gap={6}
      px={6}
      height={16}
      boxShadow="0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06)"
    >
      <Heading size="xl">Analytics</Heading>
      <Select
        isLoading={parentModelsLoading}
        isDisabled={parentModelsLoading}
        optionAccessory={(option) => ({
          type: "image",
          url: option.icon,
        })}
        placeholder="Select parent model..."
        onChange={setSelectedParentModelId}
        options={parentModelOptions}
        value={selectedParentModelId}
      />
    </Row>
  );

  const addAudienceSelect = () => {
    setSelectedAudiences((state) => [...state, undefined]);
  };

  const removeAudience = (indexToRemove: number) => {
    setSelectedAudiences((audienceIds) => audienceIds.filter((_, index) => index !== indexToRemove));
  };

  const audienceOptions = selectedMetric
    ? selectedMetric.audiences.map(({ segment, enabled }) => ({ ...segment, enabled }))
    : audiences.map((audience) => ({ ...audience, enabled: true }));

  const unselectedAudiences = audienceOptions.filter(({ id }) => {
    const found = selectedAudiences.find((audience) => audience?.id === id);
    return !found;
  });

  const showSidebar = selectedParentModelId && !missingAudiences && !missingGoals;

  const analyticsPlaceholderImage = (
    <Box as="img" boxSize={4} alignSelf="center" width="380px" height="200px" src={analyticsPlaceholder} />
  );

  return (
    <>
      <Helmet>
        <title>Analytics</title>
      </Helmet>

      <PageHeader
        outsideTopbar={
          <>
            {missingParentModels && <PageAlert {...pageAlert.parentModel!} />}
            {header}
            {missingAudiences ? (
              <PageAlert {...pageAlert.audience!} />
            ) : missingGoals ? (
              <PageAlert {...pageAlert.metric!} />
            ) : null}
          </>
        }
      />

      {audiencesAndGoalsLoading ? (
        <PageSpinner />
      ) : (
        <Row flex={1} pos="relative">
          {showSidebar && (
            <PageSidebar stateKey={SidebarKey.Analytics} width="336px">
              <Column pl="25px" gap={4} pr={6} pt={2} overflow="scroll" flex={1} minHeight={0}>
                <Column>
                  <Row align="center" justifyContent="space-between" mb={2}>
                    <SectionHeading>Metrics</SectionHeading>
                    <Row>
                      <Link href={schemaV2 ? "/schema-v2/settings/metrics" : "/schema/metrics"}>
                        <Row fontSize="20px">
                          <SettingsIcon mr={1} />
                          <Text color="inherit">Manage metrics</Text>
                        </Row>
                      </Link>
                    </Row>
                  </Row>
                  <Column>
                    <Row
                      bg="white"
                      p={2}
                      borderWidth={0.25}
                      borderStyle="solid"
                      borderColor="base.border"
                      borderRadius="md"
                      borderBottomRadius={selectedMetric ? 0 : "md"}
                      justifyContent="space-between"
                    >
                      <Box width="100%" minWidth={0}>
                        <Combobox
                          options={goals}
                          optionAccessory={() => ({
                            type: "icon",
                            icon: GreenMetricIcon,
                          })}
                          optionLabel={(option) => option.name}
                          optionValue={(option) => option}
                          placeholder="Select a metric..."
                          value={selectedMetric}
                          variant="heavy"
                          onChange={setSelectedMetric}
                        />
                      </Box>
                      {selectedMetric && (
                        <Row alignItems="center" pl={2}>
                          <Link
                            href={
                              schemaV2
                                ? `/schema-v2/settings/metrics/${selectedMetric.id}`
                                : `/settings/metrics/${selectedMetric.id}`
                            }
                            isExternal
                          >
                            <Tooltip message="View metric">
                              <IconButton
                                icon={ExternalLinkIcon}
                                color="text.secondary"
                                aria-label="Link to selected metric"
                                onClick={() => {}}
                              />
                            </Tooltip>
                          </Link>
                        </Row>
                      )}
                    </Row>
                    {selectedMetric && (
                      <Column border="1px solid" borderLeft="4px solid" borderColor="base.border" borderBottomRadius="md">
                        <Row borderBottom="1px solid" borderColor="base.border">
                          {selectedMetric?.config && (
                            <MetricDefinition
                              condition={selectedMetric.config}
                              attributionWindow={selectedMetric.attribution_window}
                            />
                          )}
                        </Row>
                      </Column>
                    )}
                  </Column>
                </Column>

                <Column>
                  <Row align="center" justifyContent="space-between" mb={2}>
                    <SectionHeading>Audiences</SectionHeading>
                    {unselectedAudiences.length > 0 && (
                      <Tooltip message="Add audience">
                        <IconButton
                          icon={PlusIcon}
                          aria-label="Add audience input"
                          color="text.secondary"
                          onClick={addAudienceSelect}
                        />
                      </Tooltip>
                    )}
                  </Row>
                  <Column gap={2}>
                    {selectedAudiences.map((audience, index) => (
                      <Row
                        key={`${audience?.id}-${index}`}
                        bg="white"
                        p={2}
                        border="1px solid #dbe1e8"
                        borderRadius="md"
                        justifyContent="space-between"
                      >
                        <Box width="100%" minWidth={0}>
                          <Combobox
                            options={
                              audience
                                ? [{ id: audience.id, name: audience.name, enabled: true }].concat(unselectedAudiences)
                                : unselectedAudiences
                            }
                            optionAccessory={(option) => ({
                              type: "icon",
                              icon: option.enabled ? BlueAudienceIcon : ErrorIcon,
                            })}
                            isOptionDisabled={(option) => !option.enabled}
                            optionLabel={(option) => option.name}
                            optionValue={(option) => option}
                            placeholder="Select an audience..."
                            value={audience}
                            variant="heavy"
                            width="auto"
                            onChange={(newValue) =>
                              setSelectedAudiences((values) => values.map((value, i) => (i === index ? newValue : value)))
                            }
                          />
                        </Box>
                        {audience && (
                          <Row alignItems="center" pl={2} width="fit-content">
                            <Link href={`/audiences/${audience.id}`} isExternal>
                              <Tooltip message="View audience">
                                <IconButton
                                  icon={ExternalLinkIcon}
                                  aria-label="Link to selected audience"
                                  variant="tertiary"
                                  onClick={() => {}}
                                />
                              </Tooltip>
                            </Link>
                            {selectedAudiences.length > 1 && (
                              <Tooltip message="Remove">
                                <IconButton
                                  icon={CloseIcon}
                                  aria-label="Remove audience selection"
                                  variant="tertiary"
                                  onClick={() => removeAudience(index)}
                                />
                              </Tooltip>
                            )}
                          </Row>
                        )}
                      </Row>
                    ))}
                  </Column>
                </Column>
              </Column>
            </PageSidebar>
          )}
          <Column height="100%" width="100%">
            {selectedMetric && definedAudiences.length ? (
              <>
                <Row justifyContent="space-between" mt={4} pl="30px" pr="50px" gap={3} width="100%">
                  <Select
                    isLoading={goalMetricsLoading}
                    options={timeOptions}
                    width="3xs"
                    value={timeValue}
                    onChange={(value) => setTimeValue(value ?? TimeOptions.OneWeek)}
                  />
                  <Row gap={2} alignItems="center">
                    <Switch isChecked={normalizeAudiences} size="sm" onChange={setNormalizeAudiences} />
                    <Text>Normalize segments</Text>
                    <Tooltip message="Divides each segment’s performance by the segment’s size. Segments include audiences and each audience's split groups. This allows for accurate comparison between different sized segments.">
                      <Box as={InformationIcon} color="text.secondary" />
                    </Tooltip>
                  </Row>
                </Row>
                <CrossAudienceGraph
                  graph={graph}
                  metricName={selectedMetric?.name ?? ""}
                  placeholder={
                    <>
                      <Column justifyContent="center" alignItems="center" height="100%">
                        <Column textAlign="center" width={placeholderContentWidthPx}>
                          {analyticsPlaceholderImage}
                        </Column>
                        <SectionHeading>No data</SectionHeading>
                        <Paragraph>Please adjust the time filter to view the graph</Paragraph>
                      </Column>
                    </>
                  }
                />
              </>
            ) : (
              <Box display="flex" justifyContent="center" alignItems="center" height="100%">
                <Column textAlign="center" width={placeholderContentWidthPx}>
                  {analyticsPlaceholderImage}
                  {missingGoals || missingAudiences ? (
                    <>
                      <SectionHeading mb={2}>Analytics aren't available yet</SectionHeading>
                      <Paragraph>
                        This page allows you to create visualizations of audiences across user-defined metrics such as “Total
                        revenue”, “Count of purchase events”, or “Average lifetime value”. You can compare different audiences,
                        analyze a single audience, or compare split groups within an audience.
                        <br />
                        Check out our{" "}
                        <Link isExternal href={`${import.meta.env.VITE_DOCS_URL}/customer-studio/analytics`}>
                          Analytics documentation
                        </Link>{" "}
                        to learn more.
                      </Paragraph>
                    </>
                  ) : !definedAudiences.length || !selectedMetric ? (
                    <>
                      <SectionHeading mb={2}>Select a metric and an audience to get started</SectionHeading>
                      <Paragraph>
                        A chart is comprised of a metric and one or more audiences.
                        <br />
                        Check out our{" "}
                        <Link isExternal href={`${import.meta.env.VITE_DOCS_URL}/customer-studio/analytics`}>
                          Analytics documentation
                        </Link>{" "}
                        to learn more.
                      </Paragraph>
                    </>
                  ) : (
                    <>
                      <SectionHeading mb={2}>Analytics aren't available yet</SectionHeading>
                      <Paragraph>
                        It can take up to 24 hours before your metrics are calculated. In case enough time has passed, please
                        adjust the time filter. You can also check if there are metric calculation errors for the selected
                        audiences by going to the individual audience pages and navigating to the Performance tab.
                        <br />
                        Check out our{" "}
                        <Link isExternal href={`${import.meta.env.VITE_DOCS_URL}/customer-studio/analytics`}>
                          Analytics documentation
                        </Link>{" "}
                        to learn more.
                      </Paragraph>
                    </>
                  )}
                </Column>
              </Box>
            )}
          </Column>
        </Row>
      )}
    </>
  );
};
