import { FC } from "react";

import { ButtonGroup, Row } from "@hightouchio/ui";

export const BottomBar: FC<Readonly<{ children }>> = ({ children }) => {
  return (
    <Row
      borderTop="1px"
      borderColor="base.border"
      width="100%"
      p={6}
      flexShrink={0}
      bg="white"
      pos="sticky"
      bottom={0}
      justify="space-between"
    >
      <ButtonGroup>{children}</ButtonGroup>
    </Row>
  );
};
