import { FC } from "react";

import { ConfirmationDialog, Paragraph, useToast } from "@hightouchio/ui";
import { captureException } from "@sentry/react";
import { capitalize } from "lodash";

type Props = {
  isOpen: boolean;
  onClose: () => void;
  onDelete: () => Promise<unknown>;
  label?: string;
  text?: string;
};

export const DeleteConfirmationModal: FC<Readonly<Props>> = ({ isOpen, onClose, label, onDelete, text }) => {
  const { toast } = useToast();

  const handleDelete = async () => {
    try {
      await onDelete();
      toast({
        id: "single-delete-toast",
        title: `${capitalize(label)} was deleted`,
        variant: "success",
      });
    } catch (error) {
      captureException(error);
      toast({
        id: "single-delete-toast",
        title: `Failed to delete ${label}`,
        variant: "error",
      });
    }
  };

  return (
    <ConfirmationDialog
      confirmButtonText="Delete"
      isOpen={isOpen}
      title={`Delete ${label}`}
      variant="danger"
      onClose={onClose}
      onConfirm={handleDelete}
    >
      {text ? (
        <Paragraph>{text}</Paragraph>
      ) : (
        <Paragraph>Are you sure you want to delete this {label}? You won't be able to undo this.</Paragraph>
      )}
    </ConfirmationDialog>
  );
};
