import { FC, useEffect, useMemo, useState } from "react";

import { orderBy } from "lodash";
import { useParams } from "react-router-dom";
import { Grid, Text, ThemeUIStyleObject } from "theme-ui";
import { isPresent } from "ts-extras";
import { useClipboard } from "use-clipboard-copy";

import { Page } from "src/components/layout";
import {
  useAttemptedRowsByBatchIdQuery,
  useAttemptedRowsByPrimaryKeyQuery,
  useRequestInfoSetQuery,
  useSyncAttemptQuery,
  useSyncQuery,
} from "src/graphql";
import { Badge } from "src/ui/badge";
import { Column, Row } from "src/ui/box";
import { Button } from "src/ui/button";
import { Editor } from "src/ui/editor";
import { ArrowDownIcon, CheckIcon, CopyIcon, MaximizeIcon, MinimizeIcon } from "src/ui/icons";
import { PageSpinner } from "src/ui/loading/loading";
import { Markdown } from "src/ui/markdown";
import { Modal } from "src/ui/modal";
import { InfoModal } from "src/ui/modal/info-modal";
import { SimplePagination } from "src/ui/table";
import { Placeholder } from "src/ui/table/placeholder";
import { Table, TableColumn } from "src/ui/table/table";
import { downloadJson, downloadText } from "src/utils/download";
import { DEPRECATED_ERROR, processRequestInfo } from "src/utils/syncs";

import { getOpTypeBadge } from "./run/rows";

export const RunDebug: FC = () => {
  const { run_id: runId, sync_id: syncId, row_id: rowId } = useParams<{ run_id: string; sync_id: string; row_id: string }>();

  const [selectedRequestIndex, setSelectedRequestIndex] = useState<number>(0);
  const [runError, setRunError] = useState<string | undefined | null>();
  const [page, setPage] = useState<number>(0);
  const [pageKeys, setPageKeys] = useState<string[]>([]);
  const [nextLoading, setNextLoading] = useState<boolean>(false);
  const [previousLoading, setPreviousLoading] = useState<boolean>(false);

  const { data: attemptData, isLoading: attemptLoading } = useSyncAttemptQuery({
    syncRequestId: runId ?? "",
  });

  const attempt = attemptData?.sync_attempts?.[0];
  const run = attempt?.sync_request;
  const primaryKey = run?.sync?.segment?.primary_key;

  const { data: selectedRowData, isLoading: selectedRowLoading } = useAttemptedRowsByPrimaryKeyQuery(
    {
      alreadyHashed: true,
      destinationInstanceId: Number(syncId),
      id: rowId ?? "",
      onlyRejected: false,
      onlySuccessful: false,
      plannerType: run?.planner_type ?? "", // default to diffing type sync flow
      syncRequestId: Number(runId),
    },
    { enabled: Boolean(rowId) },
  );

  const selectedRow = selectedRowData?.getAttemptedRowsByPrimaryKey?.rows?.[0];

  const { data: batchData, isFetching: loading } = useAttemptedRowsByBatchIdQuery(
    {
      destinationInstanceId: Number(syncId),
      syncRequestId: Number(runId),
      id: selectedRow?.batchId ?? "",
      pageKey: pageKeys.slice(-1)[0],
    },
    { enabled: Boolean(selectedRow) && run?.planner_type !== "all", keepPreviousData: true },
  );

  const batch = run?.planner_type === "all" ? [] : batchData?.getAttemptedRowsByBatchId?.rows;

  const requestInfoKeys = useMemo<string[] | undefined>(() => {
    return selectedRow?.requestInfoKeys?.filter(isPresent);
  }, [selectedRow]);

  const { data: requestInfoSetData, isLoading: requestInfoSetLoading } = useRequestInfoSetQuery(
    {
      requestInfoKeys: requestInfoKeys ?? [],
      plannerType: run?.planner_type ?? "", // default to diffing type sync flow
    },
    { enabled: Boolean(requestInfoKeys?.length) },
  );

  const definition = run?.sync?.destination?.definition;

  const requestsData = requestInfoSetData?.getRequestInfoSet;
  const requests = useMemo(
    () =>
      requestsData
        ?.map((request) => processRequestInfo(request, definition))
        ?.map((request, index) => ({ ...request, id: index }))
        ?.filter((request) => request.method !== "Contact.bulkload"),
    [requestsData],
  );

  const rows = useMemo(() => {
    if (selectedRow && batch) {
      return [selectedRow, ...batch.filter(isPresent).filter(({ id }) => id !== rowId)].map(
        ({ id, opType, rejectionReason, fields, batchId, isBatchError }) => ({
          hightouchRowId: id,
          opType,
          rejectionReason,
          batchId,
          isBatchError,
          fields,
          ...JSON.parse(fields),
        }),
      );
    }

    return undefined;
  }, [selectedRow, batch]);

  const selectedRequest = requests?.[selectedRequestIndex];

  const relatedRowColumns: TableColumn[] = useMemo(() => {
    let columns: TableColumn[] = [];

    try {
      columns = orderBy(
        Object.keys(JSON.parse(selectedRow?.fields ?? "{}")).map((key, i) => ({
          key,
          name: key,
          cell: (value) => (
            <Text sx={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap", maxWidth: "600px" }}>
              {typeof value === "object" ? JSON.stringify(value) : String(value)}
            </Text>
          ),
          divider: i === 0,
        })),
        (column) => column.key !== primaryKey,
      );
    } catch (error) {
      // Fields not valid JSON?
    }

    return columns;
  }, [selectedRow]);

  useEffect(() => {
    if (nextLoading) {
      setPage((page) => page + 1);
      setNextLoading(false);
    }
    if (previousLoading) {
      setPage((page) => page - 1);
      setPreviousLoading(false);
    }
  }, [batch, setPage, setPreviousLoading, setNextLoading]);

  const minimalSyncQuery = useSyncQuery({
    id: syncId!,
    includeExternalFields: false,
  });

  const sync = minimalSyncQuery.data?.syncs?.[0];
  const syncTitle = sync
    ? `${sync.segment?.name ?? "Private model"} to ${sync.destination?.name ?? "private destination"}`
    : "Sync";

  if (requestInfoSetLoading || attemptLoading || selectedRowLoading) {
    return <PageSpinner />;
  }

  if (!requests || requests?.length === 0) {
    return (
      <Page
        crumbs={[
          { label: "Syncs", link: "/syncs" },
          { label: "Sync", link: `/syncs/${syncId}` },
          {
            label: "Run",
            link: `/syncs/${syncId}/runs/${runId}`,
          },
          {
            label: "Run debugger",
          },
        ]}
        title={`Debugger - Run - ${syncTitle} - Syncs`}
      >
        <Placeholder
          content={{
            title: "No requests were found",
            body: (
              <Markdown useHightouchUi>
                {`Hightouch can't find any stored requests for this row. Request details aren't stored for syncs where the [sync mode](https://hightouch.com/docs/syncs/types-and-modes#sync-modes) is **Replace all** and all rows are replaced at every sync without any [change data capture](https://hightouch.com/docs/getting-started/concepts#change-data-capture). If you have any questions please [reach out](mailto:friends@hightouch.com).`}
              </Markdown>
            ),
          }}
          error={false}
        />
      </Page>
    );
  }

  return (
    <>
      <Page
        crumbs={[
          { label: "Syncs", link: "/syncs" },
          { label: "Sync", link: `/syncs/${syncId}` },
          {
            label: "Run",
            link: `/syncs/${syncId}/runs/${runId}`,
          },
          {
            label: "Debugger",
          },
        ]}
        title={`Debugger - Run - ${syncTitle} - Syncs`}
      >
        {attempt?.error && ![DEPRECATED_ERROR, "Error: " + DEPRECATED_ERROR].includes(attempt?.error) && (
          <Button sx={{ mb: 4 }} variant="secondary" onClick={() => setRunError(attempt?.error)}>
            View error
          </Button>
        )}
        <Grid columns={2} gap={8} sx={{ gridAutoRows: "1fr", width: "100%" }}>
          <Requests requests={requests} selection={selectedRequestIndex} onSelect={setSelectedRequestIndex} />

          <Data
            body={selectedRequest?.requestBody}
            destinationName={definition?.name}
            isJSON={selectedRequest?.requestIsJson}
            isXML={selectedRequest?.requestIsXml}
            sx={{ gridColumn: 2 }}
            timestamp={selectedRequest?.meta?.invokedTimestamp}
            title="Request"
          />
          <Data
            body={selectedRequest?.responseBody}
            destinationName={definition?.name}
            isJSON={selectedRequest?.responseIsJson}
            isXML={selectedRequest?.responseIsXml}
            sx={{ gridColumn: 2 }}
            timestamp={selectedRequest?.meta?.finishedTimestamp}
            title="Response"
          />
        </Grid>

        <Column sx={{ width: "100%", mt: 4 }}>
          <Row sx={{ mb: 4 }}>
            <Text sx={{ fontSize: 2, fontWeight: "semi", mr: 4 }}>
              {run?.planner_type === "all" ? "Selected row" : "Batch rows"}
            </Text>
            {selectedRow?.opType && getOpTypeBadge(selectedRow?.opType)}
          </Row>
          <Table
            scrollable
            columns={relatedRowColumns}
            data={rows}
            highlight={rowId}
            loading={loading}
            placeholder={{
              title: `No related rows`,
            }}
            primaryKey="hightouchRowId"
          />
          <SimplePagination
            nextLoading={nextLoading}
            page={page}
            previousLoading={previousLoading}
            onNext={() => {
              if (batchData && batchData?.getAttemptedRowsByBatchId?.nextPageKey == null) {
                // We've made a request and the server said there are no more rows.
                return;
              }
              setNextLoading(true);
              setPageKeys((pageKeys) => [...pageKeys, batchData?.getAttemptedRowsByBatchId?.nextPageKey].filter(isPresent));
            }}
            onPrevious={() => {
              setPreviousLoading(true);
              setPageKeys((pageKeys) => {
                if (page === 1) {
                  return [];
                } else {
                  return pageKeys.slice(0, -1);
                }
              });
            }}
          />
        </Column>
      </Page>

      <InfoModal isOpen={Boolean(runError)} title="Error" onClose={() => setRunError("")}>
        <Text sx={{ maxWidth: "800px" }}>{runError}</Text>
      </InfoModal>
    </>
  );
};

const requestsColumns = [
  {
    key: "status",
    name: "Status",
    cell: (status) => <Badge variant={status.match(/E[Rr][Rr]/) ? "red" : "green"}>{status}</Badge>,
  },
  {
    name: "Method",
    key: "method",
    cell: (method) => <Text sx={{ fontFamily: "monospace" }}>{method}</Text>,
  },
  {
    name: "Destination",
    key: "destination",
    cell: (destination) => <Text sx={{ fontFamily: "monospace" }}>{destination}</Text>,
  },
];

const Requests = ({ requests, selection, onSelect }) => {
  return (
    <Column sx={{ gridRow: "1 / span 2", height: "60vh" }}>
      <Text sx={{ fontSize: 3, fontWeight: "semi", width: "100%", pb: 4 }}>Requests</Text>
      <Table
        scrollable
        columns={requestsColumns}
        data={requests}
        highlight={selection}
        onRowClick={(row: any) => onSelect(row.id)}
      />
    </Column>
  );
};

const Data: FC<
  Readonly<{
    title: string;
    body?: string;
    timestamp?: string;
    destinationName?: string;
    isJSON?: boolean;
    isXML?: boolean;
    sx?: ThemeUIStyleObject;
  }>
> = ({ title, body, timestamp, destinationName, isJSON, isXML, sx }) => {
  const clipboard = useClipboard({
    copiedTimeout: 600,
  });
  const [fullscreen, setFullscreen] = useState<boolean>(false);

  // Treating any request body over 100 KB as too large to view in the window
  const isFileTooLarge = (body?.length ?? 0) > 100000;

  const copyBody = () => {
    clipboard.copy(body);
  };

  const downloadBody = () => {
    const fileName = `${title}-${timestamp}`;
    isJSON ? downloadJson(JSON.parse(body ?? ""), `${fileName}.json`) : downloadText(body ?? "", `${fileName}.txt`);
  };

  return (
    <>
      <Column sx={sx}>
        <Row
          sx={{
            pb: 2,
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Row sx={{ alignItems: "center", mr: 2 }}>
            <Text
              sx={{
                fontWeight: "semi",
                fontSize: 2,
              }}
            >
              {title}
            </Text>
            {timestamp && <Text sx={{ display: "inline", color: "base.5", fontSize: 0, ml: 2 }}>({timestamp})</Text>}
          </Row>

          {body && (
            <Row gap={2}>
              {isFileTooLarge && (
                <Button
                  tooltip="Request body is too large to copy or view in full mode. Download to view the content."
                  variant="icon"
                  onClick={downloadBody}
                >
                  <ArrowDownIcon size={12} />
                </Button>
              )}
              {!isFileTooLarge && (
                <>
                  <Button variant="icon" onClick={copyBody}>
                    {clipboard.copied ? <CheckIcon color="green" size={12} /> : <CopyIcon size={12} />}
                  </Button>
                  {fullscreen ? (
                    <Button variant="icon" onClick={() => setFullscreen(false)}>
                      <MinimizeIcon size={12} />
                    </Button>
                  ) : (
                    <Button variant="icon" onClick={() => setFullscreen(true)}>
                      <MaximizeIcon size={12} />
                    </Button>
                  )}
                </>
              )}
            </Row>
          )}
        </Row>
        <Column sx={{ border: body ? undefined : "small", flex: 1 }}>
          {body ? (
            <Editor
              code={body}
              language={isJSON ? "json" : isXML ? "xml" : "text"}
              minLines={10}
              sx={{
                width: "100%",
                height: "100%",
              }}
            />
          ) : (
            <Text sx={{ p: 4 }}>
              No {title.toLowerCase()}
              {destinationName ? ` from ${destinationName}` : ""}
            </Text>
          )}
        </Column>
      </Column>
      <Modal
        bodySx={{ p: 0 }}
        footer={
          <>
            <Button variant="secondary" onClick={copyBody}>
              {clipboard?.copied ? "Copied" : "Copy"}
            </Button>
            <Button variant="secondary" onClick={() => setFullscreen(false)}>
              Close
            </Button>
          </>
        }
        isOpen={fullscreen}
        sx={{ width: "100%", height: "100%" }}
        title={title}
        onClose={() => setFullscreen(false)}
      >
        <Column sx={{ width: "100%", height: "100%" }}>
          <Editor
            code={body ?? ""}
            language={isJSON ? "json" : isXML ? "xml" : "text"}
            sx={{
              flex: 1,
              height: "100%",
              width: "100%",
            }}
          />
        </Column>
      </Modal>
    </>
  );
};
