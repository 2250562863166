import { FC } from "react";

import { Navigate, Route, Routes } from "react-router-dom";

import { EventDestinationsRouter } from "./destinations";
import { EventSourcesRouter } from "./sources";
import { EventWarehouseSyncsRouter } from "./syncs";

export const EventsRouter: FC = () => {
  return (
    <Routes>
      <Route path="/sources/*" element={<EventSourcesRouter />} />
      <Route path="/destinations/*" element={<EventDestinationsRouter />} />
      <Route path="/syncs/*" element={<EventWarehouseSyncsRouter />} />
      <Route index element={<Navigate to={{ pathname: "syncs", search: location.search }} replace />} />
    </Routes>
  );
};
