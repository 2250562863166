import { useEffect, useState } from "react";

import { Row, Column, Heading, useToast, Textarea, FormField, TextInput, SectionHeading } from "@hightouchio/ui";
import { yupResolver } from "@hookform/resolvers/yup";
import { captureException } from "@sentry/react";
import { Controller, FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";

import { SidebarForm } from "src/components/page";
import { SourceSelect } from "src/components/sources/source-select";
import { ConnectionsBoolExp, useCreateIdentityResolutionGraphMutation } from "src/graphql";
import { QueryableSource } from "src/types/models";
import { Step, Wizard } from "src/ui/wizard/wizard";
import { useWizardStepper } from "src/utils/use-wizard-stepper";

type FormState = {
  name: string;
  description: string;
  output_table: string;
};

export const CreateIdentityResolutionGraph = () => {
  const { toast } = useToast();
  const [source, setSource] = useState<QueryableSource | undefined | null>();
  const [step, setStep] = useWizardStepper(0);
  const navigate = useNavigate();

  const form = useForm<FormState>({
    defaultValues: {
      name: "",
      description: "",
      output_table: "",
    },
    resolver: yupResolver(validationSchema),
  });

  const createMutation = useCreateIdentityResolutionGraphMutation();

  const submit: SubmitHandler<FormState> = async (data) => {
    try {
      const response = await createMutation.mutateAsync({
        input: { ...data, connection_id: source?.id, state: "enabled" },
      });

      toast({
        id: "create-idr",
        title: `Identity graph "${data.name}" was created`,
        variant: "success",
      });

      navigate(`/idr/${response.insert_idr_one?.id}`);
    } catch (error) {
      toast({
        id: "create-idr-error",
        title: `There was a problem creating your identity graph.`,
        variant: "error",
      });
      captureException(error);
    }
  };

  const steps: Step[] = [
    {
      title: "Select source",
      continue: "Click on a source to continue",
      header: <Heading>Select a data source</Heading>,
      render: () => (
        <SourceSelect
          onSelect={(source) => {
            setSource(source);
            setStep(1);
          }}
          filter={sourceFilter}
        />
      ),
    },
    {
      title: "Finalize",
      header: <Heading>Finalize identity graph</Heading>,
      render: () => {
        return (
          <Row gap={10} justify="space-between">
            <Column gap={6}>
              <Controller
                name="name"
                render={({ field, fieldState: { error } }) => (
                  <FormField label="Identity graph name">
                    <TextInput {...field} isInvalid={Boolean(error)} placeholder="Enter a name..." />
                  </FormField>
                )}
              />
              <Controller
                name="description"
                render={({ field, fieldState: { error } }) => (
                  <FormField isOptional label="Description" tip="Include details about the business purpose.">
                    <Textarea {...field} isInvalid={Boolean(error)} placeholder="Enter a description..." />
                  </FormField>
                )}
              />
              <SectionHeading>Where should Hightouch send the output of resolved identities?</SectionHeading>
              <Controller
                name="output_table"
                render={({ field, fieldState: { error } }) => (
                  <FormField
                    label="Output table prefix"
                    tip={`An identity graph run will output two tables: ${field.value || "prefix"}_star and ${
                      field.value || "prefix"
                    }_flat in the hightouch_planner schema.`}
                  >
                    <TextInput {...field} isInvalid={Boolean(error)} placeholder="Enter a prefix..." />
                  </FormField>
                )}
              />
            </Column>
            <SidebarForm hideInviteTeammate name="identity resolution" docsUrl="" />
          </Row>
        );
      },
    },
  ];

  useEffect(() => {
    if (step === 0) {
      setSource(undefined);
    }
  }, [step]);

  useEffect(() => {
    form.reset();
  }, [source]);

  return (
    <FormProvider {...form}>
      <Wizard
        steps={steps}
        step={step}
        setStep={setStep}
        title="Create identity graph"
        onCancel={() => {
          navigate("/idr");
        }}
        onSubmit={() => form.handleSubmit(submit)()}
      />
    </FormProvider>
  );
};

const sourceFilter: ConnectionsBoolExp = {
  _and: [{ plan_in_warehouse: { _eq: true } }, { type: { _in: ["snowflake", "bigquery"] } }],
};

const validationSchema = yup.object().shape({
  name: yup.string().required(),
  description: yup.string(),
  output_table: yup.string().required(),
});
