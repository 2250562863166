import { FC, useEffect } from "react";

import * as Sentry from "@sentry/react";
import { useFlags } from "launchdarkly-react-client-sdk";

import { CommandBar } from "src/components/commandbar";
import { Maintenance } from "src/components/maintenance";
import { Router, ForceReload } from "src/components/router";
import { HeaderHeightProvider } from "src/contexts/header-height-context";
import { useUser } from "src/contexts/user-context";
import * as analytics from "src/lib/analytics";
import { Intercom } from "src/lib/intercom";
import { Logrocket } from "src/lib/logrocket";

import { useWorkspaceActions } from "./commands";
import { LockOutModal } from "./lock-out-modal";

export const App: FC = () => {
  const { user, workspace } = useUser();
  const { appMaintenance } = useFlags();

  useEffect(() => {
    if (user && !user.not_member_of_current_workspace) {
      analytics.identify(String(user.id), {
        email: user.email,
        name: user.name,
      });

      if (user.current_workspace_id) {
        analytics.group(user.current_workspace_id);
      }
    }

    Sentry.setTag("user_id", String(user?.id));

    if (workspace) {
      Sentry.setTag("workspace_id", String(workspace?.id));
      Sentry.setTag("workspace_region", String(workspace?.region));
    }
  }, [user, workspace]);

  useEffect(() => {
    analytics.track("Application Opened", {
      window_height: window.innerHeight,
      window_width: window.innerWidth,
    });
  }, []);

  if (appMaintenance) {
    return <Maintenance />;
  }

  return (
    <HeaderHeightProvider>
      <Router>
        <ForceReload />
        <CommandBar>
          <Commands />
          <Intercom />
          <Logrocket />

          <div id="portalAnchor"></div>
          <LockOutModal />
        </CommandBar>
      </Router>
    </HeaderHeightProvider>
  );
};

const Commands: FC = () => {
  useWorkspaceActions();

  return null;
};
