import { FC, useMemo } from "react";

import { Row, Text } from "@hightouchio/ui";
import { Helmet } from "react-helmet";
import { Outlet, useNavigate } from "react-router-dom";

import { TRAIT_TYPE_LABELS } from "src/components/traits/utils";
import { OrderBy, TraitDefinitionsOrderBy, useTraitsQuery } from "src/graphql";
import { Pagination, SortOption, useTableConfig, useTableSort } from "src/ui/table";
import { Table, TableColumn } from "src/ui/table/table";

const initialSort: SortOption<keyof TraitDefinitionsOrderBy> = {
  key: "updated_at",
  direction: OrderBy.Desc,
  label: "Recently updated",
};

const sortOptions: SortOption<keyof TraitDefinitionsOrderBy>[] = [
  { key: "name", direction: OrderBy.Asc, label: "Name A -> Z" },
  { key: "name", direction: OrderBy.Desc, label: "Name Z -> A" },
  { key: "created_at", direction: OrderBy.Desc, label: "Newest" },
  { key: "created_at", direction: OrderBy.Asc, label: "Oldest" },
  { key: "updated_at", direction: OrderBy.Desc, label: "Recently updated" },
];

export enum TraitsTableType {
  Active = "active",
  Templates = "templates",
  Archived = "archived",
}

type Props = {
  type: TraitsTableType;
};

export const TraitsTable: FC<Readonly<Props>> = ({ type }) => {
  const navigate = useNavigate();

  const columns: TableColumn[] = useMemo(() => {
    switch (type) {
      case TraitsTableType.Active:
        return activeColumns;
      case TraitsTableType.Templates:
        return templateColumns;
      case TraitsTableType.Archived:
        return archivedColumns;
      default:
        return [];
    }
  }, [type]);

  const filter = useMemo(() => {
    switch (type) {
      case TraitsTableType.Active:
        return { is_template: { _eq: false }, archived_at: { _is_null: true } };
      case TraitsTableType.Templates:
        return { is_template: { _eq: true }, archived_at: { _is_null: true } };
      case TraitsTableType.Archived:
        return { archived_at: { _is_null: false } };
      default:
        return {};
    }
  }, [type]);

  const { limit, offset, page, setPage } = useTableConfig();
  const orderBy = useTableSort<TraitDefinitionsOrderBy>(initialSort, sortOptions);

  const { data: traits } = useTraitsQuery({
    filter,
    limit,
    offset,
    orderBy,
  });

  return (
    <>
      <Helmet>Traits</Helmet>

      <Table
        columns={columns}
        data={traits?.trait_definitions ?? []}
        sortOptions={sortOptions}
        onRowClick={(row) => {
          navigate(`${row.id}`);
        }}
      />
      <Pagination count={traits?.trait_definitions.length} page={page} rowsPerPage={limit} setPage={setPage} />

      <Outlet />
    </>
  );
};

const sharedColumns: TableColumn[] = [
  {
    name: "Name",
    key: "name",
  },
  {
    name: "Parent model",
    key: "parent_model.name",
  },
  {
    name: "Related model",
    key: "relationship.to_model.name",
  },
  {
    name: "Type",
    cell: ({ type }) => <Text textTransform="capitalize">{TRAIT_TYPE_LABELS[type]}</Text>,
  },
];

const activeColumns: TableColumn[] = [
  ...sharedColumns,
  {
    header: () => (
      <Row width="100%" justifyContent="end">
        Audiences
      </Row>
    ),
    cell: ({ trait_references }) => (
      <Row width="100%" justifyContent="end">
        {trait_references.audiences.length}
      </Row>
    ),
  },
];

const templateColumns: TableColumn[] = [...sharedColumns];

const archivedColumns: TableColumn[] = [...sharedColumns];
