import { useEffect, useState, FC } from "react";

import { Row, Column, Text, Pill } from "@hightouchio/ui";
import { useParams } from "react-router-dom";

import { EditingDraftWarning } from "src/components/drafts/draft-warning";
import { Page } from "src/components/layout";
import { MetadataBar, MetadataLabel } from "src/components/metadata-bar";
import { SyncName } from "src/components/syncs/sync-name";
import { DraftProvider, useDraft } from "src/contexts/draft-context";
import {
  ResourceToPermission,
  SyncOp,
  SyncQuery,
  useAttemptedRowsByPrimaryKeyQuery,
  useAttemptedRowsQuery,
  useSyncAttemptQuery,
  useSyncQuery,
  useTransformedSyncRunConfigurationQuery,
} from "src/graphql";
import { PageSpinner } from "src/ui/loading";
import { Tabs } from "src/ui/tabs";
import { SyncStatusBadge, getSyncAttemptDiff } from "src/utils/syncs";
import * as time from "src/utils/time";

import { Configuration } from "./configuration";
import { Rows } from "./rows";

export enum Tab {
  SUCCESSFUL = "Successful",
  REJECTED = "Rejected",
  CONFIGURATION = "Configuration",
}

const getRejectedAddedRows = (plannerType: string | undefined, syncError: unknown | undefined, syncRequest, diff) => {
  if (plannerType === "all") {
    // If there's an error, we treat all added rows as rejected rows
    return syncError ? syncRequest?.sync_request_diff?.added_count ?? 0 : 0;
  } else {
    return diff?.rejected?.add;
  }
};

export const RunWrapper: FC = () => {
  const { sync_id: id } = useParams<{ sync_id: string }>();
  const { data: syncData, isLoading: syncLoading } = useSyncQuery(
    {
      id: id ?? "",
    },
    { enabled: Boolean(id) },
  );

  if (!id) {
    return <PageSpinner />;
  }

  return (
    <DraftProvider
      initialResourceIsDraft={syncData?.syncs?.[0]?.draft || false}
      resourceId={id}
      resourceType={ResourceToPermission.Sync}
    >
      <Run syncData={syncData} syncLoading={syncLoading} />
    </DraftProvider>
  );
};

interface RunProps {
  syncData: Partial<SyncQuery> | undefined;
  syncLoading: boolean;
}

const Run: FC<RunProps> = ({ syncData, syncLoading }: RunProps) => {
  const { run_id: runId, sync_id: syncId } = useParams<{ run_id: string; sync_id: string }>();
  const { draft, editingDraft, onViewDraft, setEditingDraft } = useDraft();

  const [tab, setTab] = useState<Tab>(Tab.SUCCESSFUL);
  const [page, setPage] = useState<number>(0);
  const [pageKeys, setPageKeys] = useState<string[]>([]);
  const [searchInput, setSearchInput] = useState<string>("");
  const [showAdded, setShowAdded] = useState<boolean>(true);
  const [showChanged, setShowChanged] = useState<boolean>(true);
  const [showRemoved, setShowRemoved] = useState<boolean>(true);
  const [search, setSearch] = useState<string>("");

  const sync = syncData?.syncs?.[0];
  const model = sync?.segment;
  const source = model?.connection;
  const destination = sync?.destination;

  const { data: attemptData, isLoading: syncAttemptLoading } = useSyncAttemptQuery(
    {
      syncRequestId: runId ?? "",
    },
    {
      refetchInterval: (attemptData) => {
        if (attemptData?.sync_attempts?.[0]?.finished_at) {
          // Stop polling if this sync attempt is finished.
          return false;
        }

        return 3000;
      },
    },
  );

  const { data: prevConfig } = useTransformedSyncRunConfigurationQuery(
    { id: syncId ?? "", runId: runId ?? "" },
    { enabled: Boolean(runId && syncId), select: (data) => data.getTransformedSyncRunConfiguration },
  );

  const showSuccessful = tab === Tab.SUCCESSFUL;
  const showRejected = tab === Tab.REJECTED;

  const attempt = attemptData?.sync_attempts?.[0];
  const syncRequest = attempt?.sync_request;
  const syncError = syncRequest?.error ?? (attempt?.error ? { message: attempt.error } : undefined);
  const primaryKey = syncRequest?.sync?.segment?.primary_key;
  const resyncReason = syncRequest?.resync_reason;
  const plannerType = syncRequest?.planner_type;
  const errorCodeDetail = syncRequest?.error_code_detail;

  if (syncError && errorCodeDetail) syncError.errorCodeDetail = errorCodeDetail;

  const diff = getSyncAttemptDiff(attempt);

  const added =
    plannerType === "all" && !syncError
      ? syncRequest?.sync_request_diff?.added_count ?? 0
      : (syncRequest?.add_executed ?? 0) - (diff?.rejected?.add ?? 0);

  const changed = (syncRequest?.change_executed ?? 0) - (diff?.rejected?.change ?? 0);
  const removed = (syncRequest?.remove_executed ?? 0) - (diff?.rejected?.remove ?? 0);
  const successfulRows = added + changed + removed;

  const rejectedRows =
    plannerType === "all" && syncError
      ? syncRequest?.sync_request_diff?.added_count ?? 0
      : (diff?.rejected?.add ?? 0) + (diff?.rejected?.change ?? 0) + (diff?.rejected?.remove ?? 0);

  const addedRows: number | undefined | null = showSuccessful
    ? added
    : getRejectedAddedRows(plannerType?.toString(), syncError, syncRequest, diff);

  const changedRows: number | undefined | null = showSuccessful ? changed : diff?.rejected?.change;
  const removedRows: number | undefined | null = showSuccessful ? removed : diff?.rejected?.remove;

  let totalRows = 0;
  const opTypes: SyncOp[] = [];

  if (showAdded) {
    totalRows += addedRows ?? 0;
    opTypes.push(SyncOp.Added);
  }
  if (showChanged && plannerType !== "all") {
    totalRows += changedRows ?? 0;
    opTypes.push(SyncOp.Changed);
  }
  if (showRemoved && plannerType !== "all") {
    totalRows += removedRows ?? 0;
    opTypes.push(SyncOp.Removed);
  }

  const limit = 10;
  const pages = Math.ceil(totalRows / limit);
  const hasRows = Number(addedRows || 0) + Number(changedRows || 0) + Number(removedRows || 0) > 0;

  let {
    data: attemptedRowsData,
    isLoading: attemptedRowsLoading,
    error: attemptedRowsQueryError,
  } = useAttemptedRowsQuery(
    {
      destinationInstanceId: Number(syncId),
      syncRequestId: Number(runId),
      onlyRejected: showRejected,
      onlySuccessful: showSuccessful,
      pageKey: pageKeys.slice(-1)[0],
      opTypes,
      limit,
      plannerType: String(plannerType),
    },
    {
      keepPreviousData: true,

      // When disabled, this will return previous data.
      enabled: hasRows,
    },
  );
  if (!hasRows) {
    // Don't use previous data if attemptedRowsQuery is disabled.
    attemptedRowsData = {
      getAttemptedRows: {
        rows: [],
        nextPageKey: null,
      },
    };
    attemptedRowsLoading = false;
    attemptedRowsQueryError = null;
  }

  const { data: attemptedRowsByPKData, isLoading: attemptedRowsByPKLoading } = useAttemptedRowsByPrimaryKeyQuery(
    {
      destinationInstanceId: Number(syncId),
      id: search,
      onlyRejected: showRejected,
      onlySuccessful: showSuccessful,
      plannerType: String(plannerType),
      syncRequestId: Number(runId),
    },
    { enabled: Boolean(search) && plannerType !== "all" },
  );

  const resetPagination = () => {
    setPageKeys([]);
    setPage(0);
  };

  useEffect(resetPagination, [tab]);
  useEffect(resetPagination, [showAdded, showChanged, showRemoved]);

  if (syncLoading || syncAttemptLoading) {
    return <PageSpinner />;
  }

  return (
    <Page
      crumbs={[
        { label: "Syncs", link: "/syncs" },
        { label: "Sync", link: editingDraft ? `/syncs/${syncId}?editing=true` : `/syncs/${syncId}` },
        {
          label: "Run",
        },
      ]}
      outsideTopbar={
        draft && (
          <EditingDraftWarning
            draft={draft}
            editingDraft={editingDraft}
            resourceType={ResourceToPermission.Sync}
            setEditingDraft={setEditingDraft}
            onViewDraft={onViewDraft}
          />
        )
      }
      title={`Run - ${model?.name ?? "Private model"} to ${
        destination?.name ?? destination?.definition?.name ?? "private destination"
      } - Syncs`}
    >
      <Row sx={{ justifyContent: "space-between", mb: 5, width: "100%", borderBottom: "small", pb: 2 }}>
        <SyncName destination={destination} model={model} source={source} sync={sync} />
      </Row>

      <MetadataBar>
        <Column>
          <MetadataLabel>Status</MetadataLabel>
          <SyncStatusBadge request={syncRequest} />
        </Column>
        <Column>
          <MetadataLabel>Started at</MetadataLabel>
          <Text textTransform="capitalize">{syncRequest?.created_at && time.formatDatetime(syncRequest.created_at)}</Text>
        </Column>
        {attempt?.finished_at && (
          <Column>
            <MetadataLabel>Duration</MetadataLabel>
            <Text textTransform="capitalize">{time.diff(attempt?.created_at, attempt?.finished_at)}</Text>
          </Column>
        )}
        <Column>
          <MetadataLabel>Run ID</MetadataLabel>
          <Text textTransform="capitalize">{runId}</Text>
        </Column>
        {resyncReason && (
          <Column>
            <MetadataLabel>Resync reason</MetadataLabel>
            <Text textTransform="capitalize">
              {{
                "explicit-request": "Triggered by user",
                "added-fields": "Fields were added",
                "changed-config": "Configuration was changed",
                "changed-mappings": "Mappings changed",
                "changed-source-types": "Source types changed",
                "changed-primary-key": "Model primary key was changed",
                "retry-failed-resync": "Retrying failed resync",
                "archive-mode-resync": "Resyncing for archive mode",
              }[resyncReason] ?? resyncReason}
            </Text>
          </Column>
        )}
      </MetadataBar>

      <Tabs
        setTab={(tab) => setTab(tab as Tab)}
        tab={tab}
        tabs={[
          {
            render: () => (
              <Row align="center" gap={2}>
                <Text>Successful</Text>
                <Pill>{successfulRows}</Pill>
              </Row>
            ),
            value: Tab.SUCCESSFUL,
          },
          {
            render: () => (
              <Row align="center" gap={2}>
                <Text>Rejected</Text>
                <Pill>{rejectedRows}</Pill>
              </Row>
            ),
            value: Tab.REJECTED,
          },
          {
            render: () => (
              <Row align="center" gap={2}>
                <Text>Configuration</Text>
              </Row>
            ),
            value: Tab.CONFIGURATION,
          },
        ]}
      />

      {tab === Tab.CONFIGURATION ? (
        <Configuration
          destination={destination}
          model={model}
          prevConfig={prevConfig}
          prevConfigDate={syncRequest?.created_at}
          sync={sync}
        />
      ) : (
        <Rows
          addedRows={addedRows}
          attemptedRowsByPKData={attemptedRowsByPKData}
          attemptedRowsByPKLoading={attemptedRowsByPKLoading}
          attemptedRowsData={attemptedRowsData}
          attemptedRowsLoading={attemptedRowsLoading}
          attemptedRowsQueryError={attemptedRowsQueryError}
          changedRows={changedRows}
          page={page}
          pages={pages}
          plannerType={plannerType}
          primaryKey={primaryKey}
          removedRows={removedRows}
          search={search}
          searchInput={searchInput}
          setPage={setPage}
          setPageKeys={setPageKeys}
          setSearch={setSearch}
          setSearchInput={setSearchInput}
          setShowAdded={setShowAdded}
          setShowChanged={setShowChanged}
          setShowRemoved={setShowRemoved}
          showAdded={showAdded}
          showChanged={showChanged}
          showRejected={showRejected}
          showRemoved={showRemoved}
          source={source}
          sync={sync}
          syncError={syncError}
          syncRequest={syncRequest}
        />
      )}
    </Page>
  );
};
