import { FC } from "react";

import {
  Box,
  Button,
  ChakraListItem,
  ChakraModal,
  ChakraModalBody,
  ChakraModalContent,
  ChakraModalFooter,
  ChakraModalHeader,
  ChakraModalOverlay,
  ChakraUnorderedList,
  CloseIcon,
  Column,
  Heading,
  IconButton,
  Link,
  LinkButton,
  Paragraph,
  Text,
} from "@hightouchio/ui";
import { Image } from "theme-ui";

import { useUser } from "src/contexts/user-context";
import * as analytics from "src/lib/analytics";
import { newIntercomMessage } from "src/lib/intercom";
import { SIZES } from "src/ui/box/container";

export const DuplicateKeyModal: FC<{ cutOffDate: string; isOpen: boolean; onClose: () => void }> = ({
  cutOffDate,
  isOpen,
  onClose,
}) => {
  const { workspace } = useUser();

  return (
    <ChakraModal isCentered scrollBehavior="inside" isOpen={isOpen} onClose={onClose}>
      <ChakraModalOverlay />

      <ChakraModalContent maxW={SIZES.small} p={0}>
        <ChakraModalHeader
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          width="100%"
          borderBottom="1px solid"
          borderColor="base.border"
          p={6}
        >
          <Heading size="lg">Duplicate records detected in your models</Heading>

          <IconButton
            aria-label="Close modal"
            icon={CloseIcon}
            onClick={() => {
              analytics.track("Duplicate PK Modal View Affected Models Clicked", {
                workspace_id: workspace?.id,
              });
              onClose();
            }}
          />
        </ChakraModalHeader>

        <ChakraModalBody display="flex" flexDir="column" gap={4} p={6} mt={0}>
          <Paragraph>
            In the coming weeks, we’re introducing new safeguards to detect duplicate records and prevent them from getting
            synced to your destinations. Hightouch will soon begin excluding rows containing non-unique primary keys from all
            syncs. <Text fontWeight="semibold">You need to update one or more of your models before {cutOffDate}</Text> to
            ensure that your syncs continue to run smoothly.
          </Paragraph>

          <Heading size="md">Current behavior</Heading>

          <Paragraph>
            Hightouch does not enforce{" "}
            <Link href="https://hightouch.com/docs/getting-started/concepts#unique-primary-key-requirement">
              primary key uniqueness
            </Link>{" "}
            by default. If you’re using the standard sync engine, and your model’s primary key column contains repeated values,
            any associated syncs could send duplicate records or repeatedly overwrite existing records in your destination.
          </Paragraph>

          <Heading size="md">Future behavior</Heading>

          <Paragraph>
            Starting {cutOffDate}, Hightouch will automatically exclude rows containing non-unique primary keys from all sync
            runs. In the sync logs, they will be marked as “Rejected”.
          </Paragraph>

          <Image
            src="https://cdn.sanity.io/images/pwmfmi47/production/7fb07e24c1c586b53ae489f8b07e5792cf56c0ff-2240x549.webp"
            alt="Rejected primary keys."
          />

          <Paragraph>
            To keep you informed when this happens, an error will be logged for each affected row. You can view these errors in
            the <Link href="https://hightouch.com/docs/syncs/debugger">live debugger</Link>, or you can set up{" "}
            <Link href="https://hightouch.com/docs/syncs/alerting">alerts</Link>. This change will affect all sources and sync
            engines.
          </Paragraph>

          <Paragraph>Only non-unique primary keys will be filtered out. All other rows will be processed normally.</Paragraph>

          <Heading size="md" mt={4}>
            Where do I find a list of affected models?
          </Heading>

          <Paragraph>
            To see a list of models containing duplicate primary keys, navigate to the Models tab and use this filter:
          </Paragraph>

          <Image
            src="https://cdn.sanity.io/images/pwmfmi47/production/e2cd410a871ab665d0df763e993b39335f076a54-946x986.webp"
            alt="Models page with duplicate primary keys filter selected."
            width="400px"
          />

          <Paragraph>
            For workspaces using the Customer Studio add-on, the same filter can also be found in the Audiences tab.
          </Paragraph>

          <Paragraph>
            <Text fontWeight="semibold">
              Note: this upcoming change only impacts models that are associated with incremental syncs that require{" "}
              <Link href="https://hightouch.com/docs/getting-started/concepts">diffing</Link>.
            </Text>{" "}
            For example, primary key uniqueness will not be enforced for syncs to Google Sheets or raw file uploads to SFTP.
            (Put another way, syncs using the "mirror" or "snapshot" modes will not be affected.)
          </Paragraph>

          <Heading size="md" mt={4}>
            What do I need to do?
          </Heading>

          <Paragraph>
            We recommend editing each affected model to ensure that its primary key column does not contain any repeated values.
          </Paragraph>

          <Paragraph>
            In most cases, you can{" "}
            <Link href="https://hightouch.com/docs/models/sql-editor">filter out duplicates using SQL</Link>. If you’re using a
            different modeling method (e.g., table selector), or if SQL isn’t supported by your source (e.g., Amazon S3), then
            you may need to make the necessary changes further upstream.
          </Paragraph>

          <Column bg="danger.background" gap={6} p={3}>
            <Paragraph>
              Please be careful when modifying primary keys. Hightouch uses primary keys to keep track of changes in your
              underlying data. If you change a model’s primary key column (for example, switching from{" "}
              <Text isMonospace>email</Text> to <Text isMonospace>user_id</Text>), the next sync run will be processed as a full
              resync. This means that Hightouch will not perform any{" "}
              <Link href="https://hightouch.com/docs/getting-started/concepts">diffing</Link> and will instead process every row
              as if it’s the first time the sync has ever run. In other words, all rows will be sent to your destination again.
            </Paragraph>

            <Paragraph>
              This could have undesirable side effects for certain syncs (especially insert-only syncs) because it could create
              duplicate records in your destination. However, if you change the way that a primary key is calculated and do not
              change its column name, Hightouch will not perform a full resync on the next sync run. Instead, Hightouch will
              identify added, changed, and removed rows via the normal diffing process.
            </Paragraph>

            <Paragraph>
              For example, suppose you have a model with 1000 rows. If you change the format of the primary key (for example,
              from <Text isMonospace>12345</Text> to <Text isMonospace>user_12345</Text>), the next sync run will process 1000
              added rows{" "}
              <Box as="span" fontStyle="italic">
                and 1000 removed rows
              </Box>
              . Depending on your sync configuration, this may result in deletions in your destination. The correct approach
              here would be to use an upsert or{" "}
              <Link href="https://hightouch.com/docs/syncs/types-and-modes">update-only sync mode</Link>.
            </Paragraph>
          </Column>

          <Paragraph>
            It is your responsibility to ensure that primary key changes in Hightouch do not cause disruptions in your
            downstream destinations. If any part of this explanation is unclear, or if you require assistance, please reach out.
            We’re here for you!
          </Paragraph>

          <Heading size="md" mt={4}>
            What will happen if I do nothing?
          </Heading>

          <ChakraUnorderedList spacing={2}>
            <ChakraListItem>Your syncs will continue to run according to schedule.</ChakraListItem>

            <ChakraListItem>
              Starting {cutOffDate}, rows with non-unique primary keys will be filtered out and will not be sent to your
              destination. All other rows will be processed normally.
            </ChakraListItem>

            <ChakraListItem>
              An error will be logged for each duplicate record. If your workspace has alerting enabled for row-level errors,
              you will receive an alert during each affected sync run.
            </ChakraListItem>

            <ChakraListItem>
              Affected syncs will transition to the “warning” status due to the row-level errors described above.
            </ChakraListItem>
          </ChakraUnorderedList>

          <Heading size="md" mt={4}>
            I have a different question.
          </Heading>

          <Paragraph>Our team is here to help! Reach out via chat with any questions.</Paragraph>
        </ChakraModalBody>

        <ChakraModalFooter borderTop="1px solid" borderColor="base.border" p={6} mt={0}>
          <Button
            onClick={() => {
              onClose();
              analytics.track("Duplicate PK Modal Live Chat Loaded", {
                workspace_id: workspace?.id,
              });
              newIntercomMessage(`Hi, have a question about duplicate records in my models.`);
            }}
          >
            Contact support
          </Button>

          <LinkButton variant="primary" href="/models?duplicate_keys=true" onClick={onClose}>
            View affected models
          </LinkButton>
        </ChakraModalFooter>
      </ChakraModalContent>
    </ChakraModal>
  );
};
