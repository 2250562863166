import { FC } from "react";

import { Box, Row, SystemStyleObject, Text } from "@hightouchio/ui";

import { RelationshipFragment } from "src/graphql";
import { ConditionType, PropertyCondition, TraitDefinition } from "src/types/visual";

import { AndOrToggleButton } from "./condition-buttons";
import { PropertyFilter, PropertyFilterProps } from "./property-filter";

type NestedPropertyFilterProps = {
  columns: RelationshipFragment["to_model"]["filterable_audience_columns"] | undefined;
  traits?: TraitDefinition[];
  condition: PropertyCondition;
  isFirstCondition?: boolean;
  isWrappedWithAndCondition?: boolean;
  onChange(updates: Partial<PropertyCondition>): void;
  onRemove(): void;
  onToggleWrappingConditionType(conditionType: ConditionType.And | ConditionType.Or): void;
  containerSx?: SystemStyleObject;
};

export const NestedPropertyFilter: FC<Readonly<NestedPropertyFilterProps & PropertyFilterProps>> = ({
  condition,
  isFirstCondition = false,
  isWrappedWithAndCondition = true,
  onChange,
  onRemove,
  onToggleWrappingConditionType,
  containerSx,
  ...props
}) => {
  return (
    <Row sx={containerSx}>
      {isFirstCondition && (
        <Box mt={1.5} mr={2}>
          <Text color="text.secondary">Where</Text>
        </Box>
      )}
      {!isFirstCondition && (
        <AndOrToggleButton
          hideIcon
          conditionType={isWrappedWithAndCondition ? ConditionType.And : ConditionType.Or}
          ml={-1}
          width="44px"
          onClick={() => onToggleWrappingConditionType(isWrappedWithAndCondition ? ConditionType.Or : ConditionType.And)}
        />
      )}
      <PropertyFilter {...props} showSecondaryFilter condition={condition} onChange={onChange} onRemove={onRemove} />
    </Row>
  );
};
