import { FC, useMemo } from "react";

import {
  Column,
  Row,
  Tooltip,
  Text,
  Box,
  ChakraPopover,
  ChakraPopoverContent,
  ChakraPopoverTrigger,
  ChakraPortal,
} from "@hightouchio/ui";
import moment from "moment";
import { UnreachableCaseError } from "ts-essentials";

import { Circle } from "src/ui/circle";
import { WEEK_DAYS } from "src/utils/constants";

import { Schedule as ScheduleObject, ScheduleType, ScheduleExpression, ScheduleInterval } from "./types";
import { UTCDayToLocalDay } from "./visual-cron-expression";

type ScheduleProps = {
  schedule: ScheduleObject | undefined;
};

export const Schedule: FC<Readonly<ScheduleProps>> = ({ schedule }) => {
  if (!schedule) {
    return <Manual />;
  }

  const type = schedule.type;
  switch (type) {
    case ScheduleType.DBT_CLOUD:
      return <DBT account={schedule.schedule?.account?.name} job={schedule.schedule?.job?.name} />;
    case ScheduleType.INTERVAL:
      return <Interval interval={schedule.schedule?.interval} />;
    case ScheduleType.CRON:
      return <Cron expression={schedule.schedule?.expression} />;
    case ScheduleType.CUSTOM:
      return <VisualCron expressions={schedule.schedule?.expressions ?? []} />;
    case ScheduleType.MANUAL:
      return <Manual />;
    case ScheduleType.FIVETRAN:
      return <Text fontWeight="medium">Fivetran</Text>;
    default:
      throw new UnreachableCaseError(type);
  }
};

interface VisualCronProps {
  expressions: ScheduleExpression[];
}

export const VisualCron: FC<VisualCronProps> = ({ expressions }) => {
  if (expressions.length === 1) {
    return <VisualCronExpression expression={expressions[0] as ScheduleExpression} />;
  }

  return (
    <ChakraPopover placement="right" closeOnBlur={true} isLazy>
      <ChakraPopoverTrigger>
        <Row gap={2} align="center">
          <VisualCronExpression expression={expressions[0] as ScheduleExpression} />
          <Box color="primary.base" cursor="pointer" fontWeight="medium">
            +{expressions.length - 1} more
          </Box>
        </Row>
      </ChakraPopoverTrigger>
      <ChakraPortal>
        <Box sx={{ ">div": { zIndex: 200 } }}>
          <ChakraPopoverContent width="max-content">
            <Column gap={2} p={3}>
              {expressions.slice(1).map((expression, index) => (
                <VisualCronExpression expression={expression} key={index} />
              ))}
            </Column>
          </ChakraPopoverContent>
        </Box>
      </ChakraPortal>
    </ChakraPopover>
  );
};

interface VisualCronExpressionProps {
  expression: ScheduleExpression;
}

const VisualCronExpression: FC<VisualCronExpressionProps> = ({ expression }) => {
  const selectedDays = useMemo(() => {
    return Object.keys(expression?.days ?? {}).map((day) => {
      return UTCDayToLocalDay(expression.time, Number(moment(day, "dddd").format("e")));
    });
  }, [expression]);

  if (Object.keys(expression?.days || {}).length === 7) {
    return <Text>Every day at {moment.utc(expression?.time, "HH:mm").local().format("h:mm A")}</Text>;
  }

  return (
    <Row gap={2} align="center">
      {WEEK_DAYS.map((day, index) => {
        const isSelected = selectedDays.includes(index);

        return (
          <Tooltip key={index} message={day}>
            <Circle
              radius="20px"
              sx={{
                bg: isSelected ? "avocado" : undefined,
                border: "small",
                borderColor: isSelected ? "grass" : "gray.200",
              }}
            >
              <Text size="sm" color="text.secondary">
                {day[0]}
              </Text>
            </Circle>
          </Tooltip>
        );
      })}

      <Text fontWeight="medium">at {moment.utc(expression?.time, "HH:mm").local().format("h:mm A")}</Text>
    </Row>
  );
};

interface CronProps {
  expression: string | undefined;
}

export const Cron: FC<CronProps> = ({ expression }) => <Text fontWeight="medium">{expression}</Text>;

interface IntervalProps {
  interval: ScheduleInterval | undefined;
}

export const Interval: FC<IntervalProps> = ({ interval }) => (
  <Text fontWeight="medium">
    Every {interval?.quantity} {interval?.unit}s
  </Text>
);

export const Manual: FC = () => <Text fontWeight="medium">Manual</Text>;

interface DbtProps {
  job: string | undefined;
  account: string | undefined;
}

export const DBT: FC<DbtProps> = ({ job, account }) => (
  <Text fontWeight="medium">
    <strong>dbt Cloud</strong> ({account} - {job})
  </Text>
);
