import { FC, ReactNode } from "react";

import { Text, Heading, Row, Column, Box } from "@hightouchio/ui";
import { useOutletContext } from "react-router-dom";
import { Label as PieLabel, Cell, ResponsiveContainer, PieChart, Pie } from "recharts";

import { IdentityResolutionStatusBadge } from "src/pages/identity-resolution/status-badge";
import { IdrRunStatus } from "src/pages/identity-resolution/types";
import { commaNumber } from "src/utils/numbers";
import { diff, formatDatetime } from "src/utils/time";

import { OutletContext } from ".";

type Stats = {
  total: number;
  resolved: number;
  new: number;
};

export const Summary: FC = () => {
  const { graph } = useOutletContext<OutletContext>();
  const currentRun = graph.runs[0];
  const previousRun = graph.runs[1];

  const lastRun = currentRun?.finished_at ? currentRun : previousRun;

  if (lastRun) {
    return (
      <Column gap={6}>
        <Run {...currentRun} />
        <Row gap={6} width="100%" flexDir={["column", "column", "row"]}>
          <Profiles stats={lastRun.stats?.profiles} />
          <Events stats={lastRun.stats?.events} />
        </Row>
      </Column>
    );
  }

  return null;
};

const Label: FC<{ children: ReactNode; textAlign?: "right" }> = ({ children, textAlign }) => (
  <Box as="span" textTransform="uppercase" color="text.secondary" fontSize="sm" fontWeight="semibold" textAlign={textAlign}>
    {children}
  </Box>
);

const Run: FC<{
  created_at?: string | null;
  started_at?: string | null;
  finished_at?: string | null;
  status?: string;
  stats?: { profiles: Stats; events: Stats } | null;
}> = ({ started_at, finished_at, created_at, status, stats }) => {
  console.log({ stats });
  return (
    <Row gap={4} justify="space-between" p={6} border="1px" borderColor="base.border" borderRadius="md" boxShadow="xs">
      <Column gap={2}>
        <Label>{finished_at ? "Last run" : "Current run"}</Label>
        <Text fontWeight="medium">{finished_at ? formatDatetime(started_at ?? "") : formatDatetime(created_at ?? "")}</Text>
      </Column>
      <Column gap={2}>
        <Label>Status</Label>
        <IdentityResolutionStatusBadge status={status as IdrRunStatus} />
      </Column>
      <Column gap={2}>
        <Label>Processing time</Label>
        {started_at && finished_at ? (
          <Text fontWeight="medium">{diff(started_at, finished_at)}</Text>
        ) : (
          <Text color="text.secondary">Calculating...</Text>
        )}
      </Column>
      <Column gap={2}>
        <Label>New profiles</Label>
        <Box fontWeight="medium" textAlign="right">
          {stats?.profiles ? commaNumber(stats.profiles.new) : "--"}
        </Box>
      </Column>
      <Column gap={2}>
        <Label>New events</Label>
        <Box fontWeight="medium" textAlign="right">
          {stats?.events ? commaNumber(stats.events.new) : "--"}
        </Box>
      </Column>
    </Row>
  );
};

const Profiles: FC<{ stats: Stats | undefined }> = ({ stats }) => {
  return (
    <Column border="1px" borderColor="base.border" borderRadius="md" p={6} boxShadow="xs" flex={1} gap={6}>
      <Heading>Profiles</Heading>
      <Row gap={4}>
        <Column align="flex-end" width="120px" flexShrink={0}>
          <Label>Total</Label>
          <Box fontWeight="medium" fontSize="3xl">
            {stats ? commaNumber(stats.total) : "--"}
          </Box>
        </Column>
        <Box flex={1}>
          <Row width="100%" borderRadius="md" bg="electric.500" height="100%" opacity={0.3} />
        </Box>
      </Row>
      <Row gap={4}>
        <Column align="flex-end" width="120px" flexShrink={0}>
          <Label>Resolved</Label>
          <Box fontWeight="medium" fontSize="3xl">
            {stats ? commaNumber(stats.resolved) : "--"}
          </Box>
        </Column>
        <Box flex={1}>
          {stats && (
            <Row
              width="100%"
              maxWidth={`${Math.round((stats.resolved / stats.total) * 100)}%`}
              borderRadius="md"
              bg="electric.500"
              height="100%"
            />
          )}
        </Box>
      </Row>
    </Column>
  );
};

const Events: FC<{ stats: Stats | undefined }> = ({ stats }) => {
  const percentage = stats ? Math.round((stats.resolved / stats.total) * 100) : 0;
  return (
    <Row border="1px" borderColor="base.border" borderRadius="md" p={6} boxShadow="xs" flex={1} gap={6}>
      <Column gap={6}>
        <Heading>Events</Heading>
        <Column gap={6}>
          <Column align="flex-end" width="150px">
            <Label>Total</Label>
            <Box fontWeight="medium" fontSize="3xl">
              {stats ? commaNumber(stats.total) : "--"}
            </Box>
          </Column>
          <Column align="flex-end" width="150px">
            <Label>Resolved</Label>
            <Box fontWeight="medium" fontSize="3xl">
              {stats ? commaNumber(stats.resolved) : "--"}
            </Box>
          </Column>
        </Column>
      </Column>
      <Box
        sx={{
          ".recharts-wrapper": { position: "absolute !important" },
          flex: 1,
        }}
      >
        <ResponsiveContainer>
          <PieChart>
            <Pie
              data={stats ? [{ value: percentage }, { value: 100 - percentage }] : [{ value: 1 }]}
              innerRadius="80%"
              outerRadius="100%"
              cornerRadius={10}
              fill="#8884d8"
              paddingAngle={5}
              dataKey="value"
            >
              <PieLabel position="center" fontSize="32px" fontWeight={600}>
                {stats ? `${percentage}%` : "%"}
              </PieLabel>
              {stats ? (
                <>
                  <Cell fill="var(--chakra-colors-cyan-500)" />
                  <Cell fill="var(--chakra-colors-cyan-300)" />
                </>
              ) : (
                <Cell fill="var(--chakra-colors-gray-300)" />
              )}
            </Pie>
          </PieChart>
        </ResponsiveContainer>
      </Box>
    </Row>
  );
};
