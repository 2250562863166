import { FC, ReactNode } from "react";

import { Box, CheckIcon, Column, PlusIcon, Row } from "@hightouchio/ui";

import { PartnerLogo } from "src/partner/partner-logo";
import { Logo } from "src/ui/brand";

export const Success: FC<Readonly<{ children: ReactNode }>> = ({ children }) => {
  return (
    <Column mx="auto" p={10} gap={10} align="center" maxW="600px">
      <Row gap={6} align="center" p={10}>
        <PartnerLogo />
        <Box fontSize="2xl" color="text.secondary">
          <PlusIcon />
        </Box>
        <Logo variant="full" size="120px" textColor="var(--chakra-colors-forest-900)" />
      </Row>
      <Column border="1px" borderColor="base.border" borderRadius="md" bg="white" p={10} gap={10} boxShadow="lg" align="center">
        <Column
          align="center"
          justify="center"
          borderRadius="50%"
          width="50px"
          height="50px"
          bg="success.base"
          color="white"
          fontSize="4xl"
        >
          <CheckIcon />
        </Column>
        {children}
      </Column>
    </Column>
  );
};
