import { FC, useEffect, useState } from "react";

import {
  Box,
  Button,
  ButtonGroup,
  ChakraDrawer,
  ChakraDrawerContent,
  CloseIcon,
  Column,
  IconButton,
  Row,
  useToast,
} from "@hightouchio/ui";
import { captureException } from "@sentry/react";
import { uniq } from "lodash";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { Navigate, useNavigate, useOutletContext, useParams } from "react-router-dom";
import * as yup from "yup";

import { DeleteConfirmationModal } from "src/components/modals/delete-confirmation-modal";
import { useDeleteIdentityResolutionModelMutation, useUpdateIdentityResolutionModelMutation } from "src/graphql";
import { updateIntercomPosition } from "src/lib/intercom";
import { defaultIdentifiers } from "src/pages/identity-resolution/types";
import eventIcon from "src/pages/schema/assets/event.svg";
import profileIcon from "src/pages/schema/assets/parent.svg";
import { TextWithTooltip } from "src/ui/text";

import { OutletContext } from ".";
import { MapperField, OrderByField, validationResolver } from "./create-model";

type FormState = {
  order_by: { column: string; order: "asc" | "desc" };
  mappings: { column: string; identifier: string }[];
  identifiers: string[];
};

export const Model: FC = () => {
  const { modelId: id } = useParams<{ modelId: string }>();
  const { graph } = useOutletContext<OutletContext>();
  const navigate = useNavigate();
  const { toast } = useToast();
  const [isDeleting, setIsDeleting] = useState(false);

  const onClose = () => {
    navigate(`/idr/${graph.id}/models`);
  };

  const model = graph.models.find((model) => String(model.id) === String(id));
  const modelIdentifiers = model?.mappings.map((mapping) => mapping.identifier) || [];

  const updateMutation = useUpdateIdentityResolutionModelMutation();
  const deleteMutation = useDeleteIdentityResolutionModelMutation();

  const form = useForm<FormState>({
    defaultValues: {
      identifiers: uniq([...defaultIdentifiers, ...modelIdentifiers]),
      order_by: model?.order_by,
      mappings: [...(model?.mappings ?? []), { identifier: "", column: "" }],
    },
    resolver: validationResolver(validationSchema),
  });

  const submit: SubmitHandler<FormState> = async (data) => {
    const { mappings, order_by } = data;
    try {
      await updateMutation.mutateAsync({
        id: String(id),
        model: {
          order_by,
        },
        mappings: mappings.map((mapping) => ({
          idr_model_id: String(id),
          model_id: String(model?.model.id),
          ...mapping,
        })),
      });
      toast({
        id: "update-model",
        title: "Model updated",
        variant: "success",
      });
      onClose();
    } catch (error) {
      captureException(error);
      toast({
        id: "update-model",
        title: "There was a problem updating your model",
        variant: "error",
      });
    }
  };

  useEffect(() => {
    updateIntercomPosition(672);
    return () => {
      updateIntercomPosition(0);
    };
  }, []);

  if (!model) {
    return <Navigate to={`/idr/${graph.id}/models`} replace />;
  }

  return (
    <>
      <ChakraDrawer size="lg" isOpen onClose={onClose} placement="right" closeOnEsc closeOnOverlayClick trapFocus={false}>
        <ChakraDrawerContent borderLeft="1px" borderTop="1px" borderColor="base.border">
          <Row borderBottom="1px" p={6} borderColor="base.border" justify="space-between" align="flex-start">
            <Column>
              <Row align="center" gap={2} overflow="hidden" height="24px">
                <Box as="img" src={model.type === "event" ? eventIcon : profileIcon} width="24px" />
                <TextWithTooltip fontWeight="medium" isTruncated size="lg">
                  {model.model.name}
                </TextWithTooltip>
              </Row>
            </Column>
            <IconButton icon={CloseIcon} aria-label="Close" onClick={onClose} />
          </Row>
          <Column gap={6} p={6} flex={1}>
            <FormProvider {...form}>
              <MapperField columns={model.model.columns} />
              <OrderByField columns={model.model.columns} type={model.type as any} />
            </FormProvider>
          </Column>
          <Row borderTop="1px" borderColor="base.border" p={6} justify="space-between" align="center">
            <ButtonGroup>
              <Button
                variant="primary"
                isDisabled={!form.formState.isDirty}
                isLoading={updateMutation.isLoading}
                size="lg"
                onClick={() => {
                  form.handleSubmit(submit)();
                }}
              >
                Save changes
              </Button>
              <Button
                isDisabled={!form.formState.isDirty}
                size="lg"
                onClick={() => {
                  form.reset();
                }}
              >
                Discard changes
              </Button>
            </ButtonGroup>
            <Button
              size="lg"
              variant="warning"
              onClick={() => {
                setIsDeleting(true);
              }}
            >
              Delete
            </Button>
          </Row>
        </ChakraDrawerContent>
      </ChakraDrawer>
      <DeleteConfirmationModal
        label="model"
        text="Deleting this model will remove its data from the identity graph."
        isOpen={isDeleting}
        onClose={() => setIsDeleting(false)}
        onDelete={async () => {
          await deleteMutation.mutateAsync({ id: String(id) });
          setIsDeleting(false);
          onClose();
        }}
      />
    </>
  );
};

const validationSchema = yup.object().shape({
  identifiers: yup.array().of(yup.string()),
  order_by: yup.object().shape({
    column: yup.string().required("A column is required"),
    order: yup.string().required(),
  }),
  mappings: yup.array().of(
    yup.object().shape({
      column: yup.string(),
      identifier: yup.string(),
    }),
  ),
});
