import { createContext, FC, ReactNode, useContext } from "react";

import { Brand } from "@hightouch/core/server/routes/partner/types";

export type PartnerContextValue = { brand: Brand; watermark: boolean };

export const PartnerContext = createContext<PartnerContextValue>({ brand: {} as Brand, watermark: true });

export const usePartner = () => useContext(PartnerContext);

export const PartnerProvider: FC<Readonly<{ brand: Brand | undefined; children: ReactNode }>> = ({ brand, children }) => {
  return (
    <PartnerContext.Provider value={{ brand: brand ?? ({} as Brand), watermark: true }}>{children}</PartnerContext.Provider>
  );
};
