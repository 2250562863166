import { FC } from "react";

import { Code, Column, ConfirmationDialog, Link, Paragraph } from "@hightouchio/ui";

interface Props {
  oldPrimaryKey: string | undefined;
  newPrimaryKey: string | undefined;
  onClose: () => void;
  onConfirm: () => Promise<void>;
}

export const PrimaryKeyDialog: FC<Readonly<Props>> = ({ onConfirm, onClose, oldPrimaryKey, newPrimaryKey }) => (
  <ConfirmationDialog
    variant="danger"
    confirmButtonText="Proceed with change"
    isOpen={Boolean(newPrimaryKey)}
    title="Full resync required"
    onClose={onClose}
    onConfirm={onConfirm}
  >
    <Column gap={2}>
      <Paragraph>
        You are changing this model’s primary key from <Code>{oldPrimaryKey}</Code> to <Code>{newPrimaryKey}</Code>. This change
        requires Hightouch to perform a full resync on any active syncs using this model.
      </Paragraph>
      <Paragraph>
        A full resync sends all rows to the destination. Be careful if you have any insert-only syncs, as this may result in
        duplicate records in your destination.
      </Paragraph>
      <Paragraph>
        See our <Link href="https://hightouch.com/docs/syncs/overview#full-resync-prerequisites">docs</Link> for more
        information, or reach out if you have any questions.
      </Paragraph>
    </Column>
  </ConfirmationDialog>
);
