import { FC, useEffect, useState } from "react";

import { TraitConfig, TraitType } from "@hightouch/lib/query/visual/types";
import {
  Button,
  Spinner,
  Row,
  Column,
  Heading,
  Text,
  AddBoxIcon,
  CloseIcon,
  IconButton,
  ChakraModal,
  ChakraModalOverlay,
  ChakraModalContent,
  ChakraModalHeader,
  ChakraModalBody,
} from "@hightouchio/ui";
import { useNavigate } from "react-router-dom";

import { useTraitQuery, useTraitsQuery } from "src/graphql";

import { CalculationSummary } from "./calculation-summary";
import { Metadata } from "./metadata";
import { PreviewTrait } from "./preview-trait";

export const SelectTemplateModal: FC = () => {
  const navigate = useNavigate();
  const [tab, setTab] = useState<{ traitId: string | undefined; index: number }>({ traitId: undefined, index: -1 });

  const traitTemplatesQuery = useTraitsQuery({
    filter: {
      is_template: { _eq: true },
      archived_at: { _eq: null },
    },
  });

  // Automatically show the first trait template
  useEffect(() => {
    if (!tab.traitId) {
      if (traitTemplatesQuery.data?.trait_definitions?.[0]) {
        const firstTemplate = traitTemplatesQuery.data?.trait_definitions[0];
        setTab({ traitId: firstTemplate.id, index: 0 });
      }
    }
  }, [tab.traitId, traitTemplatesQuery.data?.trait_definitions]);

  const traitQuery = useTraitQuery(
    { id: tab.traitId ?? "" },
    {
      enabled: tab.traitId != undefined,
    },
  );

  const trait = traitQuery.data?.trait_definitions_by_pk;

  const onClose = () => navigate("/traits/active");

  const handleCreateTrait = () => {
    navigate(`/traits/active/new_from_template?template_id=${tab?.traitId}`);
  };

  const content = (
    <Row height="80vh">
      <Column width="300px" borderRight="1px solid" borderRightColor="base.border" overflowY="auto">
        {traitTemplatesQuery.data?.trait_definitions.map((trait, index) => (
          <Column
            key={trait.id}
            bg={tab.index === index ? "primary.background" : undefined}
            cursor="pointer"
            p={2}
            role="listbox"
            onClick={() => setTab({ traitId: trait.id, index: index })}
          >
            <Text>{trait.name}</Text>
            <Text color="text.secondary">{trait.description}</Text>
          </Column>
        ))}
        <Column cursor="pointer" p={2}>
          <Row gap={2}>
            <AddBoxIcon color="text.secondary" />
            <Column>
              <Text>New template</Text>
              <Text color="text.secondary">Save the calculation method</Text>
            </Column>
          </Row>
        </Column>
      </Column>
      <Column height="100%" width="600px">
        {(traitQuery.isIdle || traitQuery.isLoading) && (
          <Column height="100%" alignItems="center" justifyContent="center">
            {traitQuery.isIdle ? "Select or create a new template to get started" : <Spinner />}
          </Column>
        )}
        {trait && (
          <Column height="100%">
            <Metadata
              id={trait.id}
              name={trait.name}
              description={trait?.description}
              parentModel={trait.parent_model}
              isTemplate={trait.is_template}
            />

            <Column p={6} gap={8} height="100%" overflowY="scroll">
              <Column>
                <Text fontWeight="medium">Calculation summary</Text>
                <Row mt={2} borderRadius="6px" border="1px solid" borderColor="base.border" padding={4}>
                  <CalculationSummary
                    type={trait.type as TraitType}
                    config={trait.config as TraitConfig}
                    model={trait.relationship.to_model}
                  />
                </Row>
                <Column mt={6}>
                  <PreviewTrait
                    parentModel={trait.parent_model}
                    trait={{
                      name: trait.name,
                      type: trait.type as TraitType,
                      config: trait.config,
                      relationshipId: trait.relationship.id,
                    }}
                  />
                </Column>
              </Column>
            </Column>
            <Row borderTop="1px solid" borderTopColor="base.border" justifyContent="right" p={4}>
              <Button variant="primary" onClick={handleCreateTrait}>
                Create a new trait
              </Button>
            </Row>
          </Column>
        )}
      </Column>
    </Row>
  );

  return (
    <ChakraModal closeOnEsc closeOnOverlayClick isCentered scrollBehavior="inside" isOpen onClose={onClose}>
      <ChakraModalOverlay />
      <ChakraModalContent maxHeight="90vh" maxWidth="800px" padding={0}>
        <ChakraModalHeader alignItems="center" padding={6} borderBottom="1px solid" borderColor="base.border">
          <Row alignItems="center" justifyContent="space-between" width="100%">
            <Heading>Choose a template</Heading>
            <IconButton aria-label="Close modal" color="text.secondary" icon={CloseIcon} onClick={onClose} />
          </Row>
        </ChakraModalHeader>
        <ChakraModalBody m={0}>
          {traitTemplatesQuery.isLoading ? (
            <Column height="100px" alignItems="center" justifyContent="center">
              <Spinner />
            </Column>
          ) : (
            content
          )}
        </ChakraModalBody>
      </ChakraModalContent>
    </ChakraModal>
  );
};
