import { Extensions, JSONContent } from "@tiptap/react";

export type PluginSuggestionHandler<P extends Plugins = Plugins> = {
  type: "suggestion";
  plugin: P;
  args: {
    query: string;
    /**
     * Must be > 0.
     */
    limit?: number;
  };
};

export type PluginHandlerProps<P extends Plugins = Plugins> = PluginSuggestionHandler<P>;

export type PluginHandler<P extends Plugins = Plugins> = (props: PluginHandlerProps<P>) => Promise<any>;

export type ContentSerializer = {
  serialize: (content: JSONContent) => string;
  deserialize: (content: string) => JSONContent;
};

export enum Plugins {
  MENTION = "mention",
  CHANNEL = "channel",
  LIQUID = "liquid",
}

export type ProfileProps<P extends Plugins = Plugins> = {
  handler: PluginHandler<P>;
  profile: "liquid-slack";
};

export type Profile = {
  extensions: Extensions;
  serializer?: ContentSerializer;
};
