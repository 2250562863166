import { FC, useEffect, useState } from "react";

import { CheckCircleIcon, ExclamationCircleIcon } from "@heroicons/react/24/solid";
import { Alert, Button, Column, FormField, Row, Select, Spinner, TextInput } from "@hightouchio/ui";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import * as Sentry from "@sentry/browser";
import slugify from "@sindresorhus/slugify";
import { useFlags } from "launchdarkly-react-client-sdk";
import { compact, uniqBy } from "lodash";
import { Controller, useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import { useDebounce } from "use-debounce";
import { number, object, string } from "yup";

import { topLevelRoutes } from "src/components/router/router";
import { SelectRegion } from "src/components/workspaces/select-region";
import { useUser } from "src/contexts/user-context";
import {
  AvailableWorkspacesQuery,
  useAvailableWorkspacesQuery,
  useCreateWorkspaceMutation,
  useIsWorkspaceSlugAvailableQuery,
  usePartnerConnectLinkWorkspaceMutation,
  useWorkspacesQuery,
  WorkspacesQuery,
} from "src/graphql";
import * as analytics from "src/lib/analytics";
import { HightouchRegionOptions } from "src/utils/regions";
import { generateSlug } from "src/utils/slug";

const reservedSlugs = ["login", "signin", "signup"];

type Props = {
  onboarding?: { sourceType: string | undefined; destinationType: string | undefined };
  onSubmit: (data: { id: number; slug: string }) => void;
};

export const CreateWorkspace: FC<Readonly<Props>> = ({ onSubmit, onboarding }) => {
  const { multiRegionEnabled } = useFlags();
  const navigate = useNavigate();
  const location = useLocation();
  const state = location?.state as { partnerConnection: any } | undefined;
  const { user } = useUser();
  const { data: workspaceData } = useWorkspacesQuery();
  const { data: availableData } = useAvailableWorkspacesQuery();

  const [error, setError] = useState<string | null>(null);

  // only pass through an org to restrict them to if they are an sso user
  const { defaultOrganization, orgs } = calculateOrganizations(workspaceData, availableData, Boolean(user?.auth0_auth_id));
  const hasOrganizations = Boolean(orgs.length);

  const { mutateAsync: linkWorkspace } = usePartnerConnectLinkWorkspaceMutation();
  const { mutateAsync: createWorkspace } = useCreateWorkspaceMutation();
  const validationSchema = object().shape({
    name: string().required("Name is required."),
    slug: string().required("Slug is required."),
    region: string().required("Region is required."),
    organization: number().nullable(),
  });
  const { control, handleSubmit, watch, formState, setValue } = useForm<{
    name: string;
    slug: string;
    region: string;
    organization: string;
  }>({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      name: undefined,
      slug: undefined,
      region:
        onboarding?.sourceType === "bigquery" || !hasOrganizations
          ? HightouchRegionOptions.GCP_US_EAST_4
          : HightouchRegionOptions.AWS_US_EAST_1,
      organization: defaultOrganization?.id,
    },
  });
  const { errors } = formState;

  const [isCreatingWorkspace, setIsCreatingWorkspace] = useState(false);

  const submit = async ({ name, slug, region, organization }) => {
    const lowercaseSlug = slug.toLowerCase();
    if (topLevelRoutes.includes(lowercaseSlug) || reservedSlugs.includes(lowercaseSlug)) {
      setError(`${slug} is a reserved slug. Pick a different slug for your workspace.`);
      return;
    }

    try {
      setError(null);
      setIsCreatingWorkspace(true);

      const data = await createWorkspace({
        name,
        slug,
        region,
        organization: organization === -1 ? undefined : organization,
        onboarding,
      });
      analytics.track("New Workspace Created", {
        workspace_id: data?.createWorkspace?.id,
      });

      if (state?.partnerConnection) {
        await linkWorkspace({ uuid: state.partnerConnection.uuid, workspaceId: Number(data?.createWorkspace?.id) });
        navigate(`/partner-connect/${state.partnerConnection.uuid}`);
      } else {
        onSubmit({ id: data.createWorkspace.id, slug });
      }
    } catch (error: unknown) {
      setIsCreatingWorkspace(false);
      handleWorkspaceCreateError(error as Error);
    }
  };

  const handleWorkspaceCreateError = (error: Error) => {
    Sentry.captureException(error);

    if (error?.message.startsWith("SSO users are not allowed to create workspaces")) {
      setError(`${error.message}.`);
      return;
    }

    setError("There was a problem creating your workspace.");
  };

  // Watch name and autogenerate slugs.
  const name = watch("name");
  useEffect(() => {
    // Do not generate slug if the user has dirtied the field themselves.
    if (!formState.dirtyFields.slug) {
      const generatedSlug = name ? generateSlug(name) : "";
      setValue("slug", generatedSlug, { shouldDirty: false, shouldTouch: false, shouldValidate: false });
    }
  }, [name]);

  const slug = watch("slug");
  const [debouncedSlug, { isPending: isPendingSlugCheck }] = useDebounce(slug, 1000);

  const availableSlug = useIsWorkspaceSlugAvailableQuery(
    { slug: debouncedSlug ?? "" },
    { enabled: !isCreatingWorkspace && Boolean(debouncedSlug), select: (data) => Boolean(data.isWorkspaceSlugAvailable) },
  );

  if (user && user.not_member_of_current_workspace) {
    return (
      <Column gap={4}>
        <Alert message="You cannot create a workspace while impersonating." title="Impersonator" type="warning" />
        <Button onClick={() => navigate(-1)}>Back</Button>
      </Column>
    );
  }

  return (
    <Column gap={8}>
      <Controller
        control={control}
        name="name"
        render={({ field, fieldState: { error } }) => (
          <FormField error={error?.message} label="Workspace name">
            {/*eslint-disable-next-line @typescript-eslint/ban-ts-comment*/}
            {/*@ts-ignore*/}
            <TextInput autoFocus {...field} placeholder="The ACME Company" width="100%" />
          </FormField>
        )}
      />

      <Controller
        control={control}
        name="slug"
        render={({ field, fieldState: { error } }) => (
          <FormField
            error={availableSlug.data === false ? "This slug is not available." : error?.message}
            label="Workspace URL"
          >
            <SlugInput
              {...field}
              isAvailable={availableSlug.data}
              isLoading={availableSlug.isFetching || isPendingSlugCheck()}
            />
          </FormField>
        )}
      />

      {multiRegionEnabled && (
        <FormField error={errors?.region?.message} label="Workspace region">
          <Controller control={control} name="region" render={({ field }) => <SelectRegion {...field} />} />
        </FormField>
      )}

      {hasOrganizations && (
        <FormField error={errors?.organization?.message} label="Organization">
          <Controller
            control={control}
            name="organization"
            render={({ field }) => (
              <Select
                options={orgs}
                {...field}
                isDisabled={orgs.length === 1}
                placeholder="Select an organization..."
                width="100%"
                onChange={(value) => field.onChange(value)}
              />
            )}
          />
        </FormField>
      )}

      {error && <Alert message={error} title="Something went wrong" type="error" />}

      <Button
        isDisabled={availableSlug.isLoading || isPendingSlugCheck() || !availableSlug.data || !name}
        isLoading={isCreatingWorkspace}
        size="lg"
        variant="primary"
        onClick={handleSubmit(submit)}
      >
        Create workspace
      </Button>
    </Column>
  );
};

const SlugInput: FC<
  Readonly<{
    isLoading: boolean;
    value: string | undefined | null;
    onChange: (value: string) => void;
    isAvailable: boolean | undefined;
  }>
> = ({ isLoading, value, onChange, isAvailable }) => {
  return (
    <Row align="center">
      <Row
        sx={{
          input: {
            borderRightRadius: 0,
            borderRight: "none",
            pointerEvents: "none",
            color: "gray.700",
            width: "160px",
          },
        }}
      >
        <TextInput isReadOnly value="app.hightouch.com/" />
      </Row>

      <Row
        align="center"
        flex={1}
        position="relative"
        sx={{ input: { borderLeftRadius: 0, width: "100%", pr: 8 }, "& > div": { flex: 1 } }}
      >
        <TextInput
          id="slug"
          value={value ?? ""}
          onChange={(event) => onChange(slugify(event?.target.value, { preserveTrailingDash: true }))}
        />
        <Row align="center" gap={1} position="absolute" right={2} zIndex={2}>
          {!value ? null : isLoading ? (
            <Spinner size="sm" />
          ) : isAvailable ? (
            <CheckCircleIcon color="var(--chakra-colors-success-base)" height="100%" width="20px" />
          ) : (
            <ExclamationCircleIcon color="var(--chakra-colors-danger-base)" height="100%" width="20px" />
          )}
        </Row>
      </Row>
    </Row>
  );
};

const calculateOrganizations = (
  workspacesData: WorkspacesQuery | undefined,
  availableData: AvailableWorkspacesQuery | undefined,
  isSso: boolean, // this is the user's SSO organization (if it is a SSO user)
) => {
  if (!workspacesData || !availableData) {
    return { defaultOrganization: null, orgs: [] };
  }

  const workspaces = workspacesData.workspaces;
  const orgs = uniqBy(
    compact([
      ...(workspaces || []).map((ws) => ws.organization),
      ...(availableData.getAvailableWorkspaces.joinable || []).map((ws) => ws.organization),
    ]),
    "id",
  );

  if (orgs.length > 0) {
    // if the user is an SSO user, they should only be able to create workspaces in that organization
    if (isSso) {
      return {
        defaultOrganization: orgs[0], // they should only be part of workspaces in one org
        orgs: [{ label: orgs[0]?.name, value: orgs[0]?.id }],
      };
    }

    return {
      defaultOrganization: orgs[0],
      orgs: [{ label: "New organization", value: -1 }, ...orgs.map((org) => ({ label: org.name, value: org.id }))],
    };
  }
  return { defaultOrganization: null, orgs: [] };
};
