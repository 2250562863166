import { AndOrCondition, EventCondition, PropertyCondition } from "./condition";
import { TraitConfig, TraitType } from "./trait-definitions";
import * as yup from "yup";

export enum ColumnType {
  String = "string",
  Number = "number",
  Boolean = "boolean",
  Timestamp = "timestamp",
  Date = "date",
  Json = "json",
  JsonArrayStrings = "json-array-strings",
  JsonArrayNumbers = "json-array-numbers",
  Null = "null",
  Unknown = "unknown",
}

export type Column = RawColumn | RelatedColumn;

export type RelatedColumn = {
  type: "related";
  path: string[];
  column: RawColumn | TraitColumn | EventTraitColumn | InlineTraitColumn;
};

export type RawColumn = {
  type: "raw";
  modelId: string;
  name: string;
};

export function getRawColumnValidator() {
  return yup.object({
    type: yup.string().equals(["raw"], "must be a raw column").required(),
    modelId: yup.string().required(),
    name: yup.string().required(),
  });
}

export type TraitColumn = {
  type: "trait";
  traitDefinitionId: string;
  conditions: PropertyCondition[];
};

// This is essentially a `TraitColumn` that hasn't been materialized in the DB so it doesn't have a `traitDefinitionId`
export type InlineTraitColumn = {
  type: "inline_trait";
  traitType: TraitType;
  traitConfig: TraitConfig;
  conditions: AndOrCondition<PropertyCondition>[];
  relationshipId: string;
};

export type EventTraitColumn = {
  type: "event_trait";
  filteredEvent: Omit<EventCondition, "operator" | "value">;
  traitType: TraitType;
  traitConfig: TraitConfig;
};

export const isTraitColumn = (
  column: TraitColumn | InlineTraitColumn | EventTraitColumn | RawColumn,
): column is TraitColumn => column.type === "trait";
