import { FC } from "react";

import { TraitConfig, TraitCondition } from "@hightouch/lib/query/visual/types/trait-definitions";
import { AddBoxIcon, Column } from "@hightouchio/ui";
import { Controller, Control, FormProvider, useFieldArray, useFormContext, useWatch } from "react-hook-form";

import { FilterableColumn } from "src/graphql";
import { AudienceParent, ConditionType, initialPropertyCondition } from "src/types/visual";

import { DetailButton } from "../explore/visual/condition-buttons";
import { NestedPropertyFilter } from "../explore/visual/nested-property-filter";

type PartialFormValues = {
  config: TraitConfig;
};

const INITIAL_TOP_LEVEL_CONDITION: TraitCondition = { type: ConditionType.And, conditions: [initialPropertyCondition] };

type MinimalRelationship = {
  to_model: {
    filterable_audience_columns: FilterableColumn[];
  };
};

type TraitConditionsProps = {
  parentModel: AudienceParent;
  relationship: MinimalRelationship;
};

export const TraitConditions: FC<Readonly<TraitConditionsProps>> = ({ parentModel, relationship }) => {
  const formContext = useFormContext();

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore - Circular reference problem with Column types
  const topLevelConditions = useFieldArray<PartialFormValues, "config.conditions", "id">({
    control: formContext.control as unknown as Control<PartialFormValues>,
    name: "config.conditions",
  });

  const topLevelCondition = useWatch({
    control: formContext.control,
    name: "config.conditions.0",
    defaultValue: INITIAL_TOP_LEVEL_CONDITION,
  });

  const changeWrappingConditionType = (conditionIndex: number, newConditionType: ConditionType.And | ConditionType.Or) => {
    topLevelConditions.update(conditionIndex, { ...topLevelCondition, type: newConditionType });
  };

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore - Circular reference problem with Column types
  // Note: There can only be one top-level condition, so we only want to add a top-level condition if there are none present
  if (topLevelConditions.fields.length === 0) {
    return (
      <AddConditionButton isDisabled={!relationship} onClick={() => topLevelConditions.append(INITIAL_TOP_LEVEL_CONDITION)} />
    );
  }

  return (
    <FormProvider {...formContext}>
      <Column>
        {/* Note: there should only be one top-level condition due to the conditional above*/}
        {topLevelConditions.fields.map((condition, index) => (
          <SubConditions
            key={condition.id}
            isWrappedWithAndCondition={condition.type === ConditionType.And}
            parentConditionIndex={index}
            parentModel={parentModel}
            relationship={relationship}
            onToggleWrappingConditionType={changeWrappingConditionType}
          />
        ))}
      </Column>
    </FormProvider>
  );
};

type SubConditionsProps = {
  parentModel: AudienceParent;
  relationship: MinimalRelationship;
  parentConditionIndex: number;
  isWrappedWithAndCondition: boolean;
  onToggleWrappingConditionType: (parentConditionIndex: number, newConditionType: ConditionType.And | ConditionType.Or) => void;
};

const SubConditions: FC<Readonly<SubConditionsProps>> = ({
  isWrappedWithAndCondition,
  parentConditionIndex,
  parentModel,
  relationship,
  onToggleWrappingConditionType,
}) => {
  const { control } = useFormContext();

  const conditions = useFieldArray({
    control,
    name: `config.conditions.${parentConditionIndex}.conditions`,
  });

  return (
    <Column gap={conditions.fields.length ? 4 : 0}>
      <Column gap={4}>
        {conditions.fields.map((condition, conditionIndex) => (
          <Controller
            key={condition.id}
            name={`config.conditions.${parentConditionIndex}.conditions.${conditionIndex}`}
            control={control}
            render={({ field }) => (
              <NestedPropertyFilter
                showSecondaryFilter
                audience={undefined}
                condition={field.value}
                columns={relationship?.to_model?.filterable_audience_columns}
                traits={[]}
                parent={parentModel}
                isFirstCondition={conditionIndex === 0}
                isWrappedWithAndCondition={isWrappedWithAndCondition}
                onToggleWrappingConditionType={(newConditionType) =>
                  onToggleWrappingConditionType(parentConditionIndex, newConditionType)
                }
                onChange={(value) => field.onChange({ ...field.value, ...value })}
                onRemove={() => conditions.remove(conditionIndex)}
              />
            )}
          />
        ))}
      </Column>
      <AddConditionButton isDisabled={!relationship} onClick={() => conditions.append(initialPropertyCondition)} />
    </Column>
  );
};

type AddConditionButtonProps = {
  isDisabled: boolean;
  onClick: () => void;
};

const AddConditionButton: FC<Readonly<AddConditionButtonProps>> = ({ isDisabled, onClick }) => (
  <DetailButton icon={AddBoxIcon} isDisabled={isDisabled} size="sm" onClick={onClick}>
    Where...
  </DetailButton>
);
