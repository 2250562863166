import { FC, useEffect, useRef, useState } from "react";

import { BookOpenIcon, ChatBubbleOvalLeftIcon, GlobeAltIcon, PencilIcon } from "@heroicons/react/24/outline";
import {
  DestinationOperation,
  ErrorOriginInfo,
  isDestinationErrorOriginInfo,
  isInternalErrorOriginInfo,
  isSourceErrorOriginInfo,
} from "@hightouch/lib/sync/error-origin-types";
import { Box, Button, Column, Link, Row, Text } from "@hightouchio/ui";
import { truncate } from "lodash";

import { ErrorSyncName } from "src/components/syncs/error-sync-name";
import { SyncQuery } from "src/graphql";
import { QueryType } from "src/types/models";
import * as SyncErrors from "src/types/sync-errors";
import { Code } from "src/ui/code";
import { Markdown } from "src/ui/markdown";

import { InfoModal } from "./info-modal";

type DocumentLink = {
  label?: string;
  type: string;
  url?: string;
};

type DestinationErrorInfo = {
  origin: string;
  rawErrorHeader: string;
  title: string;
  uncategorizedErrorDocsLabel: string;
  uncategorizedErrorDocsLink: string;
};

type ErrorCodeDetails = {
  links?: DocumentLink[];
  userFriendlyMessage: string;
};

type BaseProps = {
  isOpen: boolean;
  onClose: () => void;
  sync: SyncQuery["syncs"][0] | undefined;
};

type SyncErrorProps = BaseProps & {
  errorType: "sync";
  originInfo: ErrorOriginInfo;
  syncRequestError: SyncErrors.SyncRequestErrorInfo | undefined;
};

type RowErrorProps = BaseProps & {
  errorType: "row";
  originInfo: {
    scope: "destination";
    type?: string;
    operation: DestinationOperation;
  };
  rowLevelError: SyncErrors.RowLevelErrorInfo | undefined;
};

const borderColor = "gray.200";
const height = "718px";
const width = "688px";

function checkOverflow(textContainer: HTMLSpanElement | null): boolean {
  if (textContainer) {
    return textContainer.offsetHeight < textContainer.scrollHeight || textContainer.offsetWidth < textContainer.scrollWidth;
  }
  return false;
}

type Destination = {
  definition: {
    docs: string;
    name: string | null;
  };
  id: string;
};

type Model = {
  id: string;
  query_type: string | null;
};

type Source = {
  definition: {
    docs: string;
    name: string;
  };
  id: string;
};

function getDestinationErrorInfo(
  destination: Destination | null | undefined,
  originInfo: ErrorOriginInfo,
  source: Source | null | undefined,
): DestinationErrorInfo {
  let origin = "";
  let rawErrorHeader = "Error message";
  let title = "Run error";
  let uncategorizedErrorDocsLabel = "";
  let uncategorizedErrorDocsLink = "";
  if (isInternalErrorOriginInfo(originInfo)) {
    rawErrorHeader = "Internal error message";
    title = "Internal error";
  } else if (isDestinationErrorOriginInfo(originInfo)) {
    const destinationName = destination?.definition?.name || "destination";
    origin = destinationName;
    rawErrorHeader = `Error message from ${destinationName}`;
    title = "Destination error";
    uncategorizedErrorDocsLabel = `Read docs for ${destinationName}`;
    uncategorizedErrorDocsLink = `${import.meta.env.VITE_DOCS_URL}/${destination?.definition?.docs}`;
  } else if (isSourceErrorOriginInfo(originInfo)) {
    const sourceName = source?.definition?.name || "source";
    origin = sourceName;
    rawErrorHeader = `Error message from ${sourceName}`;
    title = "Source error";
    uncategorizedErrorDocsLabel = `Read docs for ${sourceName}`;
    uncategorizedErrorDocsLink = `${import.meta.env.VITE_DOCS_URL}/${source?.definition?.docs}`;
  }
  return { origin, rawErrorHeader, title, uncategorizedErrorDocsLabel, uncategorizedErrorDocsLink };
}

function generateLinkProps(
  link: DocumentLink,
  origin: string,
  destination: Destination | null | undefined,
  model: Model | null | undefined,
  source: Source | null | undefined,
  sync: SyncQuery["syncs"][0] | null | undefined,
) {
  switch (link?.type) {
    case "destination":
      return {
        icon: PencilIcon,
        label: "Edit destination",
        url: `/destinations/${destination?.id}`,
      };
    case "model":
      return {
        icon: PencilIcon,
        label: "Edit model",
        url: model?.query_type === QueryType.Visual ? `/audiences/${model?.id}` : `/models/${model?.id}`,
      };
    case "source":
      return {
        icon: PencilIcon,
        label: "Edit source",
        url: `/sources/${source?.id}`,
      };
    case "sync":
      return {
        icon: PencilIcon,
        label: "Edit sync",
        url: `/syncs/${sync?.id}`,
      };
    case "external":
      return {
        icon: GlobeAltIcon,
        label: link?.label,
        url: link?.url,
      };
    case "internal":
      return {
        icon: BookOpenIcon,
        label: `Read docs ${origin && `for ${origin}`}`,
        url: link?.url,
      };
    default:
      return null;
  }
}

export const ErrorOriginInfoModal: FC<Readonly<SyncErrorProps | RowErrorProps>> = (props) => {
  const { isOpen, onClose, sync } = props;
  const [overflowActive, setOverflowActive] = useState(false);
  const [showMore, setShowMore] = useState(false);

  const overflowingText = useRef(null);
  useEffect(() => {
    if (checkOverflow(overflowingText.current)) {
      setOverflowActive(true);
    } else {
      setOverflowActive(false);
    }
  }, [overflowActive, isOpen]);

  const isSyncError = props.errorType === "sync";
  const errorInfo = props[isSyncError ? "syncRequestError" : "rowLevelError"];
  const errorCodeDetails: ErrorCodeDetails | null | undefined = errorInfo?.errorCodeDetail;

  const destination = sync?.destination;
  const model = sync?.segment;
  const source = model?.connection;

  const troubleshootingMessage =
    errorCodeDetails?.userFriendlyMessage || errorInfo?.userFriendlyMessage || errorInfo?.userFacingMessage;
  const rawMessage = errorInfo?.message || "No error message provided.";

  const {
    origin,
    rawErrorHeader,
    title: errorInfoTitle,
    uncategorizedErrorDocsLabel,
    uncategorizedErrorDocsLink,
  } = getDestinationErrorInfo(destination, props.originInfo, source);
  const title = isSyncError ? errorInfoTitle : `Row error`;
  const displayUncategorizedErrorDocs = !errorCodeDetails && uncategorizedErrorDocsLabel;
  const isInternalError = isInternalErrorOriginInfo(props.originInfo);
  const overflowLabel = showMore ? `See less...` : `See more...`;

  const orderedTypes = ["internal", "external", "sync", "model", "source", "destination"];
  // We disregard the order of links in Sanity, and instead use the order defined in orderedTypes
  const orderedLinks = errorCodeDetails?.links?.sort((a, b) => {
    const aIndex = orderedTypes.indexOf(a.type);
    const bIndex = orderedTypes.indexOf(b.type);
    return aIndex - bIndex;
  });

  return (
    <InfoModal height={height} isOpen={isOpen} onClose={onClose} p={0} title={title} width={width}>
      <Column flex={1} p={6} sx={{ overflow: "scroll" }}>
        {!isInternalError && (
          <Row borderBottom="1px solid" borderColor={borderColor} justifyContent="center" pb={4}>
            <ErrorSyncName destination={destination} originInfo={props.originInfo} source={source} />
          </Row>
        )}
        <Column borderBottom="1px solid" borderColor={borderColor} pb={6} pt={isInternalError ? 0 : 6}>
          <Text fontWeight="medium" size="lg">
            {rawErrorHeader}
          </Text>
          <Text mt={2}>
            <Code sx={{ bg: "gray.200", m: 0 }}>
              <Row
                ref={overflowingText}
                sx={{
                  display: "-webkit-box",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  WebkitBoxOrient: "vertical",
                  WebkitLineClamp: showMore ? "none" : "2",
                }}
              >
                <Markdown>{rawMessage}</Markdown>
              </Row>
              {overflowActive && (
                <Box display="inline-block" sx={{ fontFamily: "inter" }} onClick={() => setShowMore(!showMore)}>
                  <Link href="">
                    <Markdown>{overflowLabel}</Markdown>
                  </Link>
                </Box>
              )}
            </Code>
          </Text>
        </Column>
        {troubleshootingMessage && (
          <Column borderBottom="1px solid" borderColor={borderColor} py={6}>
            <Text fontWeight="medium" size="lg">
              Troubleshooting
            </Text>
            <Text mt={2}>
              <Markdown>{troubleshootingMessage}</Markdown>
            </Text>
            {orderedLinks && (
              <Row pt={2} sx={{ flexWrap: "wrap" }}>
                {orderedLinks?.map((link, idx) => {
                  const props = generateLinkProps(link, origin, destination, model, source, sync);
                  if (props) {
                    const { icon, label, url } = props;
                    return (
                      <Row key={idx} pr={2} pt={2}>
                        <Link href={url ?? "#"}>
                          <Button icon={icon} size="md" variant="secondary">
                            {label}
                          </Button>
                        </Link>
                      </Row>
                    );
                  }
                  return null;
                })}
              </Row>
            )}
          </Column>
        )}
        <Column py={6}>
          <Text fontWeight="medium" size="lg">
            Need more help?
          </Text>
          <Text mt={2}>
            If you feel stuck, please reach out! We want to make sure you have all the help you need. Our team is available via
            chat to help you troubleshoot this error.
          </Text>
          <Row pt={4}>
            <Box
              onClick={() =>
                window["Intercom"]?.(
                  "showNewMessage",
                  `I'm experiencing an issue with syncing from ${source?.definition?.name || "source"} to ${
                    destination?.definition?.name || "destination"
                  } and could use some assistance. The error message I'm receiving is: "${truncate(rawMessage, {
                    length: 355,
                  })}." Here's a link to the sync: ${window.location.href}.`,
                )
              }
            >
              <Button icon={ChatBubbleOvalLeftIcon} size="md" variant="secondary" onClick={() => {}}>
                Chat with support
              </Button>
            </Box>
            {displayUncategorizedErrorDocs && (
              <Row pl={2}>
                <Link href={uncategorizedErrorDocsLink}>
                  <Button icon={BookOpenIcon} size="md" variant="secondary">
                    {uncategorizedErrorDocsLabel}
                  </Button>
                </Link>
              </Row>
            )}
          </Row>
        </Column>
      </Column>
      <Row align="center" borderTop="1px solid" borderColor={borderColor} flexShrink={0} height="72px" justify="right" p={6}>
        <Button size="lg" variant="primary" onClick={onClose}>
          <Text color="white" fontWeight="semibold" size="lg">
            Close
          </Text>
        </Button>
      </Row>
    </InfoModal>
  );
};
