import { FC, useEffect, useState } from "react";

import { Alert, Button, LinkButton } from "@hightouchio/ui";

import { useUser } from "src/contexts/user-context";
import { ModelsCountQuery, ModelsCountQueryVariables, SegmentsBoolExp, useModelsCountQuery } from "src/graphql";
import { useEntitlements } from "src/hooks/use-entitlement";
import * as analytics from "src/lib/analytics";
import { getQueryWithOpts } from "src/utils/query-with-opts";

import { DuplicateKeyModal } from "./duplicate-key-modal";

const useFastModelsCountQuery = getQueryWithOpts<ModelsCountQuery, ModelsCountQueryVariables>(useModelsCountQuery, {
  useFastEndpoint: true,
});

export const DuplicateKeyBanner: FC = () => {
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const { data: entitlementsData } = useEntitlements(true);
  const { workspace } = useUser();
  const isFreePlan = !entitlementsData?.organization?.plan?.name;
  const cutOffDate = isFreePlan ? "August 21" : "August 28";

  const hasuraDuplicateKeyFilter: SegmentsBoolExp = {
    last_run_duplicate_primary_keys: { _gt: 0 },
    destination_instances: {
      last_run_planner_type: { _neq: "all", _is_null: false },
    },
  };

  const modelsQuery = useFastModelsCountQuery(
    {
      filters: { ...hasuraDuplicateKeyFilter, is_schema: { _eq: false } },
    },
    {
      notifyOnChangeProps: "tracked",
      keepPreviousData: true,
    },
  );

  const filteredModelCount = modelsQuery.data?.segments_aggregate?.aggregate?.count ?? 0;

  useEffect(() => {
    if (filteredModelCount > 0) {
      analytics.track("Duplicate PK Banner Shown", {
        workspace_id: workspace?.id,
      });
    }
  }, [filteredModelCount]);

  if (filteredModelCount <= 0) {
    return null;
  }

  return (
    <>
      <Alert
        variant="banner"
        type="warning"
        justify="center"
        title="This workspace contains models with duplicate primary keys."
        message="Please fix these models as soon as possible."
        actions={
          <>
            <Button
              onClick={() => {
                setModalOpen(true);
                analytics.track("Duplicate PK Modal Opened", {
                  workspace_id: workspace?.id,
                });
              }}
            >
              Learn more
            </Button>
            <LinkButton
              variant="warning"
              href="/models?duplicate_keys=true"
              onClick={() => {
                analytics.track("Duplicate PK Banner View Affected Models Clicked", {
                  workspace_id: workspace?.id,
                });
              }}
            >
              View affected models
            </LinkButton>
          </>
        }
      />

      <DuplicateKeyModal cutOffDate={cutOffDate} isOpen={modalOpen} onClose={() => setModalOpen(false)} />
    </>
  );
};
