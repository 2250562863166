import { FC, ReactNode } from "react";

import { Row, Text, TextProps } from "@hightouchio/ui";

export const MetadataBar: FC<Readonly<{ children: ReactNode }>> = ({ children }) => {
  return (
    <Row
      gap={8}
      mb={6}
      mt={2}
      flexShrink={0}
      sx={{
        "& > div": {
          ":not(:last-of-type)": {
            borderRight: "1px",
            pr: 8,
            borderColor: "base.border",
          },
          minHeight: "40px",
          gap: 2,
          justifyContent: "space-between",
          whiteSpace: "nowrap",
        },
      }}
    >
      {children}
    </Row>
  );
};

export const MetadataLabel: FC<Readonly<{ children: ReactNode } & TextProps>> = ({ children, ...props }) => {
  return (
    <Text {...props} fontWeight="semibold" size="sm" color="text.tertiary" textTransform="uppercase">
      {children}
    </Text>
  );
};
