import { FC } from "react";

import { Column, CodeSnippet, Row, TextInput, ClipboardButton, FormField } from "@hightouchio/ui";
import { capitalize } from "lodash";
import { useOutletContext } from "react-router-dom";
import { UnreachableCaseError } from "ts-essentials";

import { EventSourceType } from "src/events/types";

import { OutletContext } from ".";

export const Setup: FC = () => {
  const { source } = useOutletContext<OutletContext>();

  return (
    <Column gap={6}>
      <CodeSnippet
        label={`Setup Hightouch ${capitalize(source.type)} SDK`}
        code={getSnippet(source.type as EventSourceType, source.write_key)}
      />
      {source.write_key && (
        <FormField label="Write key" description="The write key is already included in the snippet above.">
          <Row align="center" gap={2}>
            <TextInput width="lg" isReadOnly value={source.write_key} />
            <ClipboardButton text={source.write_key} />
          </Row>
        </FormField>
      )}
    </Column>
  );
};

const getSnippet = (type: EventSourceType, writeKey?: string | null) => {
  switch (type) {
    case EventSourceType.JAVASCRIPT:
    case EventSourceType.HTTP:
    case EventSourceType.NODE:
    case EventSourceType.PYTHON:
    case EventSourceType.IOS:
    case EventSourceType.ANDROID:
      return `<script type = "text/javascript">
  !function(){var e=window.hightouchevents=window.hightouchevents||[];e.methods=["load","page","track","identify","alias","group","ready","reset","getAnonymousId","setAnonymousId","getUserId","getUserTraits","getGroupId","getGroupTraits","startSession","endSession"],e.factory=function(t){return function(){e.push([t].concat(Array.prototype.slice.call(arguments)))}};for(var t=0;t<e.methods.length;t++){var r=e.methods[t];e[r]=e.factory(r)}e.loadJS=function(e,t){var r=document.createElement("script");r.type="text/javascript",r.async=!0,r.src="https://cdn.hightouch.com/v1/events.min.js";var a=document.getElementsByTagName("script")[0];a.parentNode.insertBefore(r,a)},e.loadJS(),
  e.load(${writeKey},"EVENTS_URL"),
  e.page()}();
</script>`;
    default:
      throw new UnreachableCaseError(type);
  }
};
