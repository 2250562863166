import { FC, useState } from "react";

import {
  Alert,
  Button,
  Column,
  FormField,
  Paragraph,
  Radio,
  RadioGroup,
  Select,
  TextInput,
  useToast,
  Text,
  Row,
} from "@hightouchio/ui";
import { Image } from "theme-ui";

import { useFolders } from "src/components/folders/use-folders";
import { ResourcePermissionGrant, useCreateFolderMutation, useUpdateModelsMutation } from "src/graphql";
import useHasPermission from "src/hooks/use-has-permission";
import { Modal } from "src/ui/modal";

import DisabledMove from "./disabled-move.svg";

interface Folder {
  id: string;
  name: string;
  parent_id: string | null;
}

interface Props {
  modelIds: string[];
  folder: Folder | null;
  onClose: () => void;
  viewType: "syncs" | "models";
  folderType: "models" | "audiences";
  disabled?: boolean;
}

export const MoveFolder: FC<Readonly<Props>> = ({ onClose, modelIds, folder, viewType, folderType, disabled }) => {
  const {
    state: { flattenedFolders },
  } = useFolders({
    folderType,
    viewType,
  });
  const { hasPermission: userCanCreateFolders } = useHasPermission([
    { resource: "workspace", grants: [ResourcePermissionGrant.Update] },
  ]);

  const { mutateAsync: updateModels } = useUpdateModelsMutation();
  const { mutateAsync: addFolder } = useCreateFolderMutation();
  const { toast } = useToast();
  const [existingFolder, setExistingFolder] = useState(true);
  const [newFolderName, setNewFolderName] = useState("");
  const [loading, setLoading] = useState(false);

  const [selectedFolder, setSelectedFolder] = useState(folder?.id);

  const folderOptions = existingFolder ? [{ id: "_root" }, ...(flattenedFolders || [])] : flattenedFolders;

  const onClick = async () => {
    if (disabled) {
      onClose();
      return;
    }

    try {
      setLoading(true);
      if (existingFolder) {
        await updateModels({
          ids: modelIds,
          input: {
            folder_id: selectedFolder === "_root" ? null : selectedFolder,
          },
        });
      } else if (!userCanCreateFolders) {
        toast({
          id: "folder-toast",
          title: `You do not have permission to perform this action`,
          variant: "error",
        });
      } else {
        const res = await addFolder({
          name: newFolderName,
          parent_id: selectedFolder,
          type: folderType,
        });
        await updateModels({
          ids: modelIds,
          input: {
            folder_id: res.insert_folders_one?.id,
          },
        });
      }
      onClose();
      toast({
        id: "folder-toast",
        title: `Your resource has been moved to the new folder`,
        variant: "success",
      });
    } catch (err) {
      toast({
        id: "folder-toast",
        title: `Failed to move your resource`,
        variant: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      isOpen
      footer={
        <>
          {!disabled && (
            <Button variant="secondary" onClick={onClose}>
              Cancel
            </Button>
          )}
          <Button
            isDisabled={!disabled && !selectedFolder && !newFolderName}
            isLoading={loading}
            variant="primary"
            onClick={onClick}
          >
            {disabled ? "Got it" : "Move"}
          </Button>
        </>
      }
      sx={{ width: "500px" }}
      title="Move to folder"
      onClose={onClose}
    >
      {disabled ? (
        <Column gap="6">
          <Paragraph>
            Your selection contains a combination of model syncs and audience syncs. Hightouch organizes models and audiences
            into separate parent folders by default. Moving both types of syncs into a single folder is not allowed. Try
            limiting your selection.
          </Paragraph>
          <Image src={DisabledMove} />
        </Column>
      ) : (
        <>
          <Column gap="4">
            <RadioGroup
              value={existingFolder ? "existing" : "new"}
              onChange={(value) => setExistingFolder(value === "existing")}
            >
              <Radio label="Existing folder" value="existing" />
              <Radio isDisabled={!userCanCreateFolders} label="New folder" value="new" />
            </RadioGroup>
            {!existingFolder && (
              <FormField isRequired label="Folder name">
                <TextInput
                  placeholder="Enter a folder name..."
                  value={newFolderName}
                  onChange={(e) => setNewFolderName(e.target.value)}
                />
              </FormField>
            )}
            <FormField isRequired={existingFolder} label={existingFolder ? "Folder" : "Parent folder"}>
              <Select
                isClearable
                optionLabel={(folder) => {
                  const path = (folder as any).path;
                  if (path) {
                    return path.replaceAll("/", " / ");
                  } else {
                    return `All ${folderType} (root)`;
                  }
                }}
                optionValue={(folder) => folder.id}
                options={folderOptions || []}
                placeholder="Select a folder..."
                value={selectedFolder}
                width="lg"
                onChange={(folder) => {
                  setSelectedFolder(folder);
                }}
              />
            </FormField>
          </Column>
          {viewType === "syncs" && (
            <Alert
              message={
                <Column>
                  <Row>
                    <Text>1. Moves associated {folderType} to the folder</Text>
                  </Row>
                  <Row>
                    <Text>2. Move other syncs associated with the same {folderType} to the folder.</Text>
                  </Row>
                </Column>
              }
              mt="8"
              title="Moving syncs to a folder also:"
              type="info"
            />
          )}
        </>
      )}
    </Modal>
  );
};
