import { FC, ReactNode } from "react";

import { Box, Column } from "@hightouchio/ui";

import { useHeaderHeight } from "src/contexts/header-height-context";
import { usePersistedState } from "src/hooks/use-persisted-state";
import { ChevronLeftIcon, ChevronRightIcon } from "src/ui/icons";

export enum SidebarKey {
  Analytics = "analytics-sidebar-open",
  General = "sidebar-open", // non-page-specific
}

export const PageSidebar: FC<Readonly<{ children: ReactNode; stateKey?: string; width?: string }>> = ({
  children,
  stateKey = SidebarKey.General,
  width = "280px",
}) => {
  const { value: open, set: setOpen } = usePersistedState<boolean>(stateKey, true);
  const { headerHeight } = useHeaderHeight();

  return (
    <Column
      pos="sticky"
      top={`${headerHeight}px`}
      bg="gray.50"
      flexShrink={0}
      gap={4}
      height={`calc(100vh - ${headerHeight}px)`}
      overscrollBehavior="none"
      py={4}
      ml={open ? 0 : `calc(-1 * ${width} + 24px)`}
      width={width}
      borderRight="1px solid"
      borderColor="base.border"
      px={open ? 0 : 6}
      zIndex={99}
    >
      <Box pos="absolute" top="16px" right="-16px">
        <Box
          pos="relative"
          as="button"
          cursor="pointer"
          display="flex"
          width="32px"
          height="32px"
          alignItems="center"
          justifyContent="center"
          flexShrink={0}
          bg="white"
          border="1px solid"
          borderColor="base.border"
          borderRadius="50%"
          onClick={() => setOpen(!open)}
          _focus={{
            outline: "none",
          }}
          _hover={{
            bg: "base.background",
          }}
          transition="background 200ms ease-in-out"
          sx={{
            svg: { fill: "forest.700" },
          }}
        >
          {open ? <ChevronLeftIcon width="18px" /> : <ChevronRightIcon width="18px" />}
        </Box>
      </Box>

      {children}
    </Column>
  );
};
