import { FC, useEffect, useState } from "react";

import { useToast, Row, Column, ButtonGroup, Button } from "@hightouchio/ui";
import { useFlags } from "launchdarkly-react-client-sdk";
import { isEqual } from "lodash";
import { useOutletContext } from "react-router-dom";

import { ActionBar } from "src/components/action-bar";
import { SidebarForm } from "src/components/page";
import { ScheduleManager } from "src/components/schedule";
import { Schedule, ScheduleType } from "src/components/schedule/types";
import { useUser } from "src/contexts/user-context";
import { useUpdateSequenceMutation } from "src/graphql";
import * as analytics from "src/lib/analytics";
import { isScheduleComplete } from "src/utils/schedule";

import { SequenceContext } from ".";

export const SequenceSchedule: FC = () => {
  const { sequence } = useOutletContext<SequenceContext>();
  const [schedule, setSchedule] = useState<Schedule | null>(sequence.schedule ?? { type: ScheduleType.MANUAL });
  const { toast } = useToast();
  const flags = useFlags();
  const { workspace } = useUser();
  const updateMutation = useUpdateSequenceMutation();

  const isDirty = !isEqual(schedule, sequence.schedule ?? { type: ScheduleType.MANUAL });

  const reset = () => {
    console.log(sequence);
    setSchedule(sequence.schedule ?? { type: ScheduleType.MANUAL });
  };

  const update = async () => {
    await updateMutation.mutateAsync({
      id: sequence.id,
      object: {
        schedule: schedule?.type === "manual" ? null : schedule,
      },
    });

    analytics.track("Sequence Edited", {
      sync_sequence_id: sequence.id,
    });

    toast({
      id: "update-sequence",
      title: "Sequence schedule updated",
      variant: "success",
    });
  };

  useEffect(() => {
    reset();
  }, [sequence]);

  return (
    <Row width="100%">
      <Column flex={1}>
        <ScheduleManager resource="sequence" schedule={schedule} setSchedule={setSchedule} />
        <ActionBar>
          <ButtonGroup>
            <Button
              variant="primary"
              isDisabled={!isScheduleComplete({ schedule, flags, workspace }) || !isDirty}
              isLoading={updateMutation.isLoading}
              onClick={update}
            >
              Save changes
            </Button>
            <Button isDisabled={!isDirty} onClick={reset}>
              Discard changes
            </Button>
          </ButtonGroup>
        </ActionBar>
      </Column>
      <SidebarForm
        hideInviteTeammate
        hideSendMessage
        docsUrl={`${import.meta.env.VITE_DOCS_URL}/syncs/schedule-sync-ui/`}
        invite="If you need help setting up this sequence"
        name="scheduling syncs"
      />
    </Row>
  );
};
