import { useEffect, useState } from "react";

import {
  Box,
  Button,
  ChevronLeftIcon,
  ChevronRightIcon,
  Column,
  IconButton,
  Link,
  Paragraph,
  ChakraModal,
  ChakraModalBody,
  ChakraModalContent,
  ChakraModalFooter,
  ChakraModalHeader,
  ChakraModalOverlay,
} from "@hightouchio/ui";

export interface DialogProps {
  pages: { src: string; altText: string; description: string }[];
  docsLink?: string;
  title: string;
  closeOnOverlayClick?: boolean;
  confirmButtonText: string;
  featureKey: string;
}

export function NewFeatureDialog({
  closeOnOverlayClick = true,
  confirmButtonText,
  featureKey,
  docsLink,
  pages,
  title,
}: DialogProps) {
  const [show, setShow] = useState(localStorage.getItem(featureKey) === "false" ? false : true);

  const confirm = () => {
    setShow(false);
    localStorage.setItem(featureKey, "false");
  };

  const [visiblePageIndex, setVisiblePageIndex] = useState(0);

  const previousPage = () => {
    setVisiblePageIndex((prevPage) => {
      if (prevPage > 0) {
        return prevPage - 1;
      }

      return prevPage;
    });
  };

  const nextPage = () => {
    setVisiblePageIndex((prevPage) => {
      if (prevPage < pages.length - 1) {
        return prevPage + 1;
      }

      return prevPage;
    });
  };

  // force react to preload all the images
  useEffect(() => {
    for (const { src } of pages) {
      const img = new Image();
      img.src = src;
    }
  }, []);

  return (
    <ChakraModal isOpen={show} closeOnOverlayClick={closeOnOverlayClick} onClose={confirm}>
      <ChakraModalOverlay>
        <ChakraModalContent
          w="3xl"
          maxW="3xl"
          motionProps={{
            initial: {
              scale: 0.9,
              opacity: 0,
            },
            variants: {
              enter: {
                scale: 1,
                opacity: 1,
                transition: {
                  type: "spring",
                  damping: 30,
                  stiffness: 1000,
                },
              },
              exit: {
                scale: 0.9,
                opacity: 0,
                transition: {
                  type: "spring",
                  duration: 0.15,
                },
              },
            },
          }}
        >
          <Column flex="1" minHeight={0} gap={4}>
            <ChakraModalHeader>{title}</ChakraModalHeader>
            <ChakraModalBody>
              {pages.map(({ src, description, altText }, index) => {
                if (index !== visiblePageIndex) {
                  return null;
                }

                return (
                  <Column key={index} gap={4} alignItems="center">
                    <Box
                      display="grid"
                      gridTemplateColumns="40px 1fr 40px"
                      minHeight="250px"
                      alignItems="center"
                      gap={2}
                      sx={{
                        button: {
                          border: "none",
                        },
                      }}
                    >
                      <IconButton
                        aria-label="Go to previous page."
                        icon={ChevronLeftIcon}
                        isDisabled={visiblePageIndex === 0}
                        size="lg"
                        onClick={previousPage}
                      />

                      <Column height="400px" width="100%" p={4} alignItems="center" justifyContent="center">
                        <Box as="img" alt={altText} src={src} />
                      </Column>

                      <IconButton
                        aria-label="Go to next page."
                        icon={ChevronRightIcon}
                        isDisabled={visiblePageIndex >= pages.length - 1}
                        size="lg"
                        onClick={nextPage}
                      />
                    </Box>
                    <Paragraph size="lg" color="text.secondary">
                      {description}
                    </Paragraph>
                  </Column>
                );
              })}
            </ChakraModalBody>
          </Column>

          <ChakraModalFooter mt={4} alignItems="center" display="flex" flexDirection="row" justifyContent="center" gap={4}>
            <Button variant="primary" onClick={confirm} size="lg">
              {confirmButtonText}
            </Button>
            {docsLink && (
              <Link href={docsLink} target="_blank">
                Read the docs
              </Link>
            )}
          </ChakraModalFooter>
        </ChakraModalContent>
      </ChakraModalOverlay>
    </ChakraModal>
  );
}
