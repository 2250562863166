import { FC } from "react";

import { Box, Row, Column, Text } from "@hightouchio/ui";
import { useOutletContext } from "react-router-dom";

import { useIdentityResolutionRunsQuery } from "src/graphql";
import { IdentityResolutionStatusBadge } from "src/pages/identity-resolution/status-badge";
import { Table, TableColumn } from "src/ui/table";
import { TextWithTooltip } from "src/ui/text";
import { commaNumber } from "src/utils/numbers";
import * as time from "src/utils/time";

import { OutletContext } from ".";

const columns: TableColumn[] = [
  {
    name: "Status",
    cell: ({ status }) => {
      return <IdentityResolutionStatusBadge status={status} />;
    },
  },
  {
    name: "Started",
    cell: ({ started_at, finished_at }) => {
      if (!started_at) {
        return <Text fontWeight="medium">--</Text>;
      }
      const duration = time.diff(started_at, finished_at ?? new Date().toUTCString());
      return (
        <Column>
          <TextWithTooltip message={time.formatDatetime(started_at) ?? ""} fontWeight="medium" mb="2px">
            {time.formatDatetime(started_at)}
          </TextWithTooltip>
          <Text size="sm" color="text.secondary">
            Duration: {duration}
          </Text>
        </Column>
      );
    },
  },
  {
    name: "Profiles",
    cell: ({ stats }) => {
      return <ProfileStats stats={stats} />;
    },
  },
  {
    name: "Events",
    cell: ({ stats }) => {
      return <EventStats stats={stats} />;
    },
  },
];

export const Runs: FC = () => {
  const { graph } = useOutletContext<OutletContext>();

  const {
    data: runs,
    isLoading,
    error,
  } = useIdentityResolutionRunsQuery(
    {
      filter: { idr_id: { _eq: graph.id } },
    },
    {
      refetchInterval: 5000,
      select: (data) => data.idr_runs,
    },
  );

  return (
    <Table
      columns={columns}
      data={runs}
      loading={isLoading}
      error={Boolean(error)}
      placeholder={{
        title: "No runs",
        error: "Runs failed to load, please try again.",
      }}
    />
  );
};

export const ProfileStats = ({ stats }) => {
  return (
    <Row gap={4}>
      <Column align="flex-end">
        <Text fontWeight="medium">{stats?.profiles ? commaNumber(stats.profiles.resolved) : "--"}</Text>
        <Box fontSize="sm" color="text.secondary" textAlign="right">
          Resolved
        </Box>
      </Column>
      <Column align="flex-end">
        <Text fontWeight="medium">{stats?.profiles ? commaNumber(stats.profiles.total) : "--"}</Text>
        <Box fontSize="sm" color="text.secondary" textAlign="right">
          Total
        </Box>
      </Column>
    </Row>
  );
};

export const EventStats = ({ stats }) => {
  return (
    <Row gap={4}>
      <Column align="flex-end">
        <Text fontWeight="medium">{stats?.events ? commaNumber(stats.events.resolved) : "--"} </Text>
        <Box fontSize="sm" color="text.secondary" textAlign="right">
          Resolved
        </Box>
      </Column>
      <Column align="flex-end">
        <Text fontWeight="medium">{stats?.events ? commaNumber(stats.events.total) : "--"}</Text>
        <Box fontSize="sm" color="text.secondary" textAlign="right">
          Total
        </Box>
      </Column>
    </Row>
  );
};
