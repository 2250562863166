import { ArrowPathIcon } from "@heroicons/react/24/outline";
import { useToast, Button, ButtonGroup, IconButton, Row } from "@hightouchio/ui";

import { useSlackChannelsQuery, useSlackCredentialsQuery } from "src/graphql";
import { Field } from "src/ui/field";
import { TextArea } from "src/ui/input";
import { Select } from "src/ui/select";

import { SlackAlert, SlackAlertConfig } from "./types";

export const SlackForm = ({
  alert,
  setAlert,
  config,
  setConfig,
}: {
  alert: SlackAlert;
  setAlert: (alert: SlackAlert) => void;
  config: SlackAlertConfig;
  setConfig: (config: SlackAlertConfig) => void;
}) => {
  const { toast } = useToast();
  const {
    data: slackChannelsData,
    refetch: refetchSlackChannels,
    isRefetching: slackChannelsRefetching,
    isLoading: slackChannelsLoading,
    error: slackChannelsError,
  } = useSlackChannelsQuery(
    {
      id: Number(alert?.slack_credential_id),
    },
    { enabled: Boolean(alert?.slack_credential_id), select: (data) => data.listSlackChannelsByCredentials },
  );
  const {
    data: slackCredentialsData,
    isLoading: slackCredentialsLoading,
    error: slackCredentialsError,
  } = useSlackCredentialsQuery();

  return (
    <>
      <Field error={slackCredentialsError?.message} label="Credentials">
        <Select
          isLoading={slackCredentialsLoading}
          options={slackCredentialsData?.slack_credentials?.map((credential) => ({
            label: `Default Slack Account (${credential.id})`,
            value: credential.id,
          }))}
          placeholder="Select a Slack account..."
          value={alert?.slack_credential_id}
          width="360px"
          onChange={(selected) => {
            setAlert({ ...alert, slack_credential_id: selected?.value });
          }}
        />
      </Field>
      <Field
        description="The Hightouch app must be explicitly invited to a channel for it to appear on here."
        error={slackChannelsError?.message}
        label="Channel"
      >
        <Row gap={2} width="360px">
          <Select
            isLoading={slackChannelsLoading || slackChannelsRefetching}
            options={slackChannelsData?.map((channel) => ({
              label: channel.name,
              value: channel.id,
            }))}
            placeholder="Select a Slack channel..."
            value={config?.channelId}
            disabled={!alert?.slack_credential_id}
            onChange={(selected) => {
              setConfig({ ...config, channelId: selected?.value });
            }}
          />
          <IconButton
            variant="secondary"
            isDisabled={!alert?.slack_credential_id}
            aria-label="refetch channels"
            icon={ArrowPathIcon}
            onClick={() => refetchSlackChannels()}
          />
        </Row>
      </Field>
      <Field optional label="Custom fatal error blocks">
        <TextArea
          placeholder={`Ex: [{"type": "section", "text": {"type": "mrkdwn", "text": "Sync {{ id }} failed with error: {{ error }}"}}]`}
          rows={5}
          value={config?.fatalErrorBlocks || ""}
          onValue={(fatalErrorBlocks) => setConfig({ ...config, fatalErrorBlocks })}
        />
      </Field>
      <Field optional label="Custom row error blocks">
        <TextArea
          placeholder={`Ex: [{"type": "section", "text": {"type": "mrkdwn", "text": "Sync {{ id }} failed with error: {{ error }}"}}]`}
          rows={5}
          value={config?.rowErrorBlocks || ""}
          onValue={(rowErrorBlocks) => setConfig({ ...config, rowErrorBlocks })}
        />
      </Field>
      <ButtonGroup>
        <Button
          onClick={() => {
            const rowErrorBlocks = config?.rowErrorBlocks
              ? beautifyJSON(config?.rowErrorBlocks, () => {
                  toast({
                    id: "beautify-json",
                    title: "The custom row error blocks is not a valid JSON",
                    variant: "warning",
                  });
                })
              : undefined;
            const fatalErrorBlocks = config?.fatalErrorBlocks
              ? beautifyJSON(config?.fatalErrorBlocks, () => {
                  toast({
                    id: "beautify-json",
                    title: "The custom fatal error blocks is not a valid JSON",
                    variant: "warning",
                  });
                })
              : undefined;
            setConfig({ ...config, fatalErrorBlocks, rowErrorBlocks });
          }}
        >
          Beautify JSON
        </Button>
        <Button
          onClick={() => {
            try {
              const rowErrorJSON = config?.rowErrorBlocks ? JSON.parse(config?.rowErrorBlocks) : [];
              const fatalErrorJSON = config?.fatalErrorBlocks ? JSON.parse(config?.fatalErrorBlocks) : [];

              if (!(rowErrorJSON instanceof Array) || !(fatalErrorJSON instanceof Array)) {
                toast({
                  id: "beautify-json",
                  title: "The custom fatal error blocks and custom row error blocks must be an array",
                  variant: "warning",
                });

                return;
              }

              const blockString = JSON.stringify({ blocks: [...rowErrorJSON, ...fatalErrorJSON] });
              const urlString = `https://app.slack.com/block-kit-builder#${encodeURIComponent(blockString)}`;
              window.open(urlString, "_blank");
            } catch (e) {
              toast({
                id: "beautify-json",
                title: "The custom fatal error blocks and custom row error blocks are not valid JSON",
                variant: "warning",
              });
            }
          }}
        >
          Preview Block Kit
        </Button>
      </ButtonGroup>
    </>
  );
};

const beautifyJSON = (body, onError: null | (() => void) = null) => {
  let obj;
  try {
    obj = JSON.parse(body);
  } catch (err) {
    if (onError) {
      onError();
    }
    return body;
  }
  return JSON.stringify(obj, null, 4);
};

export const slackValidator = (alert: SlackAlert): boolean => {
  const config = alert?.config;

  let jsonError;

  try {
    let obj;
    if (config?.fatalErrorBlocks) {
      obj = JSON.parse(config.fatalErrorBlocks);
      if (!(obj instanceof Array)) jsonError = true;
    }
    if (config?.rowErrorBlocks) {
      JSON.parse(config.rowErrorBlocks);
      if (!(obj instanceof Array)) jsonError = true;
    }
  } catch (err) {
    jsonError = true;
  }

  return Boolean(alert?.slack_credential_id && config?.channelId) && !jsonError;
};
