import { Children, FC, useState, ReactNode } from "react";

import { Column, Text, Row, ChevronUpIcon, DragIcon, ChevronDownIcon } from "@hightouchio/ui";
import { Reorder as Framer, useDragControls } from "framer-motion";
import { get } from "lodash";

import { swap } from "src/utils/array";

type Props<T> = {
  items: T[];
  id?: string;
  children: ReactNode;
  onChange: (items: T[]) => void;
};

export function Reorder<T>({ children, items, onChange, id = "id" }: Props<T>) {
  if (!Children.count(children)) {
    return null;
  }

  return (
    <Column gap={4}>
      <Text color="text.secondary" fontWeight="medium">
        Start
      </Text>
      <Framer.Group
        axis="y"
        layoutScroll
        style={{
          listStyle: "none",
          paddingLeft: 0,
          display: "flex",
          flexDirection: "column",
          gap: "16px",
        }}
        values={items}
        onReorder={onChange}
      >
        {Children.map(children, (child, index) => {
          const item = items[index];
          if (item) {
            return (
              <Item
                onClick={(child as any).props.onClick}
                item={item}
                key={get(item, id)}
                isUpDisabled={index === 0}
                isDownDisabled={index === items.length - 1}
                onUp={() => {
                  onChange(swap(items, index, index - 1));
                }}
                onDown={() => {
                  onChange(swap(items, index, index + 1));
                }}
              >
                {child}
              </Item>
            );
          }
          return null;
        })}
      </Framer.Group>
      <Text color="text.secondary" fontWeight="medium">
        End
      </Text>
    </Column>
  );
}

const Item: FC<
  Readonly<{
    children: ReactNode;
    item: any;
    isUpDisabled: boolean;
    isDownDisabled: boolean;
    onUp: () => void;
    onDown: () => void;
    onClick: (() => void) | undefined;
  }>
> = ({ children, item, isUpDisabled, isDownDisabled, onUp, onDown, onClick }) => {
  const [dragging, setDragging] = useState(false);
  const controls = useDragControls();

  return (
    <Framer.Item
      dragListener={false}
      dragControls={controls}
      value={item}
      style={{
        display: "flex",
        border: "1px solid var(--chakra-colors-base-border)",
        borderRadius: "var(--chakra-radii-md)",
        overflow: "hidden",
        backgroundColor: "rgba(255,255,255,1)",
        userSelect: "none",
      }}
      onDragEnd={() => {
        setDragging(false);
      }}
      onDragStart={() => {
        setDragging(true);
      }}
      whileDrag={{
        backgroundColor: "rgba(255,255,255,1)",
        boxShadow:
          "0px 12px 16px rgba(16, 24, 40, 0.16), 0px 8px 16px rgba(16, 24, 40, 0.16), 0px 0px 12px rgba(16, 24, 40, 0.08)",
      }}
    >
      <Column
        p={1}
        bg="base.lightBackground"
        borderRight="1px"
        borderColor="base.border"
        justify="center"
        fontSize="3xl"
        color="text.placeholder"
      >
        <Row
          as="button"
          color={isUpDisabled ? "base.border" : "inherit"}
          disabled={isUpDisabled}
          cursor={isUpDisabled ? "default" : "pointer"}
          onClick={onUp}
        >
          <ChevronUpIcon />
        </Row>
        <Row
          cursor={dragging ? "grabbing" : "grab"}
          role="handle"
          color="base.border"
          className="handle"
          onPointerDown={(e) => controls.start(e)}
        >
          <DragIcon />
        </Row>
        <Row
          as="button"
          color={isDownDisabled ? "base.border" : "inherit"}
          disabled={isDownDisabled}
          cursor={isDownDisabled ? "default" : "pointer"}
          onClick={onDown}
        >
          <ChevronDownIcon />
        </Row>
      </Column>
      <Row
        p={4}
        flex={1}
        onClick={onClick}
        cursor={onClick ? "pointer" : "default"}
        _hover={onClick ? { bg: "gray.50" } : {}}
        transition="150ms background-color"
      >
        {children}
      </Row>
    </Framer.Item>
  );
};
