import { FC, useState } from "react";

import {
  Box,
  Text,
  Column,
  Row,
  MenuActionsButton,
  MenuList,
  MenuItem,
  Tabs,
  TabList,
  Tab,
  Menu,
  DeleteIcon,
  ArrowRightIcon,
} from "@hightouchio/ui";
import { Routes, Route, useParams, Outlet, useLocation, useNavigate, useOutletContext, Navigate } from "react-router-dom";

import { IntegrationIcon } from "src/components/integrations/integration-icon";
import { Page } from "src/components/layout";
import { DeleteConfirmationModal } from "src/components/modals/delete-confirmation-modal";
import { Warning } from "src/components/warning";
import { eventSourceDefinitions, eventWarehouseDestinationDefinitions } from "src/events/types";
import { EventWarehouseSyncQuery, useEventWarehouseSyncQuery, useDeleteEventWarehouseSyncMutation } from "src/graphql";
import { PageSpinner } from "src/ui/loading";

import { Runs } from "./runs";
import { Schedule } from "./schedule";

const tabs = ["runs", "schedule"];

export type OutletContext = { sync: NonNullable<EventWarehouseSyncQuery["event_warehouse_syncs_by_pk"]> };

export const EventWarehouseSyncRouter: FC = () => {
  return (
    <Routes>
      <Route element={<Loader />}>
        <Route element={<Layout />}>
          <Route path="/runs" element={<Runs />} />
          <Route path="/schedule" element={<Schedule />} />
          <Route index element={<Navigate to={{ pathname: "runs", search: location.search }} replace />} />
        </Route>
      </Route>
    </Routes>
  );
};

const Loader: FC = () => {
  const { id } = useParams<{ id: string }>();

  const { data: sync, isLoading } = useEventWarehouseSyncQuery(
    { id: String(id) },
    { enabled: Boolean(id), select: (data) => data.event_warehouse_syncs_by_pk },
  );

  if (isLoading) {
    return <PageSpinner />;
  }

  if (!id) {
    return null;
  }

  if (!sync) {
    return <Warning subtitle="It may have been deleted" title="Event sync not found" />;
  }

  return <Outlet context={{ sync }} />;
};

const Layout: FC = () => {
  const { sync } = useOutletContext<OutletContext>();
  const location = useLocation();
  const navigate = useNavigate();
  const [isDeleting, setIsDeleting] = useState(false);

  const deleteMutation = useDeleteEventWarehouseSyncMutation();

  const sourceDefinition = eventSourceDefinitions[sync.event_source.type];
  const destinationDefinition = eventWarehouseDestinationDefinitions[sync.event_warehouse_destination.type];

  return (
    <Page title="Sync" crumbs={[{ label: "Syncs", link: "/events/syncs" }, { label: "Sync" }]}>
      <Column minWidth={0} mb={4} gap={2}>
        <Row flex={1} justify="space-between" minWidth={0} pt={1}>
          <Row align="center" gap={4}>
            <Row gap={2} align="center">
              <IntegrationIcon name={sourceDefinition.name} src={sourceDefinition.icon} />
              <Text fontWeight="semibold">{sync.event_source.name}</Text>
            </Row>
            <Box fontSize="2xl">
              <ArrowRightIcon />
            </Box>
            <Row gap={2} align="center">
              <IntegrationIcon name={destinationDefinition.name} src={destinationDefinition.icon} />
              <Text fontWeight="semibold">{sync.event_warehouse_destination.name}</Text>
            </Row>
          </Row>
          <Row align="center" gap={4}>
            <Menu>
              <MenuActionsButton />
              <MenuList>
                <MenuItem
                  icon={DeleteIcon}
                  variant="danger"
                  onClick={() => {
                    setIsDeleting(true);
                  }}
                >
                  Delete
                </MenuItem>
              </MenuList>
            </Menu>
          </Row>
        </Row>
      </Column>
      <Tabs
        onChange={(index) => navigate(tabs[index]!)}
        index={tabs.findIndex((tab) => location.pathname.endsWith(tab))}
        mb={6}
      >
        <TabList>
          <Tab>Runs</Tab>
          <Tab>Schedule</Tab>
        </TabList>
      </Tabs>
      <Outlet context={{ sync }} />

      <DeleteConfirmationModal
        isOpen={isDeleting}
        label="sync"
        onClose={() => {
          setIsDeleting(false);
        }}
        onDelete={async () => {
          await deleteMutation.mutateAsync({ id: sync.id });
          navigate("/events/syncs");
        }}
      />
    </Page>
  );
};
