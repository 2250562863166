import { FC, forwardRef, useEffect, useMemo, useRef, useState, Fragment } from "react";

import { SparklesIcon } from "@heroicons/react/24/solid";
import { Box, SearchInput, Text as HightouchUiText, TraitIcon } from "@hightouchio/ui";
import { isEqual } from "lodash";
import { Text } from "theme-ui";

import { Column, Row } from "src/ui/box";
import { Input } from "src/ui/input";
import { getSearchRegExp } from "src/utils/string";

import { useFormkitContext, ColumnOption, MATCHBOOSTED_IDENTIFIER_KEY } from "./formkit-context";
import { FormProps } from "./types";

interface CustomColumnOption {
  label: ColumnOption["label"];
  type?: ColumnOption["type"];
  value?: ColumnOption["value"];
  options?: ColumnOption["options"];
}

const BOOSTED_COLUMN_NAME_HEADING_TEXT = "Boosted column name";

export const StandardInput: FC<Readonly<FormProps>> = ({ value, onChange, useHightouchUi = false }) => {
  const [search, setSearch] = useState<string>("");
  const { columns: allColumns } = useFormkitContext();
  const searchRef = useRef<HTMLInputElement | null>(null);
  const selectedColumnRef = useRef<HTMLDivElement | null>(null);

  const options = useMemo(() => {
    const columns =
      value?.type && value.type === "boosted"
        ? allColumns.filter((column) => column.label === MATCHBOOSTED_IDENTIFIER_KEY)
        : allColumns.filter((column) => column.label !== MATCHBOOSTED_IDENTIFIER_KEY);

    if (search) {
      const regex = getSearchRegExp(search, "i");
      const filteredColumns: CustomColumnOption[] = [];

      for (const column of columns ?? []) {
        if (column.options) {
          filteredColumns.push({
            label: column.label,
            options: column.options.filter((option) => regex.test(option.label)),
          });
        } else if (regex.test(column.label)) {
          filteredColumns.push(column);
        }
      }

      return filteredColumns;
    }

    return columns;
  }, [allColumns, search, value]);

  useEffect(() => {
    if (searchRef && searchRef.current) {
      // set timeout required to allow the input to be focused
      setTimeout(() => {
        searchRef.current?.focus();
      }, 10);
    }
  }, [searchRef]);

  useEffect(() => {
    if (selectedColumnRef && selectedColumnRef.current) {
      selectedColumnRef.current.scrollIntoView();
    }
  }, [selectedColumnRef]);

  return (
    <Column sx={{ p: 3, flex: 1 }}>
      {useHightouchUi ? (
        <SearchInput
          ref={searchRef}
          placeholder="Search..."
          value={search}
          width="100%"
          onChange={(event) => setSearch(event.target.value)}
        />
      ) : (
        <Input ref={searchRef} placeholder="Search..." sx={{ width: "100%", flex: 0 }} value={search} onChange={setSearch} />
      )}
      <Column sx={{ mt: 4, overflow: "auto" }}>
        {options.map((option, index) => {
          if (option.options) {
            const group = option.label;
            return (
              <Fragment key={index}>
                {useHightouchUi ? (
                  <Row pb={2} pl={1} pt={index > 0 ? 4 : 0} gap={1}>
                    {group === BOOSTED_COLUMN_NAME_HEADING_TEXT && <SparklesIcon color="#F5C24D" width={16} />}
                    <HightouchUiText fontWeight="semibold" letterSpacing="wide" size="sm" textTransform="uppercase">
                      {group}
                    </HightouchUiText>
                  </Row>
                ) : (
                  <Text
                    sx={{
                      fontSize: 0,
                      color: "base.5",
                      fontWeight: "bold",
                      textTransform: "uppercase",
                      pl: 1,
                      pb: 2,
                      pt: index > 0 ? 4 : 0,
                    }}
                  >
                    {group}
                  </Text>
                )}

                {option.options.map((groupedOption) => {
                  const selected = isEqual(groupedOption.value, value.from);
                  return (
                    <Option
                      key={JSON.stringify(groupedOption.value)}
                      ref={selected ? selectedColumnRef : undefined}
                      option={groupedOption}
                      selected={selected}
                      useHightouchUi={useHightouchUi}
                      onClick={() => onChange({ type: value.type ?? "standard", from: groupedOption.value })}
                    />
                  );
                })}
              </Fragment>
            );
          }
          const selected = isEqual(option.value, value);
          return (
            <Option
              key={JSON.stringify(option.value)}
              ref={selected ? selectedColumnRef : undefined}
              option={option}
              selected={selected}
              useHightouchUi={useHightouchUi}
              onClick={() => onChange({ type: value.type ?? "standard", from: option.label })}
            />
          );
        })}
      </Column>
    </Column>
  );
};

const Option = forwardRef<
  HTMLDivElement,
  { useHightouchUi: boolean; option: CustomColumnOption; selected: boolean; onClick: () => void }
>(({ useHightouchUi = false, option, selected, onClick }, ref) => {
  if (useHightouchUi) {
    return (
      <Box
        ref={ref}
        _hover={{
          bg: "gray.100",
        }}
        alignItems="center"
        bg={selected ? "forest.100" : undefined}
        borderBottom="1px"
        borderColor="gray.300"
        cursor="pointer"
        display="flex"
        flex="0"
        minHeight="32px"
        pointerEvents={selected ? "none" : undefined}
        px={2}
        sx={
          selected
            ? {
                span: {
                  color: "forest.600",
                },
              }
            : undefined
        }
        onClick={onClick}
        justifyContent="space-between"
      >
        {typeof option?.value === "object" && option?.value?.type === "additionalColumnReference" ? (
          <Box alignItems="center" display="flex" flex="1" gap={2} minWidth={0}>
            <Box as={TraitIcon} color="text.secondary" boxSize={4} />
            <HightouchUiText isTruncated>{option.label}</HightouchUiText>
          </Box>
        ) : (
          <HightouchUiText isTruncated>{option?.label}</HightouchUiText>
        )}

        {option.type && (
          <Box flex={0}>
            <HightouchUiText size="sm" fontWeight="semibold" color="text.tertiary" textTransform="uppercase">
              {option.type}
            </HightouchUiText>
          </Box>
        )}
      </Box>
    );
  }

  return (
    <Row
      ref={ref}
      sx={{
        color: selected ? "forest" : undefined,
        minHeight: "32px",
        alignItems: "center",
        fontSize: 0,
        flex: 0,
        cursor: "pointer",
        px: 2,
        borderBottom: "small",
        bg: selected ? "avocado" : undefined,
        pointerEvents: selected ? "none" : undefined,
        ":hover": { bg: "base.0" },
      }}
      onClick={onClick}
    >
      {formatValue(option)}
      <Text sx={{ flex: 1, color: "base.5", textTransform: "capitalize" }}>{option.type}</Text>
    </Row>
  );
});

export const formatValue = (option: CustomColumnOption) => {
  if (typeof option?.value === "object") {
    if (option?.value?.type === "additionalColumnReference") {
      return (
        <Row sx={{ alignItems: "center" }}>
          <Box as={TraitIcon} color="text.secondary" boxSize={4} sx={{ mr: 2 }} />
          <Text sx={{ fontWeight: "semi", flex: 1, whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>
            {option.label}
          </Text>
        </Row>
      );
    }
  }
  return option?.label;
};

Option.displayName = "Option";
