import { FC, useEffect, useState } from "react";

import {
  Row,
  SectionHeading,
  Column,
  Text,
  LinkButton,
  Box,
  SortVerticalDownIcon,
  SortVerticalUpIcon,
  Pill,
  useToast,
  ButtonGroup,
  Button,
} from "@hightouchio/ui";
import { captureException } from "@sentry/react";
import { uniq } from "lodash";
import { Route, Routes, useNavigate, useOutletContext, Outlet, useParams } from "react-router-dom";

import { ActionBar } from "src/components/action-bar";
import { Reorder } from "src/components/reorder";
import { IdrModelsUpdates, useUpdateIdentityResolutionModelsMutation } from "src/graphql";
import { Model } from "src/pages/identity-resolution/graph/model";
import { IdentityGraph } from "src/pages/identity-resolution/types";
import eventIcon from "src/pages/schema/assets/event.svg";
import parentIcon from "src/pages/schema/assets/parent.svg";
import { TextWithTooltip } from "src/ui/text";
import { abbreviateNumber } from "src/utils/numbers";

import { OutletContext } from ".";

export const Models: FC = () => {
  const navigate = useNavigate();
  const { toast } = useToast();
  const { graph } = useOutletContext<OutletContext>();
  const { models } = graph;
  const params = useParams();
  const isDrawerOpen = Boolean(params["*"]);
  const [items, setItems] = useState(models);

  const updateMutation = useUpdateIdentityResolutionModelsMutation({
    onSuccess: () => {
      // skip query invalidation
    },
  });

  const reset = () => {
    setItems(models);
  };

  const save = async () => {
    const updates = items.map((item, index) => {
      const update: IdrModelsUpdates = { where: { id: { _eq: item.id } }, _set: { rank: index + 1 } };
      return update;
    });
    try {
      await updateMutation.mutateAsync({
        updates,
      });
    } catch (e) {
      captureException(e);
      toast({
        id: "update-error",
        title: "There was a problem updating the model sequence.",
        variant: "error",
      });
    }
  };

  const isDirty = items.some((item, index) => item.id !== models?.[index]?.id);

  useEffect(() => {
    reset();
  }, [models]);

  return (
    <Routes>
      <Route
        element={
          <>
            <Column pb={24}>
              <Row gap={4} justify="space-between" mb={4}>
                <Column>
                  <SectionHeading>Model sequence</SectionHeading>
                  <Text>Select the models and their sequence to run identity resolution on.</Text>
                </Column>
                <LinkButton href="new">Add model</LinkButton>
              </Row>
              <Reorder onChange={setItems} items={items}>
                {items.map((item) => (
                  <Item
                    key={item.id}
                    model={item}
                    onClick={() => {
                      navigate(item.id);
                    }}
                  />
                ))}
              </Reorder>
            </Column>

            {!isDrawerOpen && (
              <ActionBar>
                <ButtonGroup>
                  <Button size="lg" variant="primary" onClick={save} isDisabled={!isDirty}>
                    Save changes
                  </Button>
                  <Button size="lg" isDisabled={!isDirty} onClick={reset}>
                    Discard changes
                  </Button>
                </ButtonGroup>
              </ActionBar>
            )}
            <Outlet context={{ graph }} />
          </>
        }
      >
        <Route index element={<></>} />
        <Route path=":modelId" element={<Model />} />
      </Route>
    </Routes>
  );
};

const Item: FC<Readonly<{ model: IdentityGraph["models"][0]; onClick: () => void }>> = ({ model }) => {
  const identifiers = uniq(model.mappings.map((mapping) => mapping.identifier));

  return (
    <>
      <Column flex={1} gap={2} overflow="hidden" mr={8}>
        <Text fontWeight="medium" color="text.secondary" size="sm" textTransform="uppercase">
          Model
        </Text>
        <Row align="center" gap={2} overflow="hidden" height="24px">
          <Box as="img" src={model.type === "event" ? eventIcon : parentIcon} width="24px" />
          <TextWithTooltip fontWeight="medium" isTruncated size="lg">
            {model.model.name}
          </TextWithTooltip>
        </Row>
      </Column>
      <Column width="100%" maxWidth="100px" gap={2} align="flex-end" mr={8}>
        <Text fontWeight="medium" color="text.secondary" size="sm" textTransform="uppercase">
          Rows
        </Text>
        <Row height="24px" align="center">
          <Text>
            {model.model.query_runs?.[0] ? `${abbreviateNumber(model.model.query_runs?.[0]?.size)} rows` : "Unknown size"}
          </Text>
        </Row>
      </Column>
      <Column width="100%" maxWidth="200px" gap={2}>
        <Text fontWeight="medium" color="text.secondary" size="sm" textTransform="uppercase">
          Order by
        </Text>
        <Row align="center" gap={2} overflow="hidden" height="24px">
          {model.order_by.order === "asc" ? <SortVerticalDownIcon /> : <SortVerticalUpIcon />}
          <TextWithTooltip fontWeight="medium" isTruncated>
            {model.order_by.column}
          </TextWithTooltip>
        </Row>
      </Column>
      <Column flex={1} gap={2} overflow="hidden">
        <Text fontWeight="medium" color="text.secondary" size="sm" textTransform="uppercase">
          Identifiers
        </Text>
        <Row gap={2} overflow="hidden" align="center" flexWrap="wrap">
          {identifiers.map((identifier) => (
            <Pill key={identifier}>{identifier}</Pill>
          ))}
        </Row>
      </Column>
    </>
  );
};
