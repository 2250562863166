import { FC, useEffect, useState } from "react";

import { FolderOpenIcon, LinkIcon, PencilIcon, Square2StackIcon, TrashIcon } from "@heroicons/react/24/outline";
import { DestinationInstancesInsertInput } from "@hightouch/core/server/graphql/types";
import {
  Avatar,
  Box,
  Button,
  Column,
  EditableDescription,
  EditableHeading,
  Link,
  Menu,
  MenuActionsButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Pill,
  Row,
  SectionHeading,
  Text,
  useToast,
} from "@hightouchio/ui";
import * as Sentry from "@sentry/browser";
import { useFlags } from "launchdarkly-react-client-sdk";
import pluralize from "pluralize";
import { Route, Routes, useLocation, useNavigate, useParams } from "react-router-dom";

import { AudienceExplore } from "src/components/audiences/audience-explore";
import { AudienceTraits } from "src/components/audiences/audience-traits";
import { DetailBar } from "src/components/detail-bar";
import { MoveFolder } from "src/components/folders/move-to-folder";
import { useFolder } from "src/components/folders/use-folder";
import { IntegrationIcon } from "src/components/integrations/integration-icon";
import { EditLabelModal } from "src/components/labels/edit-label-modal";
import { Labels } from "src/components/labels/labels";
import { useLabels } from "src/components/labels/use-labels";
import { DetailPage } from "src/components/layout";
import { Crumb } from "src/components/layout/header/breadcrumbs";
import { DeleteConfirmationModal } from "src/components/modals/delete-confirmation-modal";
import { Syncs } from "src/components/models/syncs";
import { Performance } from "src/components/performance";
import { allParameterizedConditionsHaveValue } from "src/components/performance/parameterized-audience-conditions/utils";
import { Permission } from "src/components/permission";
import { ResourceActivityTimeline } from "src/components/resource-activity/timeline";
import { Splits } from "src/components/splits";
import { Warning } from "src/components/warning";
import { FormErrorProvider } from "src/contexts/form-error-context";
import { PermissionProvider } from "src/contexts/permission-context";
import { useUser } from "src/contexts/user-context";
import {
  AudienceQuery,
  AudienceQueryVariables,
  DraftOperation,
  ResourcePermissionGrant,
  useAudienceQuery,
  useCreateSyncsMutation,
  useDeleteAudienceMutation,
  useSubmitDraftSyncMutation,
  useSyncTemplatesForParentModelQuery,
  useUpdateAudienceMutation,
  useUpdateSplitsConfigMutation,
} from "src/graphql";
import useHasPermission from "src/hooks/use-has-permission";
import * as analytics from "src/lib/analytics";
import { QueryType } from "src/types/models";
import { isPresent } from "src/types/utils";
import { SplitTestDefinition } from "src/types/visual";
import { PageSpinner } from "src/ui/loading";
import { Modal } from "src/ui/modal";
import { Table } from "src/ui/table";
import { useRowSelect } from "src/ui/table/use-row-select";
import { useIncrementalQuery } from "src/utils/incremental-query";
import { EMPTY_AUDIENCE_DEFINITION, QueryState, useQueryState, useUpdateQuery } from "src/utils/models";
import { getQueryWithOpts } from "src/utils/query-with-opts";
import { generateSlug } from "src/utils/slug";
import { formatDate } from "src/utils/time";
import { openUrl } from "src/utils/urls";

import { audienceActivityMappers } from "./setup/audience-activity";

const useFastAudienceQuery = getQueryWithOpts<AudienceQuery, AudienceQueryVariables>(useAudienceQuery, {
  useFastEndpoint: true,
});

export const Audience: FC = () => {
  const { id } = useParams<{ id?: string }>();
  const { toast } = useToast();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const paths = pathname.split("/");
  const tab = paths[3];
  const { workspace } = useUser();
  const [deleteModal, setDeleteModal] = useState(false);
  const [addSyncModal, setAddSyncModal] = useState(false);
  const [editLabelsModal, setEditLabelsModal] = useState(false);
  const { selectedRows: syncTemplateIdsToAdd, onRowSelect: onSyncTemplateSelected } = useRowSelect();
  const [isFolderModalOpen, setIsFolderModalOpen] = useState(false);

  const { appDisableSyncCreationForAudiences, appEnableGoals, schemaV2 } = useFlags();

  const {
    queryState,
    initQueryState,
    setVisualQueryFilter,
    canRedoVisualQueryFilterChange,
    canUndoVisualQueryFilterChange,
    redoVisualQueryFilterChange,
    undoVisualQueryFilterChange,
    resetVisualQueryFilter,
  } = useQueryState();
  const updateQuery = useUpdateQuery();

  const { mutateAsync: updateSplitsConfigAsync } = useUpdateSplitsConfigMutation();

  const {
    data: audience,
    isLoading: audienceLoading,
    refetch,
  } = useIncrementalQuery(
    useFastAudienceQuery(
      {
        id: id ?? "",
        includeExternalFields: false,
      },
      { enabled: Boolean(id), select: (data) => data.segments_by_pk },
    ),
    useAudienceQuery(
      {
        id: id ?? "",
      },
      { enabled: Boolean(id), select: (data) => data.segments_by_pk },
    ),
  );

  const parentModel = audience?.parent;

  const visualQueryFilter = audience?.visual_query_filter;
  const traits = visualQueryFilter?.additionalColumns;

  const { labels } = useLabels();
  const [name, setName] = useState(audience?.name ?? "");
  const { mutateAsync: updateAudience, isLoading: updating } = useUpdateAudienceMutation();
  const { mutateAsync: deleteAudience } = useDeleteAudienceMutation();
  const { mutateAsync: addSyncs, isLoading: addingSyncs } = useCreateSyncsMutation();
  const { mutateAsync: submitDraftSync, isLoading: submittingDraftSync } = useSubmitDraftSyncMutation();
  const { data: syncTemplatesData } = useSyncTemplatesForParentModelQuery(
    { parentModelId: parentModel?.id },
    { enabled: Boolean(parentModel) },
  );

  const { hasPermission: hasViewSchemaPermissions } = useHasPermission([
    { resource: "audience_schema", grants: [ResourcePermissionGrant.Read], resource_id: parentModel?.id },
  ]);
  const { hasPermission: userCanUpdate } = useHasPermission([
    { resource: "audience", grants: [ResourcePermissionGrant.Update], resource_id: id },
  ]);
  const { hasPermission: userCanDelete } = useHasPermission([
    { resource: "audience", grants: [ResourcePermissionGrant.Delete], resource_id: id },
  ]);
  const { hasPermission: userCanCreate } = useHasPermission([
    { resource: "audience", grants: [ResourcePermissionGrant.Create] },
  ]);

  const folder = useFolder({ folderId: audience?.folder?.id ?? null, folderType: "audiences", viewType: "models" });

  useEffect(() => {
    setName(audience?.name ?? "");
  }, [audience?.name]);

  useEffect(() => {
    initQueryState(audience);
  }, [audience]);

  useEffect(() => {
    refetch();
  }, [tab]);

  if (!id || audienceLoading) {
    return <PageSpinner />;
  }

  if (!audience) {
    return <Warning subtitle="It may have been deleted" title="Audience not found" />;
  }

  const onAddSync = () => {
    if (hasSyncTemplates) {
      setAddSyncModal(true);
    } else {
      navigate(`/syncs/new?model=${id}`);
    }
  };

  const onAddSyncTemplates = async () => {
    const approvalsRequired = workspace?.approvals_required;

    const syncObjects: DestinationInstancesInsertInput[] = syncTemplateIdsToAdd
      .map((id) => syncTemplates?.find((template) => template.id === id))
      .filter(Boolean)
      .map((template: any) => ({
        segment_id: id,
        sync_template_id: template.id,
        slug: generateSlug(template.name),
        draft: approvalsRequired,
      }));

    try {
      const syncs = await addSyncs({
        objects: syncObjects,
      });

      if (approvalsRequired) {
        const syncIds: string[] = (syncs.insert_destination_instances?.returning ?? []).map(({ id }) => id.toString());

        // Save newly created syncs as new drafts without approvers.
        // Approvers will be requested later from the individual sync pages.
        await Promise.all(
          syncIds.map(async (syncId: string) => {
            return submitDraftSync(
              {
                resourceId: syncId,
                approverIds: [],
                operation: DraftOperation.Create,
                draft: {
                  _set: {
                    draft: false,
                  },
                },
              },
              {
                onError: (error) => {
                  Sentry.captureException(error);
                },
              },
            );
          }),
        );
      }

      toast({
        id: "add-sync-to-audience",
        title: `${pluralize("sync", syncTemplateIdsToAdd.length, true)} added`,
        variant: "success",
      });

      setAddSyncModal(false);
    } catch (error) {
      toast({
        id: "add-sync-to-audience",
        title: `${pluralize("sync", syncTemplateIdsToAdd.length, true)} could not be added. Please try again.`,
        variant: "error",
      });

      Sentry.captureException(error);
    }
  };

  const updatedByUsername = audience.updated_by_user?.name || audience.created_by_user?.name;
  const membership = audience?.priority_list_memberships?.[0];
  const syncs = audience?.syncs;
  const source = audience?.connection;
  const splits = audience?.splits;
  const labelKeys = Object.keys(audience?.labels || {});
  const goals = audience?.audience_goals?.map(({ goal }) => goal);
  const doAllParameterizedConditionsHaveValue = allParameterizedConditionsHaveValue(
    audience?.parent?.goals,
    goals,
    audience?.parameterized_conditions,
  );

  const usedSyncTemplateIds = syncs?.map(({ sync_template_id }) => sync_template_id);
  const syncTemplates = syncTemplatesData?.sync_templates;
  const usedSyncTemplates = syncTemplates?.filter(({ id }) => usedSyncTemplateIds?.includes(id));
  const availableSyncTemplates = syncTemplates?.filter(({ id }) => !usedSyncTemplateIds?.includes(id));

  const hasSyncTemplates = Array.isArray(syncTemplates) && syncTemplates.length > 0;
  const cannotCreateNewSyncs = appDisableSyncCreationForAudiences && !hasSyncTemplates;

  const save = async (data, queryStateOverride: Partial<QueryState> = {}, showToast = true) => {
    await updateQuery({
      model: audience,
      queryState: {
        ...queryState,
        ...queryStateOverride,
      },
      columns: data?.columns,
    });

    onUpdate(showToast);
  };

  const saveSplitDefinition = async (splitTestDefinition: SplitTestDefinition | undefined) => {
    // Set the frontend state.
    const newVisualQueryFilter = { ...(queryState.visualQueryFilter ?? EMPTY_AUDIENCE_DEFINITION), splitTestDefinition };
    setVisualQueryFilter(newVisualQueryFilter);

    // Update the split config on the backend.
    await updateSplitsConfigAsync({
      audienceId: audience.id.toString(),
      enabled: Boolean(splitTestDefinition),
      groupColumnName: splitTestDefinition?.groupColumnName ?? "",
      samplingType: splitTestDefinition?.samplingType,
      stratificationVariables: splitTestDefinition?.stratificationVariables,
    });
  };

  const onUpdate = (showToast = true) => {
    analytics.track("Model Updated", {
      model_id: id,
      model_type: QueryType.Visual,
      model_name: audience?.name,
      source_id: source?.id,
      source_type: source?.type,
    });

    if (showToast) {
      toast({
        id: "update-audience",
        title: "Audience was updated",
        variant: "success",
      });
    }
  };

  const saveName = async () => {
    await updateAudience({
      id,
      input: {
        name,
      },
    });

    onUpdate();
  };

  const saveLabels = async (labels: Record<string, string | number>) => {
    await updateAudience({
      id,
      input: {
        tags: labels,
      },
    });

    onUpdate();
  };

  const saveDescription = async (description: string) => {
    if (!id) {
      return;
    }

    await updateAudience({
      id,
      input: {
        description,
      },
    });

    onUpdate();
  };

  const onDelete = async () => {
    try {
      await deleteAudience({ id });

      analytics.track("Model Deleted", {
        model_id: id,
        model_type: QueryType.Visual,
        model_name: audience?.name,
        source_id: source?.id,
        source_type: source?.type,
      });

      navigate("/audiences");
    } catch (error) {
      if (error.message.startsWith("Foreign key violation") && error.message.includes("segments")) {
        toast({
          id: "delete-audience",
          title: "Failed to delete this audience",
          message:
            "This audience cannot be deleted because it is used in a priority list. First remove the audience from the priority list and try again.",
          variant: "error",
        });

        setDeleteModal(false);
      } else {
        toast({
          id: "delete-audience",
          title: "Failed to delete this audience",
          variant: "error",
        });

        Sentry.captureException(error);
      }

      throw error;
    }
  };

  const tabs = [
    { title: "Query", path: "query" },
    {
      render: () => (
        <Box>
          Splits
          {splits?.length > 0 && <Pill ml={2}>{splits.length}</Pill>}
        </Box>
      ),
      title: "Splits",
      path: "splits",
    },
    {
      render: () => (
        <Box>
          Syncs
          {syncs?.length > 0 && <Pill ml={2}>{syncs.length}</Pill>}
        </Box>
      ),
      title: "Syncs",
      path: "syncs",
    },
    appEnableGoals && {
      render: () => (
        <Row gap={1} alignItems="center">
          <Text color="inherit">Performance</Text>
          {!doAllParameterizedConditionsHaveValue && <Box height={2} width={2} borderRadius="50%" bg="text.danger" />}
        </Row>
      ),
      title: "Performance",
      path: "performance",
    },
    {
      render: () => (
        <Box>
          Traits
          {traits?.length > 0 && <Pill ml={2}>{traits.length}</Pill>}
        </Box>
      ),
      title: "Traits",
      path: "traits",
    },
    { title: "Activity", path: "activity" },
  ].filter(isPresent);

  const crumbs: Crumb[] = [{ label: "Audiences", link: "/audiences" }];

  if (folder?.path) {
    folder.path.split("/").forEach((path) => {
      crumbs.push({
        label: path,
        link: "/audiences?folder=" + folder.id,
      });
    });
  }

  crumbs.push({
    label: audience?.name ?? "",
  });

  const syncTemplateColumns = [
    {
      name: "Name",
      key: "name",
      cell: (name) => (
        <Text isTruncated fontWeight="medium">
          {name}
        </Text>
      ),
    },
    {
      name: "Destination",
      cell: ({ destination }) => {
        return (
          <Row align="center" gap={2} overflow="hidden">
            <IntegrationIcon name={destination?.definition?.name} src={destination?.definition?.icon} />
            <Link href={schemaV2 ? `/schema-v2/settings/sync-templates/${id}` : `/schema/sync-templates/${destination.id}`}>
              <Text isTruncated fontWeight="medium">
                {destination?.name ?? destination?.definition?.name ?? "Private destination"}
              </Text>
            </Link>
          </Row>
        );
      },
    },
  ];

  return (
    <>
      <PermissionProvider permissions={[{ resource: "audience", grants: [ResourcePermissionGrant.Update] }]}>
        <DetailPage
          title={`${audience.name} - Audiences`}
          bg={tab === "query" ? "base.lightBackground" : "white"}
          contentFullWidth={tab === "query"}
          crumbs={crumbs}
          header={
            <Column mb={6} width="100%">
              <Row sx={{ alignItems: "center", width: "100%", justifyContent: "space-between" }}>
                <EditableHeading isDisabled={!userCanUpdate} size="lg" value={name} onChange={setName} onSubmit={saveName} />
                <Row gap={3} sx={{ alignItems: "center", ml: 8 }}>
                  {(userCanUpdate || userCanCreate || userCanDelete) && (
                    <Menu>
                      <MenuActionsButton variant="secondary" />
                      <MenuList>
                        {userCanUpdate && (
                          <MenuItem
                            icon={FolderOpenIcon}
                            onClick={() => {
                              setIsFolderModalOpen(true);
                            }}
                          >
                            Move to folder
                          </MenuItem>
                        )}
                        {userCanUpdate && (
                          <MenuItem
                            icon={PencilIcon}
                            onClick={() => {
                              setEditLabelsModal(true);
                            }}
                          >
                            Edit labels
                          </MenuItem>
                        )}
                        {userCanCreate && (
                          <MenuItem
                            icon={Square2StackIcon}
                            onClick={() => {
                              navigate(`/audiences/${id}/clone`);
                            }}
                          >
                            Clone
                          </MenuItem>
                        )}
                        {(userCanUpdate || userCanCreate) && userCanDelete && <MenuDivider />}
                        {userCanDelete && (
                          <MenuItem
                            icon={TrashIcon}
                            variant="danger"
                            onClick={() => {
                              setDeleteModal(true);
                            }}
                          >
                            Delete
                          </MenuItem>
                        )}
                      </MenuList>
                    </Menu>
                  )}

                  {!cannotCreateNewSyncs && (
                    <Permission permissions={[{ resource: "sync", grants: [ResourcePermissionGrant.Create] }]}>
                      <Button
                        variant="secondary"
                        onClick={() => {
                          onAddSync();
                        }}
                      >
                        Add sync
                      </Button>
                    </Permission>
                  )}
                </Row>
              </Row>
              <Row>
                <EditableDescription
                  isDisabled={!userCanUpdate}
                  value={audience.description ?? ""}
                  onChange={saveDescription}
                />
              </Row>
              <Row sx={{ mt: 1 }}>
                <DetailBar>
                  <Row align="center" gap={2} flexShrink={0}>
                    <IntegrationIcon src={source?.definition?.icon} name={source?.definition?.name ?? ""} />
                    {hasViewSchemaPermissions ? (
                      <Link
                        href={
                          schemaV2
                            ? `/schema-v2/view?source=${source?.id}&id=${parentModel?.id}`
                            : `/schema/parent-models/${parentModel?.id}`
                        }
                      >
                        <Text isTruncated fontWeight="medium" color="inherit">
                          {parentModel?.name}
                        </Text>
                      </Link>
                    ) : (
                      <Text isTruncated fontWeight="medium">
                        {parentModel?.name}
                      </Text>
                    )}
                  </Row>
                  <Row align="center" gap={2} flexShrink={0}>
                    <Text>Last updated:</Text>
                    <Row gap={1} align="center">
                      {formatDate((audience.updated_at || audience.created_at)!)}
                      {updatedByUsername && (
                        <>
                          <Text>by</Text>
                          <Avatar size="xs" name={updatedByUsername} />
                        </>
                      )}
                    </Row>
                  </Row>
                  {membership && (
                    <Row align="center" gap={2}>
                      <Text>#{membership.rank + 1} in</Text>
                      <Link href={`/priority-lists/${membership.priority_list.id}`}>
                        <Box
                          sx={{
                            color: "primary",
                            display: "flex",
                            gap: 2,
                            alignItems: "center",
                            ":hover": { color: "secondary" },
                          }}
                        >
                          {membership.priority_list.name}
                          <LinkIcon width="16px" />
                        </Box>
                      </Link>
                    </Row>
                  )}
                  {labelKeys?.length > 0 && (
                    <Row>
                      <Labels labels={audience?.labels} />
                    </Row>
                  )}
                </DetailBar>
              </Row>
            </Column>
          }
          tabs={tabs}
        >
          <Routes>
            <Route
              path="/query"
              element={
                <FormErrorProvider>
                  <AudienceExplore
                    audience={audience}
                    bodyOverflow="hidden"
                    canRedo={canRedoVisualQueryFilterChange}
                    canUndo={canUndoVisualQueryFilterChange}
                    parentModel={parentModel}
                    queryState={queryState}
                    source={source}
                    onRedo={redoVisualQueryFilterChange}
                    onReset={() => resetVisualQueryFilter(visualQueryFilter)}
                    onSave={save}
                    onUndo={undoVisualQueryFilterChange}
                    onVisualQueryFilterChange={setVisualQueryFilter}
                  />
                </FormErrorProvider>
              }
            />
            <Route
              path="/splits"
              element={
                audience && queryState.visualQueryFilter ? (
                  <Splits
                    audience={audience}
                    data={queryState.visualQueryFilter?.splitTestDefinition}
                    sourceType={source?.type}
                    onAddSync={onAddSync}
                    onSave={saveSplitDefinition}
                  />
                ) : null
              }
            />
            <Route path="/syncs" element={<Syncs isAudience syncs={syncs} onAdd={onAddSync} />} />
            {appEnableGoals && audience && (
              <Route path="/performance" element={<Performance audience={audience} metrics={goals} />} />
            )}
            <Route path="/traits" element={audience ? <AudienceTraits audience={audience} /> : null} />
            <Route
              path="/activity"
              element={
                id ? (
                  <ResourceActivityTimeline
                    primaryResource={{
                      mappers: audienceActivityMappers,
                      resource: "Audience",
                      resourceId: String(id),
                    }}
                  />
                ) : null
              }
            />
          </Routes>
        </DetailPage>
      </PermissionProvider>

      <Modal
        footer={
          <>
            <Button
              onClick={() => {
                setAddSyncModal(false);
              }}
            >
              Cancel
            </Button>

            {!appDisableSyncCreationForAudiences && (
              <Button isDisabled={syncTemplateIdsToAdd.length > 0} onClick={() => navigate(`/syncs/new?model=${id}`)}>
                Create new sync
              </Button>
            )}
            <Button
              isDisabled={syncTemplateIdsToAdd?.length === 0}
              isLoading={addingSyncs || submittingDraftSync}
              onClick={onAddSyncTemplates}
            >
              Add syncs
            </Button>
          </>
        }
        isOpen={addSyncModal}
        sx={{ width: "900px" }}
        title="Select sync templates"
        onClose={() => {
          setAddSyncModal(false);
        }}
      >
        <Column gap={6}>
          <Column>
            {availableSyncTemplates?.length === 0 ? (
              <Text fontWeight="medium">
                You have no available sync templates.{" "}
                <Link href={schemaV2 ? "/schema-v2/settings/sync-templates/new" : "/schema/sync-templates/new"}>
                  Create a new sync template
                </Link>{" "}
                to easily sync audiences to other destinations.
              </Text>
            ) : (
              <>
                <Text fontWeight="medium">
                  Select one or more sync templates below. Templates are pre-configured to work with this Audience's data
                  source.
                </Text>
                <Text fontWeight="medium">
                  If your destination isn't listed below, <Link href={`/syncs/new?model=${id}`}>create a new sync</Link>.
                </Text>
              </>
            )}
          </Column>
          {Array.isArray(availableSyncTemplates) && availableSyncTemplates.length > 0 && (
            <Column gap={3}>
              <SectionHeading>Available sync templates</SectionHeading>
              <Table
                data={availableSyncTemplates}
                columns={syncTemplateColumns}
                onSelect={onSyncTemplateSelected}
                selectedRows={syncTemplateIdsToAdd}
              />
            </Column>
          )}
          {Array.isArray(usedSyncTemplates) && usedSyncTemplates.length > 0 && (
            <Column gap={3}>
              <SectionHeading>Active sync templates</SectionHeading>
              <Table
                data={usedSyncTemplates}
                columns={syncTemplateColumns}
                onRowClick={({ id }, event) =>
                  openUrl(
                    schemaV2 ? `/schema-v2/settings/sync-templates/${id}` : `/schema/sync-templates/${id}`,
                    navigate,
                    event,
                  )
                }
              />
            </Column>
          )}
        </Column>
      </Modal>

      <DeleteConfirmationModal
        isOpen={deleteModal}
        label="audience"
        onClose={() => {
          setDeleteModal(false);
        }}
        onDelete={onDelete}
      />

      {audience && isFolderModalOpen && (
        <MoveFolder
          folder={audience.folder}
          folderType="audiences"
          modelIds={[audience.id]}
          viewType="models"
          onClose={() => setIsFolderModalOpen(false)}
        />
      )}

      <EditLabelModal
        description="You can label audiences that have similar properties"
        existingLabelOptions={labels}
        hint="Example keys: team, project, region, env."
        isOpen={editLabelsModal}
        labels={audience?.labels}
        loading={updating}
        saveLabel="Save"
        title="Edit labels"
        onClose={() => setEditLabelsModal(false)}
        onSave={async (labels) => {
          await saveLabels(labels);
          setEditLabelsModal(false);
        }}
      />
    </>
  );
};
