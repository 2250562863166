import { FC } from "react";

import { Column, useToast, Heading, TextInput, FormField, Row, Text, Box } from "@hightouchio/ui";
import { yupResolver } from "@hookform/resolvers/yup";
import { captureException } from "@sentry/react";
import Helmet from "react-helmet";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";

import { eventSourceDefinitions, EventSourceType } from "src/events/types";
import { useCreateEventSourceMutation } from "src/graphql";
import { TrackView } from "src/lib/analytics";
import { Selectable } from "src/ui/selectable";
import { Wizard } from "src/ui/wizard";
import { Step } from "src/ui/wizard/wizard";
import { SlugResourceType, useResourceSlug } from "src/utils/slug";
import { useWizardStepper } from "src/utils/use-wizard-stepper";

type FormState = {
  name: string;
  type: EventSourceType;
};

export const CreateEventSource: FC = () => {
  const { toast } = useToast();
  const navigate = useNavigate();
  const [step, setStep] = useWizardStepper(0);
  const { getSlug } = useResourceSlug(SlugResourceType.EventSources);

  const createMutation = useCreateEventSourceMutation();

  const form = useForm<FormState>({
    resolver: yupResolver(validationSchema),
  });

  const submit = async (data) => {
    try {
      const slug = await getSlug(data.name);
      const source = await createMutation.mutateAsync({
        input: {
          slug,
          type: data.type,
          name: data.name,
        },
      });
      toast({
        id: "create-event-source",
        title: "Event source was created",
        variant: "success",
      });
      navigate(`/events/sources/${source.createEventSource.id}`);
    } catch (error) {
      captureException(error);
      toast({
        id: "create-event-source",
        title: "Event source could not be created",
        variant: "error",
      });
    }
  };

  const steps: Step[] = [
    {
      title: "Select a source",
      header: <Heading>Select an event source</Heading>,
      continue: "Click a source to continue",
      render: () => (
        <Controller
          name="type"
          render={({ field }) => (
            <Box display="grid" gridTemplateColumns="repeat(auto-fit, minmax(200px, 1fr))" gap={4} maxWidth="800px">
              {Object.entries(eventSourceDefinitions).map(([type, definition]) => (
                <Selectable
                  key={type}
                  selected={false}
                  onSelect={() => {
                    field.onChange(type);
                    setStep((step) => step + 1);
                  }}
                >
                  <Row align="center" p={5} gap={4}>
                    <Box as="img" src={definition.icon} width="36px" />
                    <Text fontWeight="medium" size="lg">
                      {definition.name}
                    </Text>
                  </Row>
                </Selectable>
              ))}
            </Box>
          )}
        />
      ),
    },
    {
      title: "Finalize",
      header: <Heading>Finalize settings</Heading>,
      render: () => {
        return (
          <Column gap={8} maxWidth="576px" width="100%">
            <Controller
              name="name"
              render={({ field, fieldState: { error } }) => (
                <FormField label="Name" error={error?.message}>
                  <TextInput {...field} isInvalid={Boolean(error)} autoFocus />
                </FormField>
              )}
            />
          </Column>
        );
      },
    },
  ];

  return (
    <>
      <Helmet>
        <title>New event source</title>
      </Helmet>
      <TrackView name="New Event Source Page" />
      <FormProvider {...form}>
        <Wizard
          setStep={setStep}
          step={step}
          steps={steps}
          title="New event source"
          onCancel={() => {
            navigate("/event/sources");
          }}
          onSubmit={form.handleSubmit(submit)}
        />
      </FormProvider>
    </>
  );
};

const validationSchema = yup.object().shape({
  name: yup.string().required("Source name is required"),
  type: yup.string().required("Source type is required"),
});
