import { useEffect, useState, ReactNode, FC, useMemo } from "react";

import { Box, Checkbox, Column, FormErrorMessage, NumberInput, Select, TextInput, useToast } from "@hightouchio/ui";
import Papa from "papaparse";
import pluralize from "pluralize";
import Dropzone, { FileRejection } from "react-dropzone";

import {
  BooleanOperator,
  ColumnDateTypes,
  ColumnType,
  MultiValueColumnTypes,
  MultiValueOperators,
  OperatorsWithoutValue,
  PercentileOperators,
  PropertyCondition,
} from "src/types/visual";
import { CreatableMultiSelect, CreatableSelect } from "src/ui/select";
import { abbreviateNumber } from "src/utils/numbers";

import { useConditionSelect } from "../filter-popover/use-condition-select";
import { getModelName } from "../filter-popover/utils";
import { ErrorMessage } from "./error-message";
import { TimestampInput } from "./timestamp-input";

export type PropertyInputProps = {
  condition: PropertyCondition;
  onChange: (updates: Partial<PropertyCondition>) => void;
  loading?: boolean;
  suggestions: Array<{ value: string | number | null; count: number | undefined }> | undefined | null;
  error?: ReactNode;
};

export const PropertyInput: FC<Readonly<PropertyInputProps>> = ({ condition, error, loading, suggestions, onChange }) => {
  const { toast } = useToast();

  const { selectedColumn } = useConditionSelect({ condition });
  const modelName = getModelName(selectedColumn);

  const suggestionOptions = suggestions?.map(({ value, count }) => ({
    label: String(value),
    sublabel: count ? `(${abbreviateNumber(count)})` : undefined,
    value,
  }));

  const [multiSelectOptions, setMultiSelectOptions] = useState(suggestionOptions || []);

  const multiSelectSuggestionsTip = useMemo(() => {
    return `${
      multiSelectOptions.length
        ? `${multiSelectOptions.length} ${pluralize("value", multiSelectOptions.length)} found in ${
            modelName ?? "model"
          }, ordered by prevalence. `
        : ""
    }Type to create a new value.`;
  }, [multiSelectOptions.length, modelName]);

  const suggestionsTip = useMemo(() => {
    return `${
      suggestionOptions?.length
        ? `${suggestionOptions.length} ${pluralize("value", suggestionOptions.length)} found in ${
            modelName ?? "model"
          }, ordered by prevalence. `
        : ""
    }Type to create a new value.`;
  }, [suggestionOptions?.length, modelName]);

  useEffect(() => {
    setMultiSelectOptions(suggestionOptions || []);
  }, [condition.property, suggestions?.length]);

  if (OperatorsWithoutValue.includes(condition.operator)) {
    return null;
  }

  if (
    MultiValueOperators.includes(condition.operator) &&
    condition.propertyType &&
    MultiValueColumnTypes.includes(condition.propertyType)
  ) {
    const handleFileDrop = (acceptedFiles: File[], fileRejections: FileRejection[]) => {
      if (fileRejections.length > 0) {
        toast({
          id: "file-upload",
          title: 'Each file must be in ".csv" or ".txt" format and uploaded one at a time',
          variant: "error",
        });
      }

      if (acceptedFiles.length === 1) {
        const file = acceptedFiles[0];

        if (file) {
          Papa.parse(file, {
            complete: (results) => {
              const values = results.data.flat();

              toast({
                id: "file-upload",
                title: `Values from '${file.name}' were added`,
                variant: "success",
              });

              setMultiSelectOptions([...multiSelectOptions, ...values.map((value) => ({ label: value, value }))]);
              onChange({ value: [...(condition.value || []), ...values] });
            },
          });
        }
      }
    };

    // TODO: show error on dropzone component
    return (
      <Dropzone
        accept={[".csv", ".txt"]}
        maxFiles={1}
        multiple={false}
        noClick={true}
        noKeyboard={true}
        onDrop={handleFileDrop}
      >
        {({ getRootProps, getInputProps, isDragActive }) => {
          return (
            <Box {...getRootProps()}>
              <CreatableMultiSelect
                {...getInputProps()}
                isLoading={loading}
                isError={Boolean(error)}
                options={multiSelectOptions}
                placeholder={isDragActive ? "Upload your file here..." : "Add values or drag/drop a text file..."}
                tip={multiSelectSuggestionsTip}
                value={condition.value || []}
                width="300px"
                onChange={(options) => {
                  const values = options.map((option) => option.value);
                  onChange({ value: values });
                }}
                onCreateOption={(v) => {
                  const newValue = Array.isArray(condition.value)
                    ? condition.value
                    : condition.value === null
                    ? []
                    : [condition.value];
                  setMultiSelectOptions([...multiSelectOptions, { label: v, sublabel: undefined, value: v }]);
                  onChange({ value: [...newValue, v] });
                }}
              />

              {error && <ErrorMessage>{error}</ErrorMessage>}
            </Box>
          );
        }}
      </Dropzone>
    );
  }

  if (condition.propertyType && !ColumnDateTypes.includes(condition.propertyType) && suggestions) {
    return (
      <CreatableSelect
        isClearable
        isLoading={loading}
        formatCreateLabel={(string) => string}
        options={suggestionOptions ?? []}
        placeholder="search..."
        tip={suggestionsTip}
        value={condition.value}
        width="200px"
        onChange={(option) => onChange({ value: option?.value })}
        onCreateOption={(value) => onChange({ value })}
      />
    );
  }

  if (condition.propertyType === ColumnType.Boolean) {
    return (
      <Column>
        <Select
          isInvalid={Boolean(error)}
          options={[
            {
              value: true,
              label: "True",
            },
            {
              value: false,
              label: "False",
            },
          ]}
          isLoading={loading}
          placeholder="True / False"
          value={condition.value}
          width="auto"
          onChange={(value) => {
            onChange({
              operator: BooleanOperator.Equals,
              value,
            });
          }}
        />
        {error && <FormErrorMessage>{error}</FormErrorMessage>}
      </Column>
    );
  }

  if (condition.propertyType && ColumnDateTypes.includes(condition.propertyType)) {
    return (
      <TimestampInput
        condition={condition}
        error={error}
        hideTime={condition.propertyType === ColumnType.Date}
        onChange={onChange}
      />
    );
  }

  return (
    <Box display="flex" gap={2}>
      <Column>
        {condition.propertyType && [ColumnType.Number, ColumnType.JsonArrayNumbers].includes(condition.propertyType) ? (
          <NumberInput
            isInvalid={Boolean(error)}
            value={condition.value ?? undefined}
            onChange={(value) => {
              onChange({ value });
            }}
          />
        ) : (
          <TextInput
            isInvalid={Boolean(error)}
            value={condition.value}
            onChange={(event) => {
              onChange({ value: event.target.value });
            }}
          />
        )}
        {error && <ErrorMessage>{error}</ErrorMessage>}
      </Column>
      {condition.propertyType === ColumnType.Number && PercentileOperators.includes(condition.operator) && (
        <Box py="6px">
          <Checkbox
            label="Percentile"
            isChecked={Boolean(condition.propertyOptions?.percentile)}
            onChange={(event) => {
              onChange({
                propertyOptions: {
                  ...condition.propertyOptions,
                  percentile: event.target.checked,
                },
              });
            }}
          />
        </Box>
      )}
    </Box>
  );
};
