import { FC, useMemo } from "react";

import { TestDestinationStepStatus } from "@hightouch/core/server/graphql/types";
import { Button, Code } from "@hightouchio/ui";

import { Column } from "src/ui/box";
import { Markdown } from "src/ui/markdown";
import { Table, TableColumn } from "src/ui/table";

import { ErrorModal } from "../modals/error-modal";

type Props = {
  result: TestDestinationStepStatus[] | null;
  testing: boolean;
};

export const TestConnectionTable: FC<Readonly<Props>> = ({ result, testing }) => {
  const columns = useMemo(() => {
    const hasScope = result?.some((status) => status.scope);
    const tableColumns: TableColumn[] = [];
    tableColumns.push({
      name: "Status",
      max: "max-content",
      cell: ({ error, reason, success }) => {
        return !success ? (
          <ErrorModal buttonSize="small" error={error || reason} />
        ) : (
          <Button isDisabled={true} size="sm" variant="warning" color="green">
            Success
          </Button>
        );
      },
    });
    if (hasScope) {
      tableColumns.push({
        name: "Scope",
        max: "150px",
        cell: ({ scope }) => (
          <Column>
            <Code>{scope}</Code>
          </Column>
        ),
      });
    }
    tableColumns.push({
      name: "Message",
      whitespace: "unset",
      cell: ({ reason }) => {
        return (
          <Column>
            <Markdown>{reason}</Markdown>
          </Column>
        );
      },
    });
    return tableColumns;
  }, [result]);

  return (
    <>
      <Table
        allowWrap={true}
        columns={columns || []}
        data={result || []}
        loading={testing}
        placeholder={{
          title: "Test connection result",
          body: "Click `Test Connection` to view the connection status of your destination.",
          error: "Test connection failed, please try again.",
        }}
        rowHeight="auto"
      />
    </>
  );
};
