import { useMemo, useState } from "react";

import { add } from "date-fns";

import { TimeOptions } from "./utils";

export const useTimestampFilter = () => {
  const [timeValue, setTimeValue] = useState<TimeOptions>(TimeOptions.OneWeek);

  const currentDate = useMemo(() => new Date().toISOString(), []);

  const since: string | null = useMemo(() => {
    const currentDate = new Date();

    if (timeValue === TimeOptions.All) {
      return null;
    }

    let duration;

    switch (timeValue) {
      case TimeOptions.OneWeek:
        duration = { days: -7 };
        break;
      case TimeOptions.OneMonth:
        duration = { months: -1 };
        break;
      case TimeOptions.ThreeMonths:
        duration = { months: -3 };
        break;
      case TimeOptions.SixMonths:
        duration = { months: -6 };
        break;
      case TimeOptions.OneYear:
        duration = { months: -12 };
        break;
    }

    return add(currentDate, duration).toISOString();
  }, [timeValue]);

  return {
    before: currentDate,
    since,
    timeValue,
    setTimeValue,
  };
};
