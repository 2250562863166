import { ChangeEvent, FC, memo, MouseEvent, ReactElement, useCallback, useMemo, useRef } from "react";

import { Box, Checkbox, Spinner, useSize } from "@hightouchio/ui";
import { ThemeUIStyleObject } from "theme-ui";

import { SortMenu } from "src/components/sort-menu";
import { usePermission } from "src/contexts/permission-context";
import { OrderBy } from "src/graphql";
import { SortOption } from "src/ui/table/use-table-config";

import { HeaderCell, TableCell } from "./cells";
import { Placeholder, PlaceholderContent } from "./placeholder";
import { Row } from "./row";

export type TableColumn = {
  name?: string;
  header?: () => ReactElement;
  cell?: (value: any) => ReactElement | string | null;
  key?: string;
  sortKey?: string;
  defaultValue?: string;
  min?: string;
  max?: string;
  whitespace?: "unset" | undefined;
  divider?: boolean;
  disabled?: (row: any) => boolean;
  sortDirection?: OrderBy | null;
  onClick?(): void;
  breakpoint?: "lg" | "md" | "sm";
};

export type RowClickHandler<Data> = (row: Data, event: MouseEvent) => void;

export type TableProps<Data> = {
  columns: TableColumn[];
  data: Data[] | undefined;
  onRowClick?: RowClickHandler<Data>;
  onSelect?: (key: string | number | Array<string | number>) => void;
  selectedRows?: Array<any>;
  width?: string;
  defaultMin?: string;
  defaultMax?: string;
  sx?: ThemeUIStyleObject;
  error?: boolean;
  placeholder?: PlaceholderContent;
  primaryKey?: string;
  disabled?: (row: Data) => boolean;
  rowHeight?: string;
  allowWrap?: boolean;
  highlight?: number | string;
  loading?: boolean;
  showHeaders?: boolean;
  sortingEnabled?: boolean;
  scrollable?: boolean;
  top?: number | string;
  sortOptions?: SortOption<any>[];
  isDense?: boolean;
};

export function Table<Data>({
  primaryKey = "id",
  columns: propColumns,
  data = [],
  onRowClick,
  onSelect,
  selectedRows,
  defaultMin = "0",
  defaultMax = "1fr",
  placeholder,
  error,
  rowHeight = "60px",
  allowWrap,
  disabled,
  highlight,
  loading,
  sortingEnabled = true,
  showHeaders = true,
  scrollable = false,
  isDense = false,
  top,
  sortOptions,
}: Readonly<TableProps<Data>>) {
  const isEmpty = !loading && data.length === 0;
  const permission = usePermission();

  const supportsSelection = Boolean(selectedRows && onSelect) && !permission?.unauthorized;
  const tableRef = useRef<HTMLDivElement | null>(null);
  const dimensions = useSize(tableRef);
  const sortable = Boolean(sortOptions && sortOptions.length > 0);

  const columns = useMemo(() => {
    return propColumns.filter((column) => {
      if (column.breakpoint && dimensions) {
        if (column.breakpoint === "lg") {
          return dimensions.width > 1400;
        }
        if (column.breakpoint === "md") {
          return dimensions.width > 1000;
        }
        if (column.breakpoint === "sm") {
          return dimensions.width > 600;
        }
      }
      return true;
    });
  }, [propColumns, dimensions]);

  const rows = useMemo(
    () =>
      data.map((row, index: number) => (
        <MemoizedTableRow
          key={row[primaryKey]}
          columns={columns}
          disabled={disabled}
          height={isDense ? "auto" : rowHeight}
          index={index}
          primaryKey={primaryKey}
          row={row}
          selected={
            selectedRows?.includes(row[primaryKey]) || (typeof highlight !== "undefined" && highlight === row[primaryKey])
          }
          onClick={onRowClick}
          onSelect={supportsSelection ? onSelect : undefined}
          sortable={sortable}
          isDense={isDense}
        />
      )),
    [data, columns, highlight, onRowClick, onSelect, disabled, selectedRows, primaryKey, rowHeight, supportsSelection, isDense],
  );

  const headerCells = useMemo(() => {
    return columns.map(({ header, name, sortDirection, onClick }: TableColumn, index) => {
      const sortingProps = sortingEnabled
        ? {
            sortDirection,
            onClick,
          }
        : {};

      return (
        <HeaderCell key={name ? name : `header-column-${index}-cell`} top={top} isDense={isDense} {...sortingProps}>
          {header ? header() : name}
        </HeaderCell>
      );
    });
  }, [columns, top, isDense]);

  const onSelectAll = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      if (typeof onSelect !== "function") {
        return;
      }

      if (event.target.checked) {
        onSelect(data.filter((row) => (disabled ? !disabled(row) : true)).map((row) => row[primaryKey]));
      } else {
        onSelect([]);
      }
    },
    [data, onSelect, primaryKey, disabled],
  );

  const isPlaceholder = isEmpty || error || loading;

  return (
    <Box
      ref={tableRef as any}
      flex={isPlaceholder ? 1 : undefined}
      height={isPlaceholder ? "100%" : undefined}
      as="table"
      overflow={isPlaceholder ? "hidden" : undefined}
      bg="white"
      display={isPlaceholder ? "flex" : "grid"}
      p={isPlaceholder ? placeholder?.p : undefined}
      flexDirection="column"
      gridTemplateColumns={getGridTemplateColumns(
        [supportsSelection && { max: "max-content" }, ...columns, sortable && { max: "max-content" }].filter(Boolean),
        scrollable ? "min-content" : defaultMin,
        defaultMax,
      )}
      gridTemplateRows={allowWrap || isDense ? undefined : `40px repeat(auto-fill, ${rowHeight})`}
      width="100%"
      overflowX={scrollable ? "scroll" : undefined}
      sx={{
        [`td:nth-child(1)`]: { pl: isDense ? undefined : 8 },
        [`td:nth-child(${columns.length + 1}n)`]: { pr: isDense ? undefined : 8 },
      }}
    >
      {showHeaders && !isPlaceholder && (
        <Box as="thead" display="contents">
          <Box as="tr" display="contents">
            {supportsSelection && (
              <HeaderCell top={top}>
                <Checkbox
                  isChecked={
                    data?.length > 0 &&
                    selectedRows?.length === data?.filter((row) => (disabled ? !disabled(row) : true))?.length
                  }
                  isIndeterminate={
                    data?.length > 0 &&
                    (selectedRows?.length ?? 0) > 0 &&
                    (selectedRows?.length ?? 0) < data?.filter((row) => (disabled ? !disabled(row) : true))?.length
                  }
                  onChange={onSelectAll}
                />
              </HeaderCell>
            )}
            {headerCells}
            {sortable && (
              <HeaderCell top={top} isSortMenu>
                <SortMenu options={sortOptions} />
              </HeaderCell>
            )}
          </Box>
        </Box>
      )}

      {loading ? (
        <Spinner size="lg" m="auto" />
      ) : isEmpty || error ? (
        <Placeholder content={placeholder} error={Boolean(error)} />
      ) : (
        <Box as="tbody" display="contents" position="relative">
          {rows}
        </Box>
      )}
    </Box>
  );
}

const getGridTemplateColumns = (columns, defaultMin, defaultMax) =>
  columns.map(({ min, max }) => `minmax(${min || defaultMin}, ${max || defaultMax})`).join(" ");

type TableRowProps = {
  row: any;
  primaryKey: string;
  selected: boolean;
  disabled?: (row: any) => boolean;
  height?: string;
  onClick?: RowClickHandler<any>;
  onSelect?: (value: number | string) => void;
  columns: TableColumn[];
  index: number;
  sortable: boolean;
  isDense: boolean;
};

const TableRow: FC<Readonly<TableRowProps>> = ({
  row,
  disabled,
  selected,
  height,
  columns,
  onClick,
  onSelect,
  primaryKey,
  sortable,
  isDense,
}) => {
  return (
    <Row
      clickable={Boolean(onClick)}
      disabled={disabled ? disabled(row) : false}
      primaryKey={primaryKey}
      row={row}
      selected={selected}
      onClick={onClick}
      onSelect={onSelect}
      sortable={sortable}
    >
      {columns.map((column: TableColumn, index) => (
        <TableCell
          isDense={isDense}
          key={column.name ? column.name : `column-${index}-cell`}
          column={column}
          height={height}
          row={row}
        />
      ))}
    </Row>
  );
};

const MemoizedTableRow = memo(TableRow);
