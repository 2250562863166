import { FC, ReactElement, useEffect, useMemo, useState } from "react";

import { ChevronDownIcon, PlayIcon } from "@heroicons/react/24/solid";
import { Button } from "@hightouchio/ui";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { Flex, Text } from "theme-ui";

import { useDraft } from "src/contexts/draft-context";
import { ResourcePermissionGrant, SyncQuery, SyncRequestsBoolExp, SyncRequestsOrderBy, useSyncRunsQuery } from "src/graphql";
import useHasPermission from "src/hooks/use-has-permission";
import { SyncRequestErrorInfo } from "src/types/sync-errors";
import { Column, Row } from "src/ui/box";
import { InfoModal } from "src/ui/modal/info-modal";
import { Popout } from "src/ui/popout";
import { RadioGroup } from "src/ui/radio";
import { BasicPagination, Table, useTableConfig } from "src/ui/table";
import { TextWithTooltip } from "src/ui/text";
import { Tooltip } from "src/ui/tooltip";
import { commaNumber, truncateNumber } from "src/utils/numbers";
import {
  DEPRECATED_ERROR,
  DLQ_RELEASE_TIMESTAMP,
  getSyncAttemptDiff,
  SyncStatus,
  SyncStatusBadge,
  syncStatusIsTerminal,
} from "src/utils/syncs";
import * as time from "src/utils/time";
import { openUrl } from "src/utils/urls";

import { SyncRequestErrorModal } from "./error-modals";
import { NumberBadge } from "./number-badge";

enum SyncRunsFilter {
  ALL_RUNS = "all_runs",
  NO_OPERATIONS = "no_operations",
  NO_SUCCESSFUL_OPERATIONS = "no_successful_operations",
}

enum SortKeys {
  RowsQueried = "query_run.size",
  CreatedAt = "created_at",
}

type Props = {
  onRun: () => void;
  sync: SyncQuery["syncs"][0];
  disableRowClick?: boolean;
};

const PAGE_SIZE = 10;

const getHasuraFilter = (type: SyncRunsFilter): SyncRequestsBoolExp => {
  switch (type) {
    case SyncRunsFilter.NO_OPERATIONS:
      return {
        sync_attempts: {
          _not: {
            add_checkpoint: { _eq: 0 },
            change_checkpoint: { _eq: 0 },
            remove_checkpoint: { _eq: 0 },
          },
        },
      };
    case SyncRunsFilter.NO_SUCCESSFUL_OPERATIONS:
      return {
        sync_attempts: { successful_operations_count: { _gt: 0 } },
      };
    default:
      // Get all attempts if they exist or not
      return {};
  }
};

export const Runs: FC<Readonly<Props>> = ({ sync, onRun, disableRowClick }) => {
  const navigate = useNavigate();
  const { editingDraft } = useDraft();
  const [runError, setError] = useState<SyncRequestErrorInfo & { syncStatus: SyncStatus }>();
  const [showUnsupported, setShowUnsupported] = useState<boolean>(false);
  const [runsFilter, setRunsFilter] = useState<SyncRunsFilter>(SyncRunsFilter.ALL_RUNS);

  const { limit, offset, orderBy, page, setPage, onSort } = useTableConfig<SyncRequestsOrderBy>({
    defaultSortKey: "created_at",
    sortOptions: Object.values(SortKeys),
    limit: PAGE_SIZE,
  });

  const hasuraFilter = useMemo(
    () => ({
      destination_instance_id: { _eq: sync.id },
      ...getHasuraFilter(runsFilter),
    }),
    [runsFilter],
  );

  const {
    data,
    error,
    isLoading: isLoading,
    isPreviousData,
  } = useSyncRunsQuery(
    {
      filter: hasuraFilter,
      offset,
      limit: limit + 1, // use limit + 1 to look ahead if another page is available
      orderBy,
    },
    {
      refetchInterval: 5000,
      notifyOnChangeProps: "tracked",
      keepPreviousData: true,
    },
  );

  const runs = useMemo(() => {
    const runs = data?.sync_requests;
    if (runs?.length === PAGE_SIZE + 1) {
      return runs.slice(0, PAGE_SIZE);
    }
    return runs;
  }, [data?.sync_requests]);

  useEffect(() => {
    // reset pagination when changing filter
    setPage(0);
  }, [runsFilter]);

  const { hasPermission: userCanViewRuns } = useHasPermission([
    { resource: "sync", grants: [ResourcePermissionGrant.Debugger], resource_id: sync.id },
  ]);

  const rowClick = ({ created_at, id }: { created_at: string; id: string }, event) => {
    const isCreatedAtBeforeDLQReleaseTimestamp = moment(created_at).unix() < DLQ_RELEASE_TIMESTAMP;

    if (isCreatedAtBeforeDLQReleaseTimestamp) {
      setShowUnsupported(true);
    } else if (userCanViewRuns) {
      const params = editingDraft ? `?editing=true` : "";
      openUrl(`/syncs/${sync.id}/runs/${id}${params}`, navigate, event);
    }
  };

  const columns = useMemo(
    () => [
      {
        name: "Status",
        max: "160px",
        min: "160px",
        cell: (sync_request) => <SyncStatusBadge request={sync_request} />,
      },
      {
        name: "Started",
        sortDirection: orderBy?.created_at,
        onClick: () => onSort(SortKeys.CreatedAt),
        cell: ({ sync_attempts, created_at: createdAt }) => {
          const attempt = sync_attempts?.[0];

          const duration = time.diff(attempt?.created_at, attempt?.finished_at || new Date().toUTCString());
          return (
            <Column sx={{ span: { height: "20px" } }}>
              <TextWithTooltip message={time.formatDatetime(createdAt) ?? ""} fontWeight="semibold" mb="2px">
                {time.formatDatetime(createdAt)}
              </TextWithTooltip>
              <Text sx={{ fontSize: 0, color: "base.5", fontWeight: "semi" }}>Duration: {duration}</Text>
            </Column>
          );
        },
      },
      {
        sortDirection: orderBy?.query_run?.size,
        onClick: () => onSort(SortKeys.RowsQueried),
        header: () => (
          <Flex sx={{ alignItems: "center", justifyContent: "flex-end", width: "100%", position: "relative", right: "-6px" }}>
            <Tooltip portal size={14} text="Number of rows your query returned from the Source.">
              <Text sx={{ whiteSpace: "nowrap", textAlign: "right" }}>Rows queried</Text>
            </Tooltip>
          </Flex>
        ),
        cell: ({ query_run }) => {
          return (
            <Column sx={{ alignItems: "flex-end", flex: 1 }}>
              <Text sx={{ fontWeight: "bold", height: "20px", mb: "2px" }}>
                {query_run?.size ? commaNumber(query_run.size) : "0"}
              </Text>
              <Text sx={{ fontSize: 0, color: "base.5", fontWeight: "semi" }}>Rows</Text>
            </Column>
          );
        },
      },
      {
        header: () => (
          <Flex sx={{ alignItems: "center", justifyContent: "flex-end", width: "100%", position: "relative" }}>
            <Tooltip portal size={14} text="Number of operations performed while we process your sync.">
              <Text>Operations</Text>
            </Tooltip>
          </Flex>
        ),
        cell: ({ planner_type, add_executed, change_executed, remove_executed, query_run }) => {
          const added = add_executed;
          const changed = change_executed;
          const removed = remove_executed;

          const total = added + changed + removed;

          let operationCountPlaceholder: string;
          if (total === 0) {
            if (planner_type === "all") {
              operationCountPlaceholder = commaNumber(query_run?.size);
            } else {
              operationCountPlaceholder = "--";
            }
            return (
              <Column sx={{ alignItems: "flex-end", flex: 1 }}>
                <Text sx={{ fontWeight: "bold", height: "20px", mb: "2px" }}>{operationCountPlaceholder}</Text>
                <Text sx={{ fontSize: 0, color: "base.5", fontWeight: "semi" }}>Operations</Text>
              </Column>
            );
          }

          return (
            <Popout
              content={
                <>
                  <Row sx={{ justifyContent: "space-between", width: "100%", p: 3, borderBottom: "small" }}>
                    <Text sx={{ color: "base.7", mr: 6, fontWeight: "semi" }}>Add row</Text>
                    <Text sx={{}}>{added}</Text>
                  </Row>
                  <Row sx={{ justifyContent: "space-between", width: "100%", p: 3, borderBottom: "small" }}>
                    <Text sx={{ color: "base.7", mr: 6, fontWeight: "semi" }}>Change row</Text>
                    <Text sx={{}}>{changed}</Text>
                  </Row>
                  <Row sx={{ justifyContent: "space-between", width: "100%", p: 3 }}>
                    <Text sx={{ color: "base.7", mr: 6, fontWeight: "semi" }}>Remove row</Text>
                    <Text sx={{}}>{removed}</Text>
                  </Row>
                </>
              }
              contentSx={{ minWidth: "200px", p: 0 }}
              offset={-14}
              placement="bottom-end"
              strategy="fixed"
              sx={{ width: "min-content", ml: "auto" }}
              onClick={(event) => {
                event.stopPropagation();
              }}
            >
              <Column sx={{ alignItems: "flex-end", position: "relative" }}>
                <Row sx={{ justifyContent: "flex-end", alignItems: "center" }}>
                  <Text sx={{ fontWeight: "bold", height: "20px", mb: "2px" }}>{commaNumber(total)}</Text>
                  <ChevronDownIcon width={16} />
                </Row>

                <Text sx={{ fontSize: 0, color: "base.5", fontWeight: "semi" }}>Operations</Text>
              </Column>
            </Popout>
          );
        },
      },
      {
        header: () => (
          <Flex sx={{ alignItems: "center", justifyContent: "flex-end", width: "100%", position: "relative" }}>
            <Tooltip portal size={14} text="Breakdown of operations that were successful or rejected during your sync.">
              <Text>Results</Text>
            </Tooltip>
          </Flex>
        ),
        cell: ({
          status_computed,
          error,
          planner_type,
          query_run,
          sync_attempts,
          add_executed,
          change_executed,
          remove_executed,
        }) => {
          const attempt = sync_attempts?.[0];
          const diff = getSyncAttemptDiff(attempt);
          const added = add_executed - (diff?.rejected?.add ?? 0);
          const changed = change_executed - (diff?.rejected?.change ?? 0);
          const removed = remove_executed - (diff?.rejected?.remove ?? 0);
          const successful = added + changed + removed;
          const rejected = (diff?.rejected?.add ?? 0) + (diff?.rejected?.change ?? 0) + (diff?.rejected?.remove ?? 0);

          if (planner_type === "all" || (rejected === 0 && successful === 0)) {
            let countPlaceholder: string | ReactElement = "0";

            if (planner_type === "all") {
              if (syncStatusIsTerminal(status_computed) && !error) {
                return (
                  <Column sx={{ alignItems: "flex-end", flex: 1 }}>
                    <Row gap={1} sx={{ mb: "2px" }}>
                      <NumberBadge color="green" tooltip="Successful operations" value={query_run?.size} />
                      <NumberBadge color="red" tooltip="Rejected operations" value={0} />
                    </Row>
                    <Text sx={{ fontSize: 0, color: "base.5", fontWeight: "semi" }}>Operations</Text>
                  </Column>
                );
              } else {
                countPlaceholder = "--";
              }
            }

            return (
              <Column sx={{ alignItems: "flex-end", flex: 1 }}>
                <Text sx={{ fontWeight: "bold", height: "20px", mb: "2px" }}>{countPlaceholder}</Text>
                <Text sx={{ fontSize: 0, color: "base.5", fontWeight: "semi" }}>Operations</Text>
              </Column>
            );
          }

          return (
            <Column sx={{ alignItems: "flex-end", flex: 1 }}>
              <Row gap={1} sx={{ mb: "2px" }}>
                <NumberBadge color="green" tooltip="Successful operations" value={successful} />
                <NumberBadge color="red" tooltip="Rejected operations" value={rejected} />
              </Row>
              <Text sx={{ fontSize: 0, color: "base.5", fontWeight: "semi" }}>Operations</Text>
            </Column>
          );
        },
      },
      {
        name: "",
        cell: ({
          error: syncRequestError,
          sync_attempts,
          status,
          query_run,
          planner_type,
          add_executed,
          change_executed,
          remove_executed,
          error_code_detail,
        }) => {
          const current = true; //recentSyncRequest?.id === id;
          const attempt = sync_attempts?.[0];

          // Use `sync_requests.error` if present, then fall back to `sync_attempts.error`.
          const error: SyncRequestErrorInfo =
            syncRequestError ||
            (attempt?.error && ![DEPRECATED_ERROR, "Error: " + DEPRECATED_ERROR].includes(attempt?.error)
              ? { message: attempt?.error }
              : undefined);

          if (error && error_code_detail) error.errorCodeDetail = error_code_detail;

          if (
            current &&
            !attempt?.finished_at &&
            (status === SyncStatus.ACTIVE || status === SyncStatus.QUERYING || status === SyncStatus.PREPARING)
          ) {
            // TODO: After URQL changes update this to show current percent
            return <Text sx={{ fontWeight: "bold", color: "base.5", ml: "auto" }}>Processing</Text>;
          }

          if (error && error.message !== DEPRECATED_ERROR && error.message !== "Error: " + DEPRECATED_ERROR) {
            return (
              <Button
                size="sm"
                ml="auto"
                variant="danger"
                onClick={(event) => {
                  event.preventDefault();
                  event.stopPropagation();
                  setError({
                    ...(error ?? {}),
                    syncStatus: status as SyncStatus,
                  });
                }}
              >
                View error
              </Button>
            );
          }

          const diff = getSyncAttemptDiff(attempt);
          const added = add_executed - (diff?.rejected?.add ?? 0);
          const changed = change_executed - (diff?.rejected?.change ?? 0);
          const removed = remove_executed - (diff?.rejected?.remove ?? 0);
          const successful = added + changed + removed;
          const rejected = (diff?.rejected?.add ?? 0) + (diff?.rejected?.change ?? 0) + (diff?.rejected?.remove ?? 0);
          const total = successful + rejected;

          if (total === 0) {
            if (planner_type === "all") {
              return (
                <Row sx={{ justifyContent: "end", flex: 1 }}>
                  <Text sx={{ fontWeight: "bold", color: "base.5", textAlign: "right", mr: 2 }}>
                    {query_run?.size === 0 ? "No rows to sync" : "All rows synced"}
                  </Text>
                  <Tooltip text="This sync does not employ diffing and syncs the entire result set of the model to the destination on each run." />
                </Row>
              );
            } else {
              return <Text sx={{ pt: 2, fontWeight: "bold", color: "base.5", ml: "auto" }}>No changes</Text>;
            }
          }

          const percent = (successful / total) * 100;

          return (
            <Column sx={{ alignItems: "flex-end", flex: 1 }}>
              <Text sx={{ fontWeight: "bold", height: "20px" }}>{truncateNumber(percent)}%</Text>
              <Text sx={{ fontSize: 0, color: "base.5", fontWeight: "semi" }}>Synced</Text>
            </Column>
          );
        },
      },
    ],
    [orderBy],
  );

  return (
    <>
      <Column sx={{ alignItems: "flex-start" }}>
        {runs?.[0]?.planner_type === "all" || (runsFilter === SyncRunsFilter.ALL_RUNS && !runs?.length) ? null : (
          <Popout
            content={
              <RadioGroup
                options={[
                  { label: "Show all runs", value: SyncRunsFilter.ALL_RUNS },
                  { label: "Hide runs with 0 operations", value: SyncRunsFilter.NO_OPERATIONS },
                  { label: "Hide runs with 0 successful operations", value: SyncRunsFilter.NO_SUCCESSFUL_OPERATIONS },
                ]}
                size="small"
                value={runsFilter}
                onChange={(val) => setRunsFilter(val as SyncRunsFilter)}
              />
            }
            contentSx={{ width: "max-content", p: 2 }}
            sx={{ mb: 4 }}
          >
            <Button variant="secondary" directionIcon={ChevronDownIcon}>
              Filter
            </Button>
          </Popout>
        )}

        <Table
          columns={columns}
          data={runs}
          error={Boolean(error)}
          loading={isLoading || isPreviousData}
          placeholder={{
            title: "No sync runs",
            body: "Set a schedule or manually run the sync.",
            error: "Runs failed to load, please try again.",
            button: (
              <Button onClick={onRun} variant="primary" icon={PlayIcon}>
                Run sync
              </Button>
            ),
          }}
          onRowClick={disableRowClick ? undefined : rowClick}
        />
        <BasicPagination disableNextPage={data?.sync_requests?.length !== PAGE_SIZE + 1} page={page} setPage={setPage} />
      </Column>

      <SyncRequestErrorModal
        isOpen={Boolean(runError)}
        onClose={() => setError(undefined)}
        sync={sync}
        syncRequestError={runError}
        syncStatus={runError?.syncStatus}
      />

      <InfoModal isOpen={showUnsupported} title="Run details unsupported" onClose={() => setShowUnsupported(false)}>
        <Text>Run details were not supported at the time of this run. Please check a more recent run to view details.</Text>
      </InfoModal>
    </>
  );
};
