import { FC } from "react";

import { Row, Select, Text } from "@hightouchio/ui";
import { set, format, getHours, getMinutes } from "date-fns";

import { AM_PM_OPTIONS, HOUR_OPTIONS, MINUTE_OPTIONS } from "./constants";

export type Props = {
  value: Date;
  onChange: (value: Date) => void;
  onSelectClose?: (elementId: string) => void;
  onSelectOpen?: (elementId: string) => void;
};

export const TimeSelect: FC<Readonly<Props>> = ({ value, onChange, onSelectClose, onSelectOpen }) => {
  const militaryHours = getHours(value);
  const minutes = getMinutes(value);

  const isPM = militaryHours >= 12;
  const hours = militaryHours === 0 ? 12 : militaryHours > 12 ? militaryHours - 12 : militaryHours;

  return (
    <Row align="center" height="32px" gap={1} sx={{ button: { fontWeight: "medium" } }}>
      <Select
        options={HOUR_OPTIONS}
        value={hours}
        width="auto"
        onChange={(hours) => {
          if (hours === undefined) {
            return;
          }

          onChange(
            set(value, {
              hours: hours === 12 && !isPM ? 0 : isPM && hours < 12 ? hours + 12 : hours,
            }),
          );
        }}
        onClose={() => onSelectClose?.("time-select-1")}
        onOpen={() => onSelectOpen?.("time-select-1")}
      />
      <Text fontWeight="medium">:</Text>
      <Select
        options={MINUTE_OPTIONS}
        value={minutes}
        width="auto"
        onChange={(minutes) => onChange(set(value, { minutes }))}
        onClose={() => onSelectClose?.("time-select-2")}
        onOpen={() => onSelectOpen?.("time-select-2")}
      />
      <Select
        options={AM_PM_OPTIONS}
        value={isPM ? "PM" : "AM"}
        width="auto"
        onChange={(val) => onChange(set(value, { hours: val === "PM" ? militaryHours + 12 : militaryHours - 12 }))}
        onClose={() => onSelectClose?.("time-select-3")}
        onOpen={() => onSelectOpen?.("time-select-3")}
      />
      <Text>({format(value, "z")})</Text>
    </Row>
  );
};
