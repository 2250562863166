import { useState } from "react";

import { useToast } from "@hightouchio/ui";
import * as Sentry from "@sentry/browser";
import { cloneDeep } from "lodash";
import { useQueryClient } from "react-query";

import { useUser } from "src/contexts/user-context";
import {
  RunVisualBackgroundQueryVariables,
  useRunVisualBackgroundQuery,
  useVisualQueryBackgroundResultQuery,
} from "src/graphql";
import { VisualQueryFilter } from "src/types/visual";

interface UseCalculateAudienceSize {
  audienceId: string | undefined;
  parentModelId: string;
  sourceId: string;
  visualQueryFilter: VisualQueryFilter;
}

/**
 * A hook that can be used to run a count-only audience preview.
 *
 * It exposes functions to update stored audience size since breakdowns, overlaps
 * and previews in `useModelRun` can return an audience size.
 */
export const useCalculateAudienceSize = ({
  audienceId,
  parentModelId,
  sourceId,
  visualQueryFilter,
}: UseCalculateAudienceSize) => {
  const { featureFlags } = useUser();
  const { toast } = useToast();
  const queryClient = useQueryClient();

  const [audienceSize, setAudienceSize] = useState<number | null>(null);
  const [lastUsedFilter, setLastUsedFilter] = useState<VisualQueryFilter | null>(null);
  const [audienceSizeUpdatedAt, setAudienceSizeUpdatedAt] = useState<number | null>(null);

  const updateAudienceSize = (size: number, filter?: VisualQueryFilter) => {
    // Anything can set the audience size. We need to track which filter was
    // run on the last audience size update.

    setAudienceSize(size);
    if (filter) {
      setLastUsedFilter(filter);
    }

    setAudienceSizeUpdatedAt(Date.now());
  };

  const [backgroundJobId, setBackgroundJobId] = useState<string | null>(null);
  const [queryVariables, setQueryVariables] = useState<null | RunVisualBackgroundQueryVariables>(null);
  const [shouldPollForResults, setShouldPollForResults] = useState(false);

  const runVisualBackgroundQuery = useRunVisualBackgroundQuery(
    {
      audienceId,
      filter: visualQueryFilter,
      parentModelId,
      sourceId,
      countOnly: true,
    },
    {
      enabled: false,
      // Disable caching the query results.
      cacheTime: 0,
    },
  );

  useVisualQueryBackgroundResultQuery(
    {
      jobId: backgroundJobId ?? "",
      page: 0,
      filter: visualQueryFilter,
      parentModelId,
    },
    {
      enabled: Boolean(backgroundJobId) && Boolean(shouldPollForResults),
      refetchInterval: 500,
      // Disable caching the query results.
      cacheTime: 0,
      onError: (error) => {
        Sentry.captureException(error);
        setShouldPollForResults(false);
        setBackgroundJobId(null);

        toast({
          id: "calculate-size-query",
          title: "Size calculation failed",
          message: error.message,
          variant: "error",
        });
      },
      onSuccess: (data) => {
        if (!data.visualQueryBackgroundResult) {
          return;
        }

        if (data.visualQueryBackgroundResult.__typename === "SuccessfulQueryResponse") {
          setAudienceSize(
            data.visualQueryBackgroundResult.numRowsWithoutLimit === null
              ? null
              : Number(data.visualQueryBackgroundResult.numRowsWithoutLimit),
          );
        } else {
          toast({
            id: "calculate-size-query",
            title: "Size calculation failed",
            message: data.visualQueryBackgroundResult.error,
            variant: "error",
          });
        }

        setShouldPollForResults(false);
        setBackgroundJobId(null);
      },
    },
  );

  const calculateAudienceSize = async () => {
    setShouldPollForResults(true);
    setQueryVariables({
      audienceId,
      filter: visualQueryFilter,
      parentModelId,
      sourceId,
      countOnly: true,
      // NOTE: the feature flag names on the right side need to match the names
      // in the database
      disableRowCounter: featureFlags?.sql_row_counter_disabled,
    });

    const currentFilter = cloneDeep(visualQueryFilter);

    try {
      const result = await runVisualBackgroundQuery.refetch();

      setLastUsedFilter(visualQueryFilter);
      setBackgroundJobId(result.data?.visualQueryBackground.jobId ?? null);
    } catch (error) {
      setLastUsedFilter(currentFilter);
      setShouldPollForResults(false);
      setBackgroundJobId(null);

      toast({
        id: "calculate-size-query",
        title: "Failed to calculate size",
        message: "Please try again",
        variant: "error",
      });

      Sentry.captureException(error);
    }
  };

  const cancelQuery = async () => {
    if (queryVariables !== null) {
      const queryKey = useRunVisualBackgroundQuery.getKey(queryVariables);
      queryClient.cancelQueries([queryKey]);
      setShouldPollForResults(false);

      toast({
        id: "calculate-size-query",
        title: "Size calculation cancelled",
        variant: "success",
      });
    }
  };

  return {
    audienceSize,
    audienceSizeUpdatedAt,
    isLoading: shouldPollForResults,
    lastUsedFilter,

    calculateAudienceSize,
    cancelCalculateAudienceSize: cancelQuery,
    updateAudienceSize,
  };
};
