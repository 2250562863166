import { FC, useEffect, useMemo, useState } from "react";

import { ColumnType, TraitConfig, TraitType } from "@hightouch/lib/query/visual/types";
import {
  Column,
  FormField,
  Link,
  Radio,
  RadioGroup,
  Select,
  ChakraUnorderedList,
  ChakraListItem,
  Box,
  Combobox,
  TextInput,
  useToast,
  Text,
  Textarea,
} from "@hightouchio/ui";
import * as Sentry from "@sentry/react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import {
  ModelRelationships,
  ParentModelsQuery,
  TraitDefinitionsConstraint,
  useCreateTraitMutation,
  useParentModelsQuery,
} from "src/graphql";

import { EventColumn, RelationColumn } from "../explore/filter-popover/constants";
import { IconBox } from "../explore/filter-popover/icon-box";
import { SimpleWizard, SimpleWizardStep } from "../simple-wizard";
import { SqlEditor } from "../sql-editor";
import { CalculationSummary } from "./calculation-summary";
import { Drawer } from "./drawer";
import { PreviewTrait } from "./preview-trait";
import { TraitConditions } from "./trait-conditions";
import { CALCULATION_METHODS, CalculationMethod, validateConfig } from "./utils";

type Props = {
  isTemplate: boolean;
};

type FormProps = {
  name: string;
  description: string;
  parent_model: ParentModelsQuery["segments"][0];
  calculation_method: CalculationMethod;
  relationship: ModelRelationships;
  type: TraitType;
  config: TraitConfig;
};

export const CreateTrait: FC<Readonly<Props>> = ({ isTemplate }) => {
  const navigate = useNavigate();
  const { toast } = useToast();
  const [step, setStep] = useState(0);

  const createTrait = useCreateTraitMutation();

  const form = useForm<FormProps>();

  const { control, handleSubmit, setValue, watch } = form;

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore - Circular reference problem with Column types
  const parentModel = watch("parent_model");
  const calculationMethod = watch("calculation_method");
  const relationship = watch("relationship");
  const type = watch("type");
  const config = watch("config");
  const name = watch("name");

  const columns = useMemo(() => {
    return parentModel?.relationships.find(({ id }) => id === relationship?.id)?.to_model?.filterable_audience_columns;
  }, [parentModel, relationship?.id]);

  const propertyOptions = useMemo(() => {
    if (columns) {
      const validColumns = type === TraitType.Sum ? columns.filter((column) => column.type === ColumnType.Number) : columns;
      return validColumns.map(({ alias, name, column_reference }) => ({
        label: alias || name,
        value: column_reference,
      }));
    }
    return [];
  }, [columns, type]);

  const { data: parentModelsData, isLoading: isParentModelsLoading } = useParentModelsQuery({
    filters: { is_schema: { _eq: true }, _not: { event: {} }, primary_key: { _is_null: false } },
  });

  useEffect(() => {
    if (calculationMethod === CalculationMethod.Count) {
      setValue("type", TraitType.Count);
    } else if (calculationMethod === CalculationMethod.Sql) {
      setValue("type", TraitType.RawSql);
    }
  }, [calculationMethod, setValue]);

  const onClose = () => {
    isTemplate ? navigate("/traits/templates") : navigate("/traits/active");
  };

  const onSubmit = async (formData: FormProps) => {
    try {
      const newTrait = await createTrait.mutateAsync({
        input: {
          name: formData.name,
          description: formData.description,
          parent_model_id: formData.parent_model.id,
          relationship_id: formData.relationship.id,
          type: formData.type,
          config: formData.config,
          is_template: isTemplate,
        },
      });

      toast({
        id: "create-trait",
        title: "Trait was created successfully",
        variant: "success",
      });

      const newTraitId = newTrait.insert_trait_definitions_one?.id;
      navigate(`/traits/${isTemplate ? "templates" : "active"}/${newTraitId}`);
    } catch (error) {
      toast({
        id: "create-trait",
        title: "Trait failed to be created",
        message: error.message.includes(TraitDefinitionsConstraint.TraitDefinitionsNameParentModelIdKey)
          ? `There is an existing ${isTemplate ? "template" : "trait"} named "${
              formData.name
            }" associated with this parent model. Please choose a different name and try again.`
          : error.message,
        variant: "error",
      });
      Sentry.captureException(error);
    }
  };

  const parentModelOptions =
    parentModelsData?.segments.map((segment) => {
      const relationshipCount = segment.relationships.map((relationship) => relationship.to_model).length;

      return {
        label: segment.name,
        description: relationshipCount === 0 ? "No related or event models" : undefined,
        value: segment,
        logo: segment.connection?.definition.icon || "",
        isDisabled: relationshipCount === 0,
      };
    }) ?? [];

  const ParentModelSelect = parentModelOptions.length > 10 ? Combobox : Select;

  const relationshipOptions = parentModel
    ? parentModel.relationships.map((relationship) => {
        const modelType = relationship.to_model.event ? EventColumn : RelationColumn;
        const Icon = () => <IconBox bg={modelType.color} boxSize={4} icon={modelType.icon} iconSize={3} />;

        return {
          label: relationship.name || relationship.to_model.name,
          value: relationship,
          icon: Icon,
        };
      })
    : [];

  const RelationshipSelect = relationshipOptions.length > 10 ? Combobox : Select;

  const steps: SimpleWizardStep[] = [
    {
      title: "Method",
      continueLabel: "Continue to calculation",
      disabled: !parentModel || !calculationMethod,
      render: () => (
        <Column gap={6}>
          <Column gap={2}>
            <Text fontWeight="medium">Create a new trait</Text>
            <Text>
              A trait lets you perform a calculation on a column of a related/event model. It acts as a new column on a parent
              model and can be utilized in associated Audiences.{" "}
              <Link href={`${import.meta.env.VITE_DOCS_URL}/customer-studio/traits`} isExternal>
                Learn more.
              </Link>
            </Text>
          </Column>
          <FormField label="Parent model" tip="This trait will be available in the audiences of this parent model.">
            <Controller
              control={control}
              name="parent_model"
              render={({ field }) => (
                <ParentModelSelect
                  isDisabled={isParentModelsLoading}
                  isLoading={isParentModelsLoading}
                  isOptionDisabled={(option) => option.isDisabled}
                  options={parentModelOptions}
                  optionAccessory={(option: any) => ({
                    type: "image",
                    url: option.logo,
                  })}
                  placeholder="Select a parent model"
                  value={field.value}
                  width="100%"
                  onChange={field.onChange}
                />
              )}
            />
          </FormField>
          <FormField label="Calculation method">
            <Controller
              control={control}
              name="calculation_method"
              render={({ field }) => (
                <Box display="grid" gridTemplateColumns="60% 40%" gap={1} width="100%">
                  <RadioGroup value={field.value} onChange={field.onChange}>
                    {Object.values(CALCULATION_METHODS).map(({ label, value, description }) => (
                      <Radio key={label} label={label} description={description} value={value} />
                    ))}
                  </RadioGroup>
                  {field.value && (
                    <Column bg="base.lightBackground" p={4}>
                      <Text color="text.secondary" fontWeight="medium">
                        Aggregation examples:
                      </Text>

                      <ChakraUnorderedList>
                        {CALCULATION_METHODS[field.value]?.examples.map((tip: string) => (
                          <ChakraListItem key={tip}>{tip}</ChakraListItem>
                        ))}
                      </ChakraUnorderedList>
                    </Column>
                  )}
                </Box>
              )}
            />
          </FormField>
        </Column>
      ),
    },
    {
      title: "Calculation",
      continueLabel: "Continue to finalize",
      disabled: !relationship || !validateConfig(type, config),
      render: () => (
        <Column gap={6}>
          <FormField
            label="Select a related or event model"
            tip={isTemplate ? "Add filter conditions once this template is used in a trait" : undefined}
          >
            <Controller
              control={control}
              name="relationship"
              render={({ field }) => (
                <RelationshipSelect
                  options={relationshipOptions}
                  optionAccessory={(option: any) => ({
                    type: "icon",
                    icon: option.icon,
                  })}
                  placeholder="Select a model"
                  value={field.value ?? ""}
                  width="100%"
                  onChange={field.onChange}
                />
              )}
            />
          </FormField>

          {!isTemplate && (
            <Column border="1px solid" borderColor="base.border" borderRadius="6px" p={4} gap={4}>
              <FormProvider {...form}>
                <TraitConditions parentModel={parentModel} relationship={relationship} />
              </FormProvider>
            </Column>
          )}

          {calculationMethod === CalculationMethod.Aggregation && (
            <>
              <FormField label="Aggregation type">
                <Controller
                  control={control}
                  name="type"
                  render={({ field }) => (
                    <Select
                      isDisabled={!relationship}
                      options={[
                        { label: "Sum", value: TraitType.Sum },
                        { label: "Average", value: TraitType.Average },
                      ]}
                      placeholder="Select an aggregation type"
                      {...field}
                      width="100%"
                    />
                  )}
                />
              </FormField>
              <FormField label="Sum by">
                <Controller
                  control={control}
                  name="config.column"
                  render={({ field }) => (
                    <Select
                      isDisabled={!type}
                      options={propertyOptions}
                      placeholder="Select a column"
                      width="100%"
                      {...field}
                    />
                  )}
                />
              </FormField>
            </>
          )}
          {calculationMethod === CalculationMethod.Count && (
            <FormField label="Count by">
              <Controller
                control={control}
                name="config.column"
                render={({ field }) => (
                  <Select
                    isDisabled={!relationship}
                    options={propertyOptions}
                    placeholder="Select a column"
                    width="100%"
                    {...field}
                  />
                )}
              />
            </FormField>
          )}
          {calculationMethod === CalculationMethod.Occurrence && (
            <>
              <FormField label="Occurrence type">
                <Controller
                  control={control}
                  name="type"
                  render={({ field }) => (
                    <Select
                      options={[
                        { label: "First", value: TraitType.First },
                        { label: "Last", value: TraitType.Last },
                        { label: "Most frequent", value: TraitType.MostFrequent },
                        { label: "Least frequent", value: TraitType.LeastFrequent },
                      ]}
                      placeholder="Select an occurrence type"
                      width="100%"
                      {...field}
                    />
                  )}
                />
              </FormField>
              <FormField label="Trait value" tip="The column that represents the value">
                <Controller
                  control={control}
                  name="config.toSelect"
                  render={({ field }) => (
                    <Select
                      isDisabled={!relationship}
                      options={propertyOptions}
                      placeholder="Select a column"
                      width="100%"
                      {...field}
                    />
                  )}
                />
              </FormField>
              <FormField label="Order by" tip="Rows will be ordered according to this column">
                <Controller
                  control={control}
                  name="config.orderBy"
                  render={({ field }) => (
                    <Select
                      isDisabled={!relationship}
                      options={propertyOptions}
                      placeholder="Select a column"
                      width="100%"
                      {...field}
                    />
                  )}
                />
              </FormField>
            </>
          )}
          {calculationMethod === CalculationMethod.Sql && (
            <>
              <FormField label="SQL" description="Rows will be aggregated according to a custom SQL aggregation.">
                <Controller
                  control={control}
                  name="config.aggregation"
                  render={({ field }) => (
                    <SqlEditor
                      placeholder={"CASE WHEN SUM({{column \"price\"}}) > 100 THEN 'high' ELSE 'low' END`"}
                      source={parentModel?.connection}
                      {...field}
                    />
                  )}
                />
              </FormField>
              <FormField label="Default value" tip="Defines the value when the above SQL returns no rows.">
                <Controller
                  control={control}
                  name="config.defaultValue"
                  render={({ field }) => (
                    <TextInput placeholder="Enter the default value" width="100%" {...field} value={field.value || ""} />
                  )}
                />
              </FormField>
              <FormField label="Property type" tip="Defines the type of resulting value">
                <Controller
                  control={control}
                  name="config.resultingType"
                  render={({ field }) => (
                    <Select
                      options={[
                        {
                          value: ColumnType.Boolean,
                          label: "Boolean",
                        },
                        {
                          value: ColumnType.Number,
                          label: "Number",
                        },
                        {
                          value: ColumnType.String,
                          label: "String",
                        },
                        {
                          value: ColumnType.Timestamp,
                          label: "Timestamp",
                        },
                        {
                          value: ColumnType.Date,
                          label: "Date",
                        },
                        {
                          value: ColumnType.JsonArrayNumbers,
                          label: "JSON Array (Numbers)",
                        },
                        {
                          value: ColumnType.JsonArrayStrings,
                          label: "JSON Array (Strings)",
                        },
                      ]}
                      placeholder="Select a column type"
                      width="100%"
                      {...field}
                    />
                  )}
                />
              </FormField>
            </>
          )}

          <PreviewTrait
            parentModel={parentModel}
            trait={{ type, config, relationshipId: relationship?.id }}
            isDisabled={Boolean(!relationship || !validateConfig(type, config))}
          />
        </Column>
      ),
    },
    {
      title: "Finalize",
      continueLabel: isTemplate ? "Save template" : "Create trait",
      disabled: !name,
      render: () => (
        <Column gap={6}>
          <FormField label="Name" tip="Shows up in the audience query builder when selecting from a list of traits.">
            <Column gap={6}>
              <Controller
                control={control}
                name="name"
                render={({ field }) => <TextInput placeholder="Enter a name" width="100%" {...field} />}
              />
              <Controller
                control={control}
                name="description"
                render={({ field }) => <Textarea placeholder="Enter a description" width="100%" {...field} />}
              />
            </Column>
          </FormField>

          <Column>
            <Text fontWeight="medium" mb={1}>
              Calculation summary
            </Text>
            <Box borderRadius="6px" border="1px solid" borderColor="base.border" p={4}>
              <CalculationSummary type={type} config={config} model={relationship.to_model} />
            </Box>
          </Column>
        </Column>
      ),
    },
  ];

  return (
    <Drawer isOpen onClose={onClose}>
      <SimpleWizard
        setStep={setStep}
        step={step}
        steps={steps}
        title="New trait"
        onCancel={onClose}
        onSubmit={handleSubmit(onSubmit)}
      />
    </Drawer>
  );
};
