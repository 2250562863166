import { useEffect, useState, FC } from "react";

import { ArrowPathIcon } from "@heroicons/react/24/outline";
import { Combobox, IconButton, Row } from "@hightouchio/ui";

import { QueryType, Model } from "src/types/models";
import { useModelRun, useQueryState, useUpdateQuery } from "src/utils/models";

type Props = {
  model: Model;
  value: string;
  columns?: { name: string }[];
  onChange: (value: string) => void;
  creatable?: boolean;
  isDisabled?: boolean;
  isLoading?: boolean;
};

export const ColumnSelect: FC<Readonly<Props>> = ({ model, columns, creatable, onChange, value, isDisabled, isLoading }) => {
  const { queryState, initQueryState } = useQueryState();
  const [loading, setLoading] = useState<boolean>(false);

  const type = model?.query_type;
  const source = model?.connection;
  const cols = columns || model?.columns;
  const columnOptions: { value: string; label: string }[] = cols ? cols.map(({ name }) => ({ value: name, label: name })) : [];

  const update = useUpdateQuery();

  const { runQuery } = useModelRun(type as QueryType | undefined, undefined, {
    modelId: model?.id,
    variables: { sourceId: source?.id, ...queryState },
    onCompleted: async ({ columns }, error) => {
      if (!error) {
        await update({ model, columns });
      }
      setLoading(false);
    },
  });

  useEffect(() => {
    initQueryState(model);
  }, [model]);

  return (
    <Row gap={2} align="center">
      <Combobox
        isDisabled={isDisabled}
        supportsCreatableOptions={creatable}
        isLoading={loading || isLoading}
        options={columnOptions}
        value={value}
        onChange={(value) => {
          if (value) {
            onChange(value);
          }
        }}
        onCreateOption={(newValue) => {
          onChange(newValue);
        }}
        placeholder="Select a column..."
      />
      {!isDisabled && (
        <IconButton
          isDisabled={loading}
          variant="secondary"
          aria-label="Refresh columns"
          icon={ArrowPathIcon}
          onClick={async () => {
            setLoading(true);
            await runQuery({
              limit: true,
              // No reason to include this here, since it only increases the
              // complexity of the query and doesn't affect the results.
              disableRowCounter: true,
            });
          }}
        />
      )}
    </Row>
  );
};
