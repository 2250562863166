export interface HightouchRegionConfig {
  friendlyName: string;
  apiEndpoint: string;
  ipAddresses: string[];

  tunnelsSupported: boolean;
  reverseTunnelAddress?: string;
}

export enum HightouchRegionOptions {
  AWS_US_EAST_1 = "aws-us-east-1",
  AWS_EU_WEST_1 = "aws-eu-west-1",
  GCP_US_EAST_4 = "gcp-us-east4",
  AWS_AP_SOUTH_1 = "aws-ap-south-1",
}

export const HightouchRegions: Record<string, HightouchRegionConfig> = {
  [HightouchRegionOptions.AWS_US_EAST_1]: {
    friendlyName: "United States (aws-us-east-1)",
    apiEndpoint: import.meta.env.VITE_API_REGIONAL_URL_AWS_US_EAST_1 as string,
    ipAddresses: ["54.196.30.169", "52.72.201.213", "18.213.226.96", "3.224.126.197", "3.217.26.199"],

    tunnelsSupported: true,
    reverseTunnelAddress: "tunnel.us-east-1.hightouch.io",
  },

  [HightouchRegionOptions.AWS_EU_WEST_1]: {
    friendlyName: "European Union (aws-eu-west-1)",
    apiEndpoint: import.meta.env.VITE_API_REGIONAL_URL_AWS_EU_WEST_1 as string,
    ipAddresses: ["34.255.226.212", "52.16.84.199", "52.211.114.138"],

    tunnelsSupported: true,
    reverseTunnelAddress: "tunnel.eu-west-1.hightouch.io",
  },

  [HightouchRegionOptions.GCP_US_EAST_4]: {
    friendlyName: "United States (gcp-us-east4)",
    apiEndpoint: import.meta.env.VITE_API_REGIONAL_URL_GCP_US_EAST4 as string,
    ipAddresses: ["34.145.155.148", "35.245.150.71", "35.245.58.91"],

    tunnelsSupported: false,
    reverseTunnelAddress: "tunnel.gcp-us-east4.hightouch.com",
  },

  [HightouchRegionOptions.AWS_AP_SOUTH_1]: {
    friendlyName: "Asia Pacific (aws-ap-south-1)",
    apiEndpoint: import.meta.env.VITE_API_REGIONAL_URL_AWS_AP_SOUTH_1 as string,
    ipAddresses: ["13.126.190.7", "65.1.90.146"],

    tunnelsSupported: false,
  },
};

export function getHightouchRegionConfig(region: string): HightouchRegionConfig {
  const regionConfig = HightouchRegions[region];
  if (!regionConfig) {
    throw new Error("Invalid Hightouch region: " + region);
  }
  return regionConfig;
}
