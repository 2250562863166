import { FC, useEffect, useState } from "react";

import {
  Button,
  Column,
  DeleteIcon,
  Menu,
  MenuActionsButton,
  MenuDivider,
  MenuItem,
  MenuList,
  PlayIcon,
  Row,
  Spinner,
  Switch,
  Tabs,
  Tab,
  TabList,
  Text,
  useToast,
  ArrowRightIcon,
  DestinationBadge,
} from "@hightouchio/ui";
import { captureException } from "@sentry/browser";
import { Navigate, Outlet, Route, Routes, useNavigate, useOutletContext, useParams } from "react-router-dom";

import { IntegrationIcon } from "src/components/integrations/integration-icon";
import { DeleteConfirmationModal } from "src/components/modals/delete-confirmation-modal";
import { Permission } from "src/components/permission";
import { Warning } from "src/components/warning";
import { PermissionProvider } from "src/contexts/permission-context";
import {
  ResourcePermissionGrant,
  SyncQuery,
  useDeleteSyncMutation,
  useStartSyncRunMutation,
  useSyncQuery,
  useSyncRunsQuery,
  useUpdateSyncMutation,
  useUpdateSyncRequestMutation,
} from "src/graphql";
import useHasPermission from "src/hooks/use-has-permission";
import * as analytics from "src/lib/analytics";
import { Watermark } from "src/partner/watermark";
import { getObjectName, SyncStatus, syncStatusIsTerminal } from "src/utils/syncs";
import { useQueryString } from "src/utils/use-query-string";

import { Configuration } from "./configuration";
import { Model } from "./model";
import { Runs } from "./runs";
import { Schedule } from "./schedule";

const getDeleteSyncErrorMessage = (error: Error): string => {
  return error.message.startsWith("Foreign key violation") && error.message.includes("sync_sequence")
    ? "This sync cannot be deleted because it is used in one or more sequences"
    : error.message;
};

const tabs = ["runs", "configuration", "schedule", "model"];

export type OutletContext = {
  sync: NonNullable<SyncQuery["syncs"][0]>;
  startRun: any;
};

export const SyncRoutes = () => {
  return (
    <Routes>
      <Route element={<Loader />}>
        <Route element={<Layout />}>
          <Route path="/runs" element={<Runs />} />
          <Route path="/configuration" element={<Configuration />} />
          <Route path="/schedule" element={<Schedule />} />
          <Route path="/model" element={<Model />} />
          <Route index element={<Navigate to={{ pathname: "runs", search: location.search }} replace />} />
        </Route>
      </Route>
    </Routes>
  );
};

const Loader: FC = () => {
  const { id } = useParams<{ id: string }>();
  const { data: sync } = useSyncQuery(
    {
      id: id ?? "",
    },
    {
      enabled: Boolean(id),
      suspense: true,
      select: (data) => data.syncs[0],
    },
  );

  if (!sync) {
    return <Warning subtitle="It may have been deleted" title="Sync not found" />;
  }

  return <Outlet context={{ sync }} />;
};

const Layout: FC = () => {
  const { sync } = useOutletContext<OutletContext>();
  const navigate = useNavigate();
  const { toast } = useToast();
  const [cancelling, setCancelling] = useState<boolean>(false);
  const [deleting, setDeleting] = useState<boolean>(false);
  const [enabled, setEnabled] = useState<boolean>(true);
  const [cancelledSyncId, setCancelledSyncId] = useState<string>();
  const [showRun, setShowRun] = useState(true);

  const { mutateAsync: updateSync } = useUpdateSyncMutation();
  const { mutateAsync: forceRun } = useStartSyncRunMutation();
  const { mutateAsync: cancelSyncRequest } = useUpdateSyncRequestMutation();
  const { mutateAsync: deleteSyncById } = useDeleteSyncMutation();

  const { data: latestRun, refetch: refetchLastRun } = useSyncRunsQuery(
    {
      filter: { destination_instance_id: { _eq: sync?.id } },
      limit: 1,
    },
    {
      enabled: Boolean(sync?.id),
      select: (data) => data.sync_requests?.[0],
      refetchInterval: 5000,
      notifyOnChangeProps: "tracked",
      keepPreviousData: true,
    },
  );

  const syncAttempt = latestRun?.sync_attempts?.[0];
  const model = sync.segment;
  const destination = sync.destination;

  const deleteSync = async () => {
    try {
      await deleteSyncById({
        id: sync.id,
      });
      toast({
        id: "delete-sync",
        title: "Sync deleted",
        variant: "success",
      });
      analytics.track("Sync Deleted", {
        sync_id: sync.id,
        destination_type: destination?.definition?.name,
        schedule_type: sync.schedule?.type,
        source_type: model?.connection?.type,
      });
      navigate("/syncs");
    } catch (error) {
      captureException(error);
      toast({
        id: "delete-sync",
        title: "Couldn't delete this sync",
        message: getDeleteSyncErrorMessage(error),
        variant: "error",
      });
    }
  };

  const toggleSyncPause = async (enabled: boolean) => {
    try {
      await updateSync({
        id: sync.id,
        object: {
          schedule_paused: !enabled,
        },
      });

      toast({
        id: "toggle-sync-pause",
        title: `Sync was ${enabled ? "enabled" : "disabled"}`,
        variant: "success",
      });
    } catch (error) {
      captureException(error);
      toast({
        id: "toggle-sync-pause",
        title: `Sync could not be ${enabled ? "enabled" : "disabled"}`,
        message: error.message,
        variant: "error",
      });
    }
  };

  const startRun = async (resync = false) => {
    setShowRun(false);

    const { startSyncRun } = await forceRun({
      id: Number(sync.id),
      full_resync: resync,
    });

    // Only render a popup message if we actually scheduled a sync.
    const scheduled = startSyncRun?.scheduled;
    analytics.track("Sync Manually Started", {
      sync_id: sync.id,
      destination_type: destination?.definition?.name,
      schedule_type: sync.schedule?.type,
      source_type: model?.connection?.type,
    });

    if (scheduled) {
      if (resync) {
        toast({
          id: "start-sync-run",
          title: "Resync will begin shortly",
          variant: "success",
        });
      } else {
        toast({
          id: "start-sync-run",
          title: "Manual run will begin shortly",
          variant: "success",
        });
      }
    }

    refetchLastRun();
  };

  const cancelRun = async () => {
    setCancelling(true);
    setCancelledSyncId(latestRun?.id);
    cancelSyncRequest({
      id: latestRun?.id,
      object: {
        trigger_cancel: true,
      },
    });

    refetchLastRun();
  };

  const running = syncAttempt?.status === SyncStatus.ACTIVE;

  const {
    data: { autorun },
  } = useQueryString();

  const { hasPermission: userCanStart } = useHasPermission([
    { resource: "sync", grants: [ResourcePermissionGrant.Start], resource_id: sync.id },
  ]);
  const { hasPermission: userCanDelete } = useHasPermission([
    { resource: "sync", grants: [ResourcePermissionGrant.Delete], resource_id: sync.id },
  ]);
  const { hasPermission: userCanUpdate } = useHasPermission([
    { resource: "sync", grants: [ResourcePermissionGrant.Update], resource_id: sync.id },
  ]);
  const { hasPermission: userCanEnable } = useHasPermission([
    { resource: "sync", grants: [ResourcePermissionGrant.Enable], resource_id: sync.id },
  ]);

  useEffect(() => {
    let autoRunTimeout: number | undefined | null;
    if (sync && autorun) {
      autoRunTimeout = window.setTimeout(() => {
        startRun();
      }, 400);
    }
    return () => {
      if (autoRunTimeout) {
        clearTimeout(autoRunTimeout);
        autoRunTimeout = null;
      }
    };
  }, [autorun, sync.id]);

  useEffect(() => {
    // if the latest run id no longer match the cancelled id then it means cancel is successful.
    if (syncAttempt?.status === SyncStatus.CANCELLED || cancelledSyncId !== latestRun?.id) {
      setCancelling(false);
    }
  }, [syncAttempt?.status, latestRun?.id, cancelledSyncId]);

  useEffect(() => {
    if (syncStatusIsTerminal(syncAttempt?.status as SyncStatus)) {
      setShowRun(true);
    }
  }, [syncAttempt?.status]);

  useEffect(() => {
    setEnabled(!sync?.schedule_paused);
  }, [sync?.schedule_paused]);

  return (
    <>
      <PermissionProvider permissions={[{ resource: "sync", grants: [ResourcePermissionGrant.Update], resource_id: sync.id }]}>
        <Watermark />

        <Column width="100%" flex={1}>
          <Row align="center" justify="space-between" width="100%" mb={6} gap={6} p={4}>
            <Row align="center" gap={4}>
              <Row align="center" gap={3}>
                <IntegrationIcon
                  size={8}
                  src={sync.segment?.connection?.definition.icon}
                  name={sync.segment?.connection?.definition.name}
                />
                <Column overflow="hidden">
                  <Text size="sm" fontWeight="semibold" color="text.tertiary" textTransform="uppercase">
                    Model
                  </Text>
                  <Text fontWeight="medium" size="lg" isTruncated>
                    {sync.segment?.name}
                  </Text>
                </Column>
              </Row>
              <Row fontSize="3xl" color="text.placeholder">
                <ArrowRightIcon />
              </Row>
              <Row align="center" gap={3}>
                <IntegrationIcon size={8} src={sync.destination?.definition.icon} name={sync.destination?.definition.name} />
                <Column overflow="hidden">
                  <Text size="sm" fontWeight="semibold" color="text.tertiary" textTransform="uppercase">
                    Destination
                  </Text>
                  <Row align="center" gap={3}>
                    <Text fontWeight="medium" size="lg" isTruncated>
                      {sync.destination?.name}
                    </Text>
                    {sync?.config?.object && <DestinationBadge>{getObjectName(sync.config.object)}</DestinationBadge>}
                  </Row>
                </Column>
              </Row>
            </Row>
            <Row gap={4}>
              <Row align="center" gap={2}>
                <Text textTransform="uppercase" size="sm" fontWeight="semibold" color="text.tertiary">
                  {enabled ? "Enabled" : "Disabled"}
                </Text>
                <Switch
                  isChecked={enabled}
                  isDisabled={!userCanEnable}
                  onChange={(value) => {
                    setEnabled(value);
                    toggleSyncPause(value);
                  }}
                />
              </Row>

              {(userCanUpdate || userCanStart || userCanDelete) && (
                <Menu>
                  <MenuActionsButton variant="secondary" />
                  <MenuList>
                    {userCanStart && (
                      <MenuItem
                        icon={PlayIcon}
                        isDisabled={running}
                        onClick={() => {
                          startRun(true);
                        }}
                      >
                        Resync full query
                      </MenuItem>
                    )}

                    {(userCanUpdate || userCanStart) && userCanDelete && <MenuDivider />}

                    {userCanDelete && (
                      <MenuItem
                        icon={DeleteIcon}
                        isDisabled={running}
                        variant="danger"
                        onClick={() => {
                          setDeleting(true);
                        }}
                      >
                        Delete
                      </MenuItem>
                    )}
                  </MenuList>
                </Menu>
              )}

              <Permission permissions={[{ resource: "sync", grants: [ResourcePermissionGrant.Start], resource_id: sync.id }]}>
                {running || cancelling ? (
                  <Button isDisabled={cancelling} onClick={cancelRun}>
                    <Spinner size="sm" mr={2} />
                    {cancelling ? "Canceling..." : "Cancel run"}
                  </Button>
                ) : (
                  <Button
                    isDisabled={!showRun}
                    icon={PlayIcon}
                    onClick={() => {
                      startRun(false);
                    }}
                  >
                    Run
                  </Button>
                )}
              </Permission>
            </Row>
          </Row>

          <Tabs onChange={(index) => navigate(tabs[index]!)} index={tabs.findIndex((tab) => location.pathname.endsWith(tab))}>
            <TabList>
              <Tab>Runs</Tab>
              <Tab>Configuration</Tab>
              <Tab>Schedule</Tab>
              <Tab>Model</Tab>
            </TabList>
          </Tabs>

          <Outlet context={{ sync, startRun }} />
        </Column>
      </PermissionProvider>

      <DeleteConfirmationModal
        isOpen={deleting}
        label="sync"
        onClose={() => {
          setDeleting(false);
        }}
        onDelete={deleteSync}
      />
    </>
  );
};
