import { FC, useEffect, useState } from "react";

import {
  Box,
  Column,
  FormField,
  Heading,
  Link,
  Paragraph,
  Select,
  Switch,
  Text,
  Textarea,
  TextInput,
  Tooltip,
  useToast,
} from "@hightouchio/ui";
import * as Sentry from "@sentry/react";
import { useFlags } from "launchdarkly-react-client-sdk";
import Helmet from "react-helmet";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate, useSearchParams } from "react-router-dom";

import { AccordionSection } from "src/components/accordion-section";
import { AudienceExplore } from "src/components/audiences/audience-explore";
import { AddFolder } from "src/components/folders/add-folder";
import { useFolders } from "src/components/folders/use-folders";
import { createObjectFromOptions } from "src/components/labels/create-object-from-options";
import { LabelData, LabelForm } from "src/components/labels/label-form";
import { useLabels } from "src/components/labels/use-labels";
import { ParentModelSelect, ParentModel } from "src/components/models/parent-model-select";
import { ParameterizedAudienceConditionsForm } from "src/components/performance/parameterized-audience-conditions/form";
import { ParameterizedModel, getParameterizedModels } from "src/components/performance/parameterized-audience-conditions/utils";
import { PermissionedButton } from "src/components/permissioned-button";
import { FormErrorProvider } from "src/contexts/form-error-context";
import { useUser } from "src/contexts/user-context";
import { ResourcePermissionGrant, useCreateAudienceMutation } from "src/graphql";
import { QueryType } from "src/types/models";
import { Option } from "src/ui/select";
import { Wizard } from "src/ui/wizard";
import { Step } from "src/ui/wizard/wizard";
import { useModelState, useQueryState } from "src/utils/models";

export const CreateAudience: FC = () => {
  const { appEnableGoals, appMetricParameterization } = useFlags();

  const { hasPermissions } = useUser();
  const canUpdateSource = hasPermissions([
    {
      resource: "source",
      grants: [ResourcePermissionGrant.Update],
    },
  ]);

  const navigate = useNavigate();
  const { labels } = useLabels();
  const { toast } = useToast();
  const [parentModel, setParentModel] = useState<ParentModel | undefined>();
  const [step, setStep] = useState<number>(0);
  const [searchParams] = useSearchParams();
  const {
    state: { flattenedFolders },
    refetchFolders,
  } = useFolders({ folderType: "audiences", viewType: "models" });
  const [selectedFolder, setSelectedFolder] = useState<string | undefined | null>(searchParams.get("folder"));
  const [addFolderOpen, setAddFolderOpen] = useState(false);

  const source = parentModel?.connection;

  const [isPerformancesEnabled, setIsPeformancesEnabled] = useState((appEnableGoals && source?.plan_in_warehouse) || false);

  useEffect(() => {
    if (source?.plan_in_warehouse != null) {
      setIsPeformancesEnabled(appEnableGoals && source?.plan_in_warehouse);
    }
  }, [appEnableGoals, source?.plan_in_warehouse, setIsPeformancesEnabled]);

  const {
    queryState,
    initQueryState,
    setVisualQueryFilter,
    canRedoVisualQueryFilterChange,
    canUndoVisualQueryFilterChange,
    redoVisualQueryFilterChange,
    undoVisualQueryFilterChange,
  } = useQueryState();
  const { modelState, setName, setDescription } = useModelState();
  const { isLoading: creating, mutateAsync: createAudience } = useCreateAudienceMutation();

  const formProps = useForm<{
    labels: LabelData[];
    parameterizedModels: ParameterizedModel[];
  }>({
    defaultValues: {
      labels: [{ key: "", value: "" }],
    },
  });

  const { getValues, setValue, watch } = formProps;

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore - Circular type problem with Column and Condition
  // TODO: react-hook-form v7 does not support circular references, upgrade to v8
  const parameterizedModelFields = watch("parameterizedModels");
  const formLabels = watch("labels");
  const validLabels = formLabels.filter(({ key, value }) => key !== "" && value !== "");

  const keyOptions: Array<{ label: string; value: string | null }> = labels
    ?.filter(({ key }) => !formLabels.find((label) => label.key === key))
    ?.map(({ key }) => ({ value: key, label: key! }));

  const getOptionsForExistingLabelValues = (fieldIndex: number): Option[] => {
    const key = getValues().labels[fieldIndex]?.key;

    if (!key) {
      return [];
    }
    const existingLabelValues = labels.find((option) => option.key === key)?.values || [];
    return existingLabelValues?.map((value) => ({ value, label: value }));
  };

  const create = async () => {
    const audienceGoals = isPerformancesEnabled
      ? (parentModel?.goals || []).map((goal) => ({ goal_id: goal.id, enabled: true }))
      : [];

    try {
      const result = await createAudience({
        input: {
          query_type: QueryType.Visual,
          visual_query_parent_id: parentModel?.id,
          visual_query_filter: queryState?.visualQueryFilter,
          name: modelState?.name,
          description: modelState?.description,
          primary_key: parentModel?.primary_key,
          connection_id: source?.id,
          destination_instances: { data: [] },
          folder_id: selectedFolder,
          audience_goals: {
            data: audienceGoals,
          },
          tags: validLabels.length > 0 ? createObjectFromOptions(validLabels) : undefined,
          parameterized_conditions: {
            data: parameterizedModelFields.map(({ id: filteredModelId, propertyConditions }) => ({
              parent_model_id: parentModel?.id,
              filtered_model_id: filteredModelId,
              parameterized_conditions: propertyConditions,
            })),
          },
        },
      });

      toast({
        id: "create-audience",
        title: `${modelState?.name ?? "The audience"} created.`,
        variant: "success",
      });

      navigate(`/audiences/${result.insert_segments_one?.id}`);
    } catch (error) {
      toast({
        id: "create-audience",
        title: `${modelState?.name ? `"${modelState.name}"` : "The audience"} could not be created. Please try again.`,
        variant: "error",
      });

      Sentry.captureException(error);
    }
  };

  const showMetricParameters = appMetricParameterization && isPerformancesEnabled && parameterizedModelFields.length > 0;

  const steps: Step[] = [
    {
      title: "Select parent model",
      continue: "Click on a parent model to continue",
      header: <Heading>Select a parent model</Heading>,
      render: () => (
        <ParentModelSelect
          onSelect={(parentModel) => {
            setParentModel(parentModel);
            setStep(1);

            const parameterizedModels = getParameterizedModels(parentModel.goals, parentModel.relationships);
            setValue("parameterizedModels", parameterizedModels);
          }}
        />
      ),
    },
    {
      title: "Define audience",
      render: () => (
        <FormErrorProvider>
          <AudienceExplore
            canRedo={canRedoVisualQueryFilterChange}
            canUndo={canUndoVisualQueryFilterChange}
            parentModel={parentModel}
            queryState={queryState}
            source={source}
            onRedo={redoVisualQueryFilterChange}
            onUndo={undoVisualQueryFilterChange}
            onVisualQueryFilterChange={setVisualQueryFilter}
          />
        </FormErrorProvider>
      ),
    },
    {
      title: "Finalize audience",
      disabled: !modelState?.name,
      submitting: creating,
      header: <Heading>Finalize settings for this audience</Heading>,
      render: () => (
        <Column gap={8}>
          <FormField label="Audience name">
            <TextInput value={modelState?.name} width="xs" onChange={(event) => setName(event.target.value)} />
          </FormField>
          <FormField isOptional label="Description">
            <Textarea
              placeholder="Enter a description..."
              value={modelState?.description}
              onChange={(e) => setDescription(e.target.value)}
            />
          </FormField>
          <FormField isOptional label="Move to folder">
            <Select
              isClearable
              optionLabel={(folder) => folder.path.replaceAll("/", " / ")}
              optionValue={(folder) => folder.id}
              options={flattenedFolders || []}
              placeholder="Select a folder..."
              value={selectedFolder}
              onChange={(folder) => {
                setSelectedFolder(folder);
              }}
            />
            <Box mt={4}>
              <PermissionedButton
                permissions={[{ resource: "workspace", grants: [ResourcePermissionGrant.Update] }]}
                size="sm"
                onClick={() => setAddFolderOpen(true)}
              >
                New Folder
              </PermissionedButton>
            </Box>
            {addFolderOpen && (
              <AddFolder
                toggleDisabled
                folderType="audiences"
                viewType="models"
                onSave={(folder) => {
                  setSelectedFolder(folder);
                  refetchFolders();
                }}
                onClose={() => {
                  setAddFolderOpen(false);
                }}
              />
            )}
          </FormField>

          <AccordionSection label="Advanced configuration">
            <>
              {appEnableGoals && (
                <Box mb={8}>
                  <Box
                    as={FormField}
                    isOptional
                    display="flex"
                    alignItems="center"
                    label="Enable performance tracking"
                    sx={{ div: { mt: 0 } }}
                  >
                    <Tooltip
                      isDisabled={source?.plan_in_warehouse}
                      message="Performance tracking for this source is not enabled."
                    >
                      <Switch
                        isChecked={isPerformancesEnabled}
                        isDisabled={!source?.plan_in_warehouse}
                        onChange={setIsPeformancesEnabled}
                      />
                    </Tooltip>
                  </Box>
                  <Text color="text.secondary">
                    View performance tracking of this audience and its split groups against metrics defined in your schema for{" "}
                    <Link href={`/schema/parent-models/${parentModel?.id}`}>{parentModel?.name}</Link>.
                  </Text>
                  {!source?.plan_in_warehouse && (
                    <>
                      <Paragraph color="text.secondary">
                        Performance tracking requires using the{" "}
                        <Link
                          href={`${import.meta.env.VITE_DOCS_URL}/syncs/warehouse-sync-logs/#get-the-most-common-sync-error`}
                        >
                          Lightning sync engine
                        </Link>
                        .{" "}
                        {canUpdateSource ? (
                          <>
                            Please go to the source configuration for{" "}
                            <Link href={`/sources/${source?.id}`}>{source?.name}</Link> to turn it on.
                          </>
                        ) : (
                          <>
                            If you are interested in enabling performance tracking, please ask your workspace admin to enable
                            the lightning sync engine.
                          </>
                        )}
                      </Paragraph>
                    </>
                  )}
                </Box>
              )}

              {showMetricParameters && parentModel && (
                <Column my={4}>
                  <Text>Metric parameters</Text>
                  <Text color="text.secondary" mb={3}>
                    Metric parameters filter performance data to ensure only relevant events are counted for this audience.
                  </Text>
                  <ParameterizedAudienceConditionsForm parentModel={parentModel} />
                </Column>
              )}

              <LabelForm
                heading="Add labels"
                hint="Example keys: team, project, region, env."
                keyOptions={keyOptions}
                getExistingLabelOptions={getOptionsForExistingLabelValues}
              />
            </>
          </AccordionSection>
        </Column>
      ),
    },
  ];

  useEffect(() => {
    initQueryState(null);
  }, []);

  return (
    <>
      <Helmet>
        <title>New audience</title>
      </Helmet>

      <FormProvider {...formProps}>
        <Wizard
          fullscreen={step === 1}
          setStep={setStep}
          step={step}
          steps={steps}
          title="New audience"
          onCancel={() => {
            navigate("/audiences");
          }}
          onSubmit={create}
        />
      </FormProvider>
    </>
  );
};
