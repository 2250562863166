import { FC, useState, useCallback, useMemo } from "react";

import { TagIcon, TrashIcon } from "@heroicons/react/24/outline";
import {
  Text,
  Row,
  Column,
  Heading,
  useToast,
  Menu,
  MenuButton,
  MenuList,
  MenuDivider,
  MenuItem,
  ButtonGroup,
  SearchInput,
} from "@hightouchio/ui";
import * as Sentry from "@sentry/browser";
import { isEmpty } from "lodash";
import pluralize from "pluralize";
import { useNavigate } from "react-router-dom";

import destinationPlaceholder from "src/assets/placeholders/destination.svg";
import searchPlaceholder from "src/assets/placeholders/search.svg";
import {
  createdByFilterConfig,
  destinationTypeFilterConfig,
  Filters,
  labelFilterConfig,
  useFilters,
} from "src/components/folders/filters";
import { IntegrationIcon } from "src/components/integrations/integration-icon";
import { EditLabelModal } from "src/components/labels/edit-label-modal";
import { Labels } from "src/components/labels/labels";
import { Page } from "src/components/layout";
import { BulkDeleteConfirmationModal } from "src/components/modals/bulk-delete-confirmation-modal";
import { PermissionedLinkButton } from "src/components/permissioned-button";
import { PermissionProvider } from "src/contexts/permission-context";
import { useUser } from "src/contexts/user-context";
import {
  DestinationsBoolExp,
  DestinationsOrderBy,
  ResourcePermissionGrant,
  useDeleteDestinationsMutation,
  useDestinationFiltersQuery,
  useDestinationsQuery,
  useUpdateDestinationV2Mutation,
} from "src/graphql";
import { useEntitlements } from "src/hooks/use-entitlement";
import useHasPermission from "src/hooks/use-has-permission";
import useQueryState from "src/hooks/use-query-state";
import * as analytics from "src/lib/analytics";
import { PageTable, TableColumn, useTableSort, OrderBy, SortOption } from "src/ui/table";
import { LastUpdatedColumn } from "src/ui/table/columns/last-updated";
import { Placeholder } from "src/ui/table/placeholder";
import { useRowSelect } from "src/ui/table/use-row-select";
import { openUrl } from "src/utils/urls";

import { useLabels } from "../../components/labels/use-labels";

const initialSort: SortOption<keyof DestinationsOrderBy> = {
  key: "updated_at",
  direction: OrderBy.Desc,
  label: "Recently updated",
};
const sortOptions: SortOption<keyof DestinationsOrderBy>[] = [
  { key: "name", direction: OrderBy.Asc, label: "Name A -> Z" },
  { key: "name", direction: OrderBy.Desc, label: "Name Z -> A" },
  { key: "type", direction: OrderBy.Asc, label: "Type" },
  initialSort,
  { key: "created_at", direction: OrderBy.Desc, label: "Newest" },
  { key: "created_at", direction: OrderBy.Asc, label: "Oldest" },
];

const columns: TableColumn[] = [
  {
    name: "Name",
    cell: ({ name, definition }) => {
      return (
        <Text isTruncated fontWeight="medium">
          {name ?? definition?.name ?? "Private destination"}
        </Text>
      );
    },
  },
  {
    name: "Type",
    cell: ({ definition }) => {
      return (
        <Row align="center" gap={2} overflow="hidden">
          <IntegrationIcon name={definition?.name} src={definition?.icon} />
          <Text isTruncated fontWeight="medium">
            {definition?.name ?? "Private destination"}
          </Text>
        </Row>
      );
    },
  },
  {
    ...LastUpdatedColumn,
    breakpoint: "md",
  },
  {
    name: "Labels",
    key: "tags",
    cell: (tags) => {
      if (isEmpty(tags)) {
        return "--";
      }
      return <Labels labels={tags} />;
    },
    breakpoint: "lg",
  },
];

export const Destinations: FC = () => {
  const { toast } = useToast();
  const navigate = useNavigate();
  const [search, setSearch] = useQueryState("search");
  const [confirmingDelete, setConfirmingDelete] = useState<boolean>(false);
  const { selectedRows, onRowSelect } = useRowSelect();
  const [addingLabels, setAddingLabels] = useState(false);

  const { hasPermission: userCanUpdate } = useHasPermission([
    { resource: "destination", grants: [ResourcePermissionGrant.Update] },
  ]);
  const { hasPermission: userCanDelete } = useHasPermission([
    { resource: "destination", grants: [ResourcePermissionGrant.Delete] },
  ]);

  const { labels } = useLabels();

  const { mutateAsync: updateDestination, isLoading: updatingDestination } = useUpdateDestinationV2Mutation();
  const { mutateAsync: bulkDelete } = useDeleteDestinationsMutation();

  const orderBy = useTableSort<DestinationsOrderBy>(initialSort, sortOptions);

  const buildHasuraFilters = () => {
    const labelsFilters: DestinationsBoolExp["_or"] = filters.label?.selected.map((filter) => {
      const key = filter.id.split(":")[0];
      const value = filter.id.split(":")[1];
      const obj = {};
      obj[key!] = value;
      return {
        tags: { _contains: obj },
      };
    });

    const initial: DestinationsBoolExp = {
      _and: [
        {
          type: !filters.destination?.isAllSelected ? { _in: filters.destination?.selected.map((filter) => filter.id) } : {},
        },
        !filters.label?.isAllSelected
          ? {
              _or: labelsFilters,
            }
          : {},
        !filters.created?.isAllSelected
          ? {
              _or: [
                {
                  created_by: { _in: filters.created?.selected.map((f) => f.id) },
                },
                {
                  created_by: { _is_null: true },
                },
              ],
            }
          : {},
      ],
    };
    if (search) {
      initial._and!.push({ name: { _ilike: `%${search}%` } });
    }
    return initial;
  };

  const { data: allDestinations, isLoading: filtersLoading } = useDestinationFiltersQuery(undefined, {
    select: (data) => data.destinations,
  });

  const filterDefinitions = useMemo(() => {
    return {
      viewKey: "destination",
      loading: filtersLoading,
      filters: {
        destination: {
          options: destinationTypeFilterConfig(allDestinations || []),
          title: "Type",
        },
        created: { options: createdByFilterConfig(allDestinations || []), title: "Created by" },
        label: { options: labelFilterConfig(allDestinations || []), title: "Labels" },
      },
    };
  }, [allDestinations]);

  const {
    result: { state: filters, data: filterData },
    state: { creatingView, selectedView, viewNotSaved, views, updatingView },
    actions: { createView, deleteView, selectView, updateCurrentView, resetViewFilters, clearFilters },
  } = useFilters(filterDefinitions);

  const hasuraFilters = useMemo(() => {
    return buildHasuraFilters();
  }, [search, filters]);

  const {
    data: destinations,
    error: destinationsError,
    isLoading,
  } = useDestinationsQuery(
    {
      filters: hasuraFilters,
      orderBy,
    },
    { select: (data) => data.destinations },
  );

  const bulkAddLabels = async (labels: Record<string, string>) => {
    try {
      const promises = selectedRows.map((id) => updateDestination({ id: String(id), append: { tags: labels } }));
      await Promise.all(promises);

      setAddingLabels(false);
      onRowSelect([]);

      const labelCount = Object.keys(labels).length;

      toast({
        id: "bulk-add-destination-labels",
        title: `Added ${labelCount} ${pluralize("label", labelCount)} to ${selectedRows.length} ${pluralize(
          "destination",
          selectedRows.length,
        )}`,
        variant: "success",
      });
    } catch (error) {
      toast({
        id: "bulk-add-destination-labels",
        title: "Couldn't update labels for selected destinations",
        variant: "error",
      });

      Sentry.captureException(error);
    }
  };

  const bulkDeleteDestinations = async () => {
    if (userCanDelete) {
      const count = selectedRows.length;
      const pluralizedLabel = pluralize("destination", count);

      try {
        await bulkDelete({ ids: selectedRows.map(String) });

        toast({
          id: "bulk-delete-destinations",
          title: `Deleted ${count} ${pluralizedLabel}`,
          variant: "success",
        });

        onRowSelect([]);
      } catch (error) {
        toast({
          id: "bulk-delete-destinations",
          title: `Failed to delete ${pluralizedLabel}`,
          variant: "error",
        });

        Sentry.captureException(error);
      }
    }
  };

  const placeholder = useMemo(
    () => ({
      image: searchPlaceholder,
      title: "No destinations found",
      error: "Destinations failed to load, please try again.",
    }),
    [],
  );

  const { data: entitlementsData, isLoading: _loadingEntitlements } = useEntitlements(true);
  const { overageLockout, destinationOverageText } = entitlementsData.overage;
  const overageText = destinationOverageText + " To create a destination, upgrade your plan.";

  const onRowClick = useCallback(({ id }, event) => openUrl(`/destinations/${id}`, navigate, event), [navigate]);

  return (
    <PermissionProvider permissions={[{ resource: "destination", grants: [ResourcePermissionGrant.Create] }]}>
      <Page
        sidebar={
          <>
            <Row pr={6} pl={2}>
              <SearchInput
                placeholder="Search all destinations..."
                value={search ?? ""}
                onChange={(e) => {
                  setSearch(e.target.value);
                }}
              />
            </Row>
            <Column px={2} overflow="auto">
              <Filters
                clearFilters={clearFilters}
                createView={createView}
                creatingView={creatingView}
                deleteView={deleteView}
                filters={filterData}
                resetFilters={resetViewFilters}
                resource="destination"
                selectView={selectView}
                selectedView={selectedView}
                updateCurrentView={updateCurrentView}
                updatingView={updatingView}
                viewNotSaved={viewNotSaved}
                views={views}
              />
            </Column>
          </>
        }
        title="Destinations"
      >
        <PageTable
          header={
            <>
              <Heading isTruncated size="xl">
                Destinations
              </Heading>
              <ButtonGroup>
                {(userCanUpdate || userCanDelete) && selectedRows.length > 0 && (
                  <Row align="center" gap={2}>
                    <Text>{`${pluralize("destination", selectedRows.length, true)} selected`}</Text>
                    <Menu>
                      <MenuButton>Actions</MenuButton>
                      <MenuList>
                        {userCanUpdate && (
                          <MenuItem
                            icon={TagIcon}
                            onClick={() => {
                              setAddingLabels(true);
                            }}
                          >
                            Add labels
                          </MenuItem>
                        )}
                        {userCanUpdate && userCanDelete && <MenuDivider />}
                        {userCanDelete && (
                          <MenuItem
                            icon={TrashIcon}
                            variant="danger"
                            onClick={() => {
                              setConfirmingDelete(true);
                            }}
                          >
                            Delete
                          </MenuItem>
                        )}
                      </MenuList>
                    </Menu>
                  </Row>
                )}
                <PermissionedLinkButton
                  href="/destinations/new"
                  isDisabled={overageLockout}
                  permissions={[{ resource: "destination", grants: [ResourcePermissionGrant.Create] }]}
                  tooltip={overageLockout && overageText}
                  variant="primary"
                  onClick={() => {
                    analytics.track("Add Destination Clicked");
                  }}
                >
                  Add destination
                </PermissionedLinkButton>
              </ButtonGroup>
            </>
          }
          columns={columns}
          data={destinations}
          error={Boolean(destinationsError)}
          loading={isLoading}
          placeholder={placeholder}
          selectedRows={selectedRows}
          onRowClick={onRowClick}
          onSelect={userCanUpdate || userCanDelete ? onRowSelect : undefined}
          sortOptions={sortOptions}
        />

        <BulkDeleteConfirmationModal
          count={selectedRows.length}
          isOpen={confirmingDelete}
          label="destination"
          onClose={() => setConfirmingDelete(false)}
          onDelete={bulkDeleteDestinations}
        />

        <EditLabelModal
          description="You can label destinations that have similar properties"
          existingLabelOptions={labels}
          hint="Example keys: team, project, region, env."
          isOpen={addingLabels}
          loading={updatingDestination}
          saveLabel={`Apply to ${selectedRows.length} ${pluralize("destination", selectedRows.length)}`}
          title="Add labels"
          onClose={() => setAddingLabels(false)}
          onSave={bulkAddLabels}
        />
      </Page>
    </PermissionProvider>
  );
};

const Loader = () => {
  const { resources } = useUser();

  if (resources?.destination) {
    return <Destinations />;
  }

  return (
    <Page fullWidth title="Destinations">
      <Heading mb={8} size="xl">
        Destinations
      </Heading>
      <Placeholder
        content={{
          image: destinationPlaceholder,
          title: "No destinations in this workspace",
          body: "A destination is where your data will be sent. Hightouch supports 100+ popular destinations, including CRMs like Salesforce, marketing automation tools like HubSpot, and ad platforms like Google. You can also build your own destination.",
          button: (
            <PermissionedLinkButton
              href="/destinations/new"
              permissions={[{ resource: "destination", grants: [ResourcePermissionGrant.Create] }]}
              variant="primary"
            >
              Add destination
            </PermissionedLinkButton>
          ),
        }}
      />
    </Page>
  );
};

export default Loader;
