import { FC } from "react";

import { Routes, Route } from "react-router-dom";

import { CreateEventWarehouseSync } from "./create";
import { EventWarehouseSyncRouter } from "./sync";
import { EventWarehouseSyncs } from "./syncs";

export const EventWarehouseSyncsRouter: FC = () => {
  return (
    <Routes>
      <Route path="/" element={<EventWarehouseSyncs />} />
      <Route path="new" element={<CreateEventWarehouseSync />} />
      <Route path=":id/*" element={<EventWarehouseSyncRouter />} />
    </Routes>
  );
};
