import { FC } from "react";

import { Alert, Button, Column, Text, useToast } from "@hightouchio/ui";
import * as Sentry from "@sentry/react";
import { FormProvider, useForm } from "react-hook-form";

import { useUpsertParameterizedAudienceConditionsMutation } from "src/graphql";
import { NonNullableAudience } from "src/types/visual";
import { Modal } from "src/ui/modal";

import { ParameterizedAudienceConditionsForm } from "./form";
import { ParameterizedModel } from "./utils";

type Props = {
  audience: NonNullableAudience;
  parameterizedModels: ParameterizedModel[];
  onClose: () => void;
};

export const ParameterizedMetricsModal: FC<Props> = ({ audience, parameterizedModels, onClose }) => {
  const { toast } = useToast();
  const upsertParamterizedAudienceConditions = useUpsertParameterizedAudienceConditionsMutation();

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore - tsc warning about incompatible types because defaultValues can be optional/undefined
  const formProps = useForm<{ parameterizedModels: ParameterizedModel[] }>({ defaultValues: { parameterizedModels } });
  const {
    handleSubmit,
    formState: { isDirty, dirtyFields },
  } = formProps;

  const onSubmit = async (data: { parameterizedModels: ParameterizedModel[] }) => {
    const upsertObjects = data.parameterizedModels.map((model) => ({
      audience_id: audience.id.toString(),
      parent_model_id: audience?.parent?.id.toString(),
      filtered_model_id: model.id.toString(),
      parameterized_conditions: model.propertyConditions,
    }));

    const affectedGoalIds = dirtyFields.parameterizedModels?.reduce((accum, current, index) => {
      const parameterizedModel = data.parameterizedModels[index];
      if (!current || !parameterizedModel) return accum;

      return parameterizedModel.goalIds;
    }, [] as string[]);

    // If the conditions have changed, that will affect goal metrics since future results may be substantially different.
    // Since we don't have anything on the graphs to indicate some kind of "change event", just erase the existing metrics.
    const deleteGoalMetricsWhere = affectedGoalIds
      ? {
          _and: [{ segment_id: { _eq: audience.id } }, { goal_id: { _in: affectedGoalIds } }],
        }
      : {};

    try {
      await upsertParamterizedAudienceConditions.mutateAsync({
        upsert_objects: upsertObjects,
        delete_goal_metrics_where: deleteGoalMetricsWhere,
      });

      toast({
        id: "update-parameterized-audience-conditions",
        title: "Metric parameters updated for this audience",
        variant: "success",
      });
      onClose();
    } catch (error) {
      toast({
        id: "update-parameterized-audience-conditions",
        title: "Metric parameters were not updated",
        message: error.message,
        variant: "error",
      });
      Sentry.captureException(error);
    }
  };

  const isSaveDisabled = !isDirty || upsertParamterizedAudienceConditions.isLoading;

  return (
    <Modal
      title="Edit metric parameters"
      footer={
        <>
          <Button onClick={onClose}>Cancel</Button>
          <Button
            isDisabled={isSaveDisabled}
            isLoading={upsertParamterizedAudienceConditions.isLoading}
            variant="primary"
            onClick={handleSubmit(onSubmit)}
          >
            Save
          </Button>
        </>
      }
      footerSx={{ gap: 4 }}
      onClose={onClose}
    >
      <Alert type="warning" title="Warning" message="Editing metric parameters will clear historical performance." />
      <Column my={4}>
        <Text>Metric parameters</Text>
        <Text color="text.secondary">
          Metric parameters filter performance data to ensure only relevant events are counted for this audience.
        </Text>
      </Column>
      <FormProvider {...formProps}>
        {audience.parent && <ParameterizedAudienceConditionsForm parentModel={audience.parent} />}
      </FormProvider>
    </Modal>
  );
};
