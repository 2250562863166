import { InlineTraitColumn } from "@hightouch/lib/query/visual/types";
import { TraitConfig, TraitType } from "@hightouch/lib/query/visual/types/trait-definitions";
import { useToast } from "@hightouchio/ui";
import * as Sentry from "@sentry/browser";

import { TraitQuery } from "src/graphql";
import { QueryType } from "src/types/models";
import { useModelRun } from "src/utils/models";

export const usePreviewTrait = ({
  parentModel,
  trait,
}: {
  parentModel: NonNullable<TraitQuery["trait_definitions_by_pk"]>["config"];
  trait: {
    type: TraitType;
    config: TraitConfig;
    relationshipId: string;
  };
}) => {
  const { toast } = useToast();

  const inlineTraitColumn: InlineTraitColumn = {
    type: "inline_trait",
    traitType: trait.type,
    traitConfig: trait.config,
    relationshipId: trait.relationshipId,
    conditions: [],
  };

  const { runQuery, columns, rows, loading } = useModelRun(QueryType.Visual, parentModel?.columns, {
    variables: {
      parentModelId: parentModel?.id,
      sourceId: parentModel?.connection.id,
      visualQueryFilter: {
        conditions: [],
        additionalColumns: [
          {
            alias: "trait_preview",
            column: {
              type: "related",
              column: inlineTraitColumn,
              path: [trait.relationshipId],
            },
          },
        ],
      },
    },
    onCompleted: (_data, error) => {
      if (error) {
        toast({
          id: "trait-preview",
          title: "Trait preview failed",
          variant: "error",
        });

        Sentry.captureException(error);
        return;
      }
    },
  });

  const previewTrait = async () => {
    await runQuery({ limit: true, disableRowCounter: true });
  };

  return {
    previewTrait,
    isLoading: loading,
    data: { rows, columns },
  };
};
