import { useMemo } from "react";

import { UseQueryResult } from "react-query";

interface IncrementalQuery<TDataType, TError> {
  data: TDataType | undefined;
  error: TError | null;
  isFetching: boolean;
  isLoading: boolean;
  refetch: () => void;
}

export function useIncrementalQuery<TQueryData, TError>(
  initialQuery: UseQueryResult<TQueryData, TError>,
  fullQuery: UseQueryResult<TQueryData, TError>,
): IncrementalQuery<TQueryData, TError> {
  const data = useMemo(() => {
    return fullQuery.data ?? initialQuery.data;
  }, [initialQuery.data, fullQuery.data]);

  return {
    data,
    error: fullQuery.error || initialQuery.error,
    isFetching: initialQuery.isFetching,
    isLoading: initialQuery.isLoading,
    refetch: fullQuery.refetch,
  };
}
