import { useEffect, useState } from "react";

import { useFlags } from "launchdarkly-react-client-sdk";
import qs from "query-string";
import { Route, Routes } from "react-router-dom";

import { hideIntercom } from "src/lib/intercom";
import { PartnerDashboard } from "src/partner/dashboard";

import { CreateSource } from "./create-source";
import { CreateSync } from "./create-sync";
import { Layout } from "./layout";
import Source from "./source";
import { SyncRoutes } from "./sync";
import { Brand, CreateSyncConfig, CreateSourceConfig, ViewSourceConfig } from "./types";

export type Config = {
  brand: Brand;
  config: CreateSyncConfig | ViewSourceConfig | CreateSourceConfig;
};

export const PartnerRoutes = () => {
  const { partnerUser } = useFlags();
  const [config, setConfig] = useState<Config>();

  useEffect(() => {
    const parsed = qs.parse(window.location.search) as Record<string, string>;
    if (parsed.config) {
      const json = JSON.parse(atob(parsed.config));
      setConfig(json);
    }
  }, []);

  useEffect(() => {
    hideIntercom();
  }, []);

  return (
    <Routes>
      {partnerUser && <Route index element={<PartnerDashboard />} />}
      {config && (
        <Route element={<Layout config={config} />}>
          <Route path="/sources/:id" element={<Source />} />
          <Route path="/sources/new" element={<CreateSource config={config?.config as CreateSourceConfig} />} />
          <Route path="/syncs/new" element={<CreateSync config={config?.config as CreateSyncConfig} />} />
          <Route path="/syncs/:id/*" element={<SyncRoutes />} />
        </Route>
      )}
    </Routes>
  );
};

// Testing config
// {
//   type: "create-sync",
//   brand: {
//     logo: "https://raw.githubusercontent.com/Iterable/iterable-android-sdk/master/images/Iterable-Logo.png",
//     name: "Iterable",
//   },
//   config: { sources: ["snowflake"], destinationId: "53630" },
// }
