import { FC, useState } from "react";

import {
  Alert,
  Button,
  ChakraModal,
  ChakraModalBody,
  ChakraModalContent,
  ChakraModalFooter,
  ChakraModalHeader,
  ChakraModalOverlay,
  Column,
  Link,
  Text,
  Paragraph,
  useToast,
  ChakraUnorderedList,
  ChakraListItem,
} from "@hightouchio/ui";
import * as Sentry from "@sentry/react";
import capitalize from "lodash/capitalize";
import { useNavigate } from "react-router-dom";

import { TraitQuery, useArchiveTraitMutation } from "src/graphql";

type Props = {
  trait: NonNullable<TraitQuery["trait_definitions_by_pk"]>;
};

export const ArchiveTrait: FC<Readonly<Props>> = ({ trait }) => {
  const navigate = useNavigate();
  const { toast } = useToast();

  const [showArchiveConfirmation, setShowArchiveConfirmation] = useState<boolean>(false);
  const archiveTrait = useArchiveTraitMutation();

  const isTraitBeingUsed = trait.trait_references.audiences.length > 0;
  const traitType = trait.is_template ? "template" : "trait";

  const handleArchiveTrait = async () => {
    try {
      await archiveTrait.mutateAsync({ id: trait.id });
      navigate(`/traits/archived/${trait.id}`);

      toast({
        id: "archive-trait",
        title: `${capitalize(traitType)} was archived successfully`,
        variant: "success",
      });
    } catch (error) {
      toast({
        id: "archive-trait",
        title: `${capitalize(traitType)} failed to be archived`,
        variant: "error",
      });
      Sentry.captureException(error);
    }
  };

  return (
    <>
      <Button variant="warning" onClick={() => setShowArchiveConfirmation(true)}>
        Archive
      </Button>

      <ChakraModal
        isOpen={showArchiveConfirmation}
        onClose={() => setShowArchiveConfirmation(false)}
        closeOnEsc
        closeOnOverlayClick
        isCentered
        scrollBehavior="inside"
      >
        <ChakraModalOverlay>
          <ChakraModalContent p={0} maxWidth="500px">
            <ChakraModalHeader p={6} borderBottom="1px solid" borderColor="base.border">
              Archive {traitType}: {trait.name}
            </ChakraModalHeader>
            <ChakraModalBody p={6} m={0}>
              {isTraitBeingUsed ? (
                <Column>
                  <Alert
                    type="error"
                    title={`${capitalize(traitType)} in use`}
                    message={`Cannot archive a ${traitType} that is used in an ${
                      trait.is_template ? "active trait, audience, or sync" : "audience or sync"
                    }.`}
                  />

                  <Column mt={4} gap={2} alignItems="start">
                    <Text>This {traitType} is used in the following:</Text>
                    {trait.trait_references.audiences.length && (
                      <Column alignItems="start">
                        <Text fontWeight="semibold">Audiences:</Text>
                        <ChakraUnorderedList>
                          {trait.trait_references.audiences.map(({ id, name }: { id: string; name: string }) => (
                            <ChakraListItem key={id}>
                              <Link href={`/audiences/${id}`} isExternal>
                                {name}
                              </Link>
                            </ChakraListItem>
                          ))}
                        </ChakraUnorderedList>
                      </Column>
                    )}
                  </Column>
                </Column>
              ) : (
                <Paragraph textAlign="left">
                  Are you sure you want to archive the {traitType} "<Text fontWeight="semibold">{trait.name}</Text>"?
                  <br />
                  <br />
                  This {traitType} will no longer be available to use in audiences or syncs but you can restore it from the{" "}
                  <Link href="/traits/archived" isExternal>
                    archived table
                  </Link>
                  .
                </Paragraph>
              )}
            </ChakraModalBody>
            <ChakraModalFooter p={4} m={0} borderTop="1px solid" borderColor="base.border">
              <Button onClick={() => setShowArchiveConfirmation(false)}>Cancel</Button>
              <Button
                isDisabled={isTraitBeingUsed || archiveTrait.isLoading}
                isLoading={archiveTrait.isLoading}
                variant="danger"
                onClick={handleArchiveTrait}
              >
                Archive
              </Button>
            </ChakraModalFooter>
          </ChakraModalContent>
        </ChakraModalOverlay>
      </ChakraModal>
    </>
  );
};
