import { FC } from "react";

import { Column, Row, Text } from "@hightouchio/ui";
import { Controller, useFieldArray, useFormContext } from "react-hook-form";

import { QueryBuilderProvider } from "src/components/explore/context/query-builder-context";
import { ParameterizedPropertyFilter } from "src/components/explore/visual/parameterized-property-filter";
import { getPropertyNameFromProperty } from "src/components/explore/visual/utils";
import { AudienceParent } from "src/types/visual";

import { ParameterizedModel } from "./utils";

type Props = {
  parentModel: AudienceParent;
};

export const ParameterizedAudienceConditionsForm: FC<Props> = ({ parentModel }) => {
  const { control } = useFormContext();
  const { fields: _fields } = useFieldArray({
    control,
    name: "parameterizedModels",
    keyName: "fieldId",
  });

  // The type has been given in the form, but react hook form isn't recognizing all of the properties besides `fieldId`
  const fields = _fields as (ParameterizedModel & { fieldId: string })[];

  return (
    <QueryBuilderProvider>
      <Column gap={4}>
        {fields.map(({ fieldId, name, propertyConditions }, modelIndex) => (
          <Column key={fieldId}>
            <Text color="text.secondary" fontWeight="semibold" mb={2} textTransform="uppercase">
              "{name}" parameters
            </Text>
            <Column gap={2}>
              {propertyConditions.map((propertyCondition, propertyConditionIndex) => (
                <Row key={propertyConditionIndex} gap={2} alignItems="center">
                  <Text fontWeight="medium">{getPropertyNameFromProperty(propertyCondition.property)}</Text>
                  <Controller
                    control={control}
                    name={`parameterizedModels.${modelIndex}.propertyConditions.${propertyConditionIndex}`}
                    render={({ field }) => (
                      <ParameterizedPropertyFilter condition={field.value} parent={parentModel} onChange={field.onChange} />
                    )}
                  />
                </Row>
              ))}
            </Column>
          </Column>
        ))}
      </Column>
    </QueryBuilderProvider>
  );
};
