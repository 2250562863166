import { FC } from "react";

import { Alert, Column, LinkButton } from "@hightouchio/ui";
import { useOutletContext } from "react-router-dom";

import { Runs as SyncRuns } from "src/components/syncs/runs";
import { DuplicateKeyWarning } from "src/pages/syncs/duplicate-key-warnings";

import { OutletContext } from ".";

export const Runs: FC = () => {
  const { sync, startRun } = useOutletContext<OutletContext>();

  const duplicatePrimaryKeysCount = sync.segment?.last_run_duplicate_primary_keys;
  const plannerType = sync.last_run_planner_type;
  const hasPrimaryKeyIssue = Boolean(
    sync.segment?.columns?.length && !sync.segment?.columns.some((c) => c.name === sync.segment?.primary_key),
  );

  return (
    <Column p={4}>
      {!!duplicatePrimaryKeysCount && plannerType !== "all" && plannerType !== null && (
        <DuplicateKeyWarning
          count={duplicatePrimaryKeysCount}
          inWarehouseDiff={Boolean(sync.segment?.connection?.plan_in_warehouse)}
          primaryKey={sync.segment?.primary_key || ""}
          parentModelUrl="/model"
        />
      )}
      {hasPrimaryKeyIssue && (
        <Alert
          type="warning"
          title="Undefined primary key"
          message="Without a primary key your syncs may fail or undefined behavior may occur. Go to your model and check that your primary key is set to a valid column in your model."
          actions={<LinkButton href="/model">Go to your model</LinkButton>}
        />
      )}
      <SyncRuns
        disableRowClick
        sync={sync}
        onRun={() => {
          startRun(false);
        }}
      />
    </Column>
  );
};
