import { useEffect, useMemo, useState, SetStateAction, Dispatch } from "react";

import { Folder, FolderType } from "src/components/folders/types";
import useQueryState from "src/hooks/use-query-state";

import { useFolders } from "./use-folders";
import { flattenFolders } from "./utils";

export const useFolderState = ({
  search,
  resourceType,
  folderType,
}: {
  search: string | null;
  resourceType: "models" | "syncs";
  folderType?: FolderType;
}): {
  selectedFolder: (Folder & { flattenedChildren: Folder[] }) | null;
  setSelectedFolder: (folder: string | null) => void;
  movingToFolder: boolean;
  setMovingToFolder: Dispatch<SetStateAction<boolean>>;
  selectedFolderType: FolderType | undefined;
  setSelectedFolderType: Dispatch<SetStateAction<FolderType | undefined>>;
  header: string | undefined;
  nestedFolders: Folder[] | undefined;
  totalCount: number | undefined;
  audienceCount: number | undefined;
  modelCount: number | undefined;
  loadingFolders: boolean;
  refetchFolders: () => void;
  clearFolderType: () => void;
} => {
  const [selectedFolderType, setSelectedFolderType] = useState<FolderType | undefined>(folderType);
  const [movingToFolder, setMovingToFolder] = useState<boolean>(false);
  const [selectedFolderQuery, setSelectedFolderQuery] = useQueryState("folder");
  const {
    state: { nestedFolders, flattenedFolders, totalCount, audienceCount, modelCount },
    loadingFolders,
    refetchFolders,
  } = useFolders({
    folderType: selectedFolderType,
    viewType: resourceType,
  });

  const selectedFolder: (Folder & { flattenedChildren: Folder[] }) | null = useMemo(() => {
    if (selectedFolderQuery) {
      const folder = flattenedFolders?.find((folder) => folder.id === selectedFolderQuery);
      return folder ? { ...folder, flattenedChildren: flattenFolders(folder?.children ?? []) } : null;
    }
    return null;
  }, [selectedFolderQuery, flattenedFolders]);

  useEffect(() => {
    if (selectedFolder) {
      setSelectedFolderType(selectedFolder.type as FolderType);
    }
  }, [selectedFolder]);

  const header = useMemo(() => {
    if (search) {
      return `Search results`;
    }
    if (!selectedFolderType) {
      return `All ${resourceType}`;
    }
    if (selectedFolder) {
      return selectedFolder.name;
    }
    if (selectedFolderType === "models") {
      if (resourceType === "models") {
        return "All models";
      }
      return "All model syncs";
    }
    if (selectedFolderType === "audiences") {
      if (resourceType === "models") {
        return "All audiences";
      }
      return "All audience syncs";
    }
    return "";
  }, [search, selectedFolder, selectedFolderType]);

  useEffect(() => {
    if (search) {
      setSelectedFolderType(undefined);
    }
  }, [search]);

  return {
    selectedFolder,
    setSelectedFolder: (folder: string | null) => {
      setSelectedFolderQuery(folder || "");
    },
    movingToFolder,
    setMovingToFolder,
    selectedFolderType,
    setSelectedFolderType,
    header,
    nestedFolders,
    refetchFolders,
    loadingFolders,
    totalCount,
    audienceCount,
    modelCount,
    clearFolderType: () => {
      setSelectedFolderType(undefined);
      setSelectedFolderQuery("");
    },
  };
};
