import { FC, useCallback } from "react";

import { Heading, Row, LinkButton, Text, Box } from "@hightouchio/ui";
import { capitalize } from "lodash";
import { useNavigate } from "react-router-dom";

import genericPlaceholder from "src/assets/placeholders/generic.svg";
import { eventSourceDefinitions } from "src/events/types";
import { useEventSourcesQuery } from "src/graphql";
import { PageTable, TableColumn } from "src/ui/table";
import { LastUpdatedColumn } from "src/ui/table/columns/last-updated";
import { TextWithTooltip } from "src/ui/text";
import { openUrl } from "src/utils/urls";

export const EventSources: FC = () => {
  const navigate = useNavigate();
  const { data: sources, isFetching, error } = useEventSourcesQuery(undefined, { select: (data) => data.event_sources });

  const onRowClick = useCallback(({ id }, event) => openUrl(`/events/sources/${id}`, navigate, event), [navigate]);

  return (
    <PageTable
      header={
        <>
          <Heading isTruncated size="xl">
            Event sources
          </Heading>
          <Row>
            <LinkButton href="/events/sources/new" variant="primary" onClick={() => {}}>
              Add source
            </LinkButton>
          </Row>
        </>
      }
      columns={columns}
      data={sources}
      error={Boolean(error)}
      loading={isFetching}
      placeholder={placeholder}
      onRowClick={onRowClick}
    />
  );
};

const columns: TableColumn[] = [
  {
    name: "Name",
    cell: ({ name }) => <TextWithTooltip fontWeight="medium">{name}</TextWithTooltip>,
  },
  {
    name: "Type",
    cell: ({ type }) => (
      <Row align="center" gap={2}>
        <Box as="img" src={eventSourceDefinitions[type].icon} alt={type} width="24px" height="24px" />
        <Text fontWeight="medium">{capitalize(type)}</Text>
      </Row>
    ),
  },
  LastUpdatedColumn,
];

const placeholder = {
  image: genericPlaceholder,
  title: "No event sources",
  body: "Create a new event source",
  error: "Event sources failed to load, please try again.",
};
