import { FC, ReactNode, useMemo, useCallback } from "react";

import { Box, Checkbox } from "@hightouchio/ui";

import { Cell } from "./cells";
import { RowClickHandler } from "./table";

type RowProps = {
  children: ReactNode;
  selected?: boolean;
  disabled?: boolean;
  clickable?: boolean;
  row: any;
  onClick?: RowClickHandler<any>;
  onSelect: ((value: number | string) => void) | undefined;
  primaryKey: string;
  sortable?: boolean;
};

export const Row: FC<Readonly<RowProps>> = ({
  selected,
  children,
  disabled,
  clickable,
  onSelect,
  row,
  primaryKey,
  onClick,
  sortable,
}) => {
  const handleClick = useCallback(
    (event: any) => {
      if (onClick && !window?.getSelection()?.toString()) {
        onClick(row, event);
      }
    },
    [onClick, row],
  );

  const handleSelect = useCallback(() => {
    if (typeof onSelect === "function") {
      const value = row[primaryKey];

      onSelect(value);
    }
  }, [onSelect, row, primaryKey]);

  const style = useMemo(
    () => ({
      display: "contents",
      cursor: clickable ? "pointer" : "auto",
      td: {
        opacity: disabled ? 0.6 : 1,
        bg: selected ? "gray.50" : "initial",
      },
      ":hover td": {
        bg: selected ? "forest.50" : clickable ? "gray.50" : undefined,
      },
    }),
    [selected, disabled],
  );

  return (
    <Box as="tr" sx={style} onClick={handleClick}>
      {onSelect && (
        <Cell onClick={stopPropagation}>
          <Checkbox isChecked={Boolean(selected)} isDisabled={disabled} onChange={handleSelect} />
        </Cell>
      )}
      {children}
      {sortable && <Cell />}
    </Box>
  );
};

const stopPropagation = (event) => {
  event.stopPropagation();
};
