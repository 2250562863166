import { forwardRef } from "react";

import pluralize from "pluralize";

import { CreatableSelect, CreatableSelectProps } from "./creatable-select";
import { Option } from "./select";

export type Props = CreatableSelectProps & {
  onChange: (options: Option[]) => void;
};

export const CreatableMultiSelect = forwardRef<HTMLSelectElement, Props>(
  ({ options = [], value, width, onChange, onCreateOption, placeholder, isError, isLoading, isDisabled, tip }, ref) => {
    const selectTip =
      tip ??
      `${
        options.length ? `${options.length} selectable ${pluralize("value", options.length)}. ` : ""
      }Type to create a new value.`;

    return (
      <CreatableSelect
        isMulti
        ref={ref}
        isLoading={isLoading}
        isDisabled={isLoading || isDisabled}
        formatCreateLabel={(label) => `Add ${label}`}
        isClearable={false}
        isError={isError}
        noOptionsMessage={() => "Type to create a new value..."}
        options={options}
        placeholder={placeholder || "Add multiple values..."}
        tip={selectTip}
        value={value}
        width={width}
        onChange={onChange}
        onCreateOption={onCreateOption}
      />
    );
  },
);

CreatableMultiSelect.displayName = "CreatableMultiSelect";
