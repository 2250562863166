import { FC, useEffect, useState } from "react";

import { Column, Row, Combobox, FormField, TextInput, useToast, Button } from "@hightouchio/ui";
import { captureException } from "@sentry/react";
import { useOutletContext } from "react-router-dom";

import { Explore } from "src/components/explore";
import { useUpdateModelMutation } from "src/graphql";
import { BottomBar } from "src/partner/bottom-bar";
import { OutletContext } from "src/partner/sync";
import { QueryType } from "src/types/models";
import { useModelRun, useQueryState, useUpdateQuery } from "src/utils/models";

export const Model: FC = () => {
  const { sync } = useOutletContext<OutletContext>();
  const { toast } = useToast();
  const [isSaving, setIsSaving] = useState(false);
  const [primaryKey, setPrimaryKey] = useState<string>(sync.segment?.primary_key ?? "");
  const [name, setName] = useState<string>(sync.segment?.name ?? "");
  const [hasQueryColumns, setHasQueryColumns] = useState(false);

  const { queryState, initQueryState, setSQL, setDBTModel, setLookerLook, setTable, setCustomQuery, setSigma, isQueryDefined } =
    useQueryState();

  const updateMutation = useUpdateModelMutation();
  const updateQuery = useUpdateQuery();

  const model = sync.segment!;
  const source = model?.connection;
  const type = model?.query_type as QueryType;

  const supportsResultSchema = source?.definition?.supportsResultSchema;

  const {
    runQuery,
    cancelQuery,
    resetRunState,
    loading: queryLoading,
    error: queryError,
    errorAtLine: queryErrorAtLine,
    rows,
    numRowsWithoutLimit,
    isResultTruncated,
    columns,
    asyncPagination,
    rowsCount,
    rawColumns,
    page,
    getSchema,
    setPage,
  } = useModelRun(type, undefined, {
    modelId: model?.id,
    variables: { sourceId: source?.id, ...queryState },
  });

  const reset = () => {
    setPrimaryKey(model?.primary_key ?? "");
    initQueryState(model);
  };

  const save = async () => {
    setIsSaving(true);
    let newColumns = rawColumns?.length ? rawColumns : model.columns;
    try {
      await updateMutation.mutateAsync({
        id: model?.id,
        input: {
          name,
          primary_key: primaryKey,
          query_type: type,
        },
      });
      if (supportsResultSchema && !hasQueryColumns) {
        const { data, error } = await getSchema();
        if (error) {
          toast({
            id: "query-schema-error",
            title: "Error previewing query schema",
            variant: "error",
          });
          captureException(error);
        } else if (data?.columns.length) {
          newColumns = data.columns;
        }
      }
      await updateQuery({ model, queryState, columns: newColumns as any, overwriteMetadata: true });
      toast({
        id: "update-model-query",
        title: "Model was updated",
        variant: "success",
      });
    } catch (error) {
      captureException(error);
      toast({
        id: "update-model-query",
        title: "Couldn't update model",
        variant: "error",
      });
    }
    setIsSaving(false);
  };

  useEffect(() => {
    reset();
  }, [model]);

  useEffect(() => {
    setHasQueryColumns(false);
  }, [queryState]);

  useEffect(() => {
    if (columns?.length) {
      setHasQueryColumns(true);
    }
  }, [rows, columns]);

  if (!model) {
    return null;
  }

  return (
    <>
      <Column p={4} flex={1}>
        <Row gap={4} mb={6} maxWidth="300px">
          <FormField label="Model name">
            <TextInput value={name} onChange={(e) => setName(e.target.value)} />
          </FormField>
          <FormField label="Primary key">
            <Combobox
              options={model.columns}
              onChange={(value) => {
                setPrimaryKey(value ?? "");
              }}
              value={primaryKey}
              optionLabel={(column) => column.alias ?? column.name}
              optionDescription={(column) => column.description ?? ""}
              optionValue={(column) => column.name}
            />
          </FormField>
        </Row>
        <Explore
          limit
          enableRowCounter
          isPlaceholder={false}
          asyncPagination={asyncPagination}
          cancelQuery={cancelQuery}
          columns={columns}
          error={queryError}
          errorAtLine={queryErrorAtLine}
          isQueryDefined={isQueryDefined}
          isResultTruncated={Boolean(isResultTruncated)}
          loading={queryLoading}
          numRowsWithoutLimit={numRowsWithoutLimit}
          page={page}
          reset={resetRunState}
          rows={rows}
          rowsCount={rowsCount}
          runQuery={runQuery}
          source={source}
          type={type}
          onPageChange={setPage}
          {...queryState}
          rowsPerPage={100}
          onCustomQueryChange={setCustomQuery}
          onDBTModelChange={setDBTModel}
          onLookerLookChange={setLookerLook}
          onSQLChange={setSQL}
          onSigmaChange={setSigma}
          onTableChange={setTable}
        />
      </Column>

      <BottomBar>
        <Button variant="primary" size="lg" onClick={save} isLoading={isSaving}>
          Save changes
        </Button>
      </BottomBar>
    </>
  );
};
