import {
  AverageTraitConfig,
  CountDedupedTraitConfig,
  CountTraitConfig,
  OrderDedupedTraitConfig,
  RawSqlTraitConfig,
  SumTraitConfig,
  TraitConfig,
  TraitType,
} from "@hightouch/lib/query/visual/types";

export enum CalculationMethod {
  Aggregation = "aggregation",
  Count = "count",
  Occurrence = "occurrence",
  Sql = "sql",
}

export const CALCULATION_METHODS = {
  [CalculationMethod.Aggregation]: {
    label: "Aggregation",
    value: CalculationMethod.Aggregation,
    description: "Calculate the sum or average",
    examples: ["Sum of all customer order values", "Average user session length"],
  },
  [CalculationMethod.Count]: {
    label: "Count",
    value: CalculationMethod.Count,
    description: "The number of occurrences",
    examples: ["Orders completed", "Page views", "User sessions"],
  },
  [CalculationMethod.Occurrence]: {
    label: "Occurrence",
    value: CalculationMethod.Occurrence,
    description: "Determine the first, last, most or least frequent",
    examples: ["First song listened to", "Last product viewed", "Most frequent product viewed", "Least frequent user login"],
  },
  [CalculationMethod.Sql]: {
    label: "SQL",
    value: CalculationMethod.Sql,
    description: "Create a custom query",
    examples: [
      "Building a JSON object",
      "Building a list",
      "Grabbing a specific field out of a JSON object",
      "Custom aggregation",
    ],
  },
};

// Returns true if validation passes
export const validateConfig = (type: TraitType, rawConfig: TraitConfig | undefined): boolean => {
  if (rawConfig == undefined) {
    return false;
  }

  switch (type) {
    case TraitType.Sum:
      return (rawConfig as SumTraitConfig).column != undefined;
    case TraitType.Average:
      return (rawConfig as AverageTraitConfig).column != undefined;
    case TraitType.Count:
      return (rawConfig as CountTraitConfig).column != undefined;
    case TraitType.MostFrequent:
    case TraitType.LeastFrequent: {
      return (rawConfig as CountDedupedTraitConfig).toSelect != undefined;
    }
    case TraitType.First:
    case TraitType.Last: {
      const config = rawConfig as OrderDedupedTraitConfig;
      return config.toSelect != undefined && config.orderBy != undefined;
    }
    case TraitType.RawSql: {
      const config = rawConfig as RawSqlTraitConfig;
      return config.aggregation != undefined && config.resultingType != undefined;
    }
    default:
      return false;
  }
};

export const TRAIT_TYPE_LABELS = {
  [TraitType.Count]: "Count",
  [TraitType.Sum]: "Sum",
  [TraitType.Average]: "Average",
  [TraitType.RawSql]: "Raw SQL",
  [TraitType.LeastFrequent]: "Least frequent",
  [TraitType.MostFrequent]: "Most frequent",
  [TraitType.First]: "First",
  [TraitType.Last]: "Last",
};
