import { BlockRule, MergeRule, Operator, Transformation } from "@hightouch/core/idr/types";
import { ColumnType } from "@hightouch/lib/query/visual/types";

import { IdentityResolutionGraphQuery } from "src/graphql";

export type IdentityGraph = NonNullable<IdentityResolutionGraphQuery["idr_by_pk"]>;

export enum IdrRunStatus {
  Cancelled = "CANCELLED",
  Cancelling = "CANCELLING",
  Failure = "FAILURE",
  Processing = "PROCESSING",
  Queued = "QUEUED",
  Success = "SUCCESS",
}

// TODO: centralize in backend?
export const defaultIdentifiers = [
  "Email",
  "Phone number",
  "First name",
  "Last name",
  "Full name",
  "Address",
  "Anonymous ID",
  "User ID",
  "Adobe ID",
  "Segment ID",
];

export const transformationOptions: Record<Transformation, { label: string; description?: string }> = {
  "case-insensitive": { label: "Case Insensitive" },
  "normalize-whitespace": { label: "Normalize", description: "Ignore whitespace" },
  "extract-number": { label: "Number", description: "Ignore non-numeric characters" },
};

export const transformationsByIdentifier: Record<string, Transformation[]> = {
  Email: ["case-insensitive", "normalize-whitespace"],
  Phone: ["extract-number"],
  Name: ["case-insensitive", "normalize-whitespace"],
  Address: ["case-insensitive", "normalize-whitespace"],
  "Anonymous ID": [],
  "Adobe ID": [],
  "Segment ID": [],
};

export const allTransformationOptions = Object.entries(transformationOptions).map(([value, option]) => ({
  value,
  ...option,
}));

export const transformationOptionsByIdentifier: Record<
  string,
  { value: Transformation; label: string; description?: string }[]
> = Object.fromEntries(
  Object.entries(transformationsByIdentifier).map(([identifier, transformations]) => [
    identifier,
    transformations.map((transformation) => ({
      value: transformation,
      ...transformationOptions[transformation],
    })),
  ]),
);

export const operatorOptions: { value: Operator; label: string }[] = [
  {
    value: "eq",
    label: "Exact",
  },
  {
    value: "fuzzy",
    label: "Fuzzy",
  },
  {
    value: "soundex",
    label: "Soundex",
  },
];

export type { BlockRule, MergeRule, Operator, Transformation };
export { ColumnType };
