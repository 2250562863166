import { FC, useState } from "react";

import {
  Link,
  Text,
  DeleteIcon,
  MenuActionsButton,
  MenuList,
  MenuItem,
  Menu,
  Row,
  Button,
  Column,
  EditableDescription,
  EditableHeading,
  PlayIcon,
  useToast,
  RefreshIcon,
} from "@hightouchio/ui";
import { Navigate, Outlet, Route, Routes, useLocation, useNavigate, useOutletContext, useParams } from "react-router-dom";

import { DetailBar } from "src/components/detail-bar";
import { IntegrationIcon } from "src/components/integrations/integration-icon";
import { DetailPage } from "src/components/layout";
import { DeleteConfirmationModal } from "src/components/modals/delete-confirmation-modal";
import { Warning } from "src/components/warning";
import {
  IdrSetInput,
  useDeleteIdentityResolutionGraphMutation,
  useIdentityResolutionGraphQuery,
  useUpdateIdentityResolutionGraphMutation,
  useRunIdentityResolutionMutation,
} from "src/graphql";
import { IdentityGraph } from "src/pages/identity-resolution/types";
import { PageSpinner } from "src/ui/loading";

import { Configuration } from "./configuration";
import { CreateIdentityResolutionModel } from "./create-model";
import { Models } from "./models";
import { Rules } from "./rules";
import { Runs } from "./runs";
import { Summary } from "./summary";

const tabs = [
  { title: "Summary", path: "summary" },
  { title: "Runs", path: "runs" },
  { title: "Models", path: "models" },
  { title: "Rules", path: "rules" },
  { title: "Configuration", path: "configuration" },
];

export type OutletContext = { graph: IdentityGraph; identifiers: string[]; tab: string };

export const IdentityResolutionGraph: FC = () => {
  return (
    <Routes>
      <Route element={<Loader />}>
        <Route element={<Layout />}>
          <Route path="/summary" element={<Summary />} />
          <Route path="/runs" element={<Runs />} />
          <Route path="/models/*" element={<Models />} />
          <Route path="/rules" element={<Rules />} />
          <Route path="/configuration" element={<Configuration />} />
          <Route index element={<Navigate to={{ pathname: "summary", search: location.search }} replace />} />
        </Route>
        <Route path="/models/new" element={<CreateIdentityResolutionModel />} />
      </Route>
    </Routes>
  );
};

const Loader: FC = () => {
  const { id } = useParams<{ id: string }>();
  const location = useLocation();
  const tab = location.pathname.split("/")[3];

  const { data: graph, isLoading } = useIdentityResolutionGraphQuery(
    { id: String(id) },
    { enabled: Boolean(id), select: (data) => data.idr_by_pk, refetchInterval: tab === "summary" ? 5000 : undefined },
  );

  if (isLoading) {
    return <PageSpinner />;
  }

  if (!id) {
    return null;
  }

  if (!graph) {
    return <Warning subtitle="It may have been deleted" title="Identity graph not found" />;
  }

  const identifiers = Array.from(
    new Set(graph.models.flatMap((model) => model.mappings.flatMap((mapping) => mapping.identifier))),
  );

  return <Outlet context={{ graph, identifiers, tab }} />;
};

const Layout: FC = () => {
  const { graph, identifiers, tab } = useOutletContext<OutletContext>();
  const { toast } = useToast();
  const navigate = useNavigate();
  const [isDeleting, setIsDeleting] = useState(false);
  const [name, setName] = useState(graph.name ?? "");

  const deleteMutation = useDeleteIdentityResolutionGraphMutation();
  const updateMutation = useUpdateIdentityResolutionGraphMutation();
  const runMutation = useRunIdentityResolutionMutation();

  const update = async (input: IdrSetInput) => {
    try {
      await updateMutation.mutateAsync({
        id: graph.id,
        input,
      });

      toast({
        id: "update-graph-name",
        title: `Identity graph was updated`,
        variant: "success",
      });
    } catch (error) {
      toast({
        id: "update-graph-name-error",
        title: `There was a problem updating your identity graph.`,
        variant: "error",
      });
    }
  };

  const startRun = async (fullReRun: boolean) => {
    try {
      await runMutation.mutateAsync({
        id: graph.id,
        fullReRun,
      });

      toast({
        id: "run",
        title: `Identity graph run has been initiated.`,
        variant: "success",
      });
    } catch (error) {
      toast({
        id: "run",
        title: `There was a problem running your identity graph.`,
        variant: "error",
      });
    }
  };

  return (
    <DetailPage
      title={`${graph.name} - Identity graph`}
      bg={tab === "rules" || tab === "models" ? "base.lightBackground" : "white"}
      crumbs={[{ label: "Identity resolution", link: "/idr" }, { label: graph.name }]}
      tabs={tabs}
      header={
        <Column minWidth={0} mb={4} gap={2}>
          <Row flex={1} justify="space-between" minWidth={0} pt={1}>
            <EditableHeading
              size="lg"
              value={name}
              onChange={setName}
              onSubmit={(name) => {
                update({ name });
              }}
            />
            <Row align="center" gap={4}>
              <Menu>
                <MenuActionsButton />

                <MenuList>
                  <MenuItem
                    icon={RefreshIcon}
                    onClick={() => {
                      startRun(true);
                    }}
                  >
                    Rerun from start
                  </MenuItem>
                  <MenuItem
                    icon={DeleteIcon}
                    variant="danger"
                    onClick={() => {
                      setIsDeleting(true);
                    }}
                  >
                    Delete
                  </MenuItem>
                </MenuList>
              </Menu>

              <Button
                icon={PlayIcon}
                onClick={() => {
                  startRun(false);
                }}
              >
                Run
              </Button>
            </Row>
          </Row>
          <Row>
            <EditableDescription value={graph.description ?? ""} onChange={(description) => update({ description })} />
          </Row>
          <DetailBar>
            <Row align="center" gap={2} flexShrink={0}>
              <IntegrationIcon src={graph.source.definition?.icon} name={graph.source.definition?.name ?? ""} />
              <Link href={`/sources/${graph.source.id}`}>
                <Text isTruncated fontWeight="medium" color="inherit">
                  {graph.source.name}
                </Text>
              </Link>
            </Row>
          </DetailBar>
        </Column>
      }
    >
      <Outlet context={{ graph, identifiers }} />

      <DeleteConfirmationModal
        isOpen={isDeleting}
        label="graph"
        onClose={() => {
          setIsDeleting(false);
        }}
        onDelete={async () => {
          await deleteMutation.mutateAsync({ id: graph.id });
          navigate("/idr");
        }}
      />
    </DetailPage>
  );
};
