import { FC, ReactNode, useState } from "react";

import { Box, Column, Button, Row, Heading, Tooltip, Text, CheckIcon } from "@hightouchio/ui";

import { PartnerLogo } from "src/partner/partner-logo";
import { Watermark } from "src/partner/watermark";

export type Step = {
  label: string;
  description: string;
  render: () => ReactNode;
  next?: ReactNode;
  tooltip?: string;
  isDisabled?: boolean;
  onNext?: () => Promise<unknown>;
};

type Props = {
  step: number;
  onStepChange: (step: number) => void;
  steps: Step[];
  onSubmit: () => Promise<unknown>;
  onCancel: () => void;
};

const maxWidth = 1200;

export const PartnerWizard: FC<Readonly<Props>> = ({ steps, step, onSubmit, onCancel, onStepChange }) => {
  const [isLoading, setIsLoading] = useState(false);
  const { render, next, label, tooltip, onNext, isDisabled } = steps[step]!;

  const isFirstStep = step === 0;
  const isLastStep = step === steps.length - 1;

  return (
    <Column width="100vw" height="100vh">
      <Watermark />
      <Row
        px={4}
        height="64px"
        align="center"
        justify="space-between"
        width="100%"
        borderBottom="1px"
        borderColor="base.border"
      >
        <PartnerLogo />
        <Button onClick={onCancel}>Exit</Button>
      </Row>
      <Column overflowY="auto" flex={1} pt={0} maxWidth="100%" bg="gray.50" sx={{ scrollbarGutter: "stable" }}>
        <Row maxW={maxWidth} width="100%" mx="auto" height="64px" pos="sticky" top={0} align="center" flexShrink={0}>
          <Heading>{label}</Heading>
        </Row>
        <Row maxW={maxWidth} mx="auto" width="100%" gap={[10, 10, 24]}>
          <Column pos="sticky" top="64px" gap={10} maxW="300px" height="max-content" flexShrink={0}>
            <Column as="ol" p={0} gap={1}>
              {steps.map((s, index) => (
                <Row gap={4} as="li" key={s.label}>
                  <Column>
                    {step > index ? (
                      <Row
                        flexShrink={0}
                        fontSize="lg"
                        bg="gray.200"
                        borderRadius="50%"
                        w="21px"
                        h="21px"
                        align="center"
                        justify="center"
                      >
                        <CheckIcon />
                      </Row>
                    ) : index > step ? (
                      <Row flexShrink={0} borderRadius="50%" w="21px" h="21px" border="1px" borderColor="gray.300" />
                    ) : (
                      <Row
                        flexShrink={0}
                        w="21px"
                        h="21px"
                        align="center"
                        justify="center"
                        border="2px"
                        borderColor="primary.base"
                        borderRadius="50%"
                      >
                        <Row flexShrink={0} w="9px" h="9px" borderRadius="50%" bg="text.primary" />
                      </Row>
                    )}
                    {index < steps.length - 1 && <Box mt="4px" w="1px" height="48px" ml="10px" bg="gray.300" />}
                  </Column>
                  <Column gap={1} display={["none", "none", "flex"]}>
                    <Text fontWeight="medium">{s.label}</Text>
                    <Text size="sm" color="text.secondary">
                      {s.description}
                    </Text>
                  </Column>
                </Row>
              ))}
            </Column>
          </Column>
          <Column flex={1} minW={0} p={10} pt={0}>
            {render()}
          </Column>
        </Row>
      </Column>
      <Row height="64px" width="100%" borderTop="1px" borderColor="base.border" justify="center" align="center">
        <Row maxW={maxWidth} width="100%" align="center" sx={{ scrollbarGutter: "stable" }}>
          {!isFirstStep && (
            <Button
              size="lg"
              onClick={() => {
                onStepChange(step - 1);
              }}
            >
              Back
            </Button>
          )}
          <Box ml="auto">
            {next ? (
              next
            ) : (
              <Tooltip isDisabled={!tooltip} message={tooltip ?? ""}>
                <Button
                  size="lg"
                  variant="primary"
                  isDisabled={isDisabled}
                  isLoading={isLoading}
                  onClick={async () => {
                    setIsLoading(true);
                    if (isLastStep) {
                      await onSubmit();
                    } else if (onNext) {
                      await onNext();
                    }
                    setIsLoading(false);
                  }}
                >
                  {isLastStep ? "Finish" : "Continue"}
                </Button>
              </Tooltip>
            )}
          </Box>
        </Row>
      </Row>
    </Column>
  );
};
