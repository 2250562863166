import { FC } from "react";

import { Column, useToast, Heading, Row, Text, Box } from "@hightouchio/ui";
import { yupResolver } from "@hookform/resolvers/yup";
import { captureException } from "@sentry/react";
import Helmet from "react-helmet";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";

import { ScheduleManager } from "src/components/schedule";
import {
  CronSchedule,
  IntervalSchedule,
  ManualSchedule,
  ScheduleType,
  VisualCronSchedule,
} from "src/components/schedule/types";
import { eventWarehouseDestinationDefinitions, eventSourceDefinitions } from "src/events/types";
import { useCreateEventWarehouseSyncMutation, useEventSourcesQuery, useEventWarehouseDestinationsQuery } from "src/graphql";
import { TrackView } from "src/lib/analytics";
import { Selectable } from "src/ui/selectable";
import { Wizard } from "src/ui/wizard";
import { Step } from "src/ui/wizard/wizard";
import { SlugResourceType, useResourceSlug } from "src/utils/slug";
import { useWizardStepper } from "src/utils/use-wizard-stepper";

type FormState = {
  schedule: ManualSchedule | IntervalSchedule | VisualCronSchedule | CronSchedule;
};

export const CreateEventWarehouseSync: FC = () => {
  const { toast } = useToast();
  const navigate = useNavigate();
  const [step, setStep] = useWizardStepper(0);
  const { getSlug } = useResourceSlug(SlugResourceType.EventWarehouseSyncs);

  const { data: destinations } = useEventWarehouseDestinationsQuery(undefined, {
    select: (data) => data.event_warehouse_destinations,
  });
  const { data: sources } = useEventSourcesQuery(undefined, { select: (data) => data.event_sources });

  const createMutation = useCreateEventWarehouseSyncMutation();

  const form = useForm<FormState>({
    defaultValues: {
      schedule: { type: ScheduleType.INTERVAL },
    },
    resolver: yupResolver(validationSchema),
  });

  const submit = async (data) => {
    const source = sources?.find((source) => source.id === data.event_source_id);
    const destination = destinations?.find((destination) => destination.id === data.event_warehouse_destination_id);
    try {
      const slug = await getSlug(`${source?.name}-${destination?.name}`);
      const response = await createMutation.mutateAsync({
        input: {
          slug,
          event_source_id: data.event_source_id,
          event_warehouse_destination_id: data.event_warehouse_destination_id,
          schedule: data.schedule,
        },
      });
      toast({
        id: "create-event-sync",
        title: "Event sync was created",
        variant: "success",
      });
      navigate(`/events/syncs/${response.createEventWarehouseSync.id}`);
    } catch (error) {
      captureException(error);
      toast({
        id: "create-event-sync",
        title: "Event sync could not be created",
        variant: "error",
      });
    }
  };

  const steps: Step[] = [
    {
      title: "Select a source",
      header: <Heading>Select a source</Heading>,
      continue: "Click a source to continue",
      render: () => (
        <Controller
          name="event_source_id"
          render={({ field }) => (
            <Box
              display="grid"
              gridTemplateColumns="repeat(auto-fit, minmax(200px, 400px))"
              gridAutoRows="64px"
              gap={4}
              maxWidth="800px"
            >
              {sources?.map((source) => {
                const definition = eventSourceDefinitions[source.type];
                return (
                  <Selectable
                    key={source.id}
                    selected={false}
                    onSelect={() => {
                      field.onChange(source.id);
                      setStep((step) => step + 1);
                    }}
                  >
                    <Row align="center" p={5} gap={4}>
                      <Box as="img" src={definition.icon} width="36px" />
                      <Text fontWeight="medium" size="lg">
                        {definition.name}
                      </Text>
                    </Row>
                  </Selectable>
                );
              })}
            </Box>
          )}
        />
      ),
    },
    {
      title: "Select a destination",
      header: <Heading>Select a destination</Heading>,
      continue: "Click a destination to continue",
      render: () => (
        <Controller
          name="event_warehouse_destination_id"
          render={({ field }) => (
            <Box
              display="grid"
              gridTemplateColumns="repeat(auto-fit, minmax(200px, 400px))"
              gridAutoRows="64px"
              gap={4}
              maxWidth="800px"
            >
              {destinations?.map((destination) => {
                const definition = eventWarehouseDestinationDefinitions[destination.type];
                return (
                  <Selectable
                    key={destination.id}
                    selected={false}
                    onSelect={() => {
                      field.onChange(destination.id);
                      setStep((step) => step + 1);
                    }}
                  >
                    <Row align="center" p={5} gap={4}>
                      <Box as="img" src={definition.icon} width="36px" />
                      <Text fontWeight="medium" size="lg">
                        {definition.name}
                      </Text>
                    </Row>
                  </Selectable>
                );
              })}
            </Box>
          )}
        />
      ),
    },
    {
      title: "Schedule",
      header: <Heading>Set your schedule</Heading>,
      render: () => {
        return (
          <Column gap={8} maxWidth="576px" width="100%">
            <Controller
              name="schedule"
              render={({ field }) => (
                <ScheduleManager
                  schedule={field.value}
                  setSchedule={field.onChange}
                  types={[ScheduleType.INTERVAL, ScheduleType.CUSTOM, ScheduleType.CRON]}
                />
              )}
            />
          </Column>
        );
      },
    },
  ];

  return (
    <>
      <Helmet>
        <title>New event sync</title>
      </Helmet>
      <TrackView name="New Event Sync Page" />
      <FormProvider {...form}>
        <Wizard
          setStep={setStep}
          step={step}
          steps={steps}
          title="New event sync"
          onCancel={() => {
            navigate("/events/syncs");
          }}
          onSubmit={form.handleSubmit(submit)}
        />
      </FormProvider>
    </>
  );
};

const validationSchema = yup.object().shape({
  schedule: yup.object().required(),
});
