import { FC, useState, useMemo } from "react";

import { Button, Row, Column, SectionHeading, Box, Link, Pill, Text } from "@hightouchio/ui";
import { useOutletContext } from "react-router-dom";

import { IntegrationIcon } from "src/components/integrations/integration-icon";
import { SyncSequenceRunsOrderBy, useSequenceRunsQuery } from "src/graphql";
import { Modal } from "src/ui/modal";
import { Pagination, Table, TableColumn, useTableConfig } from "src/ui/table";
import { SyncStatusBadge } from "src/utils/syncs";
import { formatDatetime } from "src/utils/time";

import { SequenceContext } from ".";
import { SequenceStatusBadge } from "../sequence-status-badge";

export const SequenceRuns: FC = () => {
  const {
    sequence: { id },
  } = useOutletContext<SequenceContext>();

  const [selectedRun, setSelectedRun] = useState<any>();

  const PAGE_SIZE = 10;

  const { limit, offset, page, setPage } = useTableConfig<SyncSequenceRunsOrderBy>({
    limit: PAGE_SIZE,
  });

  const { data, isLoading: loading } = useSequenceRunsQuery(
    { id, limit: limit + 1, offset },
    {
      select: (data) => data,
      refetchInterval: 5000,
      notifyOnChangeProps: "tracked",
      keepPreviousData: true,
    },
  );
  const count = data?.sync_sequence_runs_aggregate?.aggregate?.count ?? 0;
  const runs = useMemo(() => {
    if (data?.sync_sequence_runs?.length === PAGE_SIZE + 1) {
      return data.sync_sequence_runs?.slice(0, PAGE_SIZE);
    }
    return data?.sync_sequence_runs;
  }, [data?.sync_sequence_runs]);

  const columns: TableColumn[] = useMemo(
    () => [
      {
        name: "Status",
        cell: ({ status }) => <SequenceStatusBadge status={status} />,
      },
      {
        name: "Started",
        key: "created_at",
        cell: (timestamp) => formatDatetime(timestamp),
      },
      {
        name: "Sync runs",
        key: "requests",
        cell: (requests) => {
          if (requests?.length) {
            const completed = requests.reduce((prev, curr) => prev + (curr.status === "done" ? 1 : 0), 0);
            const failed = requests.reduce((prev, curr) => prev + (curr.status === "failed" ? 1 : 0), 0);
            const pending = requests.reduce((prev, curr) => prev + (curr.status === "pending" ? 1 : 0), 0);
            const running = requests.reduce((prev, curr) => prev + (curr.status === "running" ? 1 : 0), 0);
            const cancelled = requests.reduce((prev, curr) => prev + (curr.status === "cancelled" ? 1 : 0), 0);
            const entries: string[] = [];
            if (completed) entries.push(`${completed} completed`);
            if (failed) entries.push(`${failed} failed`);
            if (running) entries.push(`${running} running`);
            if (pending) entries.push(`${pending} pending`);
            if (cancelled) entries.push(`${cancelled} cancelled`);
            return entries.join(", ");
          }
          return "Pending...";
        },
      },
    ],
    [],
  );

  return (
    <>
      <SectionHeading>Sequence runs</SectionHeading>
      <Column>
        <Table
          columns={columns}
          data={runs}
          loading={loading}
          placeholder={{
            title: "No runs",
            body: "Set a schedule or manually run the sequence.",
            error: "Runs failed to load, please try again.",
          }}
          onRowClick={setSelectedRun}
        />

        <Row sx={{ justifyContent: "flex-end", width: "100%", mt: 4 }}>
          <Pagination count={count} label="runs" page={page} rowsPerPage={limit} setPage={setPage} />
        </Row>
      </Column>

      <Modal
        footer={<Button onClick={() => setSelectedRun(undefined)}>Close</Button>}
        isOpen={Boolean(selectedRun)}
        title="Sequence run"
        onClose={() => setSelectedRun(undefined)}
      >
        {selectedRun?.requests?.map(({ sync: { id: syncId, segment, destination }, sync_request, status }, index) => {
          return (
            <>
              {index !== 0 && <Box sx={{ ml: 7, width: "2px", height: "32px", bg: "base.2" }} />}
              <Box
                display="grid"
                key={id}
                gap={6}
                sx={{
                  p: 4,
                  bg: "white",
                  borderRadius: "md",
                  alignItems: "center",
                  gridTemplateColumns: "max-content 100px 1fr 1fr 1fr 80px",
                }}
              >
                <Pill>{index + 1}</Pill>
                {sync_request ? <SyncStatusBadge request={sync_request} /> : <SequenceStatusBadge status={status} />}
                <Text isTruncated>{segment.name}</Text>
                <Row align="center" gap={2} overflow="hidden">
                  <IntegrationIcon name={destination?.definition?.name} src={destination?.definition?.icon} />
                  <Text isTruncated>{destination?.name ?? "unknown"}</Text>
                </Row>
                {sync_request ? (
                  <>
                    <Link href={`/syncs/${syncId}/runs/${sync_request.id}`}>{formatDatetime(sync_request.finished_at)}</Link>
                  </>
                ) : (
                  <>
                    <Box />
                    <Box />
                  </>
                )}
              </Box>
            </>
          );
        })}
      </Modal>
    </>
  );
};
