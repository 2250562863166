import { FC } from "react";

import { Row, Column, Link, Text, Code as HightouchUICode, ClipboardButton } from "@hightouchio/ui";

import { Editor } from "src/components/editor";
import { SyncQuery } from "src/graphql";
import { SyncRequestErrorCode } from "src/types/sync-errors";
import * as SyncErrors from "src/types/sync-errors";
import { ErrorOriginInfoModal, InfoModal } from "src/ui/modal";
import { SyncStatus } from "src/utils/syncs";

type SyncRequestErrorModalProps<syncRequestError = SyncErrors.SyncRequestErrorInfo> = {
  isOpen: boolean;
  onClose: () => void;
  sync: SyncQuery["syncs"][0] | undefined;
  syncRequestError: syncRequestError | undefined;
  syncStatus: SyncStatus | undefined;
};

const oneOrMore = (singular: string, plural: string, c: number) => {
  return c > 1 ? plural : singular;
};

const maxWidth = "800px";

const NonUniquePrimaryKeyModal: FC<Readonly<SyncRequestErrorModalProps>> = ({ isOpen, onClose, syncRequestError }) => {
  const errInfo = syncRequestError as SyncErrors.NonUniquePrimaryKeyErrorInfo;
  return (
    <InfoModal isOpen={isOpen} title="Primary key is not unique" onClose={onClose} width={maxWidth}>
      <Column gap={2} overflow="hidden">
        <Text>
          Lightning Sync Engine requires that every row in your model have a{" "}
          <Link href={`${import.meta.env.VITE_DOCS_URL}/syncs/lightning-sync-engine#primary-key-is-not-unique`}>
            unique primary key value
          </Link>
          .
        </Text>
        <Text>Run the following query to identify the duplicate records:</Text>
        <Column overflow="hidden" border="1px" borderRadius="md" borderColor="base.border" bg="gray.50">
          <Row gap={4} justify="space-between" align="center" pl={4} pr={2} py={2} borderBottom="1px" borderColor="base.border">
            <Text size="lg" fontWeight="medium">
              SQL
            </Text>
            <ClipboardButton text={errInfo?.nonUniquePrimaryKeyInfo?.sqlToIdentifyDuplicateRows} />
          </Row>
          <Editor readOnly language="sql" value={errInfo?.nonUniquePrimaryKeyInfo?.sqlToIdentifyDuplicateRows} />
        </Column>
      </Column>
    </InfoModal>
  );
};

const UnsupportedPrimaryKeyModal: FC<Readonly<SyncRequestErrorModalProps>> = ({ isOpen, onClose, syncRequestError }) => {
  const errInfo = syncRequestError as SyncErrors.UnsupportedPrimaryKeyTypeErrorInfo;
  return (
    <InfoModal isOpen={isOpen} title="Type of your primary key is not supported" onClose={onClose} width={maxWidth}>
      <Column gap={2}>
        <Text>
          {`The primary key type ${errInfo.type} of your model is not supported for Lightning Sync Engine. Please cast your primary key in
            your sql. Supported types are:`}
        </Text>
        <Text>{`String variants: ${errInfo.supportedPrimaryKeyType.strings}`}</Text>
        <Text>{`Integer variants: ${errInfo.supportedPrimaryKeyType.ints}`}</Text>
        {errInfo.supportedPrimaryKeyType.floats && <Text>{`Float variants: ${errInfo.supportedPrimaryKeyType.floats}`}</Text>}
      </Column>
    </InfoModal>
  );
};

const PreviousSyncRunObjectMissingModal: FC<Readonly<SyncRequestErrorModalProps>> = ({ isOpen, onClose }) => {
  return (
    <InfoModal isOpen={isOpen} title="Full resync needed" onClose={onClose} width={maxWidth}>
      <Column gap={2}>
        <Text>
          {"Previous sync state required for diffing is missing." +
            " This is usually because your sync hasn't run in more than 30 days, so your sync data has been purged from our storage."}
        </Text>
        <Text>
          Trigger a <Link href={`${import.meta.env.VITE_DOCS_URL}/syncs/overview/#resync-full-query`}>full resync</Link> to
          restart the sync without diffing. This will cause the sync to re-run as if it were newly created.
        </Text>
        <Text>
          Check out <Link href={`${import.meta.env.VITE_DOCS_URL}/security/storage/#expiration`}>our docs</Link> for more
          information.
        </Text>
      </Column>
    </InfoModal>
  );
};

const RemoveRetryChangedColumnTypesModal: FC<Readonly<SyncRequestErrorModalProps>> = ({
  isOpen,
  onClose,
  syncRequestError,
}) => {
  const errInfo = syncRequestError as SyncErrors.RemoveRetryChangedColumnTypes;
  const changedColumns = Array.isArray(errInfo?.changedColumns) ? errInfo.changedColumns : [];
  const maxColumns = 10;
  return (
    <InfoModal isOpen={isOpen} title="Column types changed in model" onClose={onClose} width={maxWidth}>
      <Column gap={2}>
        <Text>
          Changing column types is not supported when there are removed rows that need to be retried. Try reverting your model
          and resolving any errors with removed rows before syncing your new query.
        </Text>
        {changedColumns.length > 0 && (
          <>
            <Text>{`The affected ${oneOrMore("column is", "columns are", changedColumns.length)}:`}</Text>
            <Column gap={1}>
              {changedColumns.slice(0, maxColumns).map((column) => {
                return <HightouchUICode key={column}>{column}</HightouchUICode>;
              })}
            </Column>
          </>
        )}
      </Column>
    </InfoModal>
  );
};

const SortRanOutOfDiskSpaceModal: FC<Readonly<SyncRequestErrorModalProps>> = ({ isOpen, onClose }) => {
  return (
    <InfoModal isOpen={isOpen} title="Sync too large" onClose={onClose} width={maxWidth}>
      <Text>
        Please enable <Link href={`${import.meta.env.VITE_DOCS_URL}/syncs/lightning-sync-engine`}>Lightning Sync Engine</Link>{" "}
        to ensure that this sync can run.
      </Text>
    </InfoModal>
  );
};

const WarehouseTableMissingModal: FC<Readonly<SyncRequestErrorModalProps>> = ({ isOpen, onClose, syncRequestError }) => {
  const errInfo = syncRequestError as SyncErrors.WarehouseTableMissing;
  const missingTables = Array.isArray(errInfo?.missingTables) ? errInfo.missingTables : [];
  return (
    <InfoModal isOpen={isOpen} title="Required tables missing" onClose={onClose}>
      <Column gap={2}>
        <Text>
          Tables required for{" "}
          <Link href={`${import.meta.env.VITE_DOCS_URL}/syncs/lightning-sync-engine`}>Lightning Sync Engine</Link> are not in
          your source.
        </Text>
        <Text>{`Missing the following ${oneOrMore("table", "tables", missingTables.length)}:`}</Text>
        <Column gap={1}>
          {missingTables.map((table) => {
            return <HightouchUICode key={table}>{table}</HightouchUICode>;
          })}
        </Column>
        <Text>
          Trigger a <Link href={`${import.meta.env.VITE_DOCS_URL}/syncs/overview/#resync-full-query`}>full resync</Link> to
          restart the sync without diffing. This will cause the sync to re-run as if it were newly created.
        </Text>
      </Column>
    </InfoModal>
  );
};

// Default modal to use when we don't need a custom UI.
// Just displays any user-facing error saved on the error object.
const DefaultModal: FC<Readonly<SyncRequestErrorModalProps>> = ({ isOpen, onClose, syncRequestError }) => {
  return (
    <InfoModal isOpen={isOpen} title="Run error" onClose={onClose} width={maxWidth}>
      <Text>
        {syncRequestError?.userFacingMessage ||
          syncRequestError?.message ||
          "We apologize for the inconvenience, but an unknown error has occurred. Please contact our customer support team for assistance."}
      </Text>
    </InfoModal>
  );
};

// Modal used when error origin info is provided.
const OriginInfoModal: FC<Readonly<SyncRequestErrorModalProps>> = ({ isOpen, onClose, sync, syncRequestError, syncStatus }) => {
  const originInfo = syncRequestError?.originInfo;
  if (originInfo) {
    return (
      <ErrorOriginInfoModal
        errorType="sync"
        isOpen={isOpen}
        onClose={onClose}
        originInfo={originInfo}
        sync={sync}
        syncRequestError={syncRequestError}
      />
    );
  }

  return <DefaultModal {...{ isOpen, onClose, sync, syncRequestError, syncStatus }} />;
};

export const SyncRequestErrorModal: FC<Readonly<SyncRequestErrorModalProps>> = ({
  isOpen,
  onClose,
  sync,
  syncRequestError,
  syncStatus,
}) => {
  if (!syncRequestError) {
    return <></>;
  }

  const defaultProps = { isOpen, onClose, sync, syncRequestError, syncStatus };

  let errorCode =
    syncRequestError.syncRequestErrorCode ||
    (syncStatus === SyncStatus.UNPROCESSABLE
      ? SyncRequestErrorCode.PREVIOUS_SYNC_RUN_OBJECT_MISSING
      : SyncRequestErrorCode.UNSPECIFIED);

  if (errorCode === SyncRequestErrorCode.UNSPECIFIED && syncRequestError.message) {
    try {
      const err: { type: string } = JSON.parse(syncRequestError.message);
      errorCode =
        err.type === "unmet_dependencies"
          ? SyncRequestErrorCode.PREVIOUS_SYNC_RUN_OBJECT_MISSING
          : SyncRequestErrorCode.UNSPECIFIED;
    } catch (err) {
      // message wasn't JSON
    }
  }

  switch (errorCode) {
    case SyncRequestErrorCode.NON_UNIQUE_PRIMARY_KEY:
      return <NonUniquePrimaryKeyModal {...defaultProps} />;
    case SyncRequestErrorCode.UNSUPPORTED_PRIMARY_KEY_TYPE:
      return <UnsupportedPrimaryKeyModal {...defaultProps} />;
    case SyncRequestErrorCode.PREVIOUS_SYNC_RUN_OBJECT_MISSING:
      return <PreviousSyncRunObjectMissingModal {...defaultProps} />;
    case SyncRequestErrorCode.REMOVE_PLAN_INCOMPLETE:
      return <DefaultModal {...defaultProps} />;
    case SyncRequestErrorCode.REMOVE_RETRY_CHANGED_COLUMN_TYPES:
      return <RemoveRetryChangedColumnTypesModal {...defaultProps} />;
    case SyncRequestErrorCode.SORT_RAN_OUT_OF_DISK_SPACE:
      return <SortRanOutOfDiskSpaceModal {...defaultProps} />;
    case SyncRequestErrorCode.WAREHOUSE_TABLE_MISSING:
      return <WarehouseTableMissingModal {...defaultProps} />;
    case SyncRequestErrorCode.UNSPECIFIED:
    default:
      if (syncRequestError.originInfo) {
        return <OriginInfoModal {...defaultProps} />;
      }
      return <DefaultModal {...defaultProps} />;
  }
};
