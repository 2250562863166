import { FC } from "react";

import { BoxProps, CheckIcon, Column, Row, Text } from "@hightouchio/ui";

import { DataTooltip } from "src/ui/data-tooltip";

import { EventColumn, FilterOption, MetadataIcons, MetadataType, RelationColumn } from "./constants";
import { IconBox } from "./icon-box";

type FilterPopoverOptionProps = {
  bg: string;
  columnType: FilterOption;
  description?: string | null;
  icon: JSX.Element;
  isFocused?: boolean;
  isSelected?: boolean;
  label: string;
  metadata?: { modelName: string; type: MetadataType } | null;
  onClick: () => void;
};

export const FilterPopoverOption: FC<Readonly<FilterPopoverOptionProps & BoxProps>> = ({
  bg,
  columnType,
  description,
  icon,
  isSelected = false,
  label,
  metadata,
  sx = {},
  ...props
}) => (
  <Row
    as="button"
    align="center"
    justify="space-between"
    role="option"
    _focus={{ boxShadow: "inset 0 0 0 4px #E0F3F5", borderRadius: "6px" }}
    _hover={{ bg: isSelected ? "forest.200" : "base.background" }}
    _active={{
      bg: isSelected ? "forest.300" : "gray.200",
      boxShadow: "none",
    }}
    bg={isSelected ? "primary.background" : "transparent"}
    border="1px solid"
    borderColor="transparent"
    borderRadius="5px"
    height={12}
    outline="none !important"
    transition="border-color 80ms ease-in"
    width="100%"
    sx={sx}
    {...props}
  >
    <DataTooltip
      description={description}
      gutter={12}
      label={label}
      placement="right"
      subtitle={
        metadata?.modelName ? (
          <Row
            as={Text}
            align="center"
            color="base.border"
            flexWrap="wrap"
            columnGap={1}
            size="sm"
            sx={{ svg: { height: "12px", width: "12px" } }}
          >
            {columnType === FilterOption.Trait ? "Based on" : "Merged from"}{" "}
            {metadata.type === MetadataType.Event ? EventColumn.icon : RelationColumn.icon} {metadata.modelName}
          </Row>
        ) : null
      }
      title={columnType}
    >
      <Row align="center" overflowX="hidden" width="100%" gap={4} p={2}>
        <Row align="center" gap={2} overflowX="hidden" width="100%">
          <IconBox bg={bg} icon={icon} boxSize={6} iconSize={4} />
          <Column justify="start" overflow="hidden" sx={{ span: { textAlign: "left" } }}>
            <Row gap={2}>
              <Text isTruncated color="text.primary">
                {label}
              </Text>

              {metadata?.modelName && (
                <Row align="center" maxWidth="150px" sx={{ "* > svg": { height: "12px", width: "12px", mr: 1 } }}>
                  <Text color="text.tertiary">{MetadataIcons[metadata.type]}</Text>
                  <Text isTruncated color="text.tertiary">
                    {metadata.modelName}
                  </Text>
                </Row>
              )}
            </Row>
            {description && (
              <Text isTruncated color="text.tertiary" size="sm">
                {description}
              </Text>
            )}
          </Column>
        </Row>
        <Row color="primary.pressed" fontSize="24px" width="24px">
          {isSelected && <CheckIcon />}
        </Row>
      </Row>
    </DataTooltip>
  </Row>
);
