import { FC, useEffect } from "react";

import { Column, Row, SectionHeading, FormField, TextInput, useToast, ButtonGroup, Button } from "@hightouchio/ui";
import { Controller, useForm } from "react-hook-form";
import { useOutletContext } from "react-router-dom";

import { ActionBar } from "src/components/action-bar";
import { SidebarForm } from "src/components/page";
import { useUpdateIdentityResolutionGraphMutation } from "src/graphql";

import { OutletContext } from ".";

export const Configuration: FC = () => {
  const { toast } = useToast();
  const { graph } = useOutletContext<OutletContext>();

  const {
    control,
    handleSubmit,
    reset,
    formState: { isDirty },
  } = useForm({
    defaultValues: {
      output_table: graph.output_table,
    },
  });

  const updateMutation = useUpdateIdentityResolutionGraphMutation();

  const update = async (data) => {
    try {
      await updateMutation.mutateAsync({
        id: graph.id,
        input: {
          output_table: data.output_table,
        },
      });
      toast({
        id: "update-success",
        title: "Configuration updated",
        variant: "success",
      });
    } catch (e) {
      toast({
        id: "update-error",
        title: "Error updating configuration",
        variant: "error",
      });
    }
  };

  useEffect(() => {
    reset({
      output_table: graph.output_table,
    });
  }, [graph]);

  return (
    <>
      <Row gap={10} justify="space-between">
        <Column gap={6}>
          <SectionHeading>Where should Hightouch send the output of resolved identities?</SectionHeading>
          <Controller
            name="output_table"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <FormField
                label="Table name"
                tip="The output will be a table in the hightouch_planner schema with this name."
                error={error?.message}
              >
                <TextInput {...field} placeholder="Enter a table name..." />
              </FormField>
            )}
          />
        </Column>
        <SidebarForm hideInviteTeammate name="identity resolution" docsUrl="" />
      </Row>
      <ActionBar>
        <ButtonGroup>
          <Button
            isDisabled={!isDirty}
            isLoading={updateMutation.isLoading}
            size="lg"
            variant="primary"
            onClick={handleSubmit(update)}
          >
            Save changes
          </Button>
          <Button isDisabled={!isDirty} size="lg" onClick={() => reset()}>
            Cancel
          </Button>
        </ButtonGroup>
      </ActionBar>
    </>
  );
};
