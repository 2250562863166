import { FC } from "react";

import { Row } from "@hightouchio/ui";

type IconBoxProps = {
  bg?: string;
  icon: JSX.Element;
  boxSize: number | string;
  iconSize: number | string;
};

export const IconBox: FC<Readonly<IconBoxProps>> = ({ bg, icon, boxSize, iconSize }) => (
  <Row
    align="center"
    justify="center"
    flexShrink={0}
    height={boxSize}
    width={boxSize}
    borderRadius="md"
    bg={bg}
    backgroundImage="linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.1) 100%)"
    sx={{ svg: { color: "white !important", height: iconSize, width: iconSize } }}
  >
    {icon}
  </Row>
);
