import { useCallback } from "react";

import slugify from "@sindresorhus/slugify";
import { customAlphabet } from "nanoid";
import { lowercase, numbers } from "nanoid-dictionary";
import { useQueryClient } from "react-query";

import { useGenerateResourceSlugQuery, SlugResourceType } from "src/graphql";

export { SlugResourceType };

const nanoid = customAlphabet(lowercase + numbers, 5);

export const generateSlug = (prefix: string) => slugify(`${prefix}-${nanoid()}`);

export const useResourceSlug = (type: SlugResourceType) => {
  const client = useQueryClient();

  const getSlug = useCallback(
    async (name: string) => {
      const variables = {
        resourceType: type,
        slug: slugify(name),
      };

      const response = await client.fetchQuery({
        queryFn: useGenerateResourceSlugQuery.fetcher(variables),
        queryKey: useGenerateResourceSlugQuery.getKey(variables),
        staleTime: 0,
        cacheTime: 0,
      });

      return response.generateResourceSlug;
    },
    [type, client],
  );

  return { getSlug };
};
